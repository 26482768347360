import * as React from 'react';
import Box from '@mui/material/Box';
import {
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterOperator,
  GridFilterInputDate,
} from '@mui/x-data-grid-pro';

const SUBMIT_FILTER_STROKE_TIME = 500;

function TimeBetweenOperatorComponent(props: GridFilterInputValueProps) {
  const { item, applyValue, focusElementRef = null } = props;

  const filterTimeout = React.useRef<any>();
  const [filterValueState, setFilterValueState] = React.useState<[any, any]>(item.value ?? '');
  const [applying, setIsApplying] = React.useState(false);

  React.useEffect(
    () => () => {
      clearTimeout(filterTimeout.current);
    },
    []
  );

  React.useEffect(() => {
    const itemValue = item.value ?? [undefined, undefined];
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (lowerBound: string | null, upperBound: string | null) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([lowerBound, upperBound]);

    setIsApplying(true);
    filterTimeout.current = setTimeout(() => {
      setIsApplying(false);
      applyValue({ ...item, value: [lowerBound, upperBound] });
    }, SUBMIT_FILTER_STROKE_TIME);
  };

  const handleUpperFilterChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void = (event) => {
    const newUpperBound = event.target.value;
    updateFilterValue(filterValueState[0], newUpperBound);
  };

  const handleLowerFilterChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void = (event) => {
    const newLowerBound = event.target.value;
    updateFilterValue(newLowerBound, filterValueState[1]);
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 36,
        pl: '10px',
        mt: 0.8,
      }}
    >
      <GridFilterInputDate
        {...props}
        label="From"
        type="datetime-local"
        value={filterValueState[0]}
        onChange={handleLowerFilterChange}
        inputRef={focusElementRef}
        sx={{ mr: 2, width: '!important' }}
      />

      <GridFilterInputDate
        {...props}
        label="To"
        type="datetime-local"
        value={filterValueState[1]}
        onChange={handleUpperFilterChange}
        inputRef={focusElementRef}
        sx={{ width: '!important' }}
      />
    </Box>
  );
}

export const getTimeStampBetweenOperator = () => {
  const timeStampBetweenOperator: GridFilterOperator = {
    label: 'between',
    value: 'between',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
        return null;
      }
      if (filterItem.value[0] == null || filterItem.value[1] == null) {
        return null;
      }
      return ({ value }): boolean =>
        value != null && filterItem.value[0] <= value && value <= filterItem.value[1];
    },
    InputComponent: TimeBetweenOperatorComponent,
    InputComponentProps: { type: 'dateTime' },
  };

  return { timeStampBetweenOperator };
};
