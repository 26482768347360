import { PoshmarkSettings } from '@beta.limited/primelister';

export interface IAutomationOption {
  name: keyof PoshmarkSettings;
  url: string;
  displayName: string;
  index: number;
  tooltipText: string;
}
export interface IAutomationOptionForm {
  automation: IAutomationOption;
}

export interface IDataForUpdateAutomationSettings {
  [key: string]: any;
  settings?: any;
  toastMessage: string;
  activeClosetAutomationPlatform: string;
  displayName: string;
  workerData?: IAutomationWorkerData;
  activeClosetCredentialsId: string;
}
export interface IAutomationWorkerData {
  activeClosetCredentialsId: string;
  marketplace: string;
  automation: string;
  status: boolean;
  toastMessage?: string;
  automationsSettings?: any;
}

export namespace WebViewMessage {
  export interface Data {
    type: Type;
    payload: unknown | InAppReviewTriggerPayload;
  }

  export interface InAppReviewTriggerPayload {
    reviewType: ReviewType;
  }

  export enum Type {
    IN_APP_REVIEW_TRIGGER = 'IN_APP_REVIEW_TRIGGER',
  }

  export enum ReviewType {
    IN_APP = 'InApp',
    STORE = 'Store',
  }
}
