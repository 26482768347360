import { Helmet } from 'react-helmet-async';
import cookie from 'react-cookies';
// @mui
import HelpIcon from '@mui/icons-material/Help';
import SchoolIcon from '@mui/icons-material/School';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import StepCheckListItem from 'src/components/checklist/StepCheckListItem';
import Iconify from 'src/components/iconify';
import { ReactNode, useEffect, useState } from 'react';
import DiscountDialog from './components/InformationDialog';
import axios from 'src/utils/axios';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'src/store';
import { setOpen, setAllCompleted, setIsCrispHidden } from 'src/store/dashboard/slices/homeSlice';
import { openCrispChat, showCrispChat } from 'src/utils/crispChatHelpers';

interface ExpectedStepsProps {
  text: string;
  step: number;
  checked?: boolean;
  helpLink?: string;
}

export default function HomePage() {
  const dispatch = useDispatch();
  const { allCompleted, isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);

  const [calculatedProgress, setCalculatedProgress] = useState<number>(0);
  const [expectedSteps, setExpectedSteps] = useState<Array<ExpectedStepsProps>>([]);
  const [completedSteps, setCompletedSteps] = useState<Array<number>>([]);

  const handleOpenDiscountDialog = () => {
    dispatch(setOpen(true));
  };

  const updateStep = async (step: number) => {
    const response = await axios.post('account/onboarding/set-step', { step: step });
    if (response.data.status) {
      const expectedArr = expectedSteps;
      const index = expectedArr.findIndex((expectedStep) => expectedStep.step === step);
      expectedArr[index].checked = true;
      setExpectedSteps([...expectedArr]);
      setCalculatedProgress(response.data.progress);
    }
  };

  useEffect(() => {
    const getOnboardingSteps = async () => {
      const response = await axios.get('account/onboarding/steps');
      setExpectedSteps(response.data.expectedSteps);
      setCompletedSteps(response.data.completedSteps);
      setCalculatedProgress(response.data.progress);
    };

    getOnboardingSteps();
  }, []);

  useEffect(() => {
    if (calculatedProgress === 100) {
      dispatch(setAllCompleted(true));
    } else {
      dispatch(setAllCompleted(false));
    }
  }, [calculatedProgress]);

  const addSkeletonToComponents = (component: any) => {
    if (expectedSteps.length > 0) {
      return component;
    } else {
      const skeletonItems = Array.from({ length: 7 }).map((_, index) => (
        <Skeleton key={index} variant="rounded" sx={{ width: '100%', height: '3.5rem', my: 1 }} />
      ));
      return <>{skeletonItems}</>;
    }
  };

  const renderStepList = () =>
    expectedSteps.map((expectedStep, index) => {
      const { text, checked, step, helpLink } = expectedStep;
      const isStepCompleted = completedSteps && completedSteps.includes(step);

      return (
        <ListItem key={index}>
          <StepCheckListItem
            label={text}
            checked={checked || (step !== undefined && isStepCompleted)}
            step={step}
            updateStep={updateStep}
          />
          {helpLink && (
            <LearnMoreLink learnMoreLink={helpLink} step={step} updateStep={updateStep} />
          )}
        </ListItem>
      );
    });

  return (
    <>
      <Helmet>
        <title> Dashboard | PrimeLister</title>
      </Helmet>

      <Container disableGutters maxWidth={'xl'}>
        <Typography gutterBottom variant="h4" sx={{ mb: '2.5rem' }}>
          Home
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader
                title={'Set up PrimeLister Account - take action!'}
                subheader={
                  <Typography variant={'subtitle1'} marginTop={0.5}>
                    Your account is {calculatedProgress}% set up
                  </Typography>
                }
                sx={{ borderBottom: '1px solid #f7f7f7', paddingBottom: 3 }}
              />
              <CardContent>
                <List>{addSkeletonToComponents(renderStepList())}</List>
              </CardContent>

              <CardActions
                sx={{
                  borderTop: '1px solid #f7f7f7',
                  paddingY: 3,
                  paddingLeft: 3,
                  opacity: allCompleted ? 1 : 0,
                  py: '25px',
                }}
              >
                <Button
                  onClick={handleOpenDiscountDialog}
                  variant={'contained'}
                  disabled={!allCompleted}
                >
                  Get 10% Discount Coupon
                </Button>
                <DiscountDialog />
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader
                title={'Knowledge Base'}
                subheader={
                  <Typography variant={'subtitle1'} marginTop={0.5}>
                    Learn how to use PrimeLister
                  </Typography>
                }
                sx={{ borderBottom: '1px solid #f7f7f7', paddingBottom: 3 }}
              />
              <CardContent>
                <List>
                  <ListItem>
                    <Button
                      href={
                        'https://chrome.google.com/webstore/detail/cross-listing-poshmark-to/eepbhjeldlodgnndnjofcnnoampciipg'
                      }
                      target={'_blank'}
                      rel={'noopener noreferrer'}
                      variant={'soft'}
                      color={'primary'}
                    >
                      <Iconify icon="ant-design:chrome-filled" width={32} sx={{ marginRight: 1 }} />
                      Install PrimeLister Extension
                    </Button>
                  </ListItem>
                  <ListItem>
                    <Link
                      href={
                        'https://docs.primelister.com/features/poshmark-automation-tool?utm_source=onboarding&utm_medium=onboarding'
                      }
                      target={'_blank'}
                      rel={'noopener noreferrer'}
                    >
                      How to use Poshmark Bot?
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      href={
                        'https://docs.primelister.com/features/cross-lister-tool/cross-list?utm_source=onboarding&utm_medium=onboarding'
                      }
                      target={'_blank'}
                      rel={'noopener noreferrer'}
                    >
                      How to crosspost a listing?
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      href={
                        'https://docs.primelister.com/getting-started/connect-marketplace-accounts?utm_source=onboarding&utm_medium=onboarding'
                      }
                      target={'_blank'}
                      rel={'noopener noreferrer'}
                    >
                      How to connect my marketplaces to PrimeLister account?
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      href={
                        'https://docs.primelister.com/features/cross-lister-tool/import?utm_source=onboarding&utm_medium=onboarding'
                      }
                      target={'_blank'}
                      rel={'noopener noreferrer'}
                    >
                      How to Import a listing?
                    </Link>
                  </ListItem>
                </List>
                <Divider />
                <List>
                  <ListItem>
                    <Typography variant={'subtitle1'} marginTop={2}>
                      Need more help?
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <LinkWithIcon
                      href={'https://docs.primelister.com/'}
                      icon={<SchoolIcon />}
                      label={'Knowledge Base'}
                      description={'Guides and answers to many popular questions'}
                    />
                  </ListItem>
                  <ListItem>
                    <ContactSupport />
                  </ListItem>
                  <ListItem>
                    <LinkWithIcon
                      href={'https://roadmap.primelister.com/b/feedback'}
                      icon={<TipsAndUpdatesIcon />}
                      label={'Leave Feedback & Suggest a Feature'}
                      description={'We would love to hear your feedback!'}
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

function LearnMoreLink({
  learnMoreLink,
  step,
  updateStep,
}: {
  learnMoreLink: string;
  step: number;
  updateStep: (step: number) => void;
}) {
  return (
    <Link
      href={learnMoreLink}
      target={'_blank'}
      rel={'noreferrer'}
      sx={{ textDecoration: 'none!important' }}
      onClick={() => (step === 4 ? updateStep(step) : null)}
    >
      <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
        <Typography variant={'body2'}>Learn more</Typography>
        <HelpIcon color={'action'} fontSize={'small'} />
      </Stack>
    </Link>
  );
}

interface LinkWithIconProps {
  href: string;
  icon: ReactNode;
  label: string;
  description: string;
}

function LinkWithIcon({ href, icon, label, description }: LinkWithIconProps) {
  return (
    <Link
      href={href}
      target={'_blank'}
      rel={'noreferrer'}
      sx={{ textDecoration: 'none!important' }}
    >
      <Stack direction={'row'} color={'black'}>
        {icon}
        <Typography variant={'body1'} marginLeft={1}>
          {label}
        </Typography>
      </Stack>
      <Typography variant={'body2'} color={'#9a9a9a'}>
        {description}
      </Typography>
    </Link>
  );
}

const ContactSupport = () => {
  const { isCrispHidden } = useSelector((state: IRootState) => state.home);
  const dispatch = useDispatch();
  const handleSupportClick = () => {
    if (isCrispHidden) {
      showCrispChat();
      dispatch(setIsCrispHidden(false));
    }
    openCrispChat();
  };
  return (
    <Stack onClick={handleSupportClick} sx={{ cursor: 'pointer' }}>
      <Stack direction={'row'} color={'black'}>
        <SupportAgentIcon />
        <Typography variant={'body1'} marginLeft={1}>
          Contact Support
        </Typography>
      </Stack>
      <Typography variant={'body2'} color={'#9a9a9a'}>
        Do you need help? Contact to our support team.
      </Typography>
    </Stack>
  );
};
