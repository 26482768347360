import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Box, Divider, Typography, Stack, MenuItem, Tooltip, useMediaQuery } from '@mui/material';
// routes
import { PATH_AUTOMATIONS, PATH_DASHBOARD } from '../routes/paths';

import MenuPopover from '../components/menu-popover';

import Iconify from 'src/components/iconify';
import { IconButtonAnimate } from 'src/components/animate';
import { IRootState } from 'src/store';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setIsCrispHidden, setIsCrispOpen } from 'src/store/dashboard/slices/homeSlice';
import { closeCrispChat, hideCrispChat } from 'src/utils/crispChatHelpers';
// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Cross-listing Tool',
    linkTo: PATH_DASHBOARD.root,
    iconActive: '/app-icons/crosslistBlue.svg',
    iconInactive: '/app-icons/crosslistGray.svg',
  },
  {
    label: 'Poshmark Automation Tool',
    linkTo: PATH_AUTOMATIONS.root,
    iconActive: '/app-icons/poshmarkBlue.svg',
    iconInactive: '/app-icons/poshmarkGray.svg',
  },
];

// ----------------------------------------------------------------------

export default function SwitchToolsPopover() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isOnBoardingActive } = useSelector((state: IRootState) => state.automations);
  const { shouldOpenSwitchPopoverForBoarding } = useSelector((state: IRootState) => state.home);
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const mobileMatches = useMediaQuery('(min-width:400px)');
  const switchButton = useRef(null);
  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    !isOnBoardingActive && setOpenPopover(event.currentTarget);
  };
  const dispatch = useDispatch();

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleClickItem = (path: string) => {
    handleClosePopover();
    hideCrispChat();
    closeCrispChat();
    dispatch(setIsCrispHidden(false));
    dispatch(setIsCrispOpen(false));
    navigate(path);
  };

  const handleItemMouseEnter = (index: number) => {
    setHoveredItem(index);
  };

  const handleItemMouseLeave = () => {
    setHoveredItem(null);
  };

  useEffect(() => {
    if (shouldOpenSwitchPopoverForBoarding) {
      setOpenPopover(switchButton.current);
    } else {
      setOpenPopover(null);
    }
  }, [shouldOpenSwitchPopoverForBoarding]);

  return (
    <Box
      mr={mobileMatches ? '18px' : '4px'}
      onClick={(e) => e.preventDefault()}
      sx={{ cursor: 'pointer' }}
      className="switch-driver"
    >
      <Tooltip title="Switch to..." arrow placement="bottom">
        <IconButtonAnimate
          ref={switchButton}
          onClick={handleOpenPopover}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '40px',
            width: '40px',
            height: '40px',
            bgcolor: openPopover ? 'rgba(145, 158, 171, 0.16)' : 'white',
            color: openPopover ? 'rgba(32, 101, 209, 1)' : 'rgba(177, 185, 192, 1)',
            '&:hover': {
              bgcolor: 'rgba(145, 158, 171, 0.16)',
              color: 'rgba(32, 101, 209, 1)',
            },
          }}
        >
          <Iconify icon="heroicons:squares-2x2-20-solid" />
        </IconButtonAnimate>
      </Tooltip>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        disabledArrow
        sx={{ p: '0px !important' }}
      >
        <Stack className="switch-menu-driver">
          <Typography
            sx={{
              color: 'black',
              fontSize: '14px !important',
              fontWeight: '600!important',
              lineHeight: '22px !important',
              mb: '8px',
              mt: '16px',
              ml: '8px',
            }}
          >
            Switch to
          </Typography>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Stack sx={{ ml: '8px', mr: '8px', mb: '8px', mt: '8px' }}>
            {OPTIONS.map((option, index) => (
              <MenuItem
                key={option.label}
                onClick={() => handleClickItem(option.linkTo)}
                sx={{ p: '8px', mt: '8px' }}
                onMouseEnter={() => handleItemMouseEnter(index)}
                onMouseLeave={handleItemMouseLeave}
              >
                <img
                  src={hoveredItem === index ? option.iconActive : option.iconInactive}
                  style={{ width: '28px', height: '28px', marginRight: '16px' }}
                  alt={option.label}
                />{' '}
                {option.label}
              </MenuItem>
            ))}
          </Stack>
        </Stack>
      </MenuPopover>
    </Box>
  );
}
