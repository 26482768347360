import { useMemo } from 'react';
import UAParser from 'ua-parser-js';

type GetUAData<T = unknown> = (parser: UAParser) => T;

function createUseUA<T>(getUAData: GetUAData<T>) {
  return (uaString = window.navigator.userAgent): UAParser =>
    useMemo(() => {
      let ua;
      try {
        ua = new UAParser(uaString);
      } catch (err) {
        ua = new UAParser();
      }
      return ua;
    }, [uaString]);
}

export const useUA = createUseUA((parser) => ({
  os: parser.getOS(),
  browser: parser.getBrowser(),
  cpu: parser.getCPU(),
  device: parser.getDevice(),
  engine: parser.getEngine(),
}));

// Hook for getting Browser details
export function useBrowser(userAgent = navigator.userAgent) {
  const ua = useUA(userAgent);
  return useMemo(() => ua.getBrowser(), [ua]);
}

// Hook for getting OS details
export function useOS(userAgent = navigator.userAgent) {
  const ua = useUA(userAgent);
  return useMemo(() => ua.getOS(), [ua]);
}

// Hook for getting Device details
export function useDevice(userAgent = navigator.userAgent) {
  const ua = useUA(userAgent);
  return useMemo(() => ua.getDevice(), [ua]);
}

// Hook for getting Engine details
export function useEngine(userAgent = navigator.userAgent) {
  const ua = useUA(userAgent);
  return useMemo(() => ua.getEngine(), [ua]);
}

export const isMobileDevice = (details: UAParser.IResult['device'] | null): boolean =>
  details?.type === 'mobile';

export const isTouchDevice = (details: UAParser.IResult['device'] | null): boolean =>
  ['mobile', 'tablet', 'wearable'].includes(details?.type || '');
