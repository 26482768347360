import { Dispatch } from '@reduxjs/toolkit';
import { CRISP_CLOSE_ICON } from 'src/config';
import { setIsCrispHidden, setIsCrispOpen } from 'src/store/dashboard/slices/homeSlice';

const checkAndAddCloseIcon = (dispatch: Dispatch) => {
  let nodeInterval = setInterval(function () {
    // Select the node
    const targetNode = document.querySelector('#crisp-chatbox > div > a');
    const closeIconTargetNode = document.querySelector('#crisp-chatbox-close-icon');

    if (targetNode && !closeIconTargetNode) {
      clearInterval(nodeInterval);

      const closeIconDiv = document.createElement('div');
      closeIconDiv.id = 'crisp-chatbox-close-icon';

      closeIconDiv.innerHTML = CRISP_CLOSE_ICON;

      closeIconDiv.addEventListener('click', (e) => {
        if (e.currentTarget === closeIconDiv) {
          e.preventDefault();
          e.stopImmediatePropagation();
          closeCrispChat();
          hideCrispChat();
          dispatch(setIsCrispHidden(true));
          dispatch(setIsCrispOpen(false));
        }
      });

      targetNode.insertAdjacentElement('beforeend', closeIconDiv);
    }
  }, 500);
};

export const loadCrispChat = (dispatch: Dispatch, userId?: string) => {
  // Reset Crisp variables
  window.$crisp = [];
  window.CRISP_WEBSITE_ID = import.meta.env.VITE_CRISP_WEBSITE_ID as string;
  window.CRISP_RUNTIME_CONFIG = {
    session_merge: true
  };
  // Set CRISP_TOKEN_ID if userId exists
  if (userId) {
    window.CRISP_TOKEN_ID = userId;
  }

  // Initialize Crisp
  (function () {
    var d = document;
    var s = d.createElement('script');
    s.src = 'https://client.crisp.chat/l.js';
    s.async = true;
    d.getElementsByTagName('head')[0].appendChild(s);
  })();

  checkAndAddCloseIcon(dispatch);
};

export const initializeCrispChat = (
  email: string,
  fullName: string,
  proStatus: string,
  showCrispChatInitially: boolean,
  dispatch: Dispatch
) => {
  if (showCrispChatInitially) {
    showCrispChat();
  }

  // Set user email, fullName, and pro status for Crisp chat
  window.$crisp.push(['set', 'user:email', [`${email}`]]);
  window.$crisp.push(['set', 'user:nickname', [`${fullName}`]]);
  window.$crisp.push(['set', 'session:data', ['proStatus', `${proStatus}`]]);
  window.$crisp.push(['safe', true]);
  checkAndAddCloseIcon(dispatch);

  // Check for requested support cookie and open chat if found
  const requestedSupport = window.location.hash.includes('support');
  if (requestedSupport) {
    openCrispChat();
  }
};

export const resetCrispChatSession = () => {
  window.$crisp.push(['do', 'session:reset']);
  hideCrispChat();
};

export const hideCrispChat = () => {
  window.$crisp.push(['do', 'chat:hide']);
};

export const showCrispChat = () => {
  window.$crisp.push(['do', 'chat:show']);
};

export const openCrispChat = () => {
  window.$crisp.push(['do', 'chat:open']);
};

export const closeCrispChat = () => {
  window.$crisp.push(['do', 'chat:close']);
};
