import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//form
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

// @mui
import { Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import { authResetPasswordRequest, resetAuthError } from 'src/store/dashboard/slices/authSlice';
import { IRootState } from 'src/store';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
};

export default function AuthForgotPasswordForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { error, loading } = useSelector((state: IRootState) => state.auth);

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: { email: '' },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      dispatch(
        authResetPasswordRequest({
          payload: { email: data.email },
          navigate,
        })
      );
    } catch (err) {}
  };

  useEffect(() => {
    dispatch(resetAuthError());
  }, []);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {error?.length > 0 && (
        <Alert
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'flex-start',
            mb: 3.2,
          }}
          severity="error"
          variant="standard"
        >
          {error}
        </Alert>
      )}
      <RHFTextField name="email" label="Email address" />
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
        sx={{ mt: 3 }}
      >
        Send Reset Link
      </LoadingButton>
    </FormProvider>
  );
}