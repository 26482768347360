import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import {
  setIsUpgradeConfirmationDialogOpen,
  setStripeReferrerUrl,
  updateSubscriptionRequest,
} from 'src/store/dashboard/slices/pricingSlice';
import { IRootState } from 'src/store';

const UpgradeConfirmationDialog = () => {
  const dispatch = useDispatch();

  const { isUpgradeConfirmationDialogOpen, sliderValue, stripeReferrerUrl, stripePlanIdForUpdate } =
    useSelector((state: IRootState) => state.pricing);
  const { poshmarkShopCapacity, billingSubscriptionList } = useSelector(
    (state: IRootState) => state.account
  );

  const handleClose = () =>
    dispatch(setIsUpgradeConfirmationDialogOpen({ openDialog: false, stripePlanId: '' }));

  const handleUpgrade = () => {
    const stripeReferrerUrlControlled = stripeReferrerUrl
      ? stripeReferrerUrl
      : window.location.host + '/automations/user#gbilling';
    dispatch(
      updateSubscriptionRequest({
        returnUrl: stripeReferrerUrlControlled,
        flowType: 'subscription_update_confirm',
        subscriptionId: billingSubscriptionList[1].subscriptionId,
        subscriptionItemId: billingSubscriptionList[1].subscriptionItemId,
        planId: stripePlanIdForUpdate,
        shopCapacity: sliderValue,
      })
    );
  };

  const confirmationMessage = [
    `Your current subscription allows up to ${poshmarkShopCapacity} closet connections only. By upgrading your subscription, you will switch to a plan that allows up to ${sliderValue} closet connections.`,
    ` Please note that these changes will take effect immediately.`,
    ` Are you sure you want to proceed with upgrading your subscription?`,
  ];

  return (
    <Dialog fullWidth maxWidth="sm" open={isUpgradeConfirmationDialogOpen} onClose={handleClose}>
      <DialogTitle sx={{ pb: 3 }}>Upgrade Your Subscription: Confirmation Required</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>
        {' '}
        {confirmationMessage.map((message, index) => (
          <Typography
            sx={{
              ...(index < 2 && { pb: 3 }),
              fontSize: '16px',
              fontWeight: '400',
              color: '#637381',
            }}
            key={index}
          >
            {message}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleUpgrade}>
          Upgrade My Subscription
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default UpgradeConfirmationDialog;
