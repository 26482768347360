import { useDispatch, useSelector } from 'react-redux';

// @mui
import {
  Box,
  Grid,
  Card,
  Stack,
  Typography,
  TextField,
  Autocomplete,
  Button,
  Divider,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// form
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { getTimezones } from '../../../helpers/getTimeZones';

//redux
import { IRootState } from 'src/store';
import { accountUpdateRequest } from 'src/store/dashboard/slices/accountSlice';

// components
import DeactivateButton from './DeactivateButton';

import { useMemo } from 'react';
import { authLogout } from 'src/store/dashboard/slices/authSlice';
import { useNavigate } from 'react-router';
import { useTheme } from '@mui/material/styles';
import useResponsive from 'src/hooks/useResponsive';
import cookie from 'react-cookies';
//-------------------------------------------

const AccountDetailForm = () => {
  const dispatch = useDispatch();
  const { userAccountDetail, loading } = useSelector((state: IRootState) => state.account);
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileWeb = useResponsive('down', 'sm');

  const isMobile = isPrimeListerMobileApp || isMobileWeb;

  const formValidation = Yup.object({
    name: Yup.string()
      .required('Full Name is required')
      .matches(/^[a-zA-Z\u00C0-\u017F\s]+$/, 'Invalid characters for Full Name'),
    timeZone: Yup.string().required('Time Zone is required').nullable(),
  });

  const handleLogout = async () => {
    cookie.remove('IS_ADMIN_LOGIN');
    dispatch(authLogout({ navigate }));
  };

  const handleAccountFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: userAccountDetail.name,
      email: userAccountDetail.email,
      timeZone: userAccountDetail.timeZone?.name,
    },
    validationSchema: formValidation,
    onSubmit: (values) => {
      const updated = {
        name: values.name,
        timeZone: timeZones?.find((item) => item.name === values.timeZone),
      };
      dispatch(accountUpdateRequest(updated));
    },
  });
  //Button disable conditions
  const firstValidation =
    handleAccountFormik.values.name === userAccountDetail.name &&
    handleAccountFormik.values.timeZone === userAccountDetail.timeZone?.name;

  const secondValidation =
    handleAccountFormik.values.name === '' || handleAccountFormik.values.timeZone === '';

  const thirdValidation = handleAccountFormik.errors.name || handleAccountFormik.errors.timeZone;

  const isFormValidated = firstValidation || secondValidation || thirdValidation ? false : true;

  //Time zone data, to not fetch data on every time zone option change
  const timeZones = useMemo(() => {
    const timeZones = getTimezones();
    return timeZones;
  }, []);

  return (
    <form onSubmit={handleAccountFormik.handleSubmit}>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box rowGap={3} columnGap={2} display="grid">
              <TextField
                label="Full Name"
                type="text"
                name="name"
                value={handleAccountFormik.values.name}
                onChange={handleAccountFormik.handleChange}
                onBlur={handleAccountFormik.handleBlur}
              />

              <Typography
                variant="caption"
                color="error"
                style={{
                  fontWeight: '600',
                  marginLeft: '0.3rem',
                  marginTop: '-1.3rem',
                  opacity: `${handleAccountFormik.errors.name ? 1 : 0}`,
                }}
              >
                *{handleAccountFormik?.errors?.name && handleAccountFormik?.errors?.name}
              </Typography>

              <TextField
                name="email"
                label="Email Address"
                value={handleAccountFormik.values.email}
                onChange={handleAccountFormik.handleChange}
                onBlur={handleAccountFormik.handleBlur}
                disabled
                sx={{ marginTop: '-1.3rem' }}
              />
              <Autocomplete
                options={timeZones.map((item) => item.name)}
                getOptionLabel={(option) =>
                  option.replace(/_/g, ' ') +
                  '(GMT ' +
                  timeZones.find((item) => item.name === option)?.gmt +
                  ')'
                }
                value={handleAccountFormik.values.timeZone}
                onChange={(event, newValue) => {
                  handleAccountFormik.setFieldValue('timeZone', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Time Zone"
                    name="timeZone"
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
              <Typography
                variant="caption"
                color="error"
                style={{
                  fontWeight: '600',
                  marginLeft: '0.3rem',
                  marginTop: '-1.3rem',
                  opacity: `${handleAccountFormik.errors.timeZone ? 1 : 0}`,
                }}
              >
                <>*{handleAccountFormik?.errors?.timeZone}</>
              </Typography>
            </Box>

            <Stack
              sx={{
                mt: 0,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                  alignItems: 'initial',
                  gap: '24px',
                },
              }}
            >
              <LoadingButton
                loading={loading}
                type="submit"
                variant="contained"
                disabled={!isFormValidated}
              >
                Save Changes
              </LoadingButton>
              {isMobile && (
                <Button variant="contained" type="button" color="error" onClick={handleLogout}>
                  Logout
                </Button>
              )}
              <Divider />
              <DeactivateButton />
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default AccountDetailForm;
