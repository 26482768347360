// import { useFlags } from 'flagsmith/react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useSearchParams } from 'react-router-dom';
import { IRootState } from 'src/store';
import {
  getAllConnectedApiShopsRequest,
  ebayCreateCredentialRequest,
  shopifyCreateCredentialRequest,
} from 'src/store/dashboard/slices/myShopsAPITableSlice';
import MyShopsPageNewVersion from './MyShopsPageNewVersion';

function MyShopsPage() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLayoutRequestSent } = useSelector((state: IRootState) => state.myShopsAPITable);

  const removeQueryParameters = () => {
    // Getting the current URL
    const currentUrl = window.location.href;
    // Removing everything after the question mark including the question mark
    const baseUrl = currentUrl.split('?')[0];
    // Using history.pushState to change the URL without reloading the page
    window.history.pushState({}, '', baseUrl);
  };

  useEffect(() => {
    // TODO: In future refactor this part to a common place
    const code = searchParams.get('code');
    const hmac = searchParams.get('hmac');
    const shop = searchParams.get('shop');
    const timestamp = searchParams.get('timestamp');
    const platformState = searchParams.get('state');
    const isEbay = platformState && platformState.toLowerCase().includes('ebay');

    if (isEbay && code) {
      //--Ebay
      dispatch(ebayCreateCredentialRequest({ code: decodeURIComponent(code) }));
      removeQueryParameters();
    } else {
      //--Shopify
      if (code && shop) {
        dispatch(shopifyCreateCredentialRequest({ shop, code }));
      }
    }

    if (isLayoutRequestSent) {
      dispatch(getAllConnectedApiShopsRequest());
    }
  }, []);

  return <MyShopsPageNewVersion />;
}

export default MyShopsPage;
