import { Card, Container, Stack, Typography, useMediaQuery } from '@mui/material';
import { Helmet } from 'react-helmet-async';

import { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { IRootState } from 'src/store';
import { setStartExtensionAnimationStart } from 'src/store/dashboard/slices/homeSlice';
import usePlatformChecker from 'src/hooks/usePlatformChecker';
import { setAllPlatformsInitialLoading } from 'src/store/dashboard/slices/myShopsSlice';
import BrowserTable from './component/MyShopsBrowserTable/BrowserTable';
import APITable from './component/MyShopsAPITable/APITable';
import useOnboarding from 'src/utils/onBoardingSteps/useOnboarding';
import { UserTagTypesEnum } from 'src/store/automations/slices/automationsSlice';
import { useSearchParams } from 'react-router-dom';
import { MyShopsOnboardingEnum } from '../Inventory/components/dialogs/inventory/apiDialogs/InventoryAPIConnectionRequiredDialog';
import useIsDocumentLoaded from 'src/hooks/useIsDocumentLoaded';

function MyShopsPageNewVersion() {
  const dispatch = useDispatch();
  const { isAllPlatformsLoading } = useSelector((state: IRootState) => state.myShops);
  const { checkAllPlatformsConnection } = usePlatformChecker();
  const matches = useMediaQuery('(min-width:1160px)');
  const tableHeadTitleMatches = useMediaQuery('(min-width:640px)');
  const [searchParams] = useSearchParams();
  const [loadingTimeout, setLoadingTimeout] = useState<NodeJS.Timeout | null>(null);

  const onboardingType = searchParams.get('startOnboarding');
  const onboardingPlatform = searchParams.get('onboarding-platform');
  const isDocumentLoaded = useIsDocumentLoaded();

  const handleRefreshBrowserButton = () => {
    if (window.extension.isConnected) {
      dispatch(setAllPlatformsInitialLoading(true));
      checkAllPlatformsConnection();
      const timeout = setTimeout(() => {
        dispatch(setAllPlatformsInitialLoading(false));
      }, 30000);
      setLoadingTimeout(timeout);
    } else {
      dispatch(setStartExtensionAnimationStart(true));
      setTimeout(() => {
        dispatch(setStartExtensionAnimationStart(false));
      }, 500);
    }
  };

  useEffect(
    () => () => {
      if (loadingTimeout) {
        clearTimeout(loadingTimeout);
      }
    },
    [loadingTimeout]
  );

  const handleAPIRefreshButton = () => {};

  const { driverObject } = useOnboarding(UserTagTypesEnum.MY_SHOPS_API_ONBOARDING);
  // Handle query parameters here when this page is loaded
  useEffect(() => {
    if (onboardingType === MyShopsOnboardingEnum.API_CONNECTION && onboardingPlatform) {
      driverObject.drive();
    }
  }, [isDocumentLoaded, onboardingType, onboardingPlatform]);

  return (
    <>
      <Helmet>
        <title>My Shops | PrimeLister</title>
      </Helmet>
      <Container maxWidth={matches ? 'xl' : 'md'} disableGutters={true}>
        <Typography variant="h4" sx={{ marginBottom: '2.25rem' }}>
          My Shops
        </Typography>
        <Stack
          sx={{
            display: 'grid',
            gridTemplateColumns: matches ? 'repeat(2, 1.2fr)' : 'repeat(1,1fr)',
            gridTemplateRows: 'repeat(1,1fr)',
            gridGap: '16px 24px',
            flexWrap: 'wrap',
          }}
        >
          <Stack>
            <Stack
              direction={tableHeadTitleMatches ? 'row' : 'column'}
              spacing={2}
              sx={{
                display: 'flex',
                justifyContent: tableHeadTitleMatches ? 'space-between' : 'space-evenly',
                marginBottom: '2.25rem',
                alignItems: tableHeadTitleMatches ? 'center' : 'flex-start',
              }}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: '700',
                  lineHeight: '28px',
                }}
              >
                Browser Login Status for My Shops
              </Typography>
              <LoadingButton
                loading={isAllPlatformsLoading}
                variant="contained"
                color="primary"
                onClick={handleRefreshBrowserButton}
              >
                <Iconify icon="material-symbols:sync" sx={{ mr: 0.5, fontSize: '1rem' }} />
                Connect All
              </LoadingButton>
            </Stack>
            <Card sx={{ maxWidth: matches ? '754px' : '100%' }}>
              <BrowserTable />
            </Card>
          </Stack>
          <Stack>
            <Stack
              direction={tableHeadTitleMatches ? 'row' : 'column'}
              spacing={2}
              sx={{
                display: 'flex',
                justifyContent: tableHeadTitleMatches ? 'space-between' : 'space-evenly',
                marginBottom: '2.25rem',
                alignItems: tableHeadTitleMatches ? 'center' : 'flex-start',
                mt: matches ? 0 : '20px',
                height: '36px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: '700',
                  lineHeight: '28px',
                }}
              >
                API Connection Status for My Shops
              </Typography>
              {/* We do not need this for the moment but we might in the future  */}
              {/* <LoadingButton
                loading={false} //this will be changed with new integration
                variant="contained"
                color="primary"
                onClick={handleAPIRefreshButton}
              >
                <Iconify icon="material-symbols:sync" sx={{ mr: 0.5, fontSize: '1rem' }} />
                Refresh API Tokens
              </LoadingButton> */}
            </Stack>

            <Card className="myShopsAPITable" sx={{ maxWidth: matches ? '754px' : '100%' }}>
              <APITable />
            </Card>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}

export default MyShopsPageNewVersion;
