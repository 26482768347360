import { Box, Button, Stack, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import PopupDialog from 'src/components/dialog/PopupDialog';
import { useSelector , useDispatch } from 'react-redux';
import { setOpen } from 'src/store/dashboard/slices/homeSlice';


interface LearnMoreDialogProps {
  open: boolean;
  onClose: () => void;
  updateStep: (step: number) => void;
  step: number;
  stepUpdateWithRedirect: boolean;
  dialogText?: string;
  redirectLink?: string;
}

export default function LearnMoreDialog({
  open,
  onClose,
  dialogText,
  redirectLink,
  step,
  updateStep,
  stepUpdateWithRedirect,
}: LearnMoreDialogProps) {
  return (
    <PopupDialog
      open={open}
      onClose={onClose}
      align={'center'}
      sx={{ width: '480px' }}
      actions={
        <Box>
          <Stack direction={'row'} spacing={2} justifyContent={'center'}>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={() => {
                if (stepUpdateWithRedirect) {
                  updateStep(step);
                }

                window.open(redirectLink, '_blank', 'noopener noreferrer');
                onClose();
              }}
              sx={{ paddingX: '24px' }}
            >
              Learn More
            </Button>
            <Button
              variant={'contained'}
              color={'inherit'}
              onClick={onClose}
              sx={{ paddingX: '24px' }}
            >
              Close
            </Button>
          </Stack>
        </Box>
      }
    >
      <Box paddingTop={2}>
        <InfoOutlined
          sx={{
            fontSize: '82px',
          }}
          color={'primary'}
        />
        <Typography variant={'body2'} marginTop={'5px'}>
          {dialogText}
        </Typography>
      </Box>
    </PopupDialog>
  );
}
