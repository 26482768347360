// @mui
import { Skeleton, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';

import { Automation } from '@beta.limited/primelister';

// hooks

import AutomationOption from './AutomationOption';
import { IAutomationOption } from '../types/types';

const automationItemsList: IAutomationOption[] = [
  {
    name: Automation.AUTO_CLOSET_SHARE,
    url: '/icons/automationIcons/share.svg',
    displayName: 'Share',
    index: 0,
    tooltipText:
      'PrimeLister will automatically share items from your closet with your followers throughout the day.',
  },
  {
    name: Automation.AUTO_COMMUNITY_SHARE,
    url: '/icons/automationIcons/autoCommunityShare.svg',
    displayName: 'Community Share',
    index: 1,
    tooltipText: `PrimeLister will automatically share items from other Poshers\' closets with your followers throughout the day.`,
  },
  {
    name: Automation.AUTO_RELIST,
    url: '/icons/automationIcons/relist.svg',
    displayName: 'Re-list',
    index: 2,
    tooltipText:
      'PrimeLister will automatically re-list stale listings in your closet, ensuring your items stay visible to potential buyers.',
  },
  {
    name: Automation.AUTO_SEND_OFFER_TO_LIKERS,
    url: '/icons/automationIcons/offerToLikers.svg',
    displayName: 'Offer to Likers',
    index: 3,
    tooltipText:
      'PrimeLister will automatically send an offer to the liker for each like received from a Posher.',
  },
  {
    name: Automation.AUTO_PARTY_SHARE,
    url: '/icons/automationIcons/partyShare.svg',
    displayName: 'Party Share',
    index: 4,
    tooltipText:
      'PrimeLister will automatically share your listings that match the ongoing party theme on Poshmark Parties.',
  },
  {
    name: Automation.RETURN_SHARE,
    url: '/icons/automationIcons/returnShare.svg',
    displayName: 'Return Share',
    index: 5,
    tooltipText:
      'PrimeLister will automatically share a number of items from the closets of Poshers who shared your items.',
  },
  {
    name: Automation.RETURN_FOLLOW,
    url: '/icons/automationIcons/returnFollow.svg',
    displayName: 'Return Follow',
    index: 6,
    tooltipText: 'PrimeLister will automatically follow back Poshers who followed you.',
  },
  {
    name: Automation.AUTO_FOLLOW_NEW_CLOSETS,
    url: '/icons/automationIcons/followFreshClosets.svg',
    displayName: 'Follow New Closets',
    index: 7,
    tooltipText:
      'PrimeLister will automatically follow new Poshers from your Poshmark “Feed“ on a daily basis.',
  },
];

export default function AutomationOptionsContainer() {
  const { initialLoading } = useSelector((state: IRootState) => state.automations);

  const addSkeletonToComponents = (component: any) =>
    initialLoading ? (
      <Skeleton variant="rounded" sx={{ width: '100', height: '5rem' }} />
    ) : (
      component
    );

  return (
    <Stack spacing={2} className="multipleClosetAutomationContainer">
      {automationItemsList.map((automation) =>
        addSkeletonToComponents(
          <AutomationOption
            key={Math.random() * 9999}
            automation={automation as IAutomationOption}
          />
        )
      )}
    </Stack>
  );
}
