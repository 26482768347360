import React from 'react';
import { Box, CardMedia } from '@mui/material';
import productImg from './assets/product.png';
import { ProductCardProps } from './types';

interface ProductImageProps {
  thumbnailUrl: ProductCardProps['thumbnailUrl'];
}

const ProductImageMobile: React.FC<ProductImageProps> = ({ thumbnailUrl }) => (
  <CardMedia
    component="img"
    image={thumbnailUrl || productImg}
    sx={{
      maxWidth: '48px',
      maxHeight: '48px',
      borderRadius: '12px',
      userSelect: 'none',
      pointerEvents: 'none',
      alignSelf: 'flex-start',
    }}
  />
);

export default ProductImageMobile;
