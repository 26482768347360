import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tooltip,
  TooltipProps,
} from '@mui/material';

import { IRootState } from 'src/store';
import Image from 'src/components/image';
import usePlatformLoginStatusCheck, { ActionType } from 'src/hooks/usePlatformLoginStatusCheck';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { platformIcons } from './InventoryBulkCrossListDialog';
import useCrosslistHandler from 'src/hooks/useCrosslistHandler';
import { IPlatforms } from 'src/store/dashboard/slices/myShopsSlice';

const StyledTooltipComponent = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(`
& .MuiTooltip-tooltip {
  background: slategray
}
`);

export default function InventoryCrossListDialog() {
  // A function to format the name of the marketplace for crossposting

  const { openCrosslistDialog, popoverData } = useSelector((state: IRootState) => state.inventory);

  const { getLoginStatusOfMarketplace } = usePlatformLoginStatusCheck();

  const {
    handleSingleDialogClose,
    isLoading,
    setSelectedMarketPlaces,
    selectedMarketPlaces,
    handleSingleListingCrosslist,
  } = useCrosslistHandler();

  function handleMarketPlaceSelection(name: string) {
    // If the marketplace is already selected, remove it from the array of selected marketplaces
    // Otherwise, add it to the array of selected marketplaces
    selectedMarketPlaces.includes(name)
      ? setSelectedMarketPlaces(selectedMarketPlaces.filter((marketPlace) => marketPlace !== name))
      : setSelectedMarketPlaces([...selectedMarketPlaces, name]);
  }

  const excludedMarketPlaces = popoverData?.marketplaceSources
    ? Object.keys(popoverData.marketplaceSources)
    : [];

  const filteredPlatformIcons = platformIcons.filter(
    (icon) => !excludedMarketPlaces.includes(icon.name)
  );

  const renderIcons = (icon: { name: IPlatforms; url: string }, index: number) => (
    <Stack
      key={index}
      sx={{
        minWidth: '7rem',
        maxWidth: '7rem',
        minHeight: '7rem',
        borderRadius: '7rem',
        m: 1,
        position: 'relative',
        '&:hover': { transform: 'scale(1.05)' },
        '&:active': { transform: 'scale(1)' },
      }}
      onClick={() =>
        getLoginStatusOfMarketplace(ActionType.CROSSLIST, icon.name, {
          checkExtensionLoginStatus: true,
        }) && handleMarketPlaceSelection(icon?.name)
      }
    >
      <Image
        src={icon.url}
        sx={{
          maxWidth: '6.5rem',
          maxHeight: '6.5rem',
          borderRadius: '6.5rem',
        }}
      />
      {getLoginStatusOfMarketplace(ActionType.CROSSLIST, icon.name, {
        checkExtensionLoginStatus: true,
      }) ? (
        <Image
          src="/icons/check.png"
          sx={{
            width: '6rem',
            height: '6rem',
            borderRadius: '6rem',
            cursor: 'pointer',
            zIndex: 1,
            position: 'absolute',
            top: '0.25rem',
            left: '0.25rem',
            opacity: `${selectedMarketPlaces.includes(icon.name) ? 1 : 0}`,
            transition: 'all 0.25s ease-in-out',
          }}
        />
      ) : (
        <StyledTooltipComponent
          title={'Login Required: Please log in to this platform in order to perform any actions.'}
          placement="top"
          arrow
        >
          <Box
            sx={{
              bgcolor: 'slategray',
              width: '6rem',
              height: '6rem',
              borderRadius: '6rem',
              cursor: 'pointer',
              zIndex: 1,
              position: 'absolute',
              top: '0.25rem',
              left: '0.25rem',
              opacity: 0.5,
            }}
          />
        </StyledTooltipComponent>
      )}
    </Stack>
  );

  return (
    <Dialog fullWidth maxWidth="lg" open={openCrosslistDialog} onClose={handleSingleDialogClose}>
      <DialogTitle mb={3}>Please select target marketplaces to cross-list</DialogTitle>
      <DialogContent
        sx={{
          typography: 'body2',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        {filteredPlatformIcons.map((icon, index) => renderIcons(icon, index))}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={handleSingleDialogClose}>
          Close
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={isLoading}
          disabled={!selectedMarketPlaces.length || isLoading}
          onClick={handleSingleListingCrosslist}
        >
          {!selectedMarketPlaces.length
            ? 'Please select at least one marketplace'
            : `Crosslist to ${selectedMarketPlaces.length} marketplace`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
