import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { IRootState } from 'src/store';

import {
  createAutomationWorkerRequest,
  setIsDisableConfirmationDialogOpen,
} from 'src/store/automations/slices/automationsSlice';
import { LoadingButton } from '@mui/lab';
import { MixpanelEventTypes, mixpanelTrack } from 'src/utils/mixpanel';
import { Automation } from '@beta.limited/primelister';

export default function DisableAutomationDialog() {
  const dispatch = useDispatch();

  const { isDisableConfirmationDialogOpen, dataForUpdating, loading } = useSelector(
    (state: IRootState) => state.automations
  );
  const { activeClosetCredentialsId, activeCloset } = useSelector(
    (state: IRootState) => state.myCloset
  );
  const { activeClosetAutomationPlatform, selectedAutomationOption } = useSelector(
    (state: IRootState) => state.automations
  );
  const handleClose = () =>
    dispatch(setIsDisableConfirmationDialogOpen({ isOpen: false, dataForUpdating }));

  const dialogContent = `${dataForUpdating.displayName} automation will be disabled. Are you sure you want to disable it?`;

  const handleDisable = () => {
    const mixpanelDisableData = {
      tool: 'poshmarkAutomation',
      source: 'web',
      automation:
        (dataForUpdating.workerData?.automation as Automation) ||
        (selectedAutomationOption?.name as Automation),
      enabled: false,
      credentialsId: activeClosetCredentialsId,
      marketplace: activeClosetAutomationPlatform,
      country: activeCloset.country,
      poshmarkUserName: activeCloset.poshmarkUserName,
    };
    dispatch(
      createAutomationWorkerRequest({
        automation: dataForUpdating.workerData?.automation as Automation,
        marketplace: activeClosetAutomationPlatform,
        activeClosetCredentialsId,
        status: false,
        toastMessage: dataForUpdating.toastMessage,
        automationsSettings: dataForUpdating.workerData?.automationsSettings,
      })
    );
    handleClose();
    mixpanelTrack(MixpanelEventTypes.AUTOMATION_ENABLE_STATUS_CHANGED, mixpanelDisableData);
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={isDisableConfirmationDialogOpen} onClose={handleClose}>
      <DialogTitle sx={{ pb: 2 }}>Disable Automation</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>{dialogContent}</DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} variant="contained" color="error" onClick={handleDisable}>
          Disable
        </LoadingButton>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
