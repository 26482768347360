import { GridFilterModel } from '@mui/x-data-grid-pro';
import { IPlatforms } from 'src/store/dashboard/slices/myShopsSlice';

type PlatformName =
  | IPlatforms.MERCARI
  | IPlatforms.FACEBOOK
  | IPlatforms.EBAYUS
  | IPlatforms.EBAYCA
  | IPlatforms.EBAYAU
  | IPlatforms.ETSY
  | IPlatforms.DEPOP
  | IPlatforms.POSHMARKUS
  | IPlatforms.POSHMARKCA
  | IPlatforms.GRAILED
  | IPlatforms.SHOPIFY
  | '';

export const getPlatformName = (platform: string): PlatformName => {
  switch (platform) {
    case 'Mercari':
      return IPlatforms.MERCARI;
    case 'Facebook':
      return IPlatforms.FACEBOOK;
    case 'eBay (US)':
      return IPlatforms.EBAYUS;
    case 'eBay (CA)':
      return IPlatforms.EBAYCA;
    case 'eBay (AU)':
      return IPlatforms.EBAYAU;
    case 'Etsy':
      return IPlatforms.ETSY;
    case 'Depop':
      return IPlatforms.DEPOP;
    case 'Poshmark (US)':
      return IPlatforms.POSHMARKUS;
    case 'Poshmark (CA)':
      return IPlatforms.POSHMARKCA;
    case 'Grailed':
      return IPlatforms.GRAILED;
    case 'Shopify':
      return IPlatforms.SHOPIFY;
    default:
      return '';
  }
};

/**                                           !!! ATTENTION !!!
 *        Prior to working on this file, it is crucial to disable prettier or any other code formatter
 *        to prevent inadvertently compromising the handling of certain exceptional filter cases,
 *        such as on line 88 and 109.
 */

//this function takes the DataGridModel and converts into desired shape for api request
export const getFilterModelShaped = (model: GridFilterModel): string | undefined => {
  const hasValidFilters = model.items.every((item) => {
    if (item.operatorValue === 'isEmpty' || item.operatorValue === 'isNotEmpty') {
      return item.value === undefined;
    } else {
      return item.value !== undefined;
    }
  });
  if (!hasValidFilters) {
    return undefined;
  }

  const multiFilters: any[] = [];

  //checks for the specific rules according to every filter field
  model.items.forEach((item) => {
    let { value } = item;
    //if value comes as an array
    if (Array.isArray(value)) {
      value = value.map((val) => {
        if (item.columnField === 'refs') {
          const platform = getPlatformName(val);
          return `${platform}`;
        } else if (item.columnField === 'added') {
          return val.length ? new Date(val).getTime() : null;
        } else if (item.columnField === 'price') {
          return val === '' ? null : Number(val);
        } else {
          return val.replace(/[\W_]/g, (match: any) => {
            if (match === '"') {
              /* prettier-ignore */
              // eslint-disable-next-line no-useless-escape
              return '\"';
            } else {
              return match;
            }
          });
        }
      });
      //if value does not comes as an array
    } else {
      if (item.operatorValue === 'isEmpty' || item.operatorValue === 'isNotEmpty') {
        value = '';
      } else if (item.columnField === 'marketplaceSources') {
        value = getPlatformName(value);
      } else if (item.columnField === 'added') {
        value = value.length ? new Date(value).getTime() : null;
      } else if (item.columnField === 'price') {
        value = value === '' ? null : Number(value);
      } else {
        if (value.includes('#') || value.includes('"')) {
          value = value.replace(/[\W_]/g, (match: any) => {
            if (match === '"') {
              /* prettier-ignore */
              // eslint-disable-next-line no-useless-escape
              return '\"';
            } else {
              return match;
            }
          });
        }
      }
    }

    const shouldAddNumericText =
      item.columnField.toLowerCase() === 'price' &&
      !item?.operatorValue?.toLowerCase().includes('between') &&
      /[a-zA-Z]/.test(item?.operatorValue!);

    const filterObj: { field: string; operator: any; value?: any } = {
      field: item.columnField,
      operator:
        // Check if the column field is 'price' and the operator value is a string with letters
        shouldAddNumericText
          ? // Create the operator string for numeric filters
            `numeric${item?.operatorValue?.slice(0, 1).toUpperCase()}${item?.operatorValue?.slice(
              1
            )}`
          : item?.operatorValue,
      value,
    };
    multiFilters.push(filterObj);
  });

  const ifOperatorIsNotSpecialControl = (item: any) =>
    !['isEmpty', 'isNotEmpty', 'numericIsEmpty', 'numericIsNotEmpty'].includes(item.operator);

  const ifValueIsArrayControl = (item: any) =>
    Array.isArray(item.value) &&
    ((item.operator === 'between' &&
      (item.value.length === 0 || item.value[0] == null || item.value[1] == null)) ||
      item.value.length === 0);

  const nameFieldControl = (item: any) =>
    item.field === 'name' && (item.value === null || item.value === '');

  const addedFieldControl = (item: any) =>
    item.field === 'added' &&
    item.operator === 'between' &&
    (item.value[0] === null || item.value[0] === undefined || item.value[0] === '') &&
    (item.value[1] === null || item.value[1] === undefined || item.value[1] === '');

  const priceFieldControl = (item: any) =>
    item.field === 'price' &&
    (item.value === null || item.value === undefined || item.value === '');

  const filtered = {
    filters: multiFilters.filter((item) => {
      if (ifOperatorIsNotSpecialControl(item)) {
        if (ifValueIsArrayControl(item)) {
          return false;
        } else if (nameFieldControl(item)) {
          return false;
        } else if (addedFieldControl(item)) {
          return false;
        } else if (priceFieldControl(item)) {
          return false;
        }
      }
      return true;
    }),
    parentOperator: model.linkOperator,
  };
  const controlledFilter = filtered.filters.length > 0 ? JSON.stringify(filtered) : '';

  return controlledFilter;
};
