import React from 'react';
import MenuPopover from 'src/components/menu-popover/MenuPopover';
import { MenuItem } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'src/store';

import Iconify from 'src/components/iconify';
import {
  setIsCardActionsMenuOpen,
  setIsDeleteCardDialogOpen,
  setIsSetDefaultCardDialogOpen,
} from 'src/store/dashboard/slices/accountSlice';

const CardActionsPopoverMenu = () => {
  const dispatch = useDispatch();
  const { isCardActionsMenuOpen } = useSelector((state: IRootState) => state.account);

  const handleClose = () => dispatch(setIsCardActionsMenuOpen(null));

  const handleDelete = () => {
    dispatch(setIsDeleteCardDialogOpen(true));
    dispatch(setIsCardActionsMenuOpen(null));
  };

  const handleSetDefault = () => {
    dispatch(setIsSetDefaultCardDialogOpen(true));
    dispatch(setIsCardActionsMenuOpen(null));
  };

  return (
    <MenuPopover
      open={isCardActionsMenuOpen}
      onClose={handleClose}
      arrow="right-top"
      sx={{ minWidth: 140 }}
    >
      <MenuItem onClick={handleSetDefault}>
        <Iconify icon="mdi:credit-card-edit" />
        Make Default
      </MenuItem>

      <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
        <Iconify icon="mdi:delete" />
        Delete
      </MenuItem>
    </MenuPopover>
  );
};

export default CardActionsPopoverMenu;
