import { Typography } from '@mui/material';

export default function DataGridNameColumn({
  name,
}: {
  name: string | { id: string; title: string };
}) {
  const isNameAnObject = typeof name === 'object';
  const nameToRender = isNameAnObject ? name.title : name;
  return (
    <Typography variant="subtitle2" style={{ whiteSpace: 'pre-wrap' }}>
      {nameToRender}
    </Typography>
  );
}
