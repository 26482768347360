import { UserAction } from '@beta.limited/primelister';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RemoveTaskDialogType } from 'src/pages/automations/ActivityPage/components/OngoingTabComponent';
import { ongoingTabItemsList } from 'src/pages/automations/ActivityPage/utils/constants';
import {
  IActivityData,
  IActivityItem,
  IGetActivityRequest,
  IGetOngoingRequest,
  IOngoingJobData,
} from 'src/pages/automations/ActivityPage/utils/types';

export interface IActivityState {
  loading: boolean;
  initialLoading: boolean;
  error: string;
  activityList: IActivityData[];
  requestCursor: number | null;
  hasMoreActivity: boolean;
  selectedActivity: IActivityItem;
  selectedOngoingTask: IActivityItem;
  ongoingList: IOngoingJobData[];
  isRemoveTaskDialogOpen: boolean;
  removeTaskDialogType: RemoveTaskDialogType;
  toBeRemovedTask: IOngoingJobData | null;
}
export type IRequestType = 'new' | 'more';

const initialState: IActivityState = {
  initialLoading: true,
  loading: false,
  error: '',
  activityList: [] as IActivityData[],
  ongoingList: [] as IOngoingJobData[],
  selectedOngoingTask: ongoingTabItemsList[0],
  requestCursor: null as number | null,
  hasMoreActivity: false,
  selectedActivity: {
    value: 'all',
    name: 'allActivities',
    displayName: 'All Activities',
    autoIcon: 'allActivities',
    urlParam: 'all-activities',
  },
  isRemoveTaskDialogOpen: false,
  removeTaskDialogType: RemoveTaskDialogType.ALL,
  toBeRemovedTask: null,
};

const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setInitialLoading: (state, action: PayloadAction<boolean>) => {
      state.initialLoading = action.payload;
    },
    setToBeRemovedTask: (state, action: PayloadAction<IOngoingJobData | null>) => {
      state.toBeRemovedTask = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setIsRemoveTaskDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isRemoveTaskDialogOpen = action.payload;
    },
    setRequestCursor: (state, action: PayloadAction<number | null>) => {
      state.requestCursor = action.payload;
    },
    setHasMoreActivity: (state, action: PayloadAction<boolean>) => {
      state.hasMoreActivity = action.payload;
    },
    setSelectedActivity: (state, action: PayloadAction<IActivityItem>) => {
      state.selectedActivity = action.payload;
    },
    setSelectedOngoingTask: (state, action: PayloadAction<IActivityItem>) => {
      state.selectedOngoingTask = action.payload;
    },
    setRemoveTaskDialogType: (state, action: PayloadAction<RemoveTaskDialogType>) => {
      state.removeTaskDialogType = action.payload;
    },
    setActivityList: (state, action: PayloadAction<IActivityData[]>) => {
      state.activityList = action.payload;
    },
    //--
    getActivityListRequest: (state, action: PayloadAction<IGetActivityRequest>) => {
      state.loading = true;
      state.error = '';
    },
    getActivityListSuccess: (
      state,
      action: PayloadAction<{ activityList: IActivityData[]; requestType: IRequestType }>
    ) => {
      state.loading = false;
      state.error = '';
      state.activityList =
        action.payload.requestType === 'new'
          ? action.payload.activityList
          : [...state.activityList, ...action.payload.activityList];
    },
    getActivityListFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },

    getOngoingListRequest: (state, action: PayloadAction<IGetOngoingRequest>) => {
      state.loading = true;
      state.error = '';
    },
    getOngoingListSuccess: (
      state,
      action: PayloadAction<{ ongoingList: IOngoingJobData[] }>
      // action: PayloadAction<{ ongoingList: IOngoingJobData[]; requestType: IRequestType }>
    ) => {
      state.loading = false;
      state.error = '';
      state.ongoingList = action.payload.ongoingList;
      // state.ongoingList =
      //   action.payload.requestType === 'new'
      //     ? action.payload.ongoingList
      //     : [...state.ongoingList, ...action.payload.ongoingList];
    },
    getOngoingListFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },

    removeAllActiveJobsRequest: (
      state,
      action: PayloadAction<{
        shop: string;
        getOngoingListRequestParams: IGetOngoingRequest;
        isMobile: boolean;
      }>
    ) => {
      state.loading = true;
      state.error = '';
    },
    removeAllActiveJobsSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    removeAllActiveJobsFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },

    removeSingleActiveJobRequest: (
      state,
      action: PayloadAction<{
        jobId: string;
        getOngoingListRequestParams: IGetOngoingRequest;
        isMobile: boolean;
      }>
    ) => {
      state.loading = true;
      state.error = '';
    },
    removeSingleActiveJobSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    removeSingleActiveJobFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    resetActivityState: () => initialState,
  },
});

export const {
  setIsLoading,
  resetActivityState,
  setInitialLoading,
  setRequestCursor,
  setHasMoreActivity,
  setSelectedActivity,
  setActivityList,
  setSelectedOngoingTask,
  setIsRemoveTaskDialogOpen,
  setRemoveTaskDialogType,
  setToBeRemovedTask,
  //--
  getActivityListRequest,
  getActivityListSuccess,
  getActivityListFail,

  getOngoingListRequest,
  getOngoingListSuccess,
  getOngoingListFail,

  removeAllActiveJobsFail,
  removeAllActiveJobsRequest,
  removeAllActiveJobsSuccess,
  removeSingleActiveJobFail,
  removeSingleActiveJobRequest,
  removeSingleActiveJobSuccess,
} = activitySlice.actions;

export default activitySlice.reducer;
