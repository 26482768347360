import { memo } from 'react';
// @mui
import { Box } from '@mui/material';
//
import { StyledRootScrollbar, StyledScrollbar } from './styles';
import { ScrollbarProps } from './types';
import { isMobileDevice, useDevice } from 'src/hooks/useUA';

// ----------------------------------------------------------------------

function Scrollbar({ children, sx, ...other }: ScrollbarProps) {
  const device = useDevice();

  if (isMobileDevice(device)) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <StyledRootScrollbar>
      <StyledScrollbar timeout={500} clickOnTrack={false} sx={sx} {...other}>
        {children}
      </StyledScrollbar>
    </StyledRootScrollbar>
  );
}

export default memo(Scrollbar);
