import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

import { IRootState } from 'src/store';

import ErrorIcon from '@mui/icons-material/Error';
import { setIsNotLoggedInDialogOpen } from 'src/store/dashboard/slices/myShopsSlice';
import { ConnectionFailTypeEnum, MixpanelEventTypes, mixpanelTrack } from 'src/utils/mixpanel';
import { setCrosslistSourceLoginErrorPlatformQuickMenu } from 'src/store/dashboard/slices/inventorySlice';
// Display a dialog prompting the user to login to source or target platform for crosslist action
export default function NotLoggedInToPlatformDialog() {
  const dispatch = useDispatch();

  const { isNotLoggedInDialogOpen } = useSelector((state: IRootState) => state.myShops);
  const { crosslistSourceLoginErrorPlatforms, crosslistSourceLoginErrorPlatformQuickMenu } =
    useSelector((state: IRootState) => state.inventory);

  const hasSourceLoginError =
    !!crosslistSourceLoginErrorPlatforms.length ||
    !!crosslistSourceLoginErrorPlatformQuickMenu.length;

  const crosslistSourceErrorPlatformsText = !crosslistSourceLoginErrorPlatformQuickMenu.length
    ? Array.from(
        new Set(crosslistSourceLoginErrorPlatforms.map((platform) => platform.platformName))
      ).join(', ')
    : crosslistSourceLoginErrorPlatformQuickMenu
        .map((platform) => platform.platformName)
        .join(', ');

  const handleDialogClose = () => dispatch(setIsNotLoggedInDialogOpen(false));

  const handlePageReload = () => window.location.reload();

  const handleClose = () => {
    dispatch(setIsNotLoggedInDialogOpen(false));
    setTimeout(() => {
      dispatch(setCrosslistSourceLoginErrorPlatformQuickMenu([]));
    }, 700);
  };

  if (isNotLoggedInDialogOpen) {
    mixpanelTrack(
      MixpanelEventTypes.EXTENSION_CONNECTION_FAILED,
      hasSourceLoginError
        ? ConnectionFailTypeEnum.LOGIN_REQUIRED_FOR_CROSSLIST_TASK
        : ConnectionFailTypeEnum.LOGIN_REQUIERED
    );
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={isNotLoggedInDialogOpen} onClose={handleDialogClose}>
      {!hasSourceLoginError && (
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          Login Required
        </DialogTitle>
      )}
      <DialogContent
        sx={{
          typography: 'body2',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: hasSourceLoginError ? '1.5rem' : 0,
        }}
      >
        <ErrorIcon sx={{ fontSize: '5rem', alignSelf: 'center', mb: '1.5rem' }} color="warning" />
        <DialogContentText sx={{ textAlign: 'center' }}>
          {!hasSourceLoginError ? (
            <>
              <Typography mb={'1rem'}>
                Sorry, it looks like you're not currently logged in. To do this action on this
                platform, please sign.
              </Typography>
              <Typography>After logging into the platform, please reload the page. </Typography>
            </>
          ) : (
            <Box>
              <b>Login Required:</b> Please login to
              <b> {crosslistSourceErrorPlatformsText} </b>
              to cross-list your selected listings. Retry after logging into the marketplace(s) and
              refreshing the page or by deselecting the listings from the marketplace(s) where you
              are not currently logged in.
            </Box>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {!hasSourceLoginError ? (
          <Button variant="contained" color="primary" onClick={handlePageReload}>
            Reload Page
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={handleClose}>
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
