import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grow, SxProps } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Theme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} timeout={450} {...props} children={props.children} />;
});

interface PopupDialogProps extends PropsWithChildren {
  onClose: () => void;
  open: boolean;
  title?: string;
  actions?: ReactNode;
  sx?: SxProps<Theme>;
  align?: 'center' | 'left' | 'right';
}

const PopupDialog: FC<PopupDialogProps> = ({
  children,
  open,
  onClose,
  title,
  sx,
  actions,
  align,
}) => (
  <>
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      {title && (
        <DialogTitle sx={{ borderBottom: '1px solid #f7f7f7', paddingBottom: 3, textAlign: align }}>
          {title}
        </DialogTitle>
      )}
      <DialogContent sx={{ ...(!title ? { paddingY: 4 } : {}), textAlign: align, ...sx }}>
        {children}
      </DialogContent>
      {actions && (
        <DialogActions
          sx={{
            borderTop: '1px solid #f7f7f7',
            paddingY: 3,
            paddingLeft: 3,
            justifyContent: align,
          }}
        >
          {actions}
        </DialogActions>
      )}
    </Dialog>
  </>
);

export default PopupDialog;
