import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';

import {
  ITagData,
  deleteTagRequest,
  setTagsDialogData,
  setTagsOpenDeleteDialog,
} from 'src/store/dashboard/slices/groupsAndTagsSlice';
import { IRootState } from 'src/store';
import { LoadingButton } from '@mui/lab';
import axiosInstance from 'src/utils/axios';
import { crosslistEndpoints } from 'src/endpoints';
import { DataGridTagsColumnInterface } from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import { InventoryTagActionReason } from 'src/pages/dashboard/Inventory/helpers/useTagUtilities';
import { getFilterModelShaped } from 'src/pages/dashboard/Inventory/helpers/getFilterModelShaped';

interface TagsDeleteDialogProps {
  handleTagsUpdated: (event: any, options: ITagData[], reason: string) => void;
}

export default function TagsDeleteDialog({ handleTagsUpdated }: TagsDeleteDialogProps) {
  const dispatch = useDispatch();

  const { tagsDialogData, tagsOpenDeleteDialog, tagsOpenDeleteDialogError, loading } = useSelector(
    (state: IRootState) => state.groupsAndTags
  );
  const { quickEditSelectedTags, page, rowsPerPage, sortModel, searchValue, inventoryFilterModel } =
    useSelector((state: IRootState) => state.inventory);

  const handleOnClose = () => {
    dispatch(setTagsOpenDeleteDialog(false));
  };
  const handleDelete = async (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(
      deleteTagRequest({
        tagData: tagsDialogData,
        quickEditSelectedTags,
        getData: {
          page,
          rowsPerPage,
          orderBy: sortModel[0]?.field,
          order: sortModel[0]?.sort,
          filter: getFilterModelShaped(inventoryFilterModel),
          searchValue,
        },
      })
    );
  };
  const handleCancel = () => {
    dispatch(setTagsOpenDeleteDialog(false));
    dispatch(setTagsDialogData({}));
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={tagsOpenDeleteDialog} onClose={handleOnClose}>
      <DialogTitle sx={{ pb: 2 }}>Delete Tag</DialogTitle>

      <DialogContent sx={{ typography: 'body2' }}>
        {tagsDialogData.name} will be deleted from your tags. Are you sure you want to delete?
        <DialogContentText sx={{ color: 'error.main', mt: 1 }}>
          {tagsOpenDeleteDialogError ? tagsOpenDeleteDialogError : ''}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <LoadingButton loading={loading} variant="contained" color="error" onClick={handleDelete}>
          Delete
        </LoadingButton>

        <Button variant="outlined" color="inherit" onClick={handleCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
