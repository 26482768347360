import React from 'react';
import { Card, CardContent, Typography, Stack, Box, Divider } from '@mui/material';
import Label from 'src/components/label/Label';
import { getEventColor } from './AffiliateTable';

export enum AffiliateEventType {
  Purchase = 'Purchase',
  SignUp = 'Sign-Up',
  Refund = 'Refund',
  Payout = 'Payout',
}
interface AffiliateTransactionCardProps {
  customer: string;
  date: string;
  event: AffiliateEventType;
  reward: number;
}

const AffiliateTransactionCard: React.FC<AffiliateTransactionCardProps> = ({
  customer,
  date,
  event,
  reward,
}) => (
  <Card sx={{ mb: 2 }}>
    <CardContent
      sx={{
        p: '0 !important',
      }}
    >
      <Stack>
        <Stack
          direction="column"
          gap={1}
          sx={{
            p: '24px 24px 16px 24px',
          }}
        >
          <Typography
            sx={{
              color: 'text.primary',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '24px',
            }}
          >
            {customer}
          </Typography>
          <Typography
            sx={{
              color: 'text.disabled',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '18px',
            }}
          >
            {date}
          </Typography>
          <Label
            color={getEventColor(event)}
            variant="soft"
            sx={{
              width: 'fit-content',
            }}
          >
            {event}
          </Label>
        </Stack>

        <Divider
          sx={{
            borderStyle: 'dashed',
          }}
        />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            p: 3,
          }}
        >
          <Typography
            color="text.secondary"
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '22px',
            }}
          >
            Reward
          </Typography>
          <Typography
            sx={{
              color: 'text.primary',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '22px',
            }}
          >
            ${reward}
          </Typography>
        </Stack>
      </Stack>
    </CardContent>
  </Card>
);

export default AffiliateTransactionCard;
