import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface DeleteRuleDialogProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  isLoading: boolean;
}

const DeleteRuleDialog: React.FC<DeleteRuleDialogProps> = ({
  open,
  onClose,
  onDelete,
  isLoading,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{
          color: 'text.primary',
          fontSize: '18px',
          fontWeight: 700,
          lineHeight: '28px',
        }}
      >
        Delete Offer Rule
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            color: 'text.primary',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '24px',
          }}
        >
          This rule will be deleted from your offer rules. Are you sure you want to delete?
        </Typography>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={onDelete}
          variant="contained"
          color="error"
          loading={isLoading}
          sx={{ textTransform: 'none' }}
        >
          Delete
        </LoadingButton>
        <Button onClick={onClose} variant="outlined" color="inherit" sx={{ textTransform: 'none' }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteRuleDialog;
