import { alpha } from '@mui/material';

import Label from 'src/components/label/Label';
import { getMarketplaceColor } from 'src/pages/dashboard/Tasks/helpers/getMarketplaceColor';

import { DataGridMarketplaceColumnInterface } from '../../../../types/types';
import { taskPlatformOptions } from '../Columns';
import { Marketplace } from '@beta.limited/primelister';

// TODO: We can deprecate second cases after we migrate to v2
export const getDisplayNameFromMarketplace = (marketplace: string) => {
  switch (marketplace) {
    case Marketplace.POSHMARK_US:
    case 'PoshmarkUS':
      return 'Poshmark (US)';
    case Marketplace.POSHMARK_CA:
    case 'PoshmarkCA':
      return 'Poshmark (CA)';
    case Marketplace.EBAY_US:
    case 'eBayUS':
      return 'eBay (US)';
    case Marketplace.EBAY_CA:
    case 'eBayCA':
      return 'eBay (CA)';
    case Marketplace.EBAY_AU:
    case 'eBayAU':
      return 'eBay (AU)';
    case Marketplace.DEPOP:
    case 'Depop':
      return 'Depop';
    case Marketplace.MERCARI:
    case 'Mercari':
      return 'Mercari';
    case Marketplace.ETSY:
    case 'Etsy':
      return 'Etsy';
    case Marketplace.FACEBOOK:
    case 'Facebook':
      return 'Facebook';
    case Marketplace.GRAILED:
    case 'Grailed':
      return 'Grailed';
    case Marketplace.SHOPIFY:
    case 'Shopify':
      return 'Shopify';
    default:
      return marketplace;
  }
};

export default function DataGridMarketplaceColumn({
  marketplace,
}: DataGridMarketplaceColumnInterface) {
  const marketplaceColor = getMarketplaceColor(marketplace.toLowerCase());
  const marketplaceLabel = getDisplayNameFromMarketplace(marketplace);

  return (
    <Label
      variant="soft"
      sx={{
        color: marketplaceColor,
        backgroundColor: alpha(marketplaceColor, 0.16),
        textTransform: 'none',
        ml: 1,
      }}
    >
      {marketplaceLabel}
    </Label>
  );
}
