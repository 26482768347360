import React from 'react';
import ImportDialog from '../../../mainComponents/ImportTable/ImportDialog';
import InventoryDeleteDialog from '../../../mainComponents/ImportTable/others/InventoryDeleteDialog';
import InventoryAddGroupDialog from './apiDialogs/InventoryAddGroupDialog';
import InventoryAddTagsDialog from './apiDialogs/InventoryAddTagsDialog';
import InventoryMergeListingsDialog from './apiDialogs/InventoryMergeListingsDialog';
import InventoryMergeListingsFailDialog from './apiDialogs/InventoryMergeListingsFailDialog';
import InventoryUpdateStatusDialog from './apiDialogs/InventoryUpdateStatusDialog';
import DownloadExtensionDialog from './ExtensionDialogs/DownloadExtensionDialog';
import ExtensionCommonDialog from './ExtensionDialogs/ExtensionCommonDialog';
import InventoryBulkCrossListDialog from './ExtensionDialogs/InventoryBulkCrossListDialog';
import InventoryCrossListDialog from './ExtensionDialogs/InventoryCrossListDialog';
import NotLoggedInToPlatformDialog from './ExtensionDialogs/NotLoggedInToPlatformDialog';
import InventoryAPIConnectionRequiredDialog from './apiDialogs/InventoryAPIConnectionRequiredDialog';
import InventoryRemoveGroupsDialog from './apiDialogs/InventoryRemoveGroupsDialog';
import InventoryRemoveTagsDialog from './apiDialogs/InventoryRemoveTagsDialog';
import ExtensionUpdateRequiredDialog from './ExtensionDialogs/ExtensionUpdateRequiredDialog';
import ImportErrorDialog from '../../../mainComponents/ImportTable/others/ImportErrorDialog';

const InventoryDialogs = () => (
  <>
    <ImportDialog />
    <InventoryCrossListDialog />
    <InventoryBulkCrossListDialog />
    <InventoryDeleteDialog />
    <ExtensionCommonDialog />
    <InventoryAddGroupDialog />
    <InventoryAddTagsDialog />
    <InventoryUpdateStatusDialog />
    <InventoryMergeListingsDialog />
    <InventoryMergeListingsFailDialog />
    <NotLoggedInToPlatformDialog />
    <InventoryAPIConnectionRequiredDialog />
    <InventoryRemoveGroupsDialog />
    <InventoryRemoveTagsDialog />
    <ExtensionUpdateRequiredDialog />
    <ImportErrorDialog />
    <DownloadExtensionDialog />
  </>
);

export default InventoryDialogs;
