import { Box } from '@mui/material';
import TaskPagination from './TaskPagination';
import TasksBulkActionsMenu from './TasksBulkActionsMenu';

export default function TaskFooter() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        w: 1,
      }}
    >
      <TasksBulkActionsMenu />
      <TaskPagination />
    </Box>
  );
}
