import { Stack, Typography } from '@mui/material';
import { DataGridSkuColumnInterface } from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';

export default function DataGridCreatedDateColumn({ sku }: DataGridSkuColumnInterface) {
  return (
    <Stack direction="row" spacing={1}>
      <Typography variant="subtitle2" sx={{ fontWeight: 'normal' }}>
        {sku ? sku : ''}
      </Typography>
    </Stack>
  );
}
