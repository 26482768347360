import { Automation } from '@beta.limited/primelister';
import { IPlatforms } from 'src/store/dashboard/slices/myShopsSlice';

export enum MixpanelEventTypes {
  EXTENSION_CONNECTION_FAILED = 'connectionFailed',
  AUTOMATION_ENABLE_STATUS_CHANGED = 'automationStatusChanged',
  MOBILE_APP_REVIEW = 'mobileAppReview',
  DEBUG_USER_AGENT = 'debugUserAgent',
  API_CONNECTION_FAILED = 'apiConnectionFailed',
  MARKETPLACE_CONNECTION_ESTABLISHED = 'marketplaceConnectionEstablished',
  POSHMARK_CLOSET_CONNECT_CLICK = 'poshmarkClosetConnectClick',
}

export enum ConnectionFailTypeEnum {
  LOGIN_REQUIERED = 'loginRequired',
  EXTENSION_REQUIERED = 'extensionRequired',
  API_REQUIRED = 'apiRequired',
  LOGIN_REQUIRED_FOR_CROSSLIST_TASK = 'loginRequiredForCrosslistTask',
}

export interface IMixpanelClosetConnectionData {
  result?: string;
  userHandle: string;
  password: string;
  country: string;
  errorText?: string;
}

export interface IMixpanelAutomationStatusData {
  tool: string;
  source: string;
  automation: Automation;
  enabled: boolean;
  credentialsId: string;
  marketplace: string;
  country: 'us' | 'ca' | 'au' | 'uk';
  poshmarkUserName: string;
}

export interface IMixpanelMobileAppReviewData {
  rateScore: number;
  feedbackText?: string;
}

export interface IMixpanelMarketplaceConnectionData {
  marketplace: IPlatforms;
  shop: string;
}

export interface IMixpanelEventData {
  [MixpanelEventTypes.EXTENSION_CONNECTION_FAILED]: ConnectionFailTypeEnum;
  [MixpanelEventTypes.AUTOMATION_ENABLE_STATUS_CHANGED]: IMixpanelAutomationStatusData;
  [MixpanelEventTypes.MOBILE_APP_REVIEW]: IMixpanelMobileAppReviewData;
  [MixpanelEventTypes.DEBUG_USER_AGENT]: any;
  [MixpanelEventTypes.API_CONNECTION_FAILED]: ConnectionFailTypeEnum;
  [MixpanelEventTypes.MARKETPLACE_CONNECTION_ESTABLISHED]: IMixpanelMarketplaceConnectionData;
  [MixpanelEventTypes.POSHMARK_CLOSET_CONNECT_CLICK]: IMixpanelClosetConnectionData;
}
