import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useState } from 'react';
import LearnMoreDialog from 'src/pages/dashboard/Home/components/LearnMoreDialog';

interface StepCheckListItemProps {
  label: string;
  checked: boolean;
  step: number;
  updateStep: (step: number) => void;
}

enum StepType {
  CreatePrimeListerAccount = 6,
  InstallPrimeListerExtension = 0,
  ConnectMarketPlace = 1,
  CheckPoshmarkShareBot = 4,
  ImportListing = 2,
  CrosspostListing = 3,
  CompleteOnboardingSteps = 5,
}

export default function StepCheckListItem({
  label,
  checked,
  step,
  updateStep,
}: StepCheckListItemProps) {
  const [dialogStatus, setDialogStatus] = useState(false);

  let hasDialog = false;
  let stepUpdateWithRedirect = false;
  let dialogText = '';
  let redirectLink = '';

  switch (step) {
    case StepType.CreatePrimeListerAccount:
      hasDialog = false;
      checked = true;
      break;
    case StepType.InstallPrimeListerExtension:
      hasDialog = true;
      dialogText = 'When you complete this step, the system automatically marks it as done.';
      redirectLink =
        'https://docs.primelister.com/getting-started/install-primelister-chrome-extension?utm_source=onboarding&utm_medium=onboarding';
      stepUpdateWithRedirect = false;
      break;
    case StepType.ConnectMarketPlace:
      hasDialog = true;
      dialogText = 'When you complete this step, the system automatically marks it as done.';
      redirectLink =
        'https://docs.primelister.com/getting-started/connect-marketplace-accounts?utm_source=onboarding&utm_medium=onboarding';
      stepUpdateWithRedirect = false;
      break;
    case StepType.CheckPoshmarkShareBot:
      hasDialog = false;
      redirectLink =
        'https://docs.primelister.com/features/poshmark-automation-tool?utm_source=onboarding&utm_medium=onboarding';
      stepUpdateWithRedirect = true;
      break;
    case StepType.ImportListing:
      hasDialog = true;
      dialogText = 'When you complete this step, the system automatically marks it as done.';
      redirectLink =
        'https://docs.primelister.com/features/cross-lister-tool/import?utm_source=onboarding&utm_medium=onboarding';
      stepUpdateWithRedirect = false;
      break;
    case StepType.CrosspostListing:
      hasDialog = true;
      dialogText = 'When you complete this step, the system automatically marks it as done.';
      redirectLink =
        'https://docs.primelister.com/features/cross-lister-tool/cross-list?utm_source=onboarding&utm_medium=onboarding';
      stepUpdateWithRedirect = false;
      break;
    case StepType.CompleteOnboardingSteps:
      hasDialog = false;
      stepUpdateWithRedirect = false;
  }

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            unselectable={'on'}
            color={'success'}
            disabled={checked ? true : false}
          />
        }
        label={
          <Typography variant={'body2'} sx={checked ? { textDecoration: 'line-through' } : {}}>
            {label}
          </Typography>
        }
        onClick={() => {
          if (hasDialog) setDialogStatus(true);
          if (!hasDialog && redirectLink.length > 0)
            window.open(redirectLink, '_blank', 'noopener noreferrer');
          if (!checked && stepUpdateWithRedirect) updateStep(step);
        }}
      />
      <LearnMoreDialog
        open={dialogStatus}
        onClose={() => setDialogStatus(false)}
        dialogText={hasDialog ? dialogText : ''}
        redirectLink={hasDialog ? redirectLink : ''}
        step={step}
        updateStep={updateStep}
        stepUpdateWithRedirect={stepUpdateWithRedirect}
      />
    </>
  );
}
