// Import necessary modules, hooks, and utilities
import { Marketplace } from '@beta.limited/primelister';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getAllPlatformShops,
  getShopperId,
} from 'src/pages/dashboard/Inventory/mainComponents/ImportTable/importDataGrid/helpers';
import { IMarketplaceSources } from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import { IRootState } from 'src/store';
import {
  setBulkActionsListingList,
  setOpenBulkCrosslistDialog,
  setOpenCrosslistDialog,
  setSelectedCheckboxes,
} from 'src/store/dashboard/slices/inventorySlice';
import { IPlatforms } from 'src/store/dashboard/slices/myShopsSlice';
import { BackgroundActionsEnum } from 'src/utils/ExtensionMessageChannel';

// Define interfaces for type safety
interface IListingItemConstructuring {
  listingItem: IListingItem;
}

interface IListingItem {
  id: string | undefined;
  name: string | undefined;
  img: string | undefined;
  marketplaceSources: IMarketplaceSources | undefined;
  marketplace?: Marketplace;
}

// Define the order of marketplaces for prioritizing crossposts
const marketplacePriorityOrder: IPlatforms[] = [
  IPlatforms.POSHMARKUS,
  IPlatforms.POSHMARKCA,
  IPlatforms.MERCARI,
  IPlatforms.ETSY,
  IPlatforms.EBAYUS,
  IPlatforms.EBAYCA,
  IPlatforms.EBAYAU,
  IPlatforms.GRAILED,
  IPlatforms.FACEBOOK,
  IPlatforms.SHOPIFY,
  IPlatforms.DEPOP,
];

// Custom hook for handling crosslist functionality
const useCrosslistHandler = () => {
  // Access global state using useSelector and useDispatch from Redux
  const { popoverData, bulkActionsListingList } = useSelector(
    (state: IRootState) => state.inventory
  );
  const dispatch = useDispatch();
  const officialShops = useSelector((state: IRootState) => state.myShopsAPITable.allShops);
  const extensionShops = useSelector((state: IRootState) => state.myShops);

  // Local state management for selected marketplaces and loading indicator
  const [selectedMarketPlaces, setSelectedMarketPlaces] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Function to construct listing item object based on selected item's data
  function constructListingItem(): IListingItemConstructuring {
    // Map popover data to create a listing item and alternative listing items

    const firstMarketplaceKey = Object.keys(popoverData?.marketplaceSources || {})[0];
    const listingItem: IListingItem = {
      id: popoverData?.marketplaceSources[firstMarketplaceKey]?.id,
      name: popoverData?.title,
      img: popoverData?.image,
      marketplace: Object.keys(popoverData?.marketplaceSources!)[0] as unknown as Marketplace,
      marketplaceSources: popoverData?.marketplaceSources,
    };

    return {
      listingItem,
    };
  }

  // Handles the closure of the single listing crosslist dialog and resets relevant state
  const handleSingleDialogClose = () => {
    dispatch(setOpenCrosslistDialog(false)); // Close the dialog
    setTimeout(() => {
      setSelectedMarketPlaces([]);
      setIsLoading(false); // Reset the loading state and selected marketplaces
    }, 700); // Delay to ensure smooth transition and state update
  };

  // Function to handle the crosslisting of a single listing item
  const handleSingleListingCrosslist = async () => {
    setIsLoading(true);
    // Show success notification
    toast.success('Crosslist tasks added to queue successfully', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

    // Construct the listing item and initiate crosslist command
    const { listingItem } = constructListingItem();

    // Create an ordered marketplaceSources object based on marketplacePriorityOrder
    const orderedMarketplaceSources = marketplacePriorityOrder.reduce((acc, marketplace) => {
      if (listingItem.marketplaceSources![marketplace]) {
        acc[marketplace] = listingItem.marketplaceSources![marketplace];
      }
      return acc;
    }, {} as IMarketplaceSources);

    // Update the listingItem with ordered marketplaceSources
    const updatedListingItem = {
      ...listingItem,
      marketplaceSources: orderedMarketplaceSources,
    };

    const allPlatformShops = getAllPlatformShops(officialShops, extensionShops);

    await window.extension.sendCommand(BackgroundActionsEnum.CROSSLIST, {
      items: [updatedListingItem],
      targetMarketplaces: selectedMarketPlaces,
      allPlatformShops,
    });

    handleSingleDialogClose();
  };

  // Function to handle the closure of the bulk listing crosslist dialog
  const handleBulkDialogClose = () => {
    dispatch(setOpenBulkCrosslistDialog(false)); // Close the dialog
    setTimeout(() => {
      // Reset relevant state for a clean slate
      setSelectedMarketPlaces([]);
      dispatch(setSelectedCheckboxes([]));
      dispatch(setBulkActionsListingList([]));
      setIsLoading(false); // Ensure loading indicator is reset
    }, 1000); // Delay to ensure smooth transition and state update
  };

  // Function to handle the crosslisting of multiple listing items in bulk
  const handleBulkListingsCrosslist = async () => {
    setIsLoading(true); // Set loading state to true
    // Show success toast notification
    toast.success('Crosslist tasks added to queue successfully', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

    const mappedBulkActionsListingList = bulkActionsListingList.map(mapListingWithOrderedSources);
    const allPlatformShops = getAllPlatformShops(officialShops, extensionShops);
    await window.extension.sendCommand(BackgroundActionsEnum.CROSSLIST, {
      items: mappedBulkActionsListingList,
      targetMarketplaces: selectedMarketPlaces,
      allPlatformShops,
    });

    handleBulkDialogClose(); // Close the bulk dialog
    // Reset selection and bulk action listing after operation
    dispatch(setSelectedCheckboxes([]));
    dispatch(setBulkActionsListingList([]));
  };

  /**
   * This function takes a listing and returns a new listing with its marketplaceSources ordered based on marketplacePriorityOrder.
   *
   * @param {any} listing - The listing to be ordered.
   * @returns {any} - The listing with ordered marketplaceSources.
   */
  const mapListingWithOrderedSources = (listing: any) => {
    // Create an ordered marketplaceSources object based on marketplacePriorityOrder
    const orderedMarketplaceSources = marketplacePriorityOrder.reduce((acc, marketplace) => {
      if (listing.marketplaceSources[marketplace]) {
        acc[marketplace] = listing.marketplaceSources[marketplace];
      }
      return acc;
    }, {} as IMarketplaceSources);

    // Return the listing with the ordered marketplaceSources
    return {
      ...listing,
      marketplaceSources: orderedMarketplaceSources,
    };
  };

  // Return the handlers and state to be used by components
  return {
    handleSingleListingCrosslist,
    handleBulkListingsCrosslist,
    handleSingleDialogClose,
    handleBulkDialogClose,
    isLoading,
    setIsLoading,
    selectedMarketPlaces,
    setSelectedMarketPlaces,
  };
};

export default useCrosslistHandler;
