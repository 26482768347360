import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setIsPayoutSubmittedDialogOpen } from 'src/store/dashboard/slices/accountSlice';
import { IRootState } from 'src/store';
import { format, differenceInDays } from 'date-fns';

const PayoutSubmittedDialog: React.FC = () => {
  const dispatch = useDispatch();
  const { isPayoutSubmittedDialogOpen, affiliateSummary } = useSelector(
    (state: IRootState) => state.account
  );

  const handleClose = () => {
    dispatch(setIsPayoutSubmittedDialogOpen(false));
  };

  const lastPayoutDate = affiliateSummary?.lastPayoutRequestDate
    ? new Date(affiliateSummary.lastPayoutRequestDate)
    : null;

  const formattedDate = lastPayoutDate
    ? `${differenceInDays(new Date(), lastPayoutDate)} days ago (${format(
        lastPayoutDate,
        'MM/dd/yyyy'
      )})`
    : 'N/A';

  const blueTextStyle = {
    color: '#2065D1',
    fontWeight: 'bold',
  };

  return (
    <Dialog open={isPayoutSubmittedDialogOpen} onClose={handleClose}>
      <DialogTitle>Payout Request Submitted</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          You submitted your payout request <span style={blueTextStyle}>{formattedDate}</span>. Your
          request is currently being processed.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please note that affiliate earnings are processed and paid out on the{' '}
          <span style={blueTextStyle}>1st or 15th of each month</span>. This means payouts are
          processed once a month.
        </Typography>
        <Typography variant="body1" gutterBottom>
          While payouts are typically expected on these dates, delays may occur due to public
          holidays and weekends.
        </Typography>
        <Typography variant="body1">Thank you for your patience.</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PayoutSubmittedDialog;
