import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { IOngoingJobData } from '../../utils/types';
import {
  getOngoingListRequest,
  removeAllActiveJobsRequest,
  removeSingleActiveJobRequest,
  setInitialLoading,
  setIsRemoveTaskDialogOpen,
} from 'src/store/automations/slices/activitySlice';
import { LoadingButton } from '@mui/lab';
import { SubscriptionType, UserAction } from '@beta.limited/primelister';
import { isLoggedIn } from 'src/utils/isLoggedIn';
import Iconify from 'src/components/iconify';
import useResponsive from 'src/hooks/useResponsive';

interface RemoveTaskDialogProps {
  isMulti?: boolean;
  toBeRemovedTask?: IOngoingJobData;
}

const RemoveTaskDialog: React.FC<RemoveTaskDialogProps> = ({ isMulti, toBeRemovedTask }) => {
  const { isRemoveTaskDialogOpen, loading, selectedOngoingTask } = useSelector(
    (state: IRootState) => state.activity
  );
  const { activeCloset } = useSelector((state: IRootState) => state.myCloset);
  const { userId } = isLoggedIn();

  const dispatch = useDispatch();
  const isMobile = useResponsive('down', 'sm');

  const handleClose = () => {
    dispatch(setIsRemoveTaskDialogOpen(false));
  };

  const handleOnRemove = () => {
    const getOngoingListRequestParams = {
      ...(selectedOngoingTask &&
        selectedOngoingTask.value !== 'all' && {
          action: selectedOngoingTask.value as UserAction,
        }),
      product: SubscriptionType.POSHMARK_AUTOMATION,
      shop: activeCloset.poshmarkUserName,
    };
    if (isMulti) {
      dispatch(
        removeAllActiveJobsRequest({
          shop: activeCloset.poshmarkUserName,
          getOngoingListRequestParams,
          isMobile,
        })
      );
    } else {
      dispatch(
        removeSingleActiveJobRequest({
          jobId: toBeRemovedTask!.jobId,
          getOngoingListRequestParams,
          isMobile,
        })
      );
    }
  };

  const title = isMulti ? 'Remove All Tasks?' : 'Remove Task?';
  const description = isMulti
    ? 'All of your tasks will be removed from your ongoing task queue. Are you sure you want to remove them?'
    : 'This task will be removed from your ongoing task queue. Are you sure you want to remove it?';
  const buttonText = isMulti ? 'Remove All' : 'Remove';

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '480px',
          },
        },
      }}
      open={isRemoveTaskDialogOpen}
      onClose={handleClose}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            fontSize: '18px',
            fontWeight: '700',
            lineHeight: '28px',
            color: '#212B36',
          }}
        >
          {title}
        </Box>
        <IconButton aria-label="close" onClick={handleClose}>
          <Iconify icon="mingcute:close-line" width={18} height={18} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} onClick={handleOnRemove} color="error" variant="contained">
          {buttonText}
        </LoadingButton>
        <Button onClick={handleClose} color="inherit" variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveTaskDialog;
