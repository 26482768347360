export const getMarketplaceColor = (marketplaceName: string) => {
  if (marketplaceName.includes('poshmark')) return '#602D35';
  if (marketplaceName.includes('mercari')) return '#5E6DF2';
  if (marketplaceName.includes('ebay')) return '#3464AC';
  if (marketplaceName.includes('etsy')) return '#F26422';
  if (marketplaceName.includes('depop')) return '#FF2300';
  if (marketplaceName.includes('facebook')) return '#1877F2';
  if (marketplaceName.includes('grailed')) return '#000000';
  if (marketplaceName.includes('tradesy')) return '#000000';
  if (marketplaceName.includes('shopify')) return '#5E8E3E';

  return '#000000';
}