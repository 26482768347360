export function getHourRange(startHour: string): string {
  const [hourStr, period] = startHour.split(' ');
  let hour = parseInt(hourStr);
  let nextHour = (hour % 12) + 1;
  let nextPeriod = hour === 11 ? (period === 'AM' ? 'PM' : 'AM') : period;
  return `${startHour} - ${nextHour} ${nextPeriod}`;
}

export function formatTimeZone(timeZoneString?: string) {
  if (timeZoneString) {
    let sign = timeZoneString[0];
    let partBeforeColon = timeZoneString.split(':')[0].substring(1);
    let number = parseInt(partBeforeColon, 10);
    return sign + ' ' + number;
  }
  return null;
}
