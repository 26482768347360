import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { inventoryDeleteRequest, setDeletePopup } from 'src/store/dashboard/slices/inventorySlice';
import { IRootState } from 'src/store';
import { getFilterModelShaped } from 'src/pages/dashboard/Inventory/helpers/getFilterModelShaped';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { LoadingButton } from '@mui/lab';

export default function InventoryDeleteViaDetailDialog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    deletePopup,
    popoverData,
    deleteType,
    selectedCheckboxes,
    page,
    rowsPerPage,
    sortModel,
    searchValue,
    inventoryFilterModel,
    loading,
  } = useSelector((state: IRootState) => state.inventory);

  const handleClose = () => dispatch(setDeletePopup(false));

  const textLimiter = (text: string = '', limit: number = 80) =>
    text.length > limit ? text.substring(0, limit) + '..' : text;

  const deletedItemTitle = textLimiter(popoverData?.title);

  const singleItemDeleteMessage = `${deletedItemTitle} will be deleted from your inventory. Are you sure you want to delete it?`;

  const handleDelete = () => {
    const idsToDelete = deleteType === 1 ? selectedCheckboxes : [popoverData?._id];

    dispatch(
      inventoryDeleteRequest({
        ids: idsToDelete,
        deleteMessage: 'Item successfully deleted',
        getData: {
          page,
          rowsPerPage,
          orderBy: sortModel[0]?.field,
          order: sortModel[0]?.sort,
          filter: getFilterModelShaped(inventoryFilterModel),
          searchValue,
        },
      })
    );

    setTimeout(() => navigate(PATH_DASHBOARD.inventory.root), 500);
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={deletePopup} onClose={handleClose}>
      <DialogTitle sx={{ pb: 2 }}>Delete Item</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>{singleItemDeleteMessage}</DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} variant="contained" color="error" onClick={handleDelete}>
          Delete
        </LoadingButton>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
