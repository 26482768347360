import React, { useEffect, useState } from 'react';
import { Divider, Stack } from '@mui/material';
import { ExtendedProductCardProps, ProductCardProps, ProductCardSelectionEnum } from './types';
import ProductImage from './ProductImage';
import ProductInfo from './ProductInfo';
import ProductActions from './ProductActions';
import { StyledCard } from './styles';
import { block } from 'million/react';

const ProductCard: React.FC<ExtendedProductCardProps> = ({
  thumbnailUrl,
  id,
  title,
  size,
  brand,
  price,
  originalPrice,
  likeCount,
  commentCount,
  statusTagVisibility,
  conditionChipVisibility,
  shippingChipVisibility,
  originalPriceVisibility,
  likeCountVisibility,
  commentCountVisibility,
  shippingType,
  statusLabel,
  conditionChip,
  onCardClick,
  droppingSoonDate,
  isSelected,
  ...rest
}) => {
  const selection = isSelected ? ProductCardSelectionEnum.ALL : ProductCardSelectionEnum.DEFAULT;

  const handleOnClick = () => {
    onCardClick && onCardClick();
  };

  return (
    <StyledCard
      {...rest}
      onClick={handleOnClick}
      isSelected={selection === ProductCardSelectionEnum.ALL}
      selection={selection}
    >
      <Stack sx={{ position: 'relative' }}>
        <ProductImage
          thumbnailUrl={thumbnailUrl}
          title={title}
          selection={selection}
          statusTagVisibility={statusTagVisibility}
          conditionChipVisibility={conditionChipVisibility}
          statusLabel={statusLabel}
          conditionChip={conditionChip}
          droppingSoonDate={droppingSoonDate}
        />
      </Stack>
      <ProductInfo
        brand={brand}
        originalPrice={originalPrice}
        originalPriceVisibility={originalPriceVisibility}
        price={price}
        shippingChipVisibility={shippingChipVisibility}
        shippingType={shippingType}
        size={size}
        title={title}
      />
      <>
        <Divider sx={{ borderStyle: 'dashed', width: '100%' }} />
        <ProductActions
          commentCount={commentCount}
          commentCountVisibility={commentCountVisibility}
          likeCount={likeCount}
          likeCountVisibility={likeCountVisibility}
        />
      </>
    </StyledCard>
  );
};

export default ProductCard;
