import { Marketplace } from '@beta.limited/primelister';
import { IImportListingItem } from 'src/store/dashboard/slices/importTableSlice';
import { IAPIMarketplaces } from 'src/store/dashboard/slices/myShopsAPITableSlice';

export const shapeListingsForImportCheck = (
  listings: IImportListingItem[],
  importPlatform: string | IAPIMarketplaces | Marketplace
) => {
  const listingsToBeCheckedForImport = listings.map((item) => ({
    listingId: item.id,
    marketplace: importPlatform,
  }));

  return listingsToBeCheckedForImport;
};

export const shapeImportInformationToStringArray = (
  statusList: { listingId: string; imported: boolean }[],
  imported: boolean = true
) => {
  const initialImportStatusList = statusList
    .filter((item: { imported: boolean }) => (imported ? item.imported : !item.imported))
    .map((item: { listingId: string }) => item.listingId);

  return initialImportStatusList;
};
