import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  setIsTagsAutocompleteOpen,
  setIsTagEditPopoverOpen,
  setQuickActionsTagPopoverMode,
} from 'src/store/dashboard/slices/inventorySlice';

export const useTagAutocompleteClickOutside = (
  isTagsAutocompleteOpen: boolean,
  isTagEditPopoverOpen: boolean,
  isNoOptionsOpen: boolean
) => {
  const dispatch = useDispatch();
  const autocompleteRef = useRef<HTMLDivElement>(null);
  const tagEditPopoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !isNoOptionsOpen &&
        !isTagEditPopoverOpen &&
        autocompleteRef.current &&
        !autocompleteRef.current.contains(event.target as Node) &&
        isTagsAutocompleteOpen
      ) {
        dispatch(setIsTagsAutocompleteOpen(false));
        dispatch(setIsTagEditPopoverOpen(false));
        dispatch(setQuickActionsTagPopoverMode(null));
      }
    };

    if (!isTagEditPopoverOpen && !isNoOptionsOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isTagEditPopoverOpen, isTagsAutocompleteOpen, isNoOptionsOpen, dispatch]);

  return { autocompleteRef, tagEditPopoverRef };
};