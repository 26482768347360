import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { IRootState } from 'src/store';

import { setIsConnectionErrorDialogOpen } from 'src/store/automations/slices/automationsSlice';
import { PATH_AUTOMATIONS, PATH_DASHBOARD } from 'src/routes/paths';
import { useNavigate } from 'react-router-dom';
import { setStripeReferrerUrl } from 'src/store/dashboard/slices/pricingSlice';

export default function ConnectionOrSubscriptionMissingDialog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isConnectionErrorDialogOpen } = useSelector((state: IRootState) => state.automations);
  const { hasConnection, hasCloset } = useSelector((state: IRootState) => state.myCloset);

  const handleClose = () => dispatch(setIsConnectionErrorDialogOpen(false));

  const url = window.location.href;
  const handleConnection = () => {
    dispatch(setStripeReferrerUrl(url));
    if (!hasCloset || !hasConnection) {
      navigate(PATH_AUTOMATIONS.myCloset);
    } else {
      navigate(PATH_DASHBOARD.subscription.poshmarkAutomation);
    }
    handleClose();
  };

  const connectionDialogContent = {
    title: `${
      hasCloset && !hasConnection ? 'Re-connect' : 'Connect'
    } Your Poshmark Closet: Action Required`,
    message: `To unlock powerful automations of our tool, please ${
      hasCloset && !hasConnection ? 're-connect' : 'connect'
    }  your Poshmark closet. This will enable seamless integration with our software and empower you to automate various tasks.`,
    buttonText: `${hasCloset && !hasConnection ? 'Re-connect' : 'Connect'}  My Closet`,
  };

  const subscriptionDialogContent = {
    title: 'Subscribe to Poshmark Automation Tool: Action Required',
    message:
      "You don't have the subscription required to enable this automation. Subscribe to Poshmark Automation Tool and unlock its powerful automations with full access.",
    buttonText: 'Subscribe',
  };

  const dynamicDialogContent =
    !hasCloset || !hasConnection ? connectionDialogContent : subscriptionDialogContent;

  const { title, message, buttonText } = dynamicDialogContent;
  return (
    <Dialog fullWidth maxWidth="sm" open={isConnectionErrorDialogOpen} onClose={handleClose}>
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>{message}</DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleConnection}
          sx={{ textTransform: 'none !important' }}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
