import { IPlatforms } from 'src/store/dashboard/slices/myShopsSlice';

interface ITaskType {
  delete: number;
  copy: number;
  reList: number;
  crossPost: number;
  import: number;
  deList: number;
  sharer: number;
}

interface IUnsupportedActions {
  [key: string]: number[];
  [IPlatforms.FACEBOOK]: number[];
  [IPlatforms.EBAYUS]: number[];
  [IPlatforms.EBAYCA]: number[];
  [IPlatforms.EBAYAU]: number[];
  [IPlatforms.ETSY]: number[];
  [IPlatforms.DEPOP]: number[];
  [IPlatforms.GRAILED]: number[];
  [IPlatforms.SHOPIFY]: number[];
}
const taskType: ITaskType = {
  delete: 0,
  copy: 1,
  reList: 2,
  crossPost: 3,
  import: 4,
  deList: 5,
  sharer: 6,
};

const unsupportedActions: IUnsupportedActions = {
  [IPlatforms.FACEBOOK]: [taskType.deList, taskType.reList, taskType.copy, taskType.delete],
  [IPlatforms.EBAYUS]: [taskType.deList],
  [IPlatforms.EBAYCA]: [taskType.deList],
  [IPlatforms.EBAYAU]: [taskType.deList],
  [IPlatforms.ETSY]: [taskType.reList, taskType.copy],
  [IPlatforms.DEPOP]: [taskType.deList, taskType.reList, taskType.copy],
  [IPlatforms.GRAILED]: [taskType.deList, taskType.copy, taskType.reList],
  [IPlatforms.SHOPIFY]: [taskType.copy, taskType.reList],
};

export const checkSupportedPlatforms = (taskType: number, sv: string) => {
  // Get the list of unsupported task types for the given platform.
  const unsupportedTaskTypes = unsupportedActions[sv];
  // If there are no unsupported task types for the platform, it is supported.
  if (!unsupportedTaskTypes) {
    return true;
    // If the task type is included in the list of unsupported task types, it is not supported.
  }
  // If the task type is included in the list of unsupported task types, it is not supported.
  if (unsupportedTaskTypes.includes(taskType)) {
    return false;
  }
  // If the task type is not included in the list of unsupported task types, it is supported.
  return true;
};
