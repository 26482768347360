import { useState, ReactNode, useEffect } from 'react';
import { useFlagsmith } from 'flagsmith/react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PATH_AUTH, PATH_AUTOMATIONS, PATH_DASHBOARD } from 'src/routes/paths';

import { IRootState } from 'src/store';
import { setCoupon } from 'src/store/dashboard/slices/accountSlice';
import { closeCrispChat, hideCrispChat, initializeCrispChat } from 'src/utils/crispChatHelpers';
import Tracker from '@openreplay/tracker';

import { isLoggedIn } from 'src/utils/isLoggedIn';
// components
import LoadingScreen from '../components/loading-screen';
import { IS_SANDBOX } from 'src/config';
import useResponsive from 'src/hooks/useResponsive';
import usePrimeListerMobileApp from 'src/hooks/usePrimeListerMobileApp';
import { IFeatureFlag } from '@openreplay/tracker/lib/modules/featureFlags';
import { setFeatureFlags } from 'src/store/dashboard/slices/authSlice';
import moment from 'moment';
import { setIsAdminLogin } from 'src/store/dashboard/slices/homeSlice';
import AdminLoginBanner from 'src/components/banner/AdminLoginBanner';
import { useGetIsAdminLogin } from 'src/hooks/useGetIsAdminLogin';
// import { checkIfFlagsLoaded } from 'src/utils/checkIfFlagsLoaded';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

//--OpenReplay initialize
const openReplayTracker = new Tracker({
  projectKey: import.meta.env.VITE_OPENREPLAY_KEY as string,
  // @ts-ignore
  network: {
    // Enable HTTP payload capture in the network option
    capturePayload: true,
  },
  defaultInputMode: 0,
  obscureTextNumbers: false,
  obscureTextEmails: false,
  obscureInputEmails: false,
  obscureInputDates: false,
});

openReplayTracker.start();

/**
 * -- TODOS --
 * Conditional redirecting will be removed in one month : ( Lines between 33-41 )
 */

export default function AuthGuard({ children }: AuthGuardProps) {
  usePrimeListerMobileApp();
  const dispatch = useDispatch();

  const { isAuthenticated, isInitialized } = useSelector((state: IRootState) => state.auth);
  const navigate = useNavigate();
  const { isPrimeListerMobileApp, isCrispHidden, isMobileAppCheckLoading, isAdminLogin } =
    useSelector((state: IRootState) => state.home);
  const { pathname, hash, search } = useLocation();
  const { email, loggedIn, fullName, proStatus, userId } = isLoggedIn();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  const isMobile = useResponsive('down', 'sm');
  const [searchParams] = useSearchParams();
  // const [areFlagsLoaded, setAreFlagsLoaded] = useState(false);
  const [initializeCompleted, setInitializeCompleted] = useState(false);
  const isInAuthVerify = () => pathname.includes('auth/verify');
  const isInBugReport = () => pathname.includes('bugreport');
  const isInPanelCheckout = () => pathname.includes('panel/checkout');

  const getVerificationCode = () => searchParams.get('code');
  const getCoupon = () => searchParams.get('coupon');
  const getToken = () => searchParams.get('token');
  useGetIsAdminLogin();

  // const flagsmith = useFlagsmith();

  // useEffect(() => {
  //   checkIfFlagsLoaded(areFlagsLoaded, setAreFlagsLoaded, flagsmith);
  // }, [areFlagsLoaded]);

  useEffect(() => {
    if (isAuthenticated && !IS_SANDBOX) {
      openReplayTracker.setUserID(email);
      openReplayTracker.setMetadata('proStatus', proStatus);
      openReplayTracker.setMetadata('customerUserId', userId);
      if (isPrimeListerMobileApp) {
        openReplayTracker.setMetadata('platform', 'MobileApp');
      }
      openReplayTracker.onFlagsLoad((flags: IFeatureFlag[]) => {
        dispatch(setFeatureFlags(flags));
      });
    }
  }, [isAuthenticated, isPrimeListerMobileApp]);

  useEffect(() => {
    if (!initializeCompleted) {
      setInitializeCompleted(true);
    }
  }, [isInitialized, window.$crisp]);

  const redirectToAutomations = loggedIn && (isPrimeListerMobileApp || isMobile);

  useEffect(() => {
    // Ensure all conditions are checked here to decide if navigation should happen immediately
    if (redirectToAutomations && isInitialized && !isMobileAppCheckLoading) {
      if (!pathname.includes('automations')) {
        navigate(PATH_AUTOMATIONS.root);
      }

      if (isPrimeListerMobileApp) {
        document.body.classList.add('is-mobile-app');
      }
    }
  }, [isPrimeListerMobileApp, isInitialized, isMobileAppCheckLoading]);

  // If not logged in, navigate to login or verification
  if (!loggedIn) {
    if (isInAuthVerify()) {
      const loginPath = `/login?verificationCode=${getVerificationCode()}`;
      return <Navigate to={loginPath} />;
    }

    return <Navigate to={`/login?redirect=${pathname + search + hash}`} />;
  }

  if (!initializeCompleted || isMobileAppCheckLoading) {
    return <LoadingScreen />;
  }
  // Open bug report page if in bug report
  if (isInitialized && isAuthenticated && isInBugReport()) {
    window.open(`https://www.isharemyscreen.com/primelister?email=${email}`, '_self');
  }

  // Set coupon if one exists
  if (getCoupon()) {
    dispatch(setCoupon(getCoupon()!));
  }

  // Navigate to home if in auth verify
  if (isInAuthVerify()) {
    return <Navigate to={`/home`} />;
  }

  // Navigate to payment summary if in panel checkout
  if (isInitialized && isAuthenticated && isInPanelCheckout()) {
    const lastIndex = pathname.lastIndexOf('/');
    const lastSegment = pathname.substr(lastIndex + 1);
    const path = search
      ? `?planId=${lastSegment}&${search.split('?')[1]}`
      : `?planId=${lastSegment}`;

    return <Navigate to={PATH_DASHBOARD.subscription.paymentSummary + path} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  if (window.$crisp && email && fullName && proStatus) {
    const showCrispChatInitially = !isMobile && !isPrimeListerMobileApp && !isCrispHidden;
    initializeCrispChat(email, fullName, proStatus, showCrispChatInitially, dispatch);

    if (!showCrispChatInitially) {
      hideCrispChat();
    }
  }

  return (
    <>
      {children}
      {isAdminLogin && <AdminLoginBanner email={email} name={fullName} />}
    </>
  );
}
