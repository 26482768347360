import { Typography } from '@mui/material';
import { DataGridNameColumnInterface } from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import { PATH_DASHBOARD } from 'src/routes/paths';

export default function DataGridNameColumn({ title, id }: DataGridNameColumnInterface) {
  const handleNameClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    window.open(`${PATH_DASHBOARD.inventory.root + '/' + id}`, '_blank', 'noopener noreferrer');
  };
  return (
    <Typography
      variant="subtitle2"
      sx={{ whiteSpace: 'pre-wrap', '&:hover': { textDecoration: 'underline', cursor: 'pointer' } }}
      onClick={handleNameClick}
    >
      {title}
    </Typography>
  );
}
