import React from 'react';
import { Typography, Box, Divider, CardContent } from '@mui/material';
import ShippingChip from './ShippingChip';
import { ProductCardProps } from './types';
import OverflowTypography from './OverflowTypography';

interface ProductInfoProps {
  title: ProductCardProps['title'];
  size: ProductCardProps['size'];
  brand: ProductCardProps['brand'];
  price: ProductCardProps['price'];
  originalPrice: ProductCardProps['originalPrice'];
  originalPriceVisibility: ProductCardProps['originalPriceVisibility'];
  shippingChipVisibility: ProductCardProps['shippingChipVisibility'];
  shippingType: ProductCardProps['shippingType'];
}

const ProductInfo: React.FC<ProductInfoProps> = ({
  title,
  size,
  brand,
  price,
  originalPrice,
  originalPriceVisibility,
  shippingType,
  shippingChipVisibility,
}) => (
  <CardContent
    sx={{
      padding: '16px 16px 8px 16px',
      width: '250px',
      '&:last-child': {
        paddingBottom: '16px',
      },
    }}
  >
    <OverflowTypography
      sx={{
        fontSize: '14px',
        fontWeight: 500,
        fontStyle: 'normal',
        maxWidth: '218px',
        color: '#212B36',
        whiteSpace: 'initial',
        display: '-webkit-box',
        height: '40.7px',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        lineHeight: '20.349px',
      }}
    >
      {title}
    </OverflowTypography>
    <Box
      sx={{
        color: '#003768',
        textOverflow: 'ellipsis',
        letterSpacing: '0.15px',
        display: 'flex',
        alignItems: 'center',
        mt: '8px',
        mb: '8px',
      }}
    >
      <OverflowTypography
        sxProps={{
          fontSize: '13px',
          fontWeight: 500,
          maxWidth: '76px',
          lineHeight: '18.57px',
          overflow: 'hidden',
          wordBreak: 'break-all',
        }}
      >
        Size: {size}
      </OverflowTypography>
      <Divider
        orientation="vertical"
        flexItem
        variant="middle"
        sx={{ height: '16px', m: '0 5.5px', backgroundColor: '#E5E8EB' }}
      />
      <OverflowTypography
        sxProps={{
          fontSize: '13px',
          fontWeight: 500,
          maxWidth: '138px',
          overflow: 'hidden',
          lineHeight: '18.57px',
          wordBreak: 'break-all',
        }}
      >
        {brand}
      </OverflowTypography>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', height: '24px' }}>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 700,
          color: '#212B36',
          marginRight: '4px',
          lineHeight: '17.442px',
        }}
      >
        ${price}
      </Typography>
      {originalPriceVisibility && (
        <Typography
          sx={{
            textDecoration: 'line-through',
            fontWeight: 600,
            marginRight: '8px',
            color: '#919EAB',
            lineHeight: '17.442px',
          }}
        >
          ${originalPrice}
        </Typography>
      )}
      {shippingChipVisibility && <ShippingChip shippingType={shippingType} />}
    </Box>
  </CardContent>
);

export default ProductInfo;
