import { Box } from '@mui/material';
import InventoryPagination from './InventoryPagination';
import InventoryBulkActionsMenu from './InventoryBulkActionsMenu';

export default function InventoryFooter() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        w: 1,
      }}
    >
      <InventoryBulkActionsMenu />
      <InventoryPagination />
    </Box>
  );
}
