import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router';
import { IS_SANDBOX } from 'src/config';

export interface INewSubscriptionRequestData {
  stripeRequestData: {
    planId: string;
    coupon?: string;
    shopCapacity?: number;
  };
  navigate: NavigateFunction;
}
export interface ICouponDetails {
  coupon: {
    createdAt: string;
    discountPercentage: number;
    duration: number;
    isValid: boolean;
    name: string;
    override: boolean;
    plans: string[];
  };
  discounts: { name: string; value: number }[];
  message: string;
}

export interface ISelectedSubscription {
  planName: string;
  subscription: string;
  price: number;
  annualPrice?: number;
  labelAction: string;
  caption: string;
  planId: string;
  stripePlanId: string;
  lists: any[];
  redeemBy: any;
  stripeId: string;
  updatedAt: string;
  _id: string;
  prodLink: string;
  sandLink: string;
}

export interface IPricingState {
  error: string;
  loading: boolean;
  checkoutLoading: boolean;
  selectedSubscription: ISelectedSubscription;
  couponDetails: ICouponDetails;
  isCouponValid: boolean;
  isThereAnyCouponError: boolean;
  stripeReferrerUrl: string;
  hasUserChangedTheSliderValue: boolean;
  isClosetSelectionErrorDialogOpen: boolean;
  isDowngradeConfirmationDialogOpen: boolean;
  isUpgradeConfirmationDialogOpen: boolean;
  sliderValue: number;
  stripePlanIdForUpdate: string;
}

const INITIAL_URL_ACCORDING_TO_ENV = IS_SANDBOX
  ? 'https://sandbox.primelister.com/user#billing'
  : 'https://app.primelister.com/user#billing';

const initialState = {
  error: '',
  loading: false,
  checkoutLoading: false,
  selectedSubscription: {
    planName: '',
    subscription: '',
    price: 0,
    annualPrice: 0,
    labelAction: '',
    caption: '',
    planId: '',
    stripePlanId: '',
    lists: [],
    prodLink: '',
    sandLink: '',
  },
  couponDetails: {},
  isThereAnyCouponError: false,
  isCouponValid: false,
  stripeReferrerUrl: INITIAL_URL_ACCORDING_TO_ENV,
  hasUserChangedTheSliderValue: false,
  isClosetSelectionErrorDialogOpen: false,
  isDowngradeConfirmationDialogOpen: false,
  isUpgradeConfirmationDialogOpen: false,
  sliderValue: 1,
  stripePlanIdForUpdate: '',
};

const pricingSlice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    setSelectedSubscription: (state, action) => {
      state.selectedSubscription = action.payload;
    },
    setCouponDetails: (state, action) => {
      state.couponDetails = action.payload;
    },
    setIsThereAnyCouponError: (state, action) => {
      state.isThereAnyCouponError = action.payload;
    },
    setIsCouponValid: (state, action) => {
      state.isCouponValid = action.payload;
    },
    setStripeReferrerUrl: (state, action) => {
      state.stripeReferrerUrl = action.payload;
    },
    setHasUserChangedTheSliderValue: (state, action) => {
      state.hasUserChangedTheSliderValue = action.payload;
    },
    setIsClosetSelectionErrorDialogOpen: (state, action) => {
      state.isClosetSelectionErrorDialogOpen = action.payload;
    },
    setIsDowngradeConfirmationDialogOpen: (
      state,
      action: PayloadAction<{ openDialog: boolean; stripePlanId: string }>
    ) => {
      state.isDowngradeConfirmationDialogOpen = action.payload.openDialog;
      state.stripePlanIdForUpdate = action.payload.stripePlanId;
    },
    setIsUpgradeConfirmationDialogOpen: (
      state,
      action: PayloadAction<{ openDialog: boolean; stripePlanId: string }>
    ) => {
      state.isUpgradeConfirmationDialogOpen = action.payload.openDialog;
      state.stripePlanIdForUpdate = action.payload.stripePlanId;
    },
    setSliderValue: (state, action) => {
      state.sliderValue = action.payload;
    },
    //--
    updateSubscriptionRequest: (
      state,
      action: PayloadAction<{
        flowType: string;
        subscriptionId?: string;
        subscriptionItemId?: string;
        planId?: string;
        returnUrl?: string;
        shopCapacity?: number;
      }>
    ) => {
      state.loading = true;
      state.error = '';
    },
    updateSubscriptionSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    updateSubscriptionFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    checkCouponCodeValidationRequest: (
      state,
      action: PayloadAction<{
        plan: string | null;
        code: string;
        type?: string;
        navigate?: NavigateFunction;
        sliderValue?: number;
      }>
    ) => {
      state.loading = true;
      state.error = '';
      state.isCouponValid = false;
      state.isThereAnyCouponError = false;
    },
    checkCouponCodeValidationSuccess: (state) => {
      state.loading = false;
      state.error = '';
      state.isCouponValid = true;
      state.isThereAnyCouponError = false;
    },
    checkCouponCodeValidationFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.isCouponValid = false;
      state.isThereAnyCouponError = true;
    },
    //--
    checkCouponCodeViaComparison: (
      state,
      action: PayloadAction<{
        plan: string;
        coupon: string;
        referrerCode: string;
        typeReferrer: string;
        typeCoupon: string;
        navigate?: NavigateFunction;
        sliderValue?: number;
      }>
    ) => {
      state.loading = true;
      state.error = '';
      state.isCouponValid = false;
      state.isThereAnyCouponError = false;
    },
    checkCouponCodeViaComparisonSuccess: (state) => {
      state.loading = false;
      state.error = '';
      state.isCouponValid = true;
      state.isThereAnyCouponError = false;
    },
    checkCouponCodeViaComparisonFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.isCouponValid = false;
      state.isThereAnyCouponError = true;
    },
    //--
    newSubscriptionRequest: (state, action: PayloadAction<INewSubscriptionRequestData>) => {
      state.checkoutLoading = true;
      state.error = '';
    },
    newSubscriptionSuccess: (state) => {
      state.checkoutLoading = false;
      state.error = '';
    },
    newSubscriptionFail: (state, action) => {
      state.checkoutLoading = false;
      state.error = action.payload;
    },
    resetPricingState: (state) => initialState,
  },
});

export const {
  resetPricingState,
  setSelectedSubscription,
  setCouponDetails,
  setIsThereAnyCouponError,
  setIsCouponValid,
  setStripeReferrerUrl,
  setHasUserChangedTheSliderValue,
  setIsClosetSelectionErrorDialogOpen,
  setIsDowngradeConfirmationDialogOpen,
  setIsUpgradeConfirmationDialogOpen,
  setSliderValue,
  //--
  updateSubscriptionRequest,
  updateSubscriptionSuccess,
  updateSubscriptionFail,
  //--
  checkCouponCodeViaComparison,
  checkCouponCodeViaComparisonSuccess,
  checkCouponCodeViaComparisonFail,
  //--
  checkCouponCodeValidationRequest,
  checkCouponCodeValidationSuccess,
  checkCouponCodeValidationFail,
  //--
  newSubscriptionRequest,
  newSubscriptionSuccess,
  newSubscriptionFail,
} = pricingSlice.actions;
export default pricingSlice.reducer;
