import { CardProps } from '@mui/material';
import { Theme } from '@mui/system';

// Define enums with uppercase keys
export enum ProductCardSelectionEnum {
  DEFAULT = 'default',
  INDETERMINATE = 'indeterminate',
  ALL = 'all',
}

export enum ProductCardShippingEnum {
  FREE = 'Free',
  DISCOUNTED = 'Discounted',
}

export enum ProductCardStatusLabelEnum {
  SOLD_OUT = 'Sold Out',
  NOT_FOR_SALE = 'Not For Sale',
  DROPPING_SOON = 'Dropping Soon',
  SOLD = 'Sold',
}

export enum ProductCardConditionChipEnum {
  BOUTIQUE = 'Boutique',
  NEW_WITH_TAGS = 'NWT',
}

export interface StyledCardProps extends CardProps {
  isSelected: boolean;
  selection: ProductCardSelectionEnum;
  theme?: Theme;
  cardWidth?: number;
  optionalPropsVisibility?: boolean;
}

export interface ProductCardProps {
  id: string;
  thumbnailUrl?: string;
  title: string;
  size: string;
  brand: string;
  price: string;
  originalPrice: string;
  likeCount: number;
  commentCount: number;
  selection: ProductCardSelectionEnum;
  statusTagVisibility: boolean;
  conditionChipVisibility: boolean;
  shippingChipVisibility: boolean;
  originalPriceVisibility: boolean;
  likeCountVisibility: boolean;
  commentCountVisibility: boolean;
  shippingType: ProductCardShippingEnum;
  statusLabel: ProductCardStatusLabelEnum;
  conditionChip: ProductCardConditionChipEnum;
  cardWidth?: number;
  optionalPropsVisibility?: boolean;
  droppingSoonDate?: Date;
  onCardClick?: () => void;
  isSelected: boolean;
}

export type ExtendedProductCardProps = ProductCardProps & CardProps;
