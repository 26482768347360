import { Stack, Tab, Tabs, useTheme } from '@mui/material';
import { GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Iconify from 'src/components/iconify';
import { IRootState } from 'src/store';
import {
  setLoadingEffect,
  setPage,
  setPopoverData,
  setSelectedCheckboxes,
  setSelectedTab,
  setTasksFilterModel,
} from 'src/store/dashboard/slices/tasksSlice';

const STATUS_OPTIONS = [
  { value: 'Completed', icon: 'icon-park-solid:check-one', dimension: '22px', mr: '8px', mb: 0 },
];

const TasksToolbar = () => {
  const theme = useTheme();
  const { selectedTab } = useSelector((state: IRootState) => state.tasks);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabChange = (event: React.SyntheticEvent<Element, Event>, newValue: string) => {
    dispatch(setPopoverData(null));
    dispatch(setPage(0));
    dispatch(setSelectedTab(newValue));
    dispatch(setSelectedCheckboxes([]));
    dispatch(
      setTasksFilterModel({
        items: [],
        linkOperator: 'and',
        quickFilterLogicOperator: 'and',
        quickFilterValues: [],
      })
    );
    setSearchParams({
      orderBy: 'createdDate',
      order: 'desc',
      tab: newValue,
    });
    setLoadingEffect(true);
  };

  const tabColor = (index: number) => {
    if (index === 0) {
      return theme.palette.success.main;
    } else {
      return theme.palette.info.main;
    }
  };

  return (
    <Stack direction="column" width="100%">
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        sx={{
          px: 2,
          borderBottom: '1px solid rgba(155,155,155,0.2)',
          bgcolor: 'background.neutral',
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        }}
      >
        {STATUS_OPTIONS.map((tab, index) => (
          <Tab
            key={tab.value}
            label={tab.value}
            value={tab.value.toUpperCase()}
            icon={
              <Iconify
                icon={tab.icon}
                width={tab.dimension}
                height={tab.dimension}
                mr={tab.mr}
                sx={{ color: tabColor(index), mb: tab.mb }}
              />
            }
            sx={{ fontWeight: 700 }}
          />
        ))}
      </Tabs>
      <Stack alignItems="flex-start" justifyContent="center" p={1}>
        <GridToolbarFilterButton
          sx={{ p: 1.5 }}
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        />
      </Stack>
    </Stack>
  );
};

export default TasksToolbar;
