import { ActionType, LoginStatusOptions } from 'src/hooks/usePlatformLoginStatusCheck';
import {
  IBulkActionsSelectedListings,
  IInventoryPopoverData,
  IListingSources,
} from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import { IPlatforms } from 'src/store/dashboard/slices/myShopsSlice';

export function checkListingSourcesLoginStatus(
  listings: IInventoryPopoverData | IBulkActionsSelectedListings[],
  getLoginStatusOfMarketplace: (
    actionType: ActionType,
    platform: IPlatforms,
    options?: LoginStatusOptions
  ) => boolean
) {
  const processSingleListing = (listing: IInventoryPopoverData): IListingSources[] =>
    Object.keys(listing.marketplaceSources).map((marketplace) => ({
      loginStatus: getLoginStatusOfMarketplace(ActionType.CROSSLIST, marketplace as IPlatforms),
      platformName: listing.marketplaceSources[marketplace].platform,
      listingId: listing._id,
    }));

  let allListingSources: IListingSources[] = [];

  if (Array.isArray(listings)) {
    listings.forEach((listing) => {
      allListingSources.push(...processSingleListing(listing));
    });
  } else {
    allListingSources = processSingleListing(listings);
  }

  const atLeastOneSourceConnected = allListingSources.some((listing) => listing.loginStatus);

  return { allListingSources, atLeastOneSourceConnected };
}

export function checkBulkListingSourcesLoginStatus(
  listings: IInventoryPopoverData | IBulkActionsSelectedListings[],
  getLoginStatusOfMarketplace: (actionType: ActionType, platform: IPlatforms) => boolean
) {
  const processSingleListing = (
    listing: IInventoryPopoverData
  ): { sources: IListingSources[]; oneSourceConnected: boolean } => {
    const sources: IListingSources[] = Object.keys(listing.marketplaceSources).map(
      (marketplace) => ({
        loginStatus: getLoginStatusOfMarketplace(ActionType.CROSSLIST, marketplace as IPlatforms),
        platformName: listing.marketplaceSources[marketplace].platform,
        listingId: listing.id!,
      })
    );
    const oneSourceConnected = sources.some((source) => source.loginStatus);

    return { sources, oneSourceConnected };
  };

  const perListingData: { sources: IListingSources[]; oneSourceConnected: boolean }[] = [];

  if (Array.isArray(listings)) {
    listings.forEach((listing) => {
      perListingData.push(processSingleListing(listing));
    });
  } else {
    perListingData.push(processSingleListing(listings));
  }

  return perListingData;
}
