import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Typography, Stack, useMediaQuery, Slider, Link } from '@mui/material';

import PoshmarkAutoPricingCard from './PoshmarkAutoPricingCard';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { useEffect, useState } from 'react';
import { getPoshmarkPlans } from '../../utils/constants';
import { useSearchParams } from 'react-router-dom';
import {
  setHasUserChangedTheSliderValue,
  setIsClosetSelectionErrorDialogOpen,
  setSliderValue,
} from 'src/store/dashboard/slices/pricingSlice';
import PoshmarkPricingDialogs from './dialogs/PoshmarkPricingDialogs';
import { SubscriptionStatus } from 'src/store/dashboard/slices/accountSlice';
import { openCrispChat } from 'src/utils/crispChatHelpers';
import { useCheckSubscriptionStatus } from 'src/hooks/useCheckSubscriptionStatus';

// ----------------------------------------------------------------------

export default function PoshmarkAutomationPricingPage() {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:757px)');
  const sliderMobileMatches = useMediaQuery('(min-width:675px)');
  const [searchParams, setSearchParams] = useSearchParams();
  const [isThereAnyActivePlan, setIsThereAnyActivePlan] = useState(false);
  const [shouldDisplayContactUs, setShouldDisplayContactUs] = useState(false);
  const { billingSubscriptionList, poshmarkShopCapacity } = useSelector(
    (state: IRootState) => state.account
  );
  const { connectedShopsCount } = useSelector((state: IRootState) => state.myCloset);
  const { sliderValue } = useSelector((state: IRootState) => state.pricing);
  const { isSubscriptionExpired } = useCheckSubscriptionStatus();

  useEffect(() => {
    billingSubscriptionList[1]?.status?.toLowerCase().includes(SubscriptionStatus.ACTIVE) &&
      setIsThereAnyActivePlan(true);
  }, [billingSubscriptionList]);

  useEffect(() => {
    const isRedirectedForUpgrade = searchParams.get('action') === 'upgrade';
    const controlledConnectedShopsCount = connectedShopsCount < 1 ? 1 : connectedShopsCount;
    const controlledSliderValue = isSubscriptionExpired
      ? controlledConnectedShopsCount
      : poshmarkShopCapacity;

    if (isRedirectedForUpgrade) {
      dispatch(setSliderValue(controlledSliderValue + 1));
    } else {
      dispatch(setSliderValue(controlledSliderValue));
    }
  }, [poshmarkShopCapacity]);

  function valueLabelFormat(value: number) {
    return value > 1 ? `${value} closets` : `${value} closet`;
  }

  const handleClosetCountSliderChange = (event: Event, newValue: number | number[]) => {
    dispatch(setSliderValue(Number(newValue)));
    if (newValue !== poshmarkShopCapacity) {
      dispatch(setHasUserChangedTheSliderValue(true));
    } else {
      dispatch(setHasUserChangedTheSliderValue(false));
    }
  };

  const handleClosetCountSliderCommit = () => {
    if (sliderValue < connectedShopsCount) {
      dispatch(setIsClosetSelectionErrorDialogOpen(true));
    }
  };

  useEffect(() => {
    if (sliderValue !== poshmarkShopCapacity) {
      dispatch(setHasUserChangedTheSliderValue(true));
    } else {
      dispatch(setHasUserChangedTheSliderValue(false));
    }
    if (sliderValue >= 10) {
      setShouldDisplayContactUs(true);
    } else {
      setShouldDisplayContactUs(false);
    }
  }, [sliderValue]);

  return (
    <>
      <Helmet>
        <title> Pricing | Primelister</title>
      </Helmet>

      <Container
        disableGutters
        maxWidth={'lg'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" align="center" paragraph>
          Choose the right plan that&apos;s for your business
          <br />
        </Typography>
        <Stack sx={{ mt: '16px' }}>
          <Typography
            align="center"
            paragraph
            sx={{
              fontSize: '20px',
              fontWeight: '600',
              lineHeight: '24px',
            }}
          >
            How many Poshmark<sup>™</sup> closets do you have?
          </Typography>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              width: sliderMobileMatches ? '585px' : '95%',
              mt: '51px',
            }}
          >
            <Typography sx={{ mr: 2, fontSize: '14px', fontWeight: '700' }}>1</Typography>
            <Slider
              size="medium"
              marks
              min={1}
              step={1}
              max={10}
              defaultValue={1}
              value={sliderValue}
              valueLabelDisplay="on"
              valueLabelFormat={valueLabelFormat}
              onChange={handleClosetCountSliderChange}
              onChangeCommitted={handleClosetCountSliderCommit}
              sx={{
                flexGrow: 1,
                maxWidth: '540px',
                '&.MuiSlider-root .MuiSlider-rail': {
                  minHeight: '8px !important',
                },
                '&.MuiSlider-root .MuiSlider-mark': {
                  minHeight: '5px !important',
                  minWidth: '5px !important',
                  borderRadius: '50%',
                  ml: '-2px !important',
                },
              }}
            />
            <Typography sx={{ ml: 2, fontSize: '14px', fontWeight: '700' }}>10</Typography>
          </Stack>

          {shouldDisplayContactUs && (
            <Typography align="center" mt={'32px'}>
              Need to connect more than 10 closets?{' '}
              <Link
                color="primary"
                underline="always"
                sx={{ cursor: 'pointer', fontWeight: 'bold' }}
                onClick={openCrispChat}
              >
                Contact us.
              </Link>
            </Typography>
          )}
        </Stack>
        <Typography align="center" sx={{ color: 'text.secondary', mt: '32px' }}>
          Pay by the month or year, and cancel at any time
        </Typography>

        <Stack
          flexWrap="wrap"
          spacing={3}
          justifyContent="center"
          alignItems="center"
          mt={5}
          direction={matches ? 'row' : 'column'}
          sx={{ width: '100%' }}
        >
          {getPoshmarkPlans(isThereAnyActivePlan, sliderValue).map((card, index) => (
            <PoshmarkAutoPricingCard key={card.planId} card={card} index={index + 1} />
          ))}
        </Stack>
        <PoshmarkPricingDialogs />
      </Container>
    </>
  );
}
