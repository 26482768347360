import { IBillingSubscription, SubscriptionStatus } from 'src/store/dashboard/slices/accountSlice';
import { getDateWithTimeZone } from '../../../../../../utils/getDateWithTimezone';
import { IUserAccountDetail } from '../../../types/accountTypes';

interface SubscriptionStatusLabels {
  [key: string]: 'success' | 'info' | 'error' | 'warning';
}

const SUBSCRIPTION_STATUS_LABELS: SubscriptionStatusLabels = {
  active: 'success',
  trial: 'info',
  canceled: 'error',
  expired: 'error',
};

export const getSubscriptionQuota = (
  subscription: IBillingSubscription,
  accountDetail: IUserAccountDetail
) => {
  const isPoshmark = subscription.subscriptionName.toLowerCase().includes('poshmark');
  const isTrial = subscription?.status?.toLowerCase() === SubscriptionStatus.TRIAL;

  if (isPoshmark && isTrial) {
    return `${accountDetail.sharerTaskCount || 0}/15,000`;
  }

  if (subscription?.status?.toLowerCase() === SubscriptionStatus.ACTIVE) {
    return 'Unlimited';
  }

  return `${accountDetail.taskCount || 0}/100`;
};

export const getSubscriptionIcon = (subscription: IBillingSubscription) =>
  subscription.subscriptionName.toLowerCase().includes('poshmark')
    ? 'ph:gear-fill'
    : 'ph:arrows-left-right-bold';

export const getExpiresMessage = (subscription: IBillingSubscription, timeZone: string) => {
  if (subscription.planId === 'poshmark-plan-lifetime') {
    return 'Lifetime';
  }

  const now = Date.now();
  const isExpired = now > subscription.expires;
  const willExpireCancel =
    now < subscription?.expires &&
    subscription?.status?.toLowerCase().includes(SubscriptionStatus.CANCELED);
  const willExpireTrial =
    now < subscription?.expires && subscription?.status?.toLowerCase() === SubscriptionStatus.TRIAL;
  const expiresDate = getDateWithTimeZone(subscription.expires, timeZone, 'MMM Do, YYYY');

  if (isExpired) {
    return `Your subscription expired on ${expiresDate}`;
  }

  if (willExpireCancel) {
    return `Your subscription will expire on ${expiresDate}`;
  }
  if (willExpireTrial) {
    return `Your trial will expire on ${expiresDate}`;
  }

  return `You will be charged $${subscription.nextInvoiceAmount || '0.00'} on ${expiresDate}`;
};
export const getSubscriptionStatusLabelColor = (subscription: IBillingSubscription | undefined) => {
  const status = subscription?.status?.toLowerCase();

  if (!status) {
    return 'error';
  }

  return SUBSCRIPTION_STATUS_LABELS[status] || 'error';
};

export const addMillisecondsToTimestamp = (subscription: IBillingSubscription) => {
  const createdOn = subscription?.createdOn;

  if (createdOn === null || createdOn === undefined) {
    return createdOn;
  }

  return createdOn.toString().length < 12 ? createdOn * 1000 : createdOn;
};

export const getConditionalSubscriptionList = (
  billingSubscriptionList: IBillingSubscription[],
  isPoshmarkTool: boolean
) => {
  if (isPoshmarkTool) {
    return billingSubscriptionList.length > 1 ? [billingSubscriptionList[1]] : [];
  }
  return billingSubscriptionList;
};
