import { Box, Button, Link, Stack, Typography } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import PopupDialog from 'src/components/dialog/PopupDialog';
import { FC, useRef } from 'react';
import useCopyToClipboard from 'src/hooks/useCopyToClipboard';
import { useDispatch, useSelector } from 'react-redux';
import { setOpen } from 'src/store/dashboard/slices/homeSlice';
import { IRootState } from 'src/store';

const DiscountDialog = () => {
  const { open } = useSelector((state: IRootState) => state.home);
  const dispatch = useDispatch();
  const code = useRef('jwsofhng');
  const { copy } = useCopyToClipboard();

  const handleCopy = () => {
    copy(code.current);
    dispatch(setOpen(false));
  };

  return (
    <PopupDialog
      open={open}
      onClose={() => dispatch(setOpen(false))}
      align={'center'}
      sx={{ width: '480px' }}
      actions={
        <Box>
          <Stack direction={'row'} spacing={2} justifyContent={'center'}>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={handleCopy}
              sx={{ paddingX: '24px' }}
            >
              Copy
            </Button>
            <Button
              variant={'contained'}
              color={'inherit'}
              onClick={() => dispatch(setOpen(false))}
              sx={{ paddingX: '24px' }}
            >
              Close
            </Button>
          </Stack>
          <Link
            href={
              'https://docs.primelister.com/account-and-subscription/how-to-apply-discount-coupons'
            }
            color={'inherit'}
            target={'_blank'}
            sx={{ textDecoration: 'none!important', paddingTop: 2, display: 'block' }}
          >
            <Typography variant={'body2'}>How do I apply a discount coupon code?</Typography>
          </Link>
        </Box>
      }
    >
      <Box paddingTop={2}>
        <CheckCircleOutlineOutlinedIcon sx={{ fontSize: '72px' }} color={'success'} />
        <Typography variant={'h5'} paddingBottom={2}>
          Success!
        </Typography>
        <Typography variant={'body2'}>
          YOUR CODE: <b>{code.current}</b>
        </Typography>
      </Box>
    </PopupDialog>
  );
};

export default DiscountDialog;
