import { Divider, Stack } from '@mui/material';
import OfferDelay from './OfferDelay';
import OfferToLikersRules from './OfferToLikersSettingsRules';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { Automation } from '@beta.limited/primelister';

const mockRule = {
  filters: {
    category: { enabled: false, selectedCategories: [] },
    ageRange: { enabled: false, min: 0, max: 12 },
    dateRange: { enabled: false, min: 0, max: 12 },
    likeRange: { enabled: false, min: 0, max: 100 },
    lowestPriceRange: { enabled: false, min: 1, max: 2 },
    priceRange: { enabled: false, min: 1, max: 2 },
    condition: { enabled: false, nwt: false, boutique: false },
    earnings: { enabled: false, unit: 'percentage', value: 0 },
  },
  offer: { unit: 'percentage', value: 10 },
  shipping: { option: 'original', customValue: '' },
};

const OfferToLikersSettings = () => {
  const { automationsSettings } = useSelector((state: IRootState) => state.automations);

  const offerToLikersSettings = automationsSettings[Automation.AUTO_SEND_OFFER_TO_LIKERS];
  const rules = offerToLikersSettings?.config?.rules || [mockRule];
  return (
    <Stack spacing={'20px'} divider={<Divider sx={{ borderStyle: 'dashed' }} />}>
      <OfferDelay />
      <OfferToLikersRules initialSettings={rules} />
    </Stack>
  );
};

export default OfferToLikersSettings;
