import { Automation, UserAction } from '@beta.limited/primelister';
import {
  automationItemsList,
  completedTabItemsList,
  ongoingTabItemsList,
} from '../utils/constants';

export const getActivityTypeProps = (activityType: string, isFromCompletedTab?: boolean) => {
  const isRelist = activityType === 'relist';
  const matchValue = isRelist ? Automation.AUTO_RELIST : activityType;
  const matchedActivity = completedTabItemsList.find((item) => item.value === matchValue);

  const activityIcon = !isFromCompletedTab ? matchedActivity?.autoIcon : matchedActivity?.name;

  const activityDisplayName = isRelist ? 'Relist' : matchedActivity?.displayName;
  return { activityIcon, activityDisplayName };
};

export const getOngoingActivityTypeProps = (ongoingTaskType: UserAction) => {
  const matchedOngoingTask = ongoingTabItemsList.find((item) => item.value === ongoingTaskType);

  const activityIcon = matchedOngoingTask?.autoIcon;
  const activityDisplayName = matchedOngoingTask?.displayName;
  return { activityIcon, activityDisplayName };
};
