import * as React from 'react';
import { Box, Stack } from '@mui/material';
import Label, { LabelColor } from 'src/components/label';

const statusConfig = [
  { color: 'primary', text: 'listed', value: 'Listed' },
  { color: 'success', text: 'sold', value: 'Sold' },
];

export default function QuickEditPopoverStatusContent({
  selectedStatus,
  onSelect,
}: {
  selectedStatus: string;
  onSelect: (status: string) => void;
}) {
  return (
    <Stack
      sx={{
        p: '4px',
        gap: '4px',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      {statusConfig.map((status, index) => (
        <Box
          key={index}
          sx={{
            padding: '5px 8px',
            borderRadius: '6px',
            backgroundColor:
              status.text === selectedStatus ? 'rgba(145, 158, 171, 0.16)' : 'inherit',
            '&:hover': {
              backgroundColor:
                status.text === selectedStatus
                  ? 'rgba(145, 158, 171, 0.16)'
                  : 'rgba(145, 158, 171, 0.08)',
            },
          }}
          onClick={() => status.text !== selectedStatus && onSelect(status.value)}
        >
          <Label
            color={status.color as LabelColor}
            variant="soft"
            sx={{
              textTransform: 'capitalize',
              width: 'fit-content',
            }}
          >
            {status.text}
          </Label>
        </Box>
      ))}
    </Stack>
  );
}
