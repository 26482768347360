import { Toolbar, useTheme } from '@mui/material';
import { NavSectionHorizontal } from 'src/components/nav-section';
import { bgBlur } from 'src/utils/cssStyles';
import { INavbarProps } from './NavHorizontal';

const NavInHeader = ({ navConfig }: INavbarProps) => {
  const theme = useTheme();

  return (
    <Toolbar
      color="transparent"
      sx={{
        minHeight: '48px !important',
        position: 'absolute',
        top: '6px',
        left: '20%',
        right: '20%',
        justifySelf: 'center !important',
        alignSelf: 'center !important',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <NavSectionHorizontal data={navConfig} />
    </Toolbar>
  );
};

export default NavInHeader;
