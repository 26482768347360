import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { IRootState } from 'src/store';
import {
  createUserActionQueueJobRequest,
  setBulkActionsListingList,
  setOpenExtensionCommonDialog,
  setSelectedCheckboxes,
} from 'src/store/dashboard/slices/inventorySlice';
import { useMemo, useState } from 'react';
import { checkSupportedPlatforms } from '../../../../helpers/checkSupportedPlatforms';
import usePlatformLoginStatusCheck, { ActionType } from 'src/hooks/usePlatformLoginStatusCheck';
import { toast } from 'react-toastify';
import Image from 'src/components/image';
import { LoadingButton } from '@mui/lab';
import { Marketplace, UserAction } from '@beta.limited/primelister';
import {
  IAPIMarketplaces,
  IMarketPlaceProps,
} from 'src/store/dashboard/slices/myShopsAPITableSlice';
import { platformIcons } from './InventoryBulkCrossListDialog';
import { IPlatforms } from 'src/store/dashboard/slices/myShopsSlice';
import { getShopperId } from 'src/pages/dashboard/Inventory/mainComponents/ImportTable/importDataGrid/helpers';

interface IActionTypeMapping {
  [key: number]: string;
  0: UserAction.DELETE | 'Delete from';
  1: UserAction.DUPLICATE | 'Duplicate in';
  2: UserAction.RELIST | 'Re-list to';
  5: UserAction.DE_LIST | 'De-list from';
}

interface ILoginStatusActionTypeMapping {
  [key: number]: ActionType;
  0: ActionType.DELETE;
  1: ActionType.DUPLICATE;
  2: ActionType.RELIST;
  5: ActionType.DELIST;
}

const actionTypeMapping: IActionTypeMapping = {
  0: UserAction.DELETE,
  1: UserAction.DUPLICATE,
  2: UserAction.RELIST,
  5: UserAction.DE_LIST,
};

const loginStatusActionTypeMapping: ILoginStatusActionTypeMapping = {
  0: ActionType.DELETE,
  1: ActionType.DUPLICATE,
  2: ActionType.RELIST,
  5: ActionType.DELIST,
};

const actionButtonMapping: IActionTypeMapping = {
  0: 'Delete from',
  1: 'Duplicate in',
  2: 'Re-list to',
  5: 'De-list from',
};
const StyledTooltipComponent = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(`
& .MuiTooltip-tooltip {
  background: slategray
}
`);

//Shared dialog component for relist, delist, delete, duplicate actions
export default function ExtensionCommonDialog() {
  const dispatch = useDispatch();

  // Use object destructuring to extract the necessary state values from the redux store
  const {
    openExtensionCommonDialog,
    selectedCheckboxes,
    extensionActionType,
    bulkActionsListingList,
  } = useSelector((state: IRootState) => state.inventory);
  const myShops = useSelector((state: IRootState) => state.myShops);
  // Use a state hook to keep track of the selected marketplaces
  const [selectedMarketPlaces, setSelectedMarketPlaces] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { getLoginStatusOfMarketplace } = usePlatformLoginStatusCheck();

  // Use conditional logic to toggle the selected marketplaces
  function handleMarketPlaceSelection(name: string) {
    selectedMarketPlaces.includes(name)
      ? setSelectedMarketPlaces(selectedMarketPlaces.filter((marketPlace) => marketPlace !== name))
      : setSelectedMarketPlaces([...selectedMarketPlaces, name]);
  }

  // Use a utility function to get the selected action platforms
  const actionPlatforms = useMemo(() => {
    const selectedMarketplaceSources = selectedCheckboxes
      .map(
        (selectedId) =>
          bulkActionsListingList.find((item) => item?._id === selectedId)?.marketplaceSources
      )
      .filter((marketplaceSources) => marketplaceSources);

    // Flattens the array of 'marketplaceSources' objects into a single array of unique platform names
    const actionPlatforms = selectedMarketplaceSources
      .flatMap((marketplaceSource) => Object.keys(marketplaceSource!))
      .filter((platform) => platform);
    // Filters the platformIcons array to only include the icons with names that match the selected platforms
    const renderedMarketPlaces = platformIcons.filter((icon) =>
      actionPlatforms.includes(icon.name)
    );

    return renderedMarketPlaces;
  }, [selectedCheckboxes, bulkActionsListingList]);

  // Use a utility function to handle closing the dialog
  const handleClose = () => {
    dispatch(setOpenExtensionCommonDialog(false));
    setTimeout(() => {
      setSelectedMarketPlaces([]);
      setIsLoading(false);
    }, 700);
  };

  // Use a utility function to get the action name and button text based on the extension action type
  const actionName: string = actionTypeMapping[extensionActionType] || '';
  const actionButtonText: string = actionButtonMapping[extensionActionType] || '';
  const loginStatusActionType: ActionType = loginStatusActionTypeMapping[extensionActionType] || '';
  // Use an async function to handle selected action type
  const handleExecuteActionType = async () => {
    setIsLoading(true);
    toast.success(
      `${actionButtonMapping[extensionActionType].split(' ')[0]} tasks added to queue successfully`,
      {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      }
    );
    // Use a for...of loop to relist the selected items on each selected platform
    for (const marketplace of selectedMarketPlaces) {
      let listings: {
        id: string;
        name: string;
        img: string;
        sku: string;
        sv: string;
      }[] = [];

      listings = bulkActionsListingList
        .filter((item) => item?.marketplaceSources?.[marketplace])
        .map((item) => ({
          id: item.marketplaceSources[marketplace].id,
          name: item.title,
          img: item.image,
          sku: item.sku,
          sv: Object.keys(item.marketplaceSources)[0],
        }));

      // If the marketplace is Mercari or Depop, use the userId instead of the username
      const shopId = getShopperId(marketplace as Marketplace, myShops);

      dispatch(
        createUserActionQueueJobRequest({
          action: actionName,
          selectedCheckboxes: selectedCheckboxes,
          listings: listings
            .filter((item) => item.id !== null)
            .map((item) => ({
              id: item.id,
              image: item.img,
              title: item.name,
            })),
          marketplace: marketplace as Marketplace,
          shop: shopId,
        })
      );

      listings = [];
    }
    dispatch(setBulkActionsListingList([]));
    handleClose();
  };

  const tooltipForDisabledMarketplace = (icon: { name: string; url: string }) => {
    const message = !checkSupportedPlatforms(extensionActionType, icon.name)
      ? `This feature is not supported in this marketplace yet.`
      : 'Login Required: Please log in to this platform in order to perform any actions.';
    return message;
  };

  const renderIcons = (icon: { name: IPlatforms; url: string }, index: number) => (
    <Stack
      key={index}
      sx={{
        minWidth: '7rem',
        maxWidth: '7rem',
        minHeight: '7rem',
        borderRadius: '7rem',
        m: 1,
        position: 'relative',
        '&:hover': {
          transform: checkSupportedPlatforms(extensionActionType, icon.name)
            ? 'scale(1.05)'
            : 'scale(1)',
        },
        '&:active': { transform: 'scale(1)' },
      }}
      onClick={() =>
        getLoginStatusOfMarketplace(loginStatusActionType, icon.name) &&
        checkSupportedPlatforms(extensionActionType, icon.name) &&
        handleMarketPlaceSelection(icon?.name)
      }
    >
      {' '}
      <Image
        src={icon.url}
        sx={{
          maxWidth: '6.5rem',
          maxHeight: '6.5rem',
          borderRadius: '6.5rem',
        }}
      />
      {checkSupportedPlatforms(extensionActionType, icon.name) &&
      getLoginStatusOfMarketplace(loginStatusActionType, icon.name) ? (
        <Image
          src="/icons/check.png"
          sx={{
            width: '6rem',
            height: '6rem',
            borderRadius: '6rem',
            cursor: 'pointer',
            zIndex: 1,
            position: 'absolute',
            top: '0.25rem',
            left: '0.25rem',
            opacity: `${selectedMarketPlaces.includes(icon.name) ? 1 : 0}`,
            transition: 'all 0.25s ease-in-out',
          }}
        />
      ) : (
        <StyledTooltipComponent title={tooltipForDisabledMarketplace(icon)} placement="top" arrow>
          <Box
            sx={{
              bgcolor: 'slategray',
              width: '6rem',
              height: '6rem',
              borderRadius: '6rem',
              cursor: 'pointer',
              zIndex: 1,
              position: 'absolute',
              top: '0.25rem',
              left: '0.25rem',
              opacity: 0.5,
            }}
          />
        </StyledTooltipComponent>
      )}
    </Stack>
  );

  return (
    <Dialog fullWidth maxWidth="lg" open={openExtensionCommonDialog} onClose={handleClose}>
      <DialogTitle mb={3}>Please select target marketplaces to execute this command </DialogTitle>
      <DialogContent
        sx={{
          typography: 'body2',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        {actionPlatforms.map((icon, index) => renderIcons(icon, index))}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Close
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={isLoading}
          disabled={!selectedMarketPlaces.length || isLoading}
          onClick={handleExecuteActionType}
          sx={{ textTransform: 'none' }}
        >
          {!selectedMarketPlaces.length
            ? 'Please select at least one marketplace'
            : `${actionButtonText} ${selectedMarketPlaces.length} marketplace`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
