import { useDispatch, useSelector } from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { setErrorDetailDialogOpen } from 'src/store/dashboard/slices/tasksSlice';
import { IRootState } from 'src/store';

export default function TaskErrorDetailDialog() {
  const dispatch = useDispatch();
  const { errorDetailDialogOpen, popoverData, taskErrorList } = useSelector(
    (state: IRootState) => state.tasks
  );

  const handleClose = () => {
    dispatch(setErrorDetailDialogOpen(false));
  };

  const handleResolveError = () => {
    link && window.open(link, '_blank', 'noopener noreferrer');
  };

  const errorText = popoverData?.errorText;
  let link = taskErrorList.find((item) => {
    const matches = item.match;
    if (
      matches &&
      matches.every((match) => errorText?.toLowerCase().includes(match.toLowerCase()))
    ) {
      return true;
    }

    return false;
  })?.link;

  const showResolveButton = () =>
    link ? (
      <Button
        variant="contained"
        color="primary"
        onClick={handleResolveError}
        sx={{ textTransform: 'none' }}
      >
        Learn How to Resolve This Error
      </Button>
    ) : null;

  return (
    <Dialog fullWidth maxWidth="xs" open={errorDetailDialogOpen} onClose={handleClose}>
      <DialogTitle>Error Details</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>{popoverData?.errorText}</DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={handleClose}>
          Close
        </Button>
        {showResolveButton()}
      </DialogActions>
    </Dialog>
  );
}
