import React from 'react';
import { useDispatch } from 'react-redux';

import { HOST_API_KEY } from 'src/config';
import {
  IBillingSubscription,
  setIsCancelBillingSubscriptionDialogOpen,
  setSelectedSubscriptionToCancel,
} from 'src/store/dashboard/slices/accountSlice';

import { Button } from '@mui/material';
import { isLoggedIn } from 'src/utils/isLoggedIn';

interface ICancelButton {
  subscription: IBillingSubscription;
}

const CancelBillingSubscriptionButton = ({ subscription }: ICancelButton) => {
  const dispatch = useDispatch();
  const { email } = isLoggedIn();

  const handleCancel = () => {
    dispatch(setSelectedSubscriptionToCancel(subscription));
    dispatch(setIsCancelBillingSubscriptionDialogOpen(true));
  };
  const handleTallyCancel = () => {
    dispatch(setSelectedSubscriptionToCancel(subscription));
  };

  if (HOST_API_KEY.includes('sandbox')) {
    return (
      <Button
        color="inherit"
        variant="outlined"
        sx={{ mr: 1, minWidth: '114px !important' }}
        onClick={handleCancel}
      >
        Cancel Plan
      </Button>
    );
  } else {
    return (
      <Button
        data-tally-open="mVKBj3"
        data-tally-layout="modal"
        data-tally-width="720"
        data-tally-emoji-text="👋"
        data-tally-emoji-animation="wave"
        data-tally-auto-close="0"
        color="inherit"
        variant="outlined"
        sx={{ mr: 1, minWidth: '114px !important' }}
        onClick={handleTallyCancel}
        data-email={`${email}`}
        data-subscriptionplan={`${subscription.subscriptionName}`}
      >
        Cancel Plan
      </Button>
    );
  }
};

export default CancelBillingSubscriptionButton;
