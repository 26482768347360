import { Box, SxProps } from '@mui/material';
import Iconify from '../iconify';
import { StyledChip } from './styles';
import { ProductCardProps, ProductCardShippingEnum } from './types';

const ShippingChip: React.FC<{ shippingType: ProductCardProps['shippingType'] }> = ({
  shippingType,
}) => {
  // Define the common style for Chip
  const commonStyle: SxProps = {
    '& .MuiChip-label': {
      paddingRight: 0,
      padding: '3px 8px',
      paddingLeft: '3px',
      fontSize: '13px',
      marginTop: '2px',
      color: '#212B36',
      lineHeight: '18px',
    },
  };

  // Define the specific style for when shippingType is not FREE
  const specificStyle =
    shippingType !== ProductCardShippingEnum.FREE
      ? {
          padding: '3px',
          width: '24px',
          '& .MuiChip-icon': {
            marginLeft: 0,
            marginRight: 0,
          },
          '& .MuiChip-label': {
            padding: '3px 5px',
            marginLeft: '0 !important',
            marginRight: '0 !important',
            display: 'none',
          },
        }
      : {};

  return shippingType === ProductCardShippingEnum.FREE ? (
    <StyledChip
      variant="filled"
      label={shippingType === ProductCardShippingEnum.FREE ? shippingType : undefined}
      icon={
        <Iconify
          icon="gridicons:shipping"
          color="#000000 !important"
          width="18px"
          height="18px"
          sx={{
            marginRight: '0 !important',
            marginLeft: '3px !important',
          }}
        />
      }
      sx={{ ...commonStyle, ...specificStyle }}
    />
  ) : (
    <Box
      sx={{
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        background: 'rgba(237, 239, 242, 0.75)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Iconify
        icon="gridicons:shipping"
        color="#000000 !important"
        width="18px"
        height="18px"
        sx={{
          marginLeft: '0 !important',
          marginRight: 0,
        }}
      />
    </Box>
  );
};

export default ShippingChip;
