import { LoadingButton } from '@mui/lab';
import { Avatar, Chip, Divider, Drawer, Stack, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { LabelColor } from 'src/components/label';
import { IRootState } from 'src/store';
import {
  createAutomationWorkerRequest,
  setIsAutomationOptionDrawerOpen,
  setIsConnectionErrorDialogOpen,
  setIsDisableConfirmationDialogOpen,
} from 'src/store/automations/slices/automationsSlice';
import { getLabelProps } from '../../helpers/getLabelProps';

import CloseIcon from '@mui/icons-material/Close';
import DrawerSettings from './drawerSettings/DrawerSettings';
import { showCrispChat } from 'src/utils/crispChatHelpers';
import { IDataForUpdateAutomationSettings } from '../../types/types';
import { MixpanelEventTypes, mixpanelTrack } from 'src/utils/mixpanel';
import { useCheckSubscriptionStatus } from 'src/hooks/useCheckSubscriptionStatus';
import { setShouldOpenExpiredSubscriptionDialog } from 'src/store/automations/slices/myClosetSlice';

const AutomationOptionDrawer = () => {
  const matches = useMediaQuery('(min-width:400px)');
  const dispatch = useDispatch();
  const {
    isAutomationOptionDrawerOpen,
    selectedAutomationOption,
    automationsSettings,
    loading,
    activeClosetAutomationPlatform,
    isOnBoardingActive,
  } = useSelector((state: IRootState) => state.automations);
  const { activeClosetCredentialsId, activeCloset } = useSelector(
    (state: IRootState) => state.myCloset
  );
  const { hasCloset } = useSelector((state: IRootState) => state.myCloset);
  const { shouldConnectionErrorDialogOpen, isSubscriptionExpired } = useCheckSubscriptionStatus();

  const isEnabled =
    (automationsSettings[selectedAutomationOption.name] &&
      automationsSettings[selectedAutomationOption.name].enabled) ||
    false;

  const { topLabel, bottomLabel } = getLabelProps(isEnabled);

  const handleClose = () => {
    if (!isOnBoardingActive) {
      dispatch(setIsAutomationOptionDrawerOpen(false));
      showCrispChat();
    }
  };

  const handleEnableStatusRequest = () => {
    const enableMessage = `${selectedAutomationOption.displayName} automation successfully enabled`;
    const disableMessage = `${selectedAutomationOption.displayName} automation successfully disabled`;
    const toastMessage = !isEnabled ? enableMessage : disableMessage;
    const dataForUpdating: IDataForUpdateAutomationSettings = {
      settings: {
        [selectedAutomationOption.name]: {
          enabled: !isEnabled,
        },
      },
      toastMessage,
      displayName: selectedAutomationOption.displayName,
      activeClosetAutomationPlatform,
      activeClosetCredentialsId,
      ...(hasCloset && {
        workerData: {
          automation: selectedAutomationOption.name,
          marketplace: activeClosetAutomationPlatform,
          activeClosetCredentialsId,
          status: !isEnabled,
          automationsSettings,
          toastMessage,
        },
      }),
    };

    const mixpanelEnableData = {
      tool: 'poshmarkAutomation',
      source: 'web' as 'web',
      automation: selectedAutomationOption.name,
      enabled: true,
      credentialsId: activeClosetCredentialsId,
      marketplace: activeClosetAutomationPlatform,
      country: activeCloset?.country,
      poshmarkUserName: activeCloset?.poshmarkUserName,
    };

    if (isEnabled) {
      dispatch(setIsDisableConfirmationDialogOpen({ isOpen: true, dataForUpdating }));
    } else {
      if (shouldConnectionErrorDialogOpen) {
        dispatch(setIsConnectionErrorDialogOpen(true));
      } else if (isSubscriptionExpired) {
        dispatch(setShouldOpenExpiredSubscriptionDialog(true));
      } else {
        dispatch(
          createAutomationWorkerRequest({
            automation: selectedAutomationOption.name,
            marketplace: activeClosetAutomationPlatform,
            activeClosetCredentialsId,
            status: !isEnabled,
            toastMessage,
            automationsSettings,
          })
        );
        mixpanelTrack(MixpanelEventTypes.AUTOMATION_ENABLE_STATUS_CHANGED, mixpanelEnableData);
      }
    }
  };

  const drawerIcon = `/icons/drawerIcons/${selectedAutomationOption.name}.svg`;

  return (
    <Drawer
      anchor="right"
      open={isAutomationOptionDrawerOpen}
      onClose={handleClose}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: `${matches ? '400px !important' : '100%'}`,
        },
      }}
    >
      {/* TITLE */}
      <Stack
        direction="row"
        sx={{
          minWidth: `${matches ? '400px !important' : '100%'}`,
          maxWidth: `${matches ? '400px !important' : '100%'}`,
          position: 'relative',
          px: 2.5,
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: '40px',
          pb: '20px',
        }}
      >
        <CloseIcon
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: '10px',
            left: '16px',
            cursor: 'pointer',
            fontWeight: 700,
          }}
          className="driver-third"
        >
          X
        </CloseIcon>
        <Typography variant="h5" sx={{ fontSize: '18px !important' }}>
          {selectedAutomationOption.displayName}
        </Typography>
        <Chip
          variant={!isEnabled ? 'soft' : 'filled'}
          icon={<Avatar sx={{ width: '18px', height: '18px' }} src={topLabel.icon} />}
          label={topLabel.text}
          color={topLabel.color as LabelColor}
          sx={{ maxWidth: '100px', fontWeight: 400, fontSize: '13px !important' }}
        />
      </Stack>
      {/* BODY */}
      <Stack id="automationDrawerScrollableContent" sx={{ flexGrow: 1, overflow: 'auto' }}>
        <Stack
          sx={{
            bgcolor: '#F4F6F8 !important',
            pt: 2.5,
            pb: '20px !important',
            px: matches ? 2.5 : 1,
            height: 'auto',
          }}
          divider={<Divider sx={{ borderStyle: 'dashed', mt: '23px!important' }} />}
          spacing={2.5}
        >
          {/* Automation's Explanation Card */}
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: `${matches ? '360px !important' : '95%'}`,
              height: `${matches ? '100px !important' : 'auto'}`,
              borderRadius: '8px !important',
              background: 'linear-gradient(100deg, #3564CA 0%, #2653B6 100%)',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
              alignItems: 'center',
              px: 2,
              py: 1,
              margin: '0 auto',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              color="white"
              fontSize={'12px'}
              sx={{ lineHeight: '24px', maxWidth: '248px' }}
            >
              {selectedAutomationOption.tooltipText}
            </Typography>
            <img
              src={drawerIcon as string}
              style={{
                minWidth: matches ? '65px' : '50px',
                maxWidth: matches ? '75px' : '60px',
                minHeight: matches ? '65px' : '50px',
                maxHeight: matches ? '75px' : '60px',
                borderRadius: 0,
              }}
              alt={selectedAutomationOption.displayName}
            />
          </Stack>
          {/* Settings */}
          <DrawerSettings />
        </Stack>
      </Stack>

      {/* FOOTER */}
      <Stack sx={{ my: '20px !important' }} className="driver-second">
        <LoadingButton
          variant="soft"
          color={bottomLabel.color as 'success' | 'error'}
          sx={{
            justifySelf: 'flex-end !important',
            minWidth: `${matches ? '300px !important' : '95%'}`,
            minHeight: '48px',
            maxHeight: '48px',
            margin: '0 auto',
          }}
          loading={loading}
          onClick={() => !isOnBoardingActive && handleEnableStatusRequest()}
        >
          <Avatar sx={{ width: '18px', height: '18px', mr: 1 }} src={bottomLabel.icon} />
          <Typography variant="h6">{bottomLabel.text}</Typography>
        </LoadingButton>
      </Stack>
    </Drawer>
  );
};

export default AutomationOptionDrawer;
