import { forwardRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';

// @mui
import { Slide, Dialog, Button, DialogTitle, DialogActions, DialogContent } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import {
  setIsDeleteCardDialogOpen,
  deleteCardRequest,
} from 'src/store/dashboard/slices/accountSlice';
import { IRootState } from 'src/store';

import { LoadingButton } from '@mui/lab';

// -- Transition effect for Dialog
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteCardDialog = () => {
  const dispatch = useDispatch();

  const { actionsSelectedCard, isDeleteCardDialogOpen, loading } = useSelector(
    (state: IRootState) => state.account
  );

  const handleModalClose = () => {
    dispatch(setIsDeleteCardDialogOpen(false));
  };

  const handleDeleteCard = () => {
    dispatch(deleteCardRequest(actionsSelectedCard.id));
  };

  return (
    <Dialog
      open={isDeleteCardDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleModalClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-slide-title">Delete Card</DialogTitle>

      <DialogContent>
        Are you sure you want to delete this credit card? The card ending in **{' '}
        {actionsSelectedCard.last4} will be permanently removed from your account.
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="error"
          sx={{ textTransform: 'none' }}
          onClick={handleDeleteCard}
        >
          Yes, Delete Card
        </LoadingButton>

        <Button variant="outlined" color="inherit" onClick={handleModalClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCardDialog;
