export const shippingOffersDummyData = [
  {
    label: 'Original Shipping Cost',
    option: 'original',
  },
  {
    label: 'No Discount',
    option: 'no_discount',
  },
  {
    label: '$5.95',
    option: 'custom',
    customValue: '5.95',
  },
  {
    label: '$4.99',
    option: 'custom',
    customValue: '4.99',
  },
  {
    label: 'Free',
    option: 'free',
  },
];
