import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Iconify from 'src/components/iconify';
import MenuPopover from 'src/components/menu-popover';
import { LoadingButton } from '@mui/lab';
import { ISortOptionItem, filterOptionsList, sortOptionsList } from '../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMyListingsRequest,
  setHasActiveSearch,
  setIsListingsFilterDrawerOpen,
  setMyListings,
  setSelectedFilterOption,
  setSelectedListings,
  setSelectedSortOption,
} from 'src/store/automations/slices/myListingsSlice';
import { IRootState } from 'src/store';
import useResponsive from 'src/hooks/useResponsive';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import ListingService from '../services/ListingsService';

export default function ListingsFilter() {
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState<HTMLElement | null>(null);
  const {
    searchQuery,
    selectedFilterOption,
    selectedSortOption,
    selectedListings,
    myListings,
    loading,
    isFiltersBadgeInvisible,
  } = useSelector((state: IRootState) => state.myListings);
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const isMobileWeb = useResponsive('down', 'sm'); // < 600 px
  const isMobile = isMobileWeb || isPrimeListerMobileApp;

  const { activeClosetCredentialsId, closetList } = useSelector(
    (state: IRootState) => state.myCloset
  );

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const sortParam = searchParams.get('sort');

  if (sortParam) {
    const sortOption = sortOptionsList.find((option) => option.value === sortParam);
    if (sortOption) {
      dispatch(setSelectedSortOption(sortOption));
    }
  }

  const handleSortBySelection = (sortItem: ISortOptionItem) => {
    const availability = searchParams.get('availability') || 'available';
    const listingAgeMin = searchParams.get('listingAgeMin') || '';
    const listingAgeMax = searchParams.get('listingAgeMax') || '';
    const numberOfLikesMin = searchParams.get('numberOfLikesMin') || '';
    const numberOfLikesMax = searchParams.get('numberOfLikesMax') || '';
    const priceMin = searchParams.get('priceMin') || '';
    const priceMax = searchParams.get('priceMax') || '';
    const department = searchParams.get('department') || 'all-departments';
    const category = searchParams.get('category') || '';
    const subcategory = searchParams.get('subcategory') || '';
    const sort = searchParams.get('sort') || selectedSortOption.value;

    dispatch(setSelectedSortOption(sortItem));
    dispatch(setSelectedListings(new Set()));
    setIsSortDropdownOpen(null);
    dispatch(setHasActiveSearch(true));

    // Update search params
    searchParams.set('sort', sortItem.value);
    setSearchParams(searchParams);

    const shopParam = searchParams.get('shop');
    const credentialsIdByShopParam = ListingService.getCredentialsIdByShopParam(
      shopParam,
      closetList
    );

    const filters = ListingService.buildFilters({
      nextPageId: undefined,
      searchQuery,
      availability,
      selectedFilterOption: { value: availability },
      selectedSortOption: sortItem,
      listingAgeMin,
      listingAgeMax,
      numberOfLikesMin,
      numberOfLikesMax,
      priceMin,
      priceMax,
      category,
      subcategory,
      department
    });

    dispatch(
      getMyListingsRequest({
        credentialsId: credentialsIdByShopParam || activeClosetCredentialsId,
        filters,
      })
    );
  };

  const isAllListingsSelected = myListings.length === selectedListings.size && !loading;

  const clearSelectedListings = () => {
    dispatch(setSelectedListings(new Set()));
  };

  const handleSelectAllListings = () => {
    dispatch(setSelectedListings(new Set(myListings.map((listing) => listing.id))));
  };

  const handleAction = () => {
    if (isAllListingsSelected) {
      clearSelectedListings();
    } else {
      handleSelectAllListings();
    }
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {!isMobile ? (
          <>
            <LoadingButton
              color="inherit"
              sx={{
                mr: '8px',
              }}
              onClick={(e: React.MouseEvent<HTMLElement>) =>
                dispatch(setIsListingsFilterDrawerOpen(true))
              }
              endIcon={
                <Badge
                  sx={{
                    '& .MuiBadge-badge': {
                      top: '-2px',
                    },
                  }}
                  variant="dot"
                  invisible={isFiltersBadgeInvisible}
                  color="error"
                >
                  <Avatar
                    src="/icons/filterIcon.svg"
                    sx={{
                      width: '20px',
                      height: '20px',
                    }}
                  />
                </Badge>
              }
              variant="text"
            >
              Filters
            </LoadingButton>

            <Button
              color="inherit"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                const avatarElement = e.currentTarget.querySelector(
                  '.MuiAvatar-root'
                ) as HTMLElement;
                setIsSortDropdownOpen(avatarElement);
              }}
              endIcon={
                <Avatar
                  src="/icons/sortByIcon.svg"
                  sx={{
                    width: '20px',
                    height: '20px',
                  }}
                />
              }
              variant="text"
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '22px',
                }}
              >
                Sort By:&nbsp;
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '14px',
                  lineHeight: '22px',
                }}
              >
                {selectedSortOption
                  ? selectedSortOption.displayName
                  : sortOptionsList[0].displayName}
              </Typography>
            </Button>
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <IconButton
              disableRipple
              size="small"
              onClick={(e: React.MouseEvent<HTMLElement>) =>
                dispatch(setIsListingsFilterDrawerOpen(true))
              }
              sx={{ cursor: 'pointer', p: 0 }}
            >
              <Badge
                sx={{
                  '& .MuiBadge-badge': {},
                }}
                variant="dot"
                invisible={isFiltersBadgeInvisible}
                color="error"
              >
                <Avatar
                  src="/icons/filterIcon.svg"
                  sx={{
                    width: '24px',
                    height: '24px',
                    color: '#212B36',
                  }}
                />
              </Badge>
            </IconButton>
            <Divider orientation="vertical" sx={{ width: '1px', height: '16px' }} />

            <IconButton
              disableRipple
              size="small"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                setIsSortDropdownOpen(e.currentTarget.firstElementChild as HTMLElement);
              }}
              sx={{ cursor: 'pointer', p: 0 }}
            >
              <Iconify
                icon="basil:sort-outline"
                color="#212B36"
                sx={{
                  width: '24px',
                  height: '24px',
                }}
              />
            </IconButton>

            <Divider orientation="vertical" sx={{ width: '1px', height: '16px' }} />

            <Button
              onClick={handleAction}
              disableRipple
              disableElevation
              disableFocusRipple
              disableTouchRipple
              sx={{
                p: 0,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '22px',
                  color: '#36F',
                }}
              >
                {isAllListingsSelected ? 'Clear All' : 'Select All'}
              </Typography>
            </Button>
          </Box>
        )}
      </Box>

      <MenuPopover
        open={isSortDropdownOpen}
        onClose={() => setIsSortDropdownOpen(null)}
        arrow={'top-center'}
        sx={{
          mt: '6px !important',
        }}
        disabledArrow={false}
      >
        {sortOptionsList.map((item) => (
          <MenuItem
            onClick={() => handleSortBySelection(item)}
            key={item.name}
            sx={{
              fontWeight: '400 !important',
              backgroundColor: item.name === selectedSortOption?.name ? '#EDEFF2' : 'white',
              p: '6px 8px !important',
              maxHeight: '34px !important',
              minHeight: '34px !important',
            }}
          >
            {item.displayName}
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  );
}
