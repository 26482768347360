import { IShopifyProductDetail } from '../../../pages/dashboard/MyShops/types/shopifyTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CurrentInventoryActionEnum,
  IGetListingsPayloadData,
  IImportListingsPayload,
  ISelectedListing,
} from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import { IEbayProductDetail } from 'src/pages/dashboard/MyShops/types/ebayTypes';
import { IAPIMarketplaces, officialApiPlatforms } from './myShopsAPITableSlice';
import {
  isEbay,
  isShopify,
} from 'src/pages/dashboard/Inventory/mainComponents/ImportTable/importDataGrid/helpers';
import { IUserImportJob } from 'src/pages/dashboard/MyShops/types';

export interface IImportListingItem {
  id: string;
  img: string;
  name: string;
  price: string;
  sku: string;
  imported?: boolean;
  inventory_unit_id?: string;
}

export interface IImportTableState {
  //--ImportTable
  selectedImportCheckboxes: string[];
  openImportDialog: boolean;
  importDialogHeader: string;
  hideImportedItems: boolean;
  selectedListingDetails: ISelectedListing;
  listingsForImport: IImportListingItem[];
  importPlatform: string;
  importLoading: boolean;
  importError: string;
  importSearchText: string;
  importSearchButtonLoading: boolean;
  importClearButtonLoading: boolean;
  isClearButtonVisible: boolean;
  hasMoreImportableListings: boolean;
  importNextPageID: any;
  importedListingsList: string[];
  justCompletedList: string[];
  inProgressList: string[];
  isLoadMoreLoading: boolean;
  fetchedListings: IImportListingItem[];
  hasActiveSearch: boolean;
  currentInventoryAction: CurrentInventoryActionEnum;
  isOfficialAPIShopify: boolean;
  isOfficialAPIEbay: boolean;
  isOfficialApiPlatform: boolean;
  userActionQueueJob: IUserImportJob[];
  isImportErrorDialogOpen: boolean;
}

const initialState = {
  userActionQueueJob: [] as IUserImportJob[],
  selectedImportCheckboxes: [],
  openImportDialog: false,
  importDialogHeader: '',
  hideImportedItems: false,
  selectedListingDetails: {
    groups: [
      { name: '', _id: '' },
      { name: '', _id: '' },
    ],
    image: '',
    status: '',
    tags: [{ name: '', _id: '', color: '' }],
    listingTags: [{ name: '', id: '', color: '' }],
    inertialNotes: '',
  },
  listingsForImport: [],
  importPlatform: '',
  importLoading: false,
  importError: '',
  importSearchText: '',
  importSearchButtonLoading: false,
  importClearButtonLoading: false,
  isClearButtonVisible: false,
  hasMoreImportableListings: false,
  importNextPageID: null,
  importedListingsList: [],
  justCompletedList: [] as string[],
  inProgressList: [] as string[],
  isLoadMoreLoading: false,
  fetchedListings: [] as IImportListingItem[],
  hasActiveSearch: false,
  currentInventoryAction: '',
  isOfficialAPIShopify: false,
  isOfficialAPIEbay: false,
  isImportErrorDialogOpen: false,
};

const importTableSlice = createSlice({
  name: 'importTable',
  initialState,
  reducers: {
    setSelectedImportCheckboxes: (state, action) => {
      state.selectedImportCheckboxes = action.payload;
    },
    setImportedListingsList: (state, action) => {
      state.importedListingsList = action.payload;
    },
    setJustCompletedList: (state, action: PayloadAction<string[]>) => {
      state.justCompletedList = action.payload;
    },
    setInProgressList: (state, action: PayloadAction<string[]>) => {
      state.inProgressList = action.payload;
    },
    setIsLoadMoreLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoadMoreLoading = action.payload;
    },
    setHasActiveSearch: (state, action: PayloadAction<boolean>) => {
      state.hasActiveSearch = action.payload;
    },
    setCurrentInventoryAction: (state, action: PayloadAction<CurrentInventoryActionEnum>) => {
      state.currentInventoryAction = action.payload;
    },
    //--__IMPORT TABLE
    setOpenImportDialog: (state, action) => {
      state.openImportDialog = action.payload;
    },
    setFetchedListings: (state, action: PayloadAction<IImportListingItem[]>) => {
      state.fetchedListings = action.payload;
    },
    setListingsForImport: (state, action) => {
      state.listingsForImport = action.payload;
    },
    setImportDialogHeader: (state, action) => {
      state.importDialogHeader = action.payload;
    },
    setImportPlatform: (state, action) => {
      state.importPlatform = action.payload;
      state.isOfficialAPIShopify = isShopify(action.payload);
      state.isOfficialAPIEbay = isEbay(action.payload);
    },
    setHideImportedItems: (state, action) => {
      state.hideImportedItems = action.payload;
    },
    setSelectedListingDetails: (state, action) => {
      state.selectedListingDetails = action.payload;
    },
    setImportLoading: (state, action) => {
      state.importLoading = action.payload;
    },
    setImportSearchText: (state, action) => {
      state.importSearchText = action.payload;
    },
    setImportSearchButtonLoading: (state, action) => {
      state.importSearchButtonLoading = action.payload;
    },
    setImportClearButtonLoading: (state, action) => {
      state.importClearButtonLoading = action.payload;
    },
    setIsClearButtonVisible: (state, action) => {
      state.isClearButtonVisible = action.payload;
    },
    setHasMoreImportableListings: (state, action) => {
      state.hasMoreImportableListings = action.payload;
    },
    setImportNextPageID: (state, action) => {
      state.importNextPageID = action.payload;
    },
    setIsImportErrorDialogOpen: (state, action) => {
      state.isImportErrorDialogOpen = action.payload;
    },
    //--
    importGetListingsRequest: (state, action: PayloadAction<IGetListingsPayloadData>) => {
      state.importLoading = true;
      state.importError = '';
    },
    importGetListingsSuccess: (state) => {
      state.importLoading = false;
      state.importError = '';
    },
    importGetListingsFail: (state, action) => {
      state.importLoading = false;
      state.importError = action.payload;
    },
    //--
    importListingsRequest: (state, action: PayloadAction<IImportListingsPayload>) => {
      state.importLoading = false;
      state.importError = '';
    },
    //--
    importGetInitialStatusRequest: (
      state,
      action: PayloadAction<{ marketplace: string | IAPIMarketplaces; listingId: string }[]>
    ) => {
      state.importLoading = true;
      state.importError = '';
    },
    importGetInitialStatusSuccess: (state) => {
      state.importLoading = false;
      state.importError = '';
    },
    importGetInitialStatusFail: (state, action: PayloadAction<string>) => {
      state.importLoading = false;
      state.importError = action.payload;
    },
    //--
    apiBulkImportListingsRequest: (
      state,
      action: PayloadAction<{
        selectedListings: IShopifyProductDetail[];
        selectedImportCheckboxes: string[];
      }>
    ) => {
      state.importError = '';
      state.inProgressList = [...state.inProgressList, ...action.payload.selectedImportCheckboxes];
    },
    apiBulkImportListingsSuccess: (state, action: PayloadAction<string[]>) => {
      state.importError = '';
      state.justCompletedList = [...state.justCompletedList, ...action.payload];
      state.inProgressList = [];
      state.selectedImportCheckboxes = [];
    },
    apiBulkImportListingsFail: (state, action: PayloadAction<string>) => {
      state.importError = action.payload;
    },

    resetImportDialogState: (state) => {
      state.openImportDialog = false;
      state.importDialogHeader = '';
      state.hideImportedItems = false;
      state.isLoadMoreLoading = false;
      state.hasMoreImportableListings = false;
      state.importNextPageID = null;
      state.importedListingsList = [];
      state.justCompletedList = [];
      state.inProgressList = [];
      state.isClearButtonVisible = false;
      state.hasActiveSearch = false;
      state.currentInventoryAction = CurrentInventoryActionEnum.EMPTY;
      state.fetchedListings = [];
      state.selectedImportCheckboxes = [];
      state.listingsForImport = [];
      state.importPlatform = '';
      state.importLoading = false;
      state.importError = '';
      state.importSearchText = '';
      state.importSearchButtonLoading = false;
      state.importClearButtonLoading = false;
    },
    resetImportTableState: (state) => initialState,
  },
});

export const {
  resetImportTableState,
  resetImportDialogState,
  setSelectedListingDetails,
  setSelectedImportCheckboxes,
  setOpenImportDialog,
  setImportDialogHeader,
  setHideImportedItems,
  setListingsForImport,
  setImportPlatform,
  setImportLoading,
  setImportSearchText,
  setImportSearchButtonLoading,
  setImportClearButtonLoading,
  setIsClearButtonVisible,
  setHasMoreImportableListings,
  setImportNextPageID,
  setImportedListingsList,
  setJustCompletedList,
  setInProgressList,
  //--
  importGetListingsRequest,
  importGetListingsSuccess,
  importGetListingsFail,
  //--
  importListingsRequest,
  //--
  importGetInitialStatusRequest,
  importGetInitialStatusSuccess,
  importGetInitialStatusFail,
  //--
  apiBulkImportListingsRequest,
  apiBulkImportListingsSuccess,
  apiBulkImportListingsFail,
  setIsLoadMoreLoading,

  setFetchedListings,
  setHasActiveSearch,
  setCurrentInventoryAction,
  setIsImportErrorDialogOpen,
} = importTableSlice.actions;

export default importTableSlice.reducer;
