import { forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// @mui
import {
  Slide,
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import { IRootState } from 'src/store';

import { setIsResetRequestDialogOpen } from 'src/store/dashboard/slices/authSlice';

// -- Transition effect for Dialog
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ResetRequestDialog = () => {
  const dispatch = useDispatch();
  const { isResetRequestDialogOpen } = useSelector((state: IRootState) => state.auth);

  const handleModalClose = () => {
    dispatch(setIsResetRequestDialogOpen(false));
  };

  return (
    <Dialog
      open={isResetRequestDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleModalClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      sx={{ padding: '0 15px' }}
    >
      <DialogTitle id="alert-dialog-slide-title">Password Reset Instruction</DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          A password reset link has been sent to your email address. Please check your inbox and
          follow the instructions to reset your password. If you do not receive an email, please
          check your spam folder or try again.
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <Button variant="text" color="primary" onClick={handleModalClose}>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetRequestDialog;
