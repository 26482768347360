import { LabelColor } from 'src/components/label';
import Label from 'src/components/label/Label';
import { DataGridResultColumnInterface } from '../../../../types/types';

interface IColorMap {
  [key: string]: LabelColor;
  SUCCEEDED: LabelColor;
  MISSED: LabelColor;
  ERROR: LabelColor;
}

const DataGridResultColumn = ({ result }: DataGridResultColumnInterface) => {
  const colorMap: IColorMap = {
    SUCCEEDED: 'success',
    MISSED: 'warning',
    ERROR: 'error',
  };

  const color = colorMap[result.toUpperCase()] || 'error';

  return result.length > 0 ? (
    <Label variant="soft" color={color}>
      {result}
    </Label>
  ) : null;
};

export default DataGridResultColumn;
