import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CompareVersionEnum, DeviceTypeEnum } from 'src/auth/types';
import { compareVersions, getDevicePlatform, parseUserAgent } from 'src/auth/utils';
import { MOBILE_APP_USER_AGENT } from 'src/config';
import { IRootState } from 'src/store';
import {
  setIsAppReviewVersion,
  setIsAppReviewVersionLoading,
  setIsMobileAppCheckLoading,
  setIsPrimeListerMobileApp,
  setPrimeListerMobileAppData,
} from 'src/store/dashboard/slices/homeSlice';
import { useUA } from './useUA';
import { getAppVersionsRequest } from 'src/store/dashboard/slices/authSlice';
import { MixpanelEventTypes, mixpanelTrack } from 'src/utils/mixpanel';

const usePrimeListerMobileApp = () => {
  const dispatch = useDispatch();
  const { appVersions } = useSelector((state: IRootState) => state.auth);
  const userAgent = useUA(navigator.userAgent).getResult().ua;
  const parsedUA = parseUserAgent(userAgent);

  useEffect(() => {
    if (parsedUA && parsedUA?.app_name === MOBILE_APP_USER_AGENT) {
      dispatch(setIsAppReviewVersionLoading(true));
      dispatch(setIsMobileAppCheckLoading(true));
      dispatch(getAppVersionsRequest());
    }
  }, []);

  useEffect(() => {
    if (
      parsedUA &&
      Object.keys(parsedUA).length > 0 &&
      parsedUA.app_name === MOBILE_APP_USER_AGENT && // Only set if the user agent is the mobile app
      appVersions
    ) {
      const deviceType = getDevicePlatform(parsedUA.platform);
      const specificAppVersion: string = appVersions?.[deviceType as DeviceTypeEnum].version;

      if (specificAppVersion) {
        dispatch(
          setIsAppReviewVersion(
            compareVersions(parsedUA.version, specificAppVersion) === CompareVersionEnum.GREATER
          )
        );
        dispatch(setIsAppReviewVersionLoading(false));
      }

      dispatch(setIsPrimeListerMobileApp(parsedUA?.app_name === MOBILE_APP_USER_AGENT || false));
      dispatch(setIsMobileAppCheckLoading(false));
      dispatch(setPrimeListerMobileAppData(parsedUA));

      const mixpanelData = {
        deviceType,
        specificAppVersion,
        parsedUA,
        userAgent: navigator.userAgent,
        appVersions,
        navigatorUserAgent: userAgent,
        compareVersionResult: compareVersions(parsedUA.version, specificAppVersion),
        compareVersionResult2:
          compareVersions(parsedUA.version, specificAppVersion) === CompareVersionEnum.GREATER,
        isPrimeListerMobileApp: parsedUA?.app_name === MOBILE_APP_USER_AGENT,
      };
      mixpanelTrack(MixpanelEventTypes.DEBUG_USER_AGENT, mixpanelData);
    }
  }, [appVersions]);
};

export default usePrimeListerMobileApp;
