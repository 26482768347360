import { useDispatch, useSelector } from 'react-redux';

import { MenuItem } from '@mui/material';

import Iconify from 'src/components/iconify/Iconify';
import MenuPopover from 'src/components/menu-popover/MenuPopover';

import {
  setDeletePopup,
  setDeletePopupData,
  setErrorDetailDialogOpen,
  setOpenPopoverMenu,
} from 'src/store/dashboard/slices/tasksSlice';
import { IRootState } from 'src/store';

const SELECTED_TABS = { COMPLETED: 'COMPLETED' };

export default function ActionMenuPopover() {
  const dispatch = useDispatch();

  const { openPopoverMenu, popoverData, selectedTab } = useSelector(
    (state: IRootState) => state.tasks
  );

  const handleViewMarketplaceClick = () => {
    window.open(popoverData?.link, '_blank', 'noopener noreferrer');
    dispatch(setOpenPopoverMenu(null));
  };

  const handleDeleteRecordClick = () => {
    dispatch(setDeletePopup(true));
    dispatch(setDeletePopupData(popoverData?.name ?? ''));
    dispatch(setOpenPopoverMenu(null));
  };

  const handleErrorDetailClick = () => {
    dispatch(setErrorDetailDialogOpen(true));
    dispatch(setOpenPopoverMenu(null));
  };

  const showErrorDetailOption = () =>
    selectedTab === SELECTED_TABS.COMPLETED && popoverData?.result.toLowerCase() === 'failed' ? (
      <MenuItem onClick={handleErrorDetailClick}>
        <Iconify icon="ph:magnifying-glass-bold" />
        Check Error Details
      </MenuItem>
    ) : null;

  const menuItems = {
    [SELECTED_TABS.COMPLETED]: (
      <MenuItem onClick={handleViewMarketplaceClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M11 17H7q-2.075 0-3.537-1.463Q2 14.075 2 12t1.463-3.538Q4.925 7 7 7h4v2H7q-1.25 0-2.125.875T4 12q0 1.25.875 2.125T7 15h4Zm-3-4v-2h8v2Zm5 4v-2h4q1.25 0 2.125-.875T20 12q0-1.25-.875-2.125T17 9h-4V7h4q2.075 0 3.538 1.462Q22 9.925 22 12q0 2.075-1.462 3.537Q19.075 17 17 17Z"
          />
        </svg>
        View in Marketplace
      </MenuItem>
    ),
  };

  return (
    <MenuPopover
      open={openPopoverMenu}
      onClose={() => dispatch(setOpenPopoverMenu(null))}
      arrow="right-top"
      sx={{ minWidth: 140 }}
    >
      {menuItems[selectedTab]}

      {showErrorDetailOption()}
    </MenuPopover>
  );
}
