// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, Badge, BadgeProps, useMediaQuery } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';

// components
import Logo from './Logo';
//
import AccountPopover from './AccountPopover';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import NavInHeader from 'src/layouts/LayoutComponents/NavInHeader';
import createNavConfig from '../nav/config';
import { Shadow } from 'src/layouts/LayoutComponents/NavHorizontal';
import HelpMenuPopover from 'src/layouts/LayoutComponents/MenuDropDown';
import { useLocation } from 'react-router';
import { PATH_AUTOMATIONS, PATH_DASHBOARD } from 'src/routes/paths';
import ClosetSelectionDropDown from 'src/pages/automations/MyClosetPage/components/ClosetSelectionDropDown';
import { ConnectionStatus } from 'src/store/automations/slices/myClosetSlice';
import useResponsive from 'src/hooks/useResponsive';
import { IFeatureFlag } from '@openreplay/tracker/lib/modules/featureFlags';

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();
  const { isExtensionConnected, isPrimeListerMobileApp } = useSelector(
    (state: IRootState) => state.home
  );
  const { closetList } = useSelector((state: IRootState) => state.myCloset);
  const { isUserBoardedMultipleClosets } = useSelector((state: IRootState) => state.automations);
  const { featureFlags } = useSelector((state: IRootState) => state.auth);
  const navbarMatches = useMediaQuery('(min-width:1349px)');
  const badgeColor = isExtensionConnected ? 'success' : 'error';
  const location = useLocation();
  const isMobile = useResponsive('down', 'sm');

  const shouldHideAccountPopover =
    location.pathname.includes(PATH_DASHBOARD.subscription.root) ||
    (location.pathname.includes(PATH_AUTOMATIONS.account) && (isMobile || isPrimeListerMobileApp));
  const shouldHaveNavbar = navbarMatches && !shouldHideAccountPopover;

  const hasSpaceInHeader = useMediaQuery('(min-width:700px)');
  const hasCloset =
    closetList.filter((closet) => closet?.status !== ConnectionStatus.Removed).length > 1;
  const isInAutomationsRelatedPage =
    location.pathname.includes('poshmark') ||
    location.pathname.includes(PATH_AUTOMATIONS.activity) ||
    location.pathname.includes(PATH_AUTOMATIONS.statistics) ||
    location.pathname.includes(PATH_AUTOMATIONS.listings);

  const shouldDisplayClosetSelectionDropDown =
    hasCloset && (isInAutomationsRelatedPage || !isUserBoardedMultipleClosets);

  const titleMatches = useMediaQuery('(min-width:381px)');
  const isSubscriptionPage = location.pathname.includes(PATH_DASHBOARD.subscription.root);

  const navConfig = createNavConfig(
    isMobile,
    isPrimeListerMobileApp,
    (featureFlags as IFeatureFlag[]) ?? []
  );
  const renderContent = (
    <Stack
      direction="row"
      sx={{
        width: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '60px !important',
        maxHeight: '60px !important',
        position: 'relative',
      }}
    >
      <Stack direction="row">
        {!isMobile && <Logo sx={{ mr: 2.5 }} />}
        {shouldDisplayClosetSelectionDropDown && !hasSpaceInHeader && <ClosetSelectionDropDown />}
      </Stack>
      {shouldHaveNavbar && <NavInHeader navConfig={navConfig} />}
      {!isSubscriptionPage && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          color={theme.palette.grey[800]}
        >
          {shouldDisplayClosetSelectionDropDown && hasSpaceInHeader && <ClosetSelectionDropDown />}
          <HelpMenuPopover />

          {!shouldHideAccountPopover && (
            <StyledBadge badgeContent={''} color={badgeColor}>
              <AccountPopover />
            </StyledBadge>
          )}
        </Stack>
      )}
    </Stack>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...((shouldHaveNavbar || !shouldHideAccountPopover) && {
          borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
        }),
        width: 1,
        bgcolor: 'background.default',
        minHeight: '60px !important',
        maxHeight: '60px !important',
      }}
    >
      <Toolbar
        sx={{
          minHeight: '60px !important',
          maxHeight: '60px !important',
          px: { lg: 5 },
          ...bgBlur({
            color: theme.palette.background.default,
          }),
          ...(!shouldHaveNavbar &&
            !shouldHideAccountPopover && {
              borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
            }),
        }}
      >
        {renderContent}
      </Toolbar>
      {(shouldHaveNavbar || shouldHideAccountPopover) && <Shadow />}
    </AppBar>
  );
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 3,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));
