import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Typography, Stack, useMediaQuery } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { useEffect } from 'react';
import PaymentSummaryCard from './PaymentSummaryCard';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setSelectedSubscription } from 'src/store/dashboard/slices/pricingSlice';
import { isLoggedIn } from 'src/utils/isLoggedIn';
import { PATH_DASHBOARD } from 'src/routes/paths';
import {
  getCrosslistAnnualPlans,
  getCrosslistMonthlyPlans,
  getPoshmarkPlans,
} from '../../utils/constants';

// _mock_

// sections

// ----------------------------------------------------------------------

export default function PaymentSummaryPage() {
  const matches = useMediaQuery('(min-width:757px)');
  const dispatch = useDispatch();
  const { selectedSubscription, sliderValue } = useSelector((state: IRootState) => state.pricing);
  const { poshmarkShopCapacity } = useSelector((state: IRootState) => state.account);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { referrerCode } = isLoggedIn();

  useEffect(() => {
    const controlledSliderValue = Number(searchParams.get('closet')) || sliderValue;
    const allSubscriptionPlans = [
      ...getCrosslistMonthlyPlans(false),
      ...getCrosslistAnnualPlans(false),
      ...getPoshmarkPlans(false, controlledSliderValue),
    ];
    if (searchParams.get('planId')) {
      dispatch(
        setSelectedSubscription(
          allSubscriptionPlans.find(
            (plan) => plan.planId === searchParams.get('planId')?.replace('?', '')
          )
        )
      );
    } else if (!searchParams.get('planId') && selectedSubscription?.planId) {
      setSearchParams({
        planId: selectedSubscription?.planId,
        ...(referrerCode && { coupon: referrerCode }),
      });
    } else {
      navigate(PATH_DASHBOARD.user.root + '#billing');
    }
  }, [searchParams.get('planId'), poshmarkShopCapacity]);

  return (
    <>
      <Helmet>
        <title> Pricing | Primelister</title>
      </Helmet>

      <Container
        disableGutters
        maxWidth={'lg'}
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Typography variant="h3" align="center" paragraph>
          Let&apos;s finish powering you up!
          <br />
        </Typography>

        <Typography align="center" sx={{ color: 'text.secondary' }}>
          Professional plan is right for you.
        </Typography>

        <Stack
          flexWrap="wrap"
          spacing={3}
          justifyContent="center"
          alignItems="center"
          mt={5}
          direction={matches ? 'row' : 'column'}
          width={'100%'}
        >
          <PaymentSummaryCard />
        </Stack>
      </Container>
    </>
  );
}
