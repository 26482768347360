import React from 'react';
import { Box } from '@mui/material';

interface ListingCardsItemWrapperProps {
  children: React.ReactNode;
  [key: string]: any;
}

const ListingCardsItemWrapper: React.FC<ListingCardsItemWrapperProps> = ({
  children,
  ...props
}) => (
  <Box
    {...props}
    style={{
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    {children}
  </Box>
);

export default ListingCardsItemWrapper;
