import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Typography,
  useMediaQuery,
  SxProps,
  TextFieldProps,
  Theme,
  Backdrop,
  useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import {
  createPriceUpdateJobRequest,
  createSendOfferJobRequest,
  setActiveNewTaskDialogType,
  setIsCreateNewTaskDialogOpen,
} from 'src/store/automations/slices/myListingsSlice';
import {
  DiscountUnit,
  Marketplace,
  ShippingOfferType,
  UpdateJobPriceBase,
  UpdateJobPriceChangeMode,
  UpdateJobPriceChangeType,
  UserAction,
} from '@beta.limited/primelister';
import useResponsive from 'src/hooks/useResponsive';
import { StylesByDevice } from '../components/ListingsBulkActionsMenu';
import Iconify from 'src/components/iconify';
import UpdatePriceDialogContent from './contents/UpdatePriceDialogContent';
import { LoadingButton } from '@mui/lab';
import { useMemo, useState } from 'react';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import RelistDialogContent from './contents/RelistDialogContent';
import { BaseDateTimePickerSlotsComponentsProps } from '@mui/x-date-pickers/DateTimePicker/shared';
import SendOfferDialogContent from './contents/SendOfferDialogContent';
import { useCheckSubscriptionStatus } from 'src/hooks/useCheckSubscriptionStatus';
import { setShouldOpenExpiredSubscriptionDialog } from 'src/store/automations/slices/myClosetSlice';
import { useTaskDialog } from './contents/shared/useTaskDialog';

export enum NewTaskDialogType {
  UPDATE_PRICE = 'updatePrice',
  SEND_OFFER = 'sendOffer',
  RE_LIST = 'reList',
  NONE = 'none',
}

export const TASK_TYPES = [
  {
    label: 'Update Price',
    value: NewTaskDialogType.UPDATE_PRICE,
    icon: 'material-symbols:price-change',
  },
  {
    label: 'Send Offer',
    value: NewTaskDialogType.SEND_OFFER,
    icon: 'material-symbols:request-quote',
  },
  {
    label: 'Re-list',
    value: NewTaskDialogType.RE_LIST,
    icon: 'el:retweet',
  },
];

export const commonDateTimePickerStyles: TextFieldProps = {
  fullWidth: true,
  sx: {
    marginTop: '8px',
    '& .MuiFormLabel-root': {
      fontSize: '14px',
      lineHeight: '22px',
      display: 'flex',
      alignItems: 'center',
      marginTop: '-6px',
    },
    '& .MuiInputBase-root': {
      height: '40px',
    },
  } as SxProps<Theme>,
  margin: 'normal',
};

export default function CreateNewTaskDialog() {
  const dispatch = useDispatch();
  const { isSubscriptionExpired } = useCheckSubscriptionStatus();

  const { isCreateNewTaskDialogOpen, selectedListings, activeNewTaskDialogType } = useSelector(
    (state: IRootState) => state.myListings
  );
  const isMobileWeb = useResponsive('down', 'sm');
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const isMobile = isMobileWeb || isPrimeListerMobileApp;
  const selectedTextMatches = useMediaQuery('(max-width:340px)');

  const isSmallerMobileScreen = useMediaQuery('(max-width: 321px)');

  const { handleClose, listings, renderDialogContent, getDialogOptions } = useTaskDialog();

  const handleDialogClose = (e: any, reason: string) => {
    if (
      (reason === 'backdropClick' || reason === 'escapeKeyDown') &&
      activeNewTaskDialogType !== NewTaskDialogType.NONE
    ) {
      return;
    } else {
      dispatch(setIsCreateNewTaskDialogOpen(false));
    }
  };

  const stylesByDevice: StylesByDevice = {
    selectedListingTextStyles: {
      mobile: {
        fontSize: '12px',
        mr: '4px',
        fontWeight: '700',
      },
      desktop: {
        fontSize: '15px',
        mr: '8px',
        fontWeight: '600',
      },
    },
    listingsTextStyles: {
      mobile: {
        color: '#637381',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
      },
      desktop: {
        color: '#637381',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '26px',
      },
    },
  };

  const dialogOptions = getDialogOptions();
  
  const handleDialogAction = () => {
    if (!isSubscriptionExpired) {
      dialogOptions.onConfirm?.();
      return;
    }
    dispatch(setIsCreateNewTaskDialogOpen(false));
    dispatch(setActiveNewTaskDialogType(NewTaskDialogType.NONE));
    dispatch(setShouldOpenExpiredSubscriptionDialog(true));
  };
  return (
    <Dialog
      id="create-new-task-dialog"
      open={isCreateNewTaskDialogOpen}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="md"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            margin: !isSmallerMobileScreen ? '16px' : '8px',
            width: '100%',
            maxWidth: '400px',
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          padding: !isSmallerMobileScreen ? '24px !important' : '24px 16px !important',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 700,
              lineHeight: '26px',
              color: '#212B36',
            }}
          >
            {dialogOptions.title}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexShrink: 0,
            }}
          >
            <Typography
              sx={{
                padding: '9.5px 6px',
                minWidth: '25px',
                height: '25px',
                textAlign: 'center',
                color: '#fff',
                bgcolor: '#3564CA',
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...(isMobile
                  ? stylesByDevice.selectedListingTextStyles.mobile
                  : stylesByDevice.selectedListingTextStyles.desktop),
              }}
            >
              {selectedListings.size}
            </Typography>
            <Typography
              sx={{
                fontFamily: '"Public Sans"',
                fontStyle: 'normal',
                ...(isMobile
                  ? stylesByDevice.listingsTextStyles.mobile
                  : stylesByDevice.listingsTextStyles.desktop),
              }}
            >
              {!selectedTextMatches && 'listings'} selected
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: !isSmallerMobileScreen ? '8px 24px 16px 24px' : '8px 16px 16px 16px',
        }}
      >
        <Scrollbar
          sx={{
            maxHeight: '90%',
            overflowY: 'auto',
          }}
        >
          {renderDialogContent()}
        </Scrollbar>
      </DialogContent>
      {activeNewTaskDialogType !== NewTaskDialogType.NONE && (
        <DialogActions
          sx={{
            padding: !isSmallerMobileScreen ? '24px !important' : '24px 16px !important',
          }}
        >
          <Button
            variant="outlined"
            sx={{
              width: '71px',
            }}
            onClick={() => handleClose(true)}
            color="inherit"
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            sx={{
              textTransform: 'none',
              p: '6px 12px',
            }}
            disabled={dialogOptions.isButtonDisabled}
            loading={dialogOptions.isButtonLoading}
            onClick={() => handleDialogAction()}
          >
            <Typography
              sx={{
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '24px',
              }}
            >
              {dialogOptions.confirmButtonText}
            </Typography>
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
