import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Switch,
} from '@mui/material';
import QueueIcon from '@mui/icons-material/Queue';

import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';

import ImportInventoryDataGrid from './importDataGrid/ImportInventoryDataGrid';
import {
  createUserActionQueueJobRequest,
  inventoryGetListRequest,
} from 'src/store/dashboard/slices/inventorySlice';
import {
  importListingsRequest,
  setHideImportedItems,
  setListingsForImport,
  setOpenImportDialog,
  setSelectedImportCheckboxes,
  setImportSearchText,
  setIsClearButtonVisible,
  setJustCompletedList,
  setInProgressList,
  setImportedListingsList,
  setImportNextPageID,
  setHasMoreImportableListings,
  setIsLoadMoreLoading,
  setFetchedListings,
  setHasActiveSearch,
  setCurrentInventoryAction,
} from 'src/store/dashboard/slices/importTableSlice';

import { ChangeEvent } from 'react';
import { getFilterModelShaped } from '../../helpers/getFilterModelShaped';
import {
  IAPIMarketplaces,
  officialApiPlatforms,
} from 'src/store/dashboard/slices/myShopsAPITableSlice';
import { CurrentInventoryActionEnum } from '../../types/dataGridInterfaces';
import { getConnectedEbayShop, isEbay } from './importDataGrid/helpers';
import { Marketplace, UserAction } from '@beta.limited/primelister';

/** TODOs
 * When all related platforms are handled, check and adjust types used in api import case commonly and remove ts-ignore line at line 91st
 * */

export default function ImportDialog() {
  const dispatch = useDispatch();

  const { page, rowsPerPage, sortModel, inventoryFilterModel, searchValue } = useSelector(
    (state: IRootState) => state.inventory
  );
  const myShops = useSelector((state: IRootState) => state.myShops);
  const {
    openImportDialog,
    importDialogHeader,
    hideImportedItems,
    selectedImportCheckboxes,
    importPlatform,
    listingsForImport,
    inProgressList,
    isLoadMoreLoading,
    importLoading,
  } = useSelector((state: IRootState) => state.importTable);

  const myShopsAPITable = useSelector((state: IRootState) => state.myShopsAPITable);

  const isOfficialApiPlatform = officialApiPlatforms.includes(
    importPlatform as unknown as IAPIMarketplaces
  );

  const handleClose = () => {
    const orderBy = sortModel.length > 0 ? sortModel[0].field : 'added';
    const order = sortModel.length > 0 ? sortModel[0].sort : 'desc';
    dispatch(setOpenImportDialog(false));
    dispatch(setSelectedImportCheckboxes([]));
    dispatch(setListingsForImport([]));
    dispatch(setIsClearButtonVisible(false));
    dispatch(setFetchedListings([]));
    dispatch(setHasActiveSearch(false));
    dispatch(setImportNextPageID(null));
    dispatch(setIsLoadMoreLoading(false));
    dispatch(setHasMoreImportableListings(false));
    dispatch(setCurrentInventoryAction(CurrentInventoryActionEnum.EMPTY));
    dispatch(
      inventoryGetListRequest({
        page,
        rowsPerPage,
        orderBy,
        order,
        filter: getFilterModelShaped(inventoryFilterModel),
        searchValue,
      })
    );
    dispatch(setImportSearchText(''));
    dispatch(setJustCompletedList([]));
    dispatch(setImportedListingsList([]));
    dispatch(setInProgressList([]));
  };

  const handleImport = async () => {
    dispatch(setInProgressList([...selectedImportCheckboxes, ...inProgressList]));
    const selectedListings = listingsForImport.filter((item) =>
      selectedImportCheckboxes.includes(item.id)
    );
    if (!isOfficialApiPlatform) {
      dispatch(
        importListingsRequest({
          selectedListings,
          importPlatform,
          myShopsState: myShops,
        })
      );

      dispatch(setSelectedImportCheckboxes([]));
    } else {
      if (isEbay(importPlatform)) {
        const shop = getConnectedEbayShop(myShopsAPITable);
        dispatch(
          createUserActionQueueJobRequest({
            action: UserAction.IMPORT,
            listings: selectedListings
              .filter((item) => item.id !== null)
              .map((item) => ({
                id: item.id,
                image: item.img,
                title: item.name,
              })),
            marketplace: importPlatform as unknown as Marketplace,
            shop: shop.shopName,
            selectedImportCheckboxes: selectedImportCheckboxes,
            inProgressList,
          })
        );
      }
    }
  };

  const handleHide = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    dispatch(setHideImportedItems(checked));
  };

  const importListingsButtonText =
    selectedImportCheckboxes.length > 1
      ? `Import ${selectedImportCheckboxes.length} listings`
      : `Import ${selectedImportCheckboxes.length} listing`;

  const shouldDisableImportButton = !selectedImportCheckboxes.length;

  return (
    <Dialog fullWidth maxWidth="lg" open={openImportDialog} onClose={handleClose}>
      <DialogTitle>Import from {importDialogHeader}</DialogTitle>

      <DialogContent
        sx={{
          height: 'calc(100vh - 250px) !important',
        }}
      >
        <ImportInventoryDataGrid />
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              disabled={isLoadMoreLoading || importLoading}
              checked={hideImportedItems}
              onChange={handleHide}
            />
          }
          label="Hide Imported Listings"
          labelPlacement="end"
          sx={{
            float: 'left',
            pl: 2,
            py: 1.5,
            top: 0,
          }}
        />
        <Stack direction="row" spacing={1}>
          <Button variant="text" onClick={handleClose} color="inherit">
            Close
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleImport}
            disabled={shouldDisableImportButton}
            startIcon={<QueueIcon sx={{ maskRepeat: 1, fontSize: '1rem' }} />}
          >
            {importListingsButtonText}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
