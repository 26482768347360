import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { poshmarkAutomationEndpoints } from 'src/endpoints';
import axiosInstance from 'src/utils/axios';

import {
  getAutomationCountsRequest,
  getAutomationCountsSuccess,
  getAutomationCountsFail,
  setInitialLoading,
} from '../slices/statisticsSlice';

import { IGetTaskCountsRequest } from 'src/pages/automations/StatisticsPage/utils/types';

function* getAutomationCountsSaga(action: PayloadAction<IGetTaskCountsRequest>) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(poshmarkAutomationEndpoints.statistics.GET_CLOSET_STATISTICS(), {
        params: action.payload,
      })
    );
    yield put(getAutomationCountsSuccess(response.data));
    yield put(setInitialLoading(false));
  } catch (error) {
    yield put(getAutomationCountsFail(error.message));
  }
}

export function* statisticsModuleSaga() {
  yield takeLatest(getAutomationCountsRequest.type, getAutomationCountsSaga);
}
