import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsAdminLogin } from 'src/store/dashboard/slices/homeSlice';
import cookie from 'react-cookies';

export function useGetIsAdminLogin() {
  const dispatch = useDispatch();

  useEffect(() => {
    const isAdmin = cookie.load('IS_ADMIN_LOGIN') === 'true';
    dispatch(setIsAdminLogin(isAdmin));
  }, []);
}
