import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsPortalOpen } from 'src/store/automations/slices/myListingsSlice';

const useListingsScrollHandler = (shouldCreatePortal: boolean) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleScroll = () => {
      if (shouldCreatePortal) {
        dispatch(setIsPortalOpen(window.scrollY >= 118));
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [shouldCreatePortal, dispatch]);
};

export default useListingsScrollHandler;
