import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import {
  getAutomationListRequest,
  setIsRedirectingToRelevantPage,
} from 'src/store/automations/slices/automationsSlice';
import { PATH_AUTOMATIONS, PATH_DASHBOARD } from 'src/routes/paths';

const useAutomationRedirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { activeClosetCredentialsId, hasCloset } = useSelector(
    (state: IRootState) => state.myCloset
  );
  const { automationsSettings, activeClosetAutomationPlatform, isRedirectingToRelevantPage } =
    useSelector((state: IRootState) => state.automations);

  const [hasAutomations, setHasAutomations] = useState(false);

  // We exclude root path from the list because it's how we know this is the initial page load
  const automationPaths = Object.values(PATH_AUTOMATIONS).filter(
    (path) => path !== PATH_AUTOMATIONS.root
  );

  /**
   * Checks if any automation setting is enabled.
   *
   * @param {Record<string, any>} settings - The automation settings to check.
   * @returns {boolean} - Returns true if any setting is enabled, otherwise false.
   */
  const isAnyAutomationSettingEnabled = useCallback(
    (settings: Record<string, any>): boolean =>
      Object.entries(settings).some(([key, setting]) => key !== 'track-orders' && setting.enabled),
    []
  );
  const determineTargetPath = useCallback(() => {
    /**
     * Reference task: https://betalimited.atlassian.net/browse/PL-4352
     * This function determines the appropriate target path based on the closet and automation settings:
     * - If there is no closet, it returns the `myCloset` path.
     * - If there is a closet but no automation settings are enabled, it returns the `automations` path.
     * - If there is a closet and some automation settings are enabled, it returns the `activity` path with a `statistics` tab.
     */
    if (!hasCloset) {
      return PATH_AUTOMATIONS.myCloset;
    } else if (!isAnyAutomationSettingEnabled(automationsSettings)) {
      return PATH_AUTOMATIONS.automations;
    } else {
      return `${PATH_AUTOMATIONS.activity}?tab=statistics`;
    }
  }, [hasCloset, automationsSettings, isAnyAutomationSettingEnabled]);

  useEffect(() => {
    if (!location.pathname.includes('subscription')) {
      if (!automationPaths.includes(location.pathname)) {
        dispatch(setIsRedirectingToRelevantPage(true));
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (activeClosetCredentialsId && isRedirectingToRelevantPage) {
      dispatch(
        getAutomationListRequest({
          marketplace: activeClosetAutomationPlatform,
          activeClosetCredentialsId,
        })
      );
    }
  }, [activeClosetCredentialsId, activeClosetAutomationPlatform, isRedirectingToRelevantPage]);

  useEffect(() => {
    setHasAutomations(Object.keys(automationsSettings).length > 0);
  }, [automationsSettings]);

  useEffect(() => {
    if (!location.pathname.includes('subscription')) {
      if (hasAutomations && isRedirectingToRelevantPage) {
        const targetPath = determineTargetPath();

        if (!automationPaths.includes(location.pathname) && location.pathname !== targetPath) {
          navigate(targetPath, { replace: true });
        }
        dispatch(setIsRedirectingToRelevantPage(false));
      }
      if (!hasAutomations && !hasCloset && isRedirectingToRelevantPage) {
        if (
          !automationPaths.includes(location.pathname) &&
          location.pathname !== PATH_AUTOMATIONS.myCloset
        ) {
          navigate(PATH_AUTOMATIONS.myCloset, { replace: true });
        }
        dispatch(setIsRedirectingToRelevantPage(false));
      }
    } else {
      const searchParams = new URLSearchParams(location.search);
      navigate(`${window.location.pathname}?${searchParams.toString()}`);
      dispatch(setIsRedirectingToRelevantPage(false));
    }
  }, [hasAutomations, hasCloset, automationsSettings, isRedirectingToRelevantPage]);
};

export default useAutomationRedirect;
