import React from 'react';
import { Box, Typography } from '@mui/material';
import { DataGridPro, GridColDef, GridSortModel, GridToolbarContainer } from '@mui/x-data-grid-pro';
import HistoryIcon from '@mui/icons-material/History';
import Label from 'src/components/label/Label';
import Iconify from 'src/components/iconify';
import { EventNote } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { format } from 'date-fns';
import {
  affiliateBalanceHistoryRequest,
  affiliateSummaryRequest,
  setAffiliateBalanceHistoryPage,
  setAffiliateBalanceHistoryPageSize,
} from 'src/store/dashboard/slices/accountSlice';
import CustomToolbar from './CustomToolbar';
import { AffiliateEventType } from './AffiliateTransactionCard';

export const getEventColor = (
  event: AffiliateEventType
): 'success' | 'info' | 'warning' | 'secondary' | 'default' => {
  switch (event) {
    case AffiliateEventType.Purchase:
      return 'success';
    case AffiliateEventType.SignUp:
      return 'info';
    case AffiliateEventType.Refund:
      return 'warning';
    case AffiliateEventType.Payout:
      return 'secondary';
    default:
      return 'default';
  }
};

const columns: GridColDef[] = [
  { field: 'customer', headerName: 'Customer', flex: 1, sortable: false },
  { field: 'date', headerName: 'Date', flex: 1, sortable: false },
  {
    field: 'event',
    headerName: 'Event',
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      const color = getEventColor(params.value as AffiliateEventType);
      return <Label color={color}>{params.value}</Label>;
    },
  },
  {
    field: 'reward',
    headerName: 'Reward',
    flex: 1,
    sortable: false,
    renderCell: (params) => <Typography>${params.value.toFixed(2)}</Typography>,
  },
];

const AffiliateTable: React.FC = () => {
  const dispatch = useDispatch();

  const {
    affiliateBalanceHistory,
    affiliateSummary,
    affiliateBalanceHistoryPage,
    affiliateBalanceHistoryPageSize,
    affiliateBalanceHistoryRowsPerPageOptions,
    loading,
  } = useSelector((state: IRootState) => state.account);

  const handlePageChange = (newPage: number) => {
    dispatch(setAffiliateBalanceHistoryPage(newPage + 1));
    dispatch(
      affiliateSummaryRequest({
        page: newPage + 1,
        size: affiliateBalanceHistoryPageSize,
      })
    );
  };

  const handlePageSizeChange = (newPageSize: number) => {
    dispatch(setAffiliateBalanceHistoryPageSize(newPageSize));
    dispatch(setAffiliateBalanceHistoryPage(1));
    dispatch(
      affiliateSummaryRequest({
        page: 1,
        size: newPageSize,
      })
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        mt: 3,
        bgcolor: 'white',
        borderRadius: '16px',
        boxShadow:
          '0px 0px 2px 0px rgba(145, 158, 171, 0.20), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
      }}
    >
      <DataGridPro
        rows={affiliateBalanceHistory?.data ?? []}
        getRowHeight={() => 'auto'}
        rowCount={affiliateBalanceHistory?.count ?? 0}
        pageSize={affiliateBalanceHistoryPageSize}
        page={affiliateBalanceHistoryPage - 1}
        columns={columns}
        disableColumnResize
        disableColumnSelector
        onPageSizeChange={handlePageSizeChange}
        paginationMode="server"
        pagination
        disableColumnPinning
        onPageChange={handlePageChange}
        loading={loading}
        rowsPerPageOptions={affiliateBalanceHistoryRowsPerPageOptions}
        disableColumnFilter
        disableColumnReorder
        disableColumnMenu
        components={{
          Toolbar: () => (
            <GridToolbarContainer>
              <CustomToolbar
                summaryData={{
                  totalPayments: affiliateSummary?.totalPayments ?? 0,
                  totalRewardAmount: affiliateSummary?.totalRewardAmount ?? 0,
                  totalSignUps: affiliateSummary?.totalSignUps ?? 0,
                }}
              />
            </GridToolbarContainer>
          ),
        }}
        initialState={{
          pagination: { pageSize: 1 },
        }}
        disableSelectionOnClick
        sortingMode="server"
        getEstimatedRowHeight={() => 500}
        autoHeight
        sx={{
          '&.MuiDataGrid-root': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            border: 'none',
          },

          '& .MuiDataGrid-cell': {
            whiteSpace: 'normal !important',
            wordBreak: 'break-word !important',
            borderBottom: 'none',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-row': {
            borderBottom: '1px dashed rgba(145, 158, 171, 0.20)',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#F4F6F8',
            borderRadius: 0,
            color: '#637381',
            '& .MuiDataGrid-columnHeader': {
              padding: '16px',
            },
          },
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { px: '3px', py: '0px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { p: '16px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            px: '13px',
            py: '7.5px',
          },
        }}
      />
    </Box>
  );
};

export default AffiliateTable;
