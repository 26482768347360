import React from 'react';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';

import { HOST_API_KEY } from 'src/config';
import { setIsInitialDeactivateOpen } from 'src/store/dashboard/slices/accountSlice';
import { IRootState } from 'src/store';

const DeactivateButton = () => {
  const dispatch = useDispatch();
  const { userAccountDetail } = useSelector((state: IRootState) => state.account);

  if (HOST_API_KEY.includes('sandbox')) {
    return (
      <LoadingButton
        color="error"
        variant="text"
        onClick={() => dispatch(setIsInitialDeactivateOpen(true))}
      >
        Deactivate My Account
      </LoadingButton>
    );
  } else {
    return (
      <LoadingButton
        color="error"
        variant="text"
        data-tally-open="nGMre3"
        data-tally-layout="modal"
        data-tally-width="720"
        data-tally-emoji-text="👋"
        data-tally-emoji-animation="wave"
        data-tally-auto-close="0"
        data-email={`${userAccountDetail.email}`}
      >
        Deactivate My Account
      </LoadingButton>
    );
  }
};

export default DeactivateButton;
