import { GridColumns } from '@mui/x-data-grid-pro';
import { ImportImageColumn, ImportNameColumn, ImportPriceColumn } from './ImportTableColumns';
import ImportImportedColumn from './ImportTableColumns/ImportImportedColumn';

export const columns: GridColumns = [
  {
    field: 'image',
    headerName: 'Avatar',
    pinnable: false,
    hideable: false,
    filterable: false,
    width: 150,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    disableColumnMenu: true,
    valueGetter: (params) => params.value,
    renderCell: (params) => <ImportImageColumn url={params.value} />,
  },
  {
    field: 'title',
    headerName: 'Name',
    pinnable: false,
    hideable: false,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    width: 550,
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => params.value,
    renderCell: (params) => <ImportNameColumn title={params.value} />,
  },
  {
    field: 'price',
    headerName: 'Price',
    type: 'number',
    pinnable: false,
    hideable: false,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    width: 100,
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => params.value,
    renderCell: (params) => <ImportPriceColumn price={params.value} />,
  },
  {
    field: 'imported',
    headerName: '',
    pinnable: false,
    hideable: false,
    filterable: false,
    sortable: false,
    width: 200,
    headerAlign: 'center',
    align: 'center',
    disableColumnMenu: true,
    valueGetter: (params) => params.value,
    renderCell: (params: any) => <ImportImportedColumn id={params.value.id} />,
  },
];
