import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

import { IRootState } from 'src/store';
import { setOpenAPIConnectionRequiredDialog } from 'src/store/dashboard/slices/inventorySlice';
import ErrorIcon from '@mui/icons-material/Error';
import { ConnectionFailTypeEnum, MixpanelEventTypes, mixpanelTrack } from 'src/utils/mixpanel';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { Marketplace } from '@beta.limited/primelister';

export enum MyShopsOnboardingEnum {
  API_CONNECTION = 'api-connection',
}

export default function InventoryAPIConnectionRequiredDialog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openAPIConnectionRequiredDialog } = useSelector((state: IRootState) => state.inventory);

  const handleClose = () => dispatch(setOpenAPIConnectionRequiredDialog(false));

  const handleConnect = () => {
    navigate(
      `${PATH_DASHBOARD.myShops}?startOnboarding=${MyShopsOnboardingEnum.API_CONNECTION}&onboarding-platform=${Marketplace.EBAY_US}`
    );
    dispatch(setOpenAPIConnectionRequiredDialog(false));
  };

  if (openAPIConnectionRequiredDialog) {
    mixpanelTrack(MixpanelEventTypes.API_CONNECTION_FAILED, ConnectionFailTypeEnum.API_REQUIRED);
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={openAPIConnectionRequiredDialog} onClose={handleClose}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        API Connection Required
      </DialogTitle>
      <DialogContent
        sx={{
          typography: 'body2',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <ErrorIcon sx={{ fontSize: '5rem', alignSelf: 'center', mb: '1.5rem' }} color="warning" />
        <DialogContentText sx={{ textAlign: 'center' }}>
          <Typography mb={'1rem'}>
            We're sorry, but it looks like you don't have the API Connection set up. To use all of
            our features, you'll need to establish it.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button variant="contained" color="primary" onClick={handleConnect}>
          Click here to connect it
        </Button>
      </DialogActions>
    </Dialog>
  );
}
