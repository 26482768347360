// @mui
import { Stack, Typography, Skeleton, Box } from '@mui/material';

import Iconify from 'src/components/iconify';

import { fCurrency } from 'src/utils/formatNumber';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'src/store';
import Label, { LabelColor } from 'src/components/label';
import LaunchIcon from '@mui/icons-material/Launch';
import { fromUnixTime } from 'date-fns';
import { LoadingButton } from '@mui/lab';
import { getBillingInvoiceListRequest } from 'src/store/dashboard/slices/accountSlice';
import { IS_SANDBOX } from 'src/config';
import { getDateWithTimeZone } from '../../../../../utils/getDateWithTimezone';

// ----------------------------------------------------------------------

const INVOICE_FILTER_VALUES_FOR_POSHMARK = {
  sandbox: ['prod_IRTCQUSYpuuGe2', 'prod_OK4V8XpRNXzb4D'],
  production: ['prod_IPlmG9LFWiUi0a', 'prod_ONPDW32xDfhobx'],
};

export default function AccountBillingInvoiceHistory() {
  const {
    billingInvoiceList,
    initialLoading,
    invoicesHasMore,
    invoicesRequestLimit,
    moreInvoicesLoading,
    userAccountDetail,
  } = useSelector((state: IRootState) => state.account);

  const dispatch = useDispatch();

  function getLabelColor(status: string) {
    const lowerCaseStatus = status.toLowerCase();
    switch (lowerCaseStatus) {
      case 'open':
      case 'uncollectible':
        return 'warning';
      case 'closed':
        return 'danger';
      default:
        return 'success';
    }
  }

  const handleLink = (link: string) => window.open(link);

  const renderTime = (invoice: any) =>
    getDateWithTimeZone(
      fromUnixTime(invoice.created),
      userAccountDetail?.timeZone?.name,
      'DD MMM YYYY'
    );

  const capitalizer = (status: string) =>
    status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

  const handleViewMore = () => {
    const newLimit = invoicesRequestLimit + 10;
    dispatch(getBillingInvoiceListRequest(newLimit));
  };

  const renderInvoices = (invoice: any) => (
    <Box key={invoice.id} sx={{ display: 'flex', alignItems: 'center', width: 1 }}>
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Typography sx={{ minWidth: 50 }} variant="body2">
          {renderTime(invoice)}
        </Typography>
        <LaunchIcon
          sx={{
            fontSize: '15px',
            fontWeight: '700',
            ml: 1,
            cursor: 'pointer',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
          onClick={() => handleLink(invoice.hosted_invoice_url)}
        />
      </Box>

      <Typography
        variant="body2"
        sx={{ minWidth: 70, textAlign: 'center', flex: '0 1 auto', ml: 3 }}
      >
        {fCurrency(invoice.total / 100)}
      </Typography>

      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Label color={getLabelColor(invoice.status) as LabelColor}>
          {capitalizer(invoice.status)}
        </Label>
      </Box>
    </Box>
  );

  const renderViewMoreButton = () => (
    <LoadingButton
      loading={moreInvoicesLoading}
      variant="text"
      sx={{
        width: 'max-content',
        color: '#2065D1',
        p: 0,
        px: 0.5,
        '&:hover': { backgroundColor: 'white' },
        ml: moreInvoicesLoading ? '-2.5rem !important' : '-0.5rem !important',
      }}
      onClick={handleViewMore}
    >
      <Iconify icon={'eva:chevron-down-fill'} /> View More
    </LoadingButton>
  );

  const addSkeletonToComponents = (component: any) => {
    if (initialLoading) {
      const skeletonItems = Array.from({ length: 10 }).map((_, index) => (
        <Skeleton key={index} variant="rounded" sx={{ width: '100', height: '2rem', my: 1 }} />
      ));
      return <>{skeletonItems}</>;
    } else {
      return component;
    }
  };

  //TODO - If we add depop or mercari automations these links can be change.
  const isPoshmarkTool = window.location.href.includes('automations/user');

  const poshmarkInvoices = billingInvoiceList.filter((item) => {
    const product = item?.lines?.data?.length && item?.lines?.data[0]?.plan?.product;
    const filterValues = IS_SANDBOX
      ? INVOICE_FILTER_VALUES_FOR_POSHMARK.sandbox
      : INVOICE_FILTER_VALUES_FOR_POSHMARK.production;

    return filterValues.includes(product);
  });

  const conditionalInvoiceList = isPoshmarkTool ? poshmarkInvoices : billingInvoiceList;

  const renderInvoiceList = () => (
    <>
      {conditionalInvoiceList.length > 0 ? (
        conditionalInvoiceList.map((invoice) => addSkeletonToComponents(renderInvoices(invoice)))
      ) : (
        <Typography
          variant="body2"
          sx={{ textAlign: 'center', alignSelf: 'flex-start', mt: '2rem !important' }}
        >
          No invoices available
        </Typography>
      )}
    </>
  );

  return (
    <Stack spacing={3} alignItems="flex-end">
      <Typography variant="overline" sx={{ width: 1, color: 'text.secondary' }}>
        Invoice History
      </Typography>

      <Stack spacing={2} sx={{ width: 1 }}>
        <Stack direction="row" justifyContent="space-between" sx={{ width: 1 }}>
          <Typography sx={{ minWidth: 70 }} variant="body2" fontWeight={700}>
            Date
          </Typography>

          <Typography variant="body2" fontWeight={700} sx={{ minWidth: 50, textAlign: 'center' }}>
            Amount
          </Typography>
          <Typography variant="body2" fontWeight={700} sx={{ textAlign: 'center' }}>
            Status
          </Typography>
        </Stack>
        {addSkeletonToComponents(renderInvoiceList())}
        {invoicesHasMore && renderViewMoreButton()}
      </Stack>
    </Stack>
  );
}
