import { Avatar, Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DataGridGroupsColumnInterface } from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import {
  setCurrentSelectedGroup,
  setIsInventoryQuickEditPopoverOpen,
  setQuickEditAnchorPosition,
  setQuickEditSelectedListing,
  setSelectedQuickEditItemType,
} from 'src/store/dashboard/slices/inventorySlice';
import InventoryQuickEditPopover, {
  InventoryQuickEditPopoverEnum,
} from '../../others/InventoryQuickEditPopover';
import OverflowTypography from 'src/components/product-card/OverflowTypography';

export default function DataGridGroupsColumn({ groups, rowData }: DataGridGroupsColumnInterface) {
  const groupCheck = groups && groups.length !== 0;
  const [showEditIcon, setShowEditIcon] = useState(false);

  const { added, id, refs, title, image } = rowData;

  const listingData = useMemo(() => ({ id, refs, title, image }), [id, refs, title, image]);

  const dispatch = useDispatch();

  const handleEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setQuickEditAnchorPosition({ top: event.clientY, left: event.clientX }));
    dispatch(setCurrentSelectedGroup(groups));
    dispatch(setQuickEditSelectedListing(listingData));
    dispatch(setSelectedQuickEditItemType(InventoryQuickEditPopoverEnum.GROUP));
    dispatch(setIsInventoryQuickEditPopoverOpen(true));
  };

  const renderEditIcon = () => (
    <Tooltip title="Edit Group">
      <Box
        sx={{
          width: '24px',
          height: '24px',
          padding: '0px',
          position: 'absolute',
          right: '0px',
          zIndex: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          backgroundColor: '#ECEDEF',
          ':hover': {
            backgroundColor: '#DFE1E6',
          },
        }}
      >
        <IconButton disableRipple sx={{}} onClick={handleEdit}>
          <Avatar src="/icons/editIcon.svg" sx={{ width: 16, height: 16 }} />
        </IconButton>
      </Box>
    </Tooltip>
  );

  return (
    <Stack
      onMouseEnter={() => setShowEditIcon(true)}
      onMouseLeave={() => setShowEditIcon(false)}
      spacing={0.2}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
        textAlign: 'center',
        padding: '4px',
        position: 'relative',
      }}
    >
      {groupCheck ? (
        groups.map((item, index) => (
          <Typography
            key={index}
            sx={{ maxWidth: '80px', wordBreak: 'break-all', wordWrap: 'break-word' }}
            variant="body2"
          >
            <OverflowTypography
              sx={{
                textTransform: 'none',
                fontSize: '14px',
              }}
            >
              {item.name}
            </OverflowTypography>
          </Typography>
        ))
      ) : (
        <Box
          sx={{
            maxWidth: '60px',
            wordBreak: 'break-all',
            wordWrap: 'break-word',
          }}
        />
      )}
      {showEditIcon && renderEditIcon()}
    </Stack>
  );
}
