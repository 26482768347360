import { Chip, CircularProgress } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import Iconify from 'src/components/iconify';
import { IRootState } from 'src/store';

const statusLabels = {
  imported: 'Already Imported',
  inProgress: 'Importing',
};

export default function ImportNameColumn({ id }: { id: string }) {
  const { importedListingsList, justCompletedList, inProgressList } = useSelector(
    (state: IRootState) => state.importTable
  );

  const isImported = importedListingsList.includes(id) || justCompletedList.includes(id);
  const isInProgress = inProgressList.includes(id) && !isImported;

  const ImportedIcon = <Iconify width={24} icon="material-symbols:check-circle-rounded" />;
  const InProgressIcon = (
    <CircularProgress
      sx={{
        width: '12px !important',
        height: '12px !important',
        color: 'gray !important',
        ml: '8px !important',
      }}
    />
  );

  return (
    <Stack direction="row" sx={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      {isImported && (
        <Chip variant="soft" icon={ImportedIcon} label={statusLabels.imported} color="info" />
      )}

      {isInProgress && (
        <Chip
          variant="soft"
          icon={InProgressIcon}
          label={statusLabels.inProgress}
          color="default"
          sx={{ color: 'gray' }}
        />
      )}
    </Stack>
  );
}
