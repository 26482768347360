import { Stack, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Lottie from 'lottie-react';
import checkLottie from './lottie/tick.json';
import axiosInstance from 'src/utils/axios';

const ExtensionInstallPage: React.FC = () => (
  <>
    <Helmet>
      <title> Installation successfull | PrimeLister</title>
    </Helmet>

    <Stack
      sx={{
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        height: '100vh',
        justifyContent: 'center',
      }}
    >
      <Lottie
        animationData={checkLottie}
        loop={false}
        style={{
          marginTop: '-100px',
          height: 200,
        }}
      />

      <Typography
        sx={{
          fontSize: '2rem',
          fontWeight: 700,
          mb: 2.5,
          mt: 2,
        }}
      >
        Installation Succeded
      </Typography>

      <Typography
        sx={{
          fontSize: '1.2rem',
        }}
      >
        Thanks for installing! You're ready to boost your sales.
      </Typography>
    </Stack>
  </>
);

export default ExtensionInstallPage;
