import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Box, Divider, Stack, MenuItem, useTheme, Button, useMediaQuery } from '@mui/material';

import { PATH_AUTOMATIONS, PATH_DASHBOARD } from 'src/routes/paths';
import { IconButtonAnimate } from 'src/components/animate';
import MenuPopover from 'src/components/menu-popover';
import useResponsive from 'src/hooks/useResponsive';
import { showCrispChat } from 'src/utils/crispChatHelpers';
import HelpButton from './HelpButton';
import { IRootState } from 'src/store';
import { useSelector, useDispatch } from 'react-redux';
import { setIsCrispHidden } from 'src/store/dashboard/slices/homeSlice';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Submit Feedback',

    linkTo: 'https://roadmap.primelister.com/b/feedback',
  },
  {
    label: 'Roadmap',

    linkTo: 'https://roadmap.primelister.com/roadmap',
  },
  {
    label: 'Changelog',

    linkTo: 'https://roadmap.primelister.com/changelog',
  },
];

// ----------------------------------------------------------------------

export default function HelpMenuPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);

  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const mobileMatches = useMediaQuery('(min-width:370px)');
  const isMobile = useResponsive('down', 'sm');
  const location = useLocation();
  const isDesktop = useResponsive('up', 'sm');

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(true);
  };
  const { origin } = window.location;
  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  const handleClickItem = (path: string) => {
    handleClosePopover();
    window.open(path, '_target', 'noopener noreferrer');
  };

  const handleOpenCrispChat = () => {
    showCrispChat();
    dispatch(setIsCrispHidden(false));
    handleClosePopover();
  };

  const sytles = {
    menuItem: {
      fontSize: '14px !important',
      borderRadius: '8px',
    },
  };

  const showDropdown = location.pathname.includes(PATH_AUTOMATIONS.account) || isDesktop;

  return showDropdown ? (
    <Box sx={{ mr: !isPrimeListerMobileApp ? '8px' : 0, position: 'relative' }}>
      <HelpButton
        isOpen={openPopover}
        handleOpenPopover={handleOpenPopover}
        handleClosePopover={handleClosePopover}
      />

      {openPopover && (
        <Stack
          sx={{
            width: 200,
            borderRadius: '8px',
            position: 'absolute',
            right: '0px',
            zIndex: 1000,
            bgcolor: 'background.paper',
            boxShadow: (theme) => theme.customShadows.z8,
            fontSize: '14px',
            mr: '-12px',
            p: '8px',
            mt: '-2px',
          }}
          spacing="8px"
          onMouseEnter={handleOpenPopover}
          onMouseLeave={handleClosePopover}
        >
          <Stack>
            {isMobile && (
              <MenuItem sx={sytles.menuItem} onClick={handleOpenCrispChat}>
                Chat With Us
              </MenuItem>
            )}

            {!isPrimeListerMobileApp && (
              <MenuItem sx={sytles.menuItem} onClick={() => handleClickItem(`${origin}/bugreport`)}>
                Report Bug
              </MenuItem>
            )}
          </Stack>

          {!isPrimeListerMobileApp && (
            <>
              <Divider sx={{ borderStyle: 'dashed' }} />
              <Stack>
                {OPTIONS.map((option) => (
                  <MenuItem
                    sx={sytles.menuItem}
                    key={option.label}
                    onClick={() => handleClickItem(option.linkTo)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Stack>
              <Divider sx={{ borderStyle: 'dashed' }} />
              <Stack>
                <MenuItem
                  sx={sytles.menuItem}
                  onClick={() => handleClickItem('https://docs.primelister.com/')}
                >
                  Support Articles
                </MenuItem>
              </Stack>
            </>
          )}
        </Stack>
      )}
    </Box>
  ) : (
    <></>
  );
}
