import { EventNote } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';

const CustomToolbar = ({
  summaryData,
}: {
  summaryData: {
    totalRewardAmount: number;
    totalPayments: number;
    totalSignUps: number;
  };
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        p: 2,
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '12px',
          mb: 0.5
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Iconify icon="duoton" width={24} height={24} />
        <Typography
          sx={{
            color: '#212B36',
            fontSize: '13px',
            lineHeight: '22px',
            fontWeight: 700,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            [theme.breakpoints.down('md')]: {
              fontSize: '16px',
            },
          }}
        >
          <EventNote
            sx={{
              width: '18px',
              height: '18px',
            }}
          />
          Transactions
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '22px',
              fontWeight: 600,
              color: 'text.secondary',
            }}
          >
            Total Signup
          </Typography>
          <Label color="default" variant="soft">
            {summaryData.totalSignUps}
          </Label>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '22px',
              fontWeight: 600,
              color: 'text.secondary',
            }}
          >
            Total Payment Count
          </Typography>
          <Label color="success" variant="soft">
            {summaryData.totalPayments}
          </Label>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomToolbar;
