import { Outlet, useLocation, useParams } from 'react-router-dom';

import { Box, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import Main from '../LayoutComponents/Main';
import Header from './header';
import NavHorizontal from '../LayoutComponents/NavHorizontal';

import { isLoggedIn } from 'src/utils/isLoggedIn';
import { IRootState } from 'src/store';

import { PATH_DASHBOARD } from 'src/routes/paths';

import createNavConfig from './nav/config';
import useStripeRedirectedUser from 'src/hooks/useStripeRedirectedUser';
import useInitializeExtensionAndThirdParties from 'src/hooks/useInitializeExtensionAndThirdParties';
import LayoutAlertContainer from '../LayoutComponents/LayoutAlertContainer';
import { useEffect } from 'react';
import { getAllConnectedApiShopsRequest } from 'src/store/dashboard/slices/myShopsAPITableSlice';
import { useBrowser, useDevice } from 'src/hooks/useUA';
import useResponsive from 'src/hooks/useResponsive';
import AdminLoginBanner from 'src/components/banner/AdminLoginBanner';

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { blockState } = useSelector((state: IRootState) => state.auth);
  const { isInFullscreenMode } = useSelector((state: IRootState) => state.inventory);
  const {
    isExtensionConnected,
    isExtensionConnectedResultLoading,
    hasCheckedConnection,
    isPrimeListerMobileApp,
  } = useSelector((state: IRootState) => state.home);
  const dispatch = useDispatch();
  const location = useLocation();
  const { userId } = isLoggedIn();

  const navbarMatches = useMediaQuery('(min-width:1349px)');
  const isMobile = useResponsive('down', 'sm');
  const { id } = useParams();

  //-- Stripe redirection
  useStripeRedirectedUser();
  //--extension and third party apps
  useInitializeExtensionAndThirdParties(location, userId);

  const { pathname } = location;

  const shouldHideNavHorizontal = pathname.includes(PATH_DASHBOARD.subscription.root);

  const shouldRenderDuplicateError =
    blockState == 1 && !pathname.includes(PATH_DASHBOARD.subscription.root);

  const deviceInformation = useDevice();
  const browserInformation = useBrowser();

  const shouldRenderMobileDeviceAlert = deviceInformation?.type !== undefined; // for ua-parser-js, device type is undefined for desktop browsers

  const shouldRenderBrowserCompatibilityAlert =
    (!browserInformation?.name || !browserInformation?.name?.toLowerCase().includes('chrome')) &&
    !shouldRenderMobileDeviceAlert;

  const shouldRenderExtensionAlert =
    hasCheckedConnection &&
    !isExtensionConnectedResultLoading &&
    !isExtensionConnected &&
    (pathname.includes(PATH_DASHBOARD.myShops) ||
      pathname.includes(PATH_DASHBOARD.inventory.root)) &&
    !id &&
    !shouldRenderMobileDeviceAlert;

  const hasAnyAlert =
    shouldRenderDuplicateError ||
    shouldRenderExtensionAlert ||
    shouldRenderMobileDeviceAlert ||
    shouldRenderBrowserCompatibilityAlert;

  const alerts = {
    shouldRenderDuplicateError,
    shouldRenderExtensionAlert,
    shouldRenderMobileDeviceAlert,
    shouldRenderBrowserCompatibilityAlert,
  };

  useEffect(() => {
    dispatch(getAllConnectedApiShopsRequest());
  }, []);

  const navConfig = createNavConfig(isMobile, isPrimeListerMobileApp);
  return (
    <>
      {!isPrimeListerMobileApp && !isInFullscreenMode && <Header />}
      {!shouldHideNavHorizontal && !navbarMatches && !isInFullscreenMode && (
        <NavHorizontal navConfig={navConfig} />
      )}

      <Main hasAlert={hasAnyAlert}>
        {hasAnyAlert && !isInFullscreenMode && (
          <LayoutAlertContainer alerts={alerts} isInAutomations={false} />
        )}
        <Outlet />
      </Main>
    </>
  );
}
