import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tooltip,
  TooltipProps,
} from '@mui/material';

import { IRootState } from 'src/store';
import Image from 'src/components/image';
import { useState } from 'react';
import usePlatformLoginStatusCheck, { ActionType } from 'src/hooks/usePlatformLoginStatusCheck';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { IPlatforms } from 'src/store/dashboard/slices/myShopsSlice';
import useCrosslistHandler from 'src/hooks/useCrosslistHandler';

export const platformIcons = [
  { name: IPlatforms.DEPOP, url: '/icons/png/depop.png' },
  { name: IPlatforms.EBAYUS, url: '/icons/png/ebay.png' },
  { name: IPlatforms.EBAYAU, url: '/icons/png/ebayAU.png' },
  { name: IPlatforms.EBAYCA, url: '/icons/png/ebayCA.png' },
  { name: IPlatforms.ETSY, url: '/icons/png/etsy.png' },
  { name: IPlatforms.FACEBOOK, url: '/icons/png/facebook.png' },
  { name: IPlatforms.GRAILED, url: '/icons/png/grailed.png' },
  { name: IPlatforms.MERCARI, url: '/icons/png/mercari.png' },
  { name: IPlatforms.POSHMARKCA, url: '/icons/png/poshmarkCA.png' },
  { name: IPlatforms.POSHMARKUS, url: '/icons/png/poshmarkUS.png' },
  { name: IPlatforms.SHOPIFY, url: '/icons/png/shopify.png' },
];

const StyledTooltipComponent = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(`
& .MuiTooltip-tooltip {
  background: slategray
}
`);

export default function InventoryBulkCrossListDialog() {
  const { openBulkCrosslistDialog, bulkActionsListingList } = useSelector(
    (state: IRootState) => state.inventory
  );

  const { getLoginStatusOfMarketplace } = usePlatformLoginStatusCheck();

  const {
    handleBulkDialogClose,
    handleBulkListingsCrosslist,
    setSelectedMarketPlaces,
    selectedMarketPlaces,
    isLoading,
  } = useCrosslistHandler();

  function handleMarketPlaceSelection(name: string) {
    selectedMarketPlaces.includes(name)
      ? setSelectedMarketPlaces(selectedMarketPlaces.filter((marketPlace) => marketPlace !== name))
      : setSelectedMarketPlaces([...selectedMarketPlaces, name]);
  }

  const excludedRefList = () => {
    const refList = bulkActionsListingList.flatMap((item) => Object.keys(item.marketplaceSources));
    const newRefList = refList.filter(
      (ref) => refList.filter((r) => r === ref).length === bulkActionsListingList.length
    );
    const uniqueExcludeList = Array.from(new Set(newRefList)).sort();
    return uniqueExcludeList;
  };

  const filteredPlatformIcons = platformIcons.filter(
    (icon) => !excludedRefList().includes(icon.name)
  );

  const renderIcons = (icon: { name: IPlatforms; url: string }, index: number) => (
    <Stack
      key={index}
      sx={{
        minWidth: '7rem',
        maxWidth: '7rem',
        minHeight: '7rem',
        borderRadius: '7rem',
        m: 1,
        position: 'relative',
        '&:hover': { transform: 'scale(1.05)' },
        '&:active': { transform: 'scale(1)' },
      }}
      onClick={() =>
        getLoginStatusOfMarketplace(ActionType.CROSSLIST, icon.name, {
          checkExtensionLoginStatus: true,
        }) && handleMarketPlaceSelection(icon?.name)
      }
    >
      <Image
        src={icon.url}
        sx={{
          maxWidth: '6.5rem',
          maxHeight: '6.5rem',
          borderRadius: '6.5rem',
        }}
      />
      {getLoginStatusOfMarketplace(ActionType.CROSSLIST, icon.name, {
        checkExtensionLoginStatus: true,
      }) ? (
        <Image
          src="/icons/check.png"
          sx={{
            width: '6rem',
            height: '6rem',
            borderRadius: '6rem',
            cursor: 'pointer',
            zIndex: 1,
            position: 'absolute',
            top: '0.25rem',
            left: '0.25rem',
            opacity: `${selectedMarketPlaces.includes(icon.name) ? 1 : 0}`,
            transition: 'all 0.25s ease-in-out',
          }}
        />
      ) : (
        <StyledTooltipComponent
          title={'Login Required: Please log in to this platform in order to perform any actions.'}
          placement="top"
          arrow
        >
          <Box
            sx={{
              bgcolor: 'slategray',
              width: '6rem',
              height: '6rem',
              borderRadius: '6rem',
              cursor: 'pointer',
              zIndex: 1,
              position: 'absolute',
              top: '0.25rem',
              left: '0.25rem',
              opacity: 0.5,
            }}
          />
        </StyledTooltipComponent>
      )}
    </Stack>
  );

  return (
    <Dialog fullWidth maxWidth="lg" open={openBulkCrosslistDialog} onClose={handleBulkDialogClose}>
      <DialogTitle mb={3}>Please select target marketplaces to cross-list</DialogTitle>
      <DialogContent
        sx={{
          typography: 'body2',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        {filteredPlatformIcons.map((icon, index) => renderIcons(icon, index))}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={handleBulkDialogClose}>
          Close
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={isLoading}
          disabled={!selectedMarketPlaces.length || isLoading}
          onClick={handleBulkListingsCrosslist}
        >
          {!selectedMarketPlaces.length
            ? 'Please select at least one marketplace'
            : `Crosslist to ${selectedMarketPlaces.length} marketplace`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
