import React, { useState } from 'react';
import { Field, Formik, Form, useField, useFormikContext } from 'formik';
import * as Yup from 'yup';
import {
  Stack,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Collapse,
  Tooltip,
  CircularProgress,
  Box,
} from '@mui/material';
import Rules, { RuleProps } from './Rules';
import AgeFilter from './filters/AgeFilter';
import CurrentPriceFilter from './filters/CurrentPriceFilter';
import MinEarningFilter from './filters/MinEarningFilter';
import CategoryRichTreeView from 'src/pages/automations/MyListingsPage/components/shared/CategoryRichTreeView';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Automation, AutoSendOfferToLikersRule } from '@beta.limited/primelister';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { IDataForUpdateAutomationSettings } from 'src/pages/automations/AutomationsPage/types/types';
import {
  setIsConnectionErrorDialogOpen,
  updateAutomationSettingsRequest,
} from 'src/store/automations/slices/automationsSlice';
import ShippingOfferWithSelect from './ShippingOfferWithSelect';

interface OfferToLikersRulesProps {
  initialSettings: AutoSendOfferToLikersRule[];
}

interface FormValues {
  rules: AutoSendOfferToLikersRule[];
}

const ConditionSwitch = ({
  name,
  label,
  index,
  tooltipContent,
}: {
  name: string;
  label: string;
  index: number;
  tooltipContent: string;
}) => {
  const [field, , helpers] = useField(name);
  const { values } = useFormikContext<FormValues>();
  const dispatch = useDispatch();
  const { selectedAutomationOption, activeClosetAutomationPlatform, automationsSettings } =
    useSelector((state: IRootState) => state.automations);
  const { activeClosetCredentialsId, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.myCloset
  );

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const fieldName = name.split('.').pop() as 'nwt' | 'boutique';

    if (hasActiveClosetConnection) {
      helpers.setValue(checked);

      const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
      const updatedRules = automationsSettings[
        Automation.AUTO_SEND_OFFER_TO_LIKERS
      ].config.rules.map((rule, i) =>
        i === index
          ? {
              ...rule,
              filters: {
                ...rule.filters,
                condition: {
                  ...rule.filters.condition,
                  [fieldName]: checked,
                  enabled:
                    fieldName === 'nwt'
                      ? checked || rule.filters.condition.boutique
                      : rule.filters.condition.nwt || checked,
                },
              },
            }
          : rule
      );

      const dataForUpdating: IDataForUpdateAutomationSettings = {
        settings: {
          [Automation.AUTO_SEND_OFFER_TO_LIKERS]: {
            config: {
              rules: updatedRules,
            },
          },
        },
        toastMessage,
        displayName: selectedAutomationOption.displayName,
        activeClosetAutomationPlatform,
        activeClosetCredentialsId,
      };

      dispatch(updateAutomationSettingsRequest(dataForUpdating));
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
  };

  return (
    <FormControlLabel
      control={<Switch {...field} checked={field.value} onChange={handleSwitchChange} />}
      label={
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            textWrap: 'nowrap',
          }}
        >
          {label}
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title={tooltipContent}
            placement="top"
            sx={{ textAlign: 'center !important' }}
          >
            <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
          </Tooltip>
        </Stack>
      }
      sx={{
        marginLeft: '-10px !important',
      }}
    />
  );
};

const CategorySwitch = ({ index }: { index: number }) => {
  const [field, , helpers] = useField(`rules.${index}.filters.category.enabled`);
  const { values } = useFormikContext<FormValues>();
  const dispatch = useDispatch();
  const { selectedAutomationOption, activeClosetAutomationPlatform, automationsSettings } =
    useSelector((state: IRootState) => state.automations);
  const { activeClosetCredentialsId, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.myCloset
  );

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    if (hasActiveClosetConnection) {
      helpers.setValue(checked);

      const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
      const updatedRules = automationsSettings[
        Automation.AUTO_SEND_OFFER_TO_LIKERS
      ].config.rules.map((rule, i) =>
        i === index
          ? {
              ...rule,
              filters: {
                ...rule.filters,
                category: {
                  ...rule.filters.category,
                  enabled: checked,
                },
              },
            }
          : rule
      );

      const dataForUpdating: IDataForUpdateAutomationSettings = {
        settings: {
          [Automation.AUTO_SEND_OFFER_TO_LIKERS]: {
            config: {
              rules: updatedRules,
            },
          },
        },
        toastMessage,
        displayName: selectedAutomationOption.displayName,
        activeClosetAutomationPlatform,
        activeClosetCredentialsId,
      };

      dispatch(updateAutomationSettingsRequest(dataForUpdating));
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
  };

  return (
    <FormControlLabel
      control={<Switch {...field} checked={field.value} onChange={handleSwitchChange} />}
      label={
        <Stack direction="row" alignItems="center" sx={{ textWrap: 'nowrap' }}>
          Filter by category of listing
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Enable this option to send offers only to liked items within your selected categories."
            placement="top"
            sx={{ textAlign: 'center !important' }}
          >
            <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
          </Tooltip>
        </Stack>
      }
      sx={{
        marginLeft: '-10px !important',
      }}
    />
  );
};

const OfferToLikersRules: React.FC<OfferToLikersRulesProps> = ({ initialSettings }) => {
  const { categoriesLoading } = useSelector((state: IRootState) => state.automations);
  const defaultRule: AutoSendOfferToLikersRule = {
    filters: {
      category: { enabled: false, selectedCategories: [] },
      ageRange: { enabled: false, min: 0, max: 12 },
      dateRange: { enabled: false, min: 0, max: 12 },
      likeRange: { enabled: false, min: 0, max: 100 },
      lowestPriceRange: { enabled: false, min: 1, max: 2 },
      priceRange: { enabled: false, min: 1, max: 2 },
      condition: { enabled: false, nwt: false, boutique: false },
      earnings: { enabled: false, unit: 'percentage', value: 0 },
    },
    offer: { unit: 'percentage', value: 10 },
    shipping: { option: 'original', customValue: '' },
  };

  const validationSchema = Yup.object().shape({
    rules: Yup.array().of(
      Yup.object().shape({
        offer: Yup.object().shape({
          value: Yup.number().min(1).max(100).required('Offer value is required'),
        }),
        shipping: Yup.object().shape({
          option: Yup.string().required('Shipping option is required'),
        }),
      })
    ),
  });

  const renderRuleFields = ({
    rule,
    index,
    errors,
    touched,
    isExpanded,
    toggleExpand,
  }: RuleProps) => {
    return (
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <ShippingOfferWithSelect ruleIndex={index} />
        </Stack>
        <Collapse
          sx={{
            marginTop: '0 !important',
          }}
          in={isExpanded}
        >
          <Stack spacing={1} mt={'20px'}>
            <AgeFilter ruleIndex={index} />
            <CurrentPriceFilter ruleIndex={index} />
            <MinEarningFilter ruleIndex={index} />

            <CategorySwitch index={index} />
            {rule.filters.category.enabled && (
              <Stack
                sx={{
                  ml: !categoriesLoading ? '36px !important' : '-36px !important',
                }}
              >
                {categoriesLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      ml: '-36px !important',
                    }}
                  >
                    <CircularProgress size={24} />
                  </Box>
                ) : (
                  <CategoryRichTreeView
                    initialSelectedCategories={rule.filters.category.selectedCategories}
                    ruleIndex={index}
                  />
                )}
              </Stack>
            )}

            <ConditionSwitch
              name={`rules.${index}.filters.condition.boutique`}
              label="Exclude Boutique listings"
              index={index}
              tooltipContent="ℹ️ Enable this option to exclude boutique listings from receiving offers."
            />

            <ConditionSwitch
              name={`rules.${index}.filters.condition.nwt`}
              label="Exclude New With Tags listings"
              index={index}
              tooltipContent="ℹ️ Enable this option to exclude listings labeled as 'New With Tags' from receiving offers."
            />
          </Stack>
        </Collapse>
      </Stack>
    );
  };

  const content = (
    <>
      <Typography
        variant="body1"
        sx={{ fontWeight: 600, display: 'flex', alignItems: 'center', mb: '12px' }}
      >
        Offer Rules
        <Tooltip
          enterTouchDelay={0}
          leaveTouchDelay={3000}
          arrow
          title="ℹ️ Set up single or multiple offer rules to customize discounts, shipping costs, and filtering options. If a liked item triggers multiple offer rules, the offer with the highest discount and shipping savings will be sent."
        >
          <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 1 }} />
        </Tooltip>
      </Typography>
      <Rules
        initialRule={defaultRule}
        initialRules={initialSettings.length > 0 ? initialSettings : [defaultRule]}
        validationSchema={validationSchema}
        renderRuleFields={renderRuleFields}
        defaultExpanded={initialSettings.length === 1}
      />
    </>
  );

  return (
    <Formik
      initialValues={{ rules: initialSettings.length > 0 ? initialSettings : [defaultRule] }}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      <Form>{content}</Form>
    </Formik>
  );
};

export default OfferToLikersRules;
