import React from 'react';
import { Box, CardMedia } from '@mui/material';
import productImg from './assets/product.png';
import ProductCheckbox from './ProductCheckbox';
import StatusLabel from './StatusLabel';
import ConditionChip from './ConditionChip';
import { ProductCardProps } from './types';

interface ProductImageProps {
  thumbnailUrl: ProductCardProps['thumbnailUrl'];
  title: ProductCardProps['title'];
  selection: ProductCardProps['selection'];
  statusTagVisibility: ProductCardProps['statusTagVisibility'];
  conditionChipVisibility: ProductCardProps['conditionChipVisibility'];
  statusLabel: ProductCardProps['statusLabel'];
  conditionChip: ProductCardProps['conditionChip'];
  droppingSoonDate?: ProductCardProps['droppingSoonDate'];
}

const ProductImage: React.FC<ProductImageProps> = ({
  thumbnailUrl,
  title,
  selection,
  statusTagVisibility,
  conditionChipVisibility,
  statusLabel,
  conditionChip,
  droppingSoonDate,
}) => (
  <>
    <CardMedia
      component="img"
      image={thumbnailUrl || productImg}
      onError={(e) => {
        e.currentTarget.src = 'https://r2.primelister.com/image_thumbnail.svg';
      }}
      sx={{
        maxWidth: '234px',
        maxHeight: '234px',
        width: '234px',
        height: '234px',
        borderRadius: '12px',
        marginTop: '8px',
        userSelect: 'none',
        pointerEvents: 'none',
      }}
      alt={title}
    />
    <Box
      sx={{
        position: 'absolute',
        top: '8px',
        left: 0,
        width: '24px',
        height: '24px',
      }}
    >
      <ProductCheckbox selection={selection} />
    </Box>
    {statusTagVisibility && (
      <Box
        sx={{
          position: 'absolute',
          right: '8px',
          top: '16px',
          display: 'flex',
        }}
      >
        <StatusLabel statusLabel={statusLabel} droppingSoonDate={droppingSoonDate} />
      </Box>
    )}
    {conditionChipVisibility && (
      <Box
        sx={{
          right: '8px',
          bottom: '8px',
          position: 'absolute',
        }}
      >
        <ConditionChip conditionChip={conditionChip} />
      </Box>
    )}
  </>
);

export default ProductImage;
