import { useSelector, useDispatch } from 'react-redux';

import { IconButton } from '@mui/material';
import Iconify from 'src/components/iconify/Iconify';

import { IRootState } from 'src/store';
import {
  setDeleteType,
  setOpenPopoverMenu,
  setPopoverData,
} from 'src/store/dashboard/slices/tasksSlice';

import { DataGridActionsColumnInterface, TaskItemInterface } from '../../../../types/types';

export default function DataGridActionsColumn({ taskItemData }: DataGridActionsColumnInterface) {
  const dispatch = useDispatch();
  const { openPopoverMenu } = useSelector((state: IRootState) => state.tasks);

  const handleOpenPopover = (
    event: React.MouseEvent<HTMLElement>,
    popoverData: TaskItemInterface
  ) => {
    event.stopPropagation();
    dispatch(setOpenPopoverMenu(openPopoverMenu !== null ? null : event.currentTarget));
    dispatch(setPopoverData(popoverData));
    dispatch(setDeleteType(2));
  };

  return (
    <IconButton
      color="default"
      onClick={(event) => handleOpenPopover(event, taskItemData)}
      sx={{
        float: 'right',
      }}
    >
      <Iconify icon="eva:more-vertical-fill" />
    </IconButton>
  );
}
