import useResponsive from 'src/hooks/useResponsive';
import AffiliateBanner from './AffiliateBanner';
import AffiliateCards from './AffiliateCards';
import AffiliateTable from './AffiliateTable';
import AffiliateTransactionCard, { AffiliateEventType } from './AffiliateTransactionCard';
import { Box, CircularProgress, Pagination, TablePagination, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  affiliateBalanceHistoryRequest,
  affiliateSummaryRequest,
  setAffiliateBalanceHistoryPage,
  setAffiliateBalanceHistoryPageSize,
} from 'src/store/dashboard/slices/accountSlice';
import { IRootState } from 'src/store';
import CustomToolbar from './CustomToolbar';
import InfiniteScroll from 'react-infinite-scroll-component';

// ----------------------------------------------------------------------

export default function AccountAffiliate() {
  const isMobile = useResponsive('down', 'sm');
  const dispatch = useDispatch();
  const {
    affiliateBalanceHistory,
    loading,
    affiliateSummary,
    affiliateBalanceHistoryPage,
    affiliateBalanceHistoryPageSize,
    affiliateBalanceHistoryRowsPerPageOptions,
  } = useSelector((state: IRootState) => state.account);

  useEffect(() => {
    dispatch(
      affiliateSummaryRequest({
        page: affiliateBalanceHistoryPage,
        size: affiliateBalanceHistoryPageSize,
      })
    );
  }, []);

  useEffect(() => {
    if (isMobile) {
      dispatch(
        affiliateSummaryRequest({
          page: affiliateBalanceHistoryPage,
          size: affiliateBalanceHistoryPageSize,
        })
      );
    }
  }, [isMobile, affiliateBalanceHistoryPage, affiliateBalanceHistoryPageSize]);

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(setAffiliateBalanceHistoryPage(newPage + 1));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    dispatch(setAffiliateBalanceHistoryPageSize(newRowsPerPage));
    dispatch(setAffiliateBalanceHistoryPage(1));
  };

  return (
    <>
      <AffiliateBanner />
      <AffiliateCards />
      {!isMobile ? (
        <AffiliateTable />
      ) : (
        <Box sx={{ mt: 3 }}>
          <CustomToolbar
            summaryData={{
              totalRewardAmount: affiliateSummary?.totalRewardAmount ?? 0,
              totalPayments: affiliateSummary?.totalPayments ?? 0,
              totalSignUps: affiliateSummary?.totalSignUps ?? 0,
            }}
          />
          {affiliateBalanceHistory?.data?.map((transaction, index) => (
            <AffiliateTransactionCard
              key={index}
              customer={transaction.customer}
              date={transaction.date}
              event={transaction.event as AffiliateEventType}
              reward={transaction.reward}
            />
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>
            <TablePagination
              component="div"
              count={affiliateBalanceHistory?.count ?? 0}
              sx={{
                border: 'none',
              }}
              page={affiliateBalanceHistoryPage - 1}
              onPageChange={handleChangePage}
              rowsPerPage={affiliateBalanceHistoryPageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={affiliateBalanceHistoryRowsPerPageOptions}
            />
          </Box>
        </Box>
      )}
    </>
  );
}
