import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, delay } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';

import {
  updateSubscriptionSuccess,
  updateSubscriptionFail,
  updateSubscriptionRequest,
  //--
  checkCouponCodeValidationRequest,
  checkCouponCodeValidationSuccess,
  checkCouponCodeValidationFail,
  //--
  newSubscriptionRequest,
  newSubscriptionSuccess,
  newSubscriptionFail,
  setIsThereAnyCouponError,
  setCouponDetails,
  setIsCouponValid,
  checkCouponCodeViaComparison,
  checkCouponCodeViaComparisonSuccess,
  INewSubscriptionRequestData,
} from '../slices/pricingSlice';

import axiosInstance from 'src/utils/axios';

import { crosslistEndpoints } from 'src/endpoints';
import { toast } from 'react-toastify';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { NavigateFunction } from 'react-router';

function* updateSubscriptionSaga(
  action: PayloadAction<{
    flowType: string;
    subscriptionId?: string;
    subscriptionItemId?: string;
    planId?: string;
    returnUrl: string;
  }>
) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.post(crosslistEndpoints.billing.GET_CREATE_PRICING_SESSION(), action.payload)
    );
    yield put(updateSubscriptionSuccess());
    window.open(response.data.data.url, '_self');
  } catch (error) {
    yield put(updateSubscriptionFail(error.message));
  }
}

function* checkCouponCodeValidationSaga(
  action: PayloadAction<{
    plan: string;
    code: string;
    type?: string;
    navigate?: NavigateFunction;
    sliderValue?: number;
  }>
) {
  const hasReferrerCode = Boolean(
    action.payload.type && action.payload.type === 'referrerCode' && action.payload.navigate
  );
  const sliderValueParam = action.payload.sliderValue
    ? '&closet=' + action.payload.sliderValue
    : '';
  const paymentSummaryPath = `${PATH_DASHBOARD.subscription.paymentSummary}?planId=${action.payload.plan}${sliderValueParam}`;
  const { type, code } = action.payload;
  const couponUrl = type ? '&' + type + '=' + code : '&coupon=' + code;
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(
        crosslistEndpoints.billing.GET_STRIPE_COUPON_CHECK(action.payload.plan, action.payload.code)
      )
    );

    yield put(setCouponDetails(response.data.data));
    !hasReferrerCode &&
      toast.success(response.data.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });

    yield put(checkCouponCodeValidationSuccess());
    if (hasReferrerCode) {
      yield delay(0); // Wait for other steps to complete
      yield action.payload.navigate!(paymentSummaryPath + couponUrl);
    }
  } catch (error) {
    if (hasReferrerCode) {
      yield action.payload.navigate!(paymentSummaryPath);
    }
    yield put(setIsThereAnyCouponError(true));
    yield put(checkCouponCodeValidationFail(error.message));
  }
}

function* newSubscriptionSaga(action: PayloadAction<INewSubscriptionRequestData>) {
  try {
    const { stripeRequestData } = action.payload;

    const response: AxiosResponse = yield call(() =>
      axiosInstance.post(crosslistEndpoints.billing.GET_BILLING_CHECKOUT(), stripeRequestData)
    );
    yield put(newSubscriptionSuccess());
    window.open(response.data.data.url, '_self');
  } catch (error) {
    error.message.toLowerCase().includes('active subscription') &&
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    yield put(newSubscriptionFail(error.message));
  }
}

function* checkCouponCodeViaComparisonSaga(
  action: PayloadAction<{
    plan: string;
    coupon: string;
    referrerCode: string;
    typeReferrer: string;
    typeCoupon: string;
    navigate?: NavigateFunction;
    sliderValue?: number;
  }>
) {
  const sliderValueParam = action.payload.sliderValue
    ? `&closet=${action.payload.sliderValue}`
    : '';
  const { plan, coupon, referrerCode, navigate, typeReferrer, typeCoupon } = action.payload;
  const paymentSummaryPath = `${PATH_DASHBOARD.subscription.paymentSummary}?planId=${plan}${sliderValueParam}`;
  try {
    let referrerData, couponData;

    try {
      const responseReferrer: AxiosResponse = yield call(() =>
        axiosInstance.get(crosslistEndpoints.billing.GET_STRIPE_COUPON_CHECK(plan, referrerCode))
      );
      referrerData = responseReferrer.data.data;
    } catch (referrerError) {
      console.log('Error in responseReferrer:', referrerError);
      referrerData = null;
    }

    try {
      const responseCoupon: AxiosResponse = yield call(() =>
        axiosInstance.get(crosslistEndpoints.billing.GET_STRIPE_COUPON_CHECK(plan, coupon))
      );
      couponData = responseCoupon.data.data;
    } catch (couponError) {
      console.log('Error in responseCoupon:', couponError);
      couponData = null;
    }

    const referrerIsValid = referrerData && referrerData.coupon.isValid;
    const couponIsValid = couponData && couponData.coupon.isValid;

    if (referrerIsValid && couponIsValid) {
      const referrerDiscount = referrerData.discounts[0].value;
      const couponDiscount = couponData.discounts[0].value;
      const couponDetails = couponDiscount > referrerDiscount ? couponData : referrerData;
      const couponType = couponDiscount > referrerDiscount ? typeCoupon : typeReferrer;
      const couponValue = couponDiscount > referrerDiscount ? coupon : referrerCode;

      yield put(setCouponDetails(couponDetails));
      yield put(setIsCouponValid(true));
      yield navigate!(`${paymentSummaryPath}&${couponType}=${couponValue}`);
    } else if (referrerIsValid) {
      yield put(setCouponDetails(referrerData));
      yield put(setIsCouponValid(true));
      yield navigate!(`${paymentSummaryPath}&${typeReferrer}=${referrerCode}`);
    } else if (couponIsValid) {
      yield put(setCouponDetails(couponData));
      yield put(setIsCouponValid(true));
      yield navigate!(`${paymentSummaryPath}&${typeCoupon}=${coupon}`);
    } else {
      yield put(setIsCouponValid(false));
      yield navigate!(paymentSummaryPath);
    }
    yield put(checkCouponCodeViaComparisonSuccess());
  } catch (error) {
    yield put(setIsCouponValid(false));
    yield navigate!(paymentSummaryPath);
  }
}

export function* pricingModuleSaga() {
  yield takeLatest(updateSubscriptionRequest.type, updateSubscriptionSaga);
  yield takeLatest(checkCouponCodeValidationRequest.type, checkCouponCodeValidationSaga);
  yield takeLatest(checkCouponCodeViaComparison.type, checkCouponCodeViaComparisonSaga);
  yield takeLatest(newSubscriptionRequest.type, newSubscriptionSaga);
}
