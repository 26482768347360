import React, { useEffect, useMemo, useState } from 'react';
import { Popover, SxProps, TextField, Theme, Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  QuickActionsTagPopoverModeEnum,
  setIsTagEditPopoverOpen,
  setQuickActionsTagPopoverMode,
  setQuickEditSelectedTags,
  setSelectedTagEditItem,
  setSelectedTagsToAdd,
  setTagEditPopoverAnchorEl,
} from 'src/store/dashboard/slices/inventorySlice';
import { IRootState } from 'src/store';
import { LoadingButton } from '@mui/lab';
import {
  ITagData,
  addNewTagRequest,
  deleteTagRequest,
  editTagRequest,
  setTagsDialogData,
  setTagsOpenDeleteDialog,
} from 'src/store/dashboard/slices/groupsAndTagsSlice';
import Iconify from 'src/components/iconify';
import { DataGridTagsColumnInterface } from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import axiosInstance from 'src/utils/axios';
import { crosslistEndpoints } from 'src/endpoints';
import { InventoryTagActionReason } from 'src/pages/dashboard/Inventory/helpers/useTagUtilities';
import { getFilterModelShaped } from 'src/pages/dashboard/Inventory/helpers/getFilterModelShaped';

export const colorConfig = [
  {
    colorName: 'Green',
    colorCode: '#00A76F',
    borderColor: 'rgba(0, 167, 111, 0.08)',
    shadowColor: 'rgba(0, 167, 111, 0.24)',
  },
  {
    colorName: 'Blue',
    colorCode: '#00B8D9',
    borderColor: 'rgba(0, 184, 217, 0.08)',
    shadowColor: 'rgba(0, 184, 217, 0.24)',
  },
  {
    colorName: 'Orange',
    colorCode: '#FFAB00',
    borderColor: 'rgba(255, 171, 0, 0.08)',
    shadowColor: 'rgba(255, 171, 0, 0.24)',
  },
  {
    colorName: 'Purple',
    colorCode: '#5119B7',
    borderColor: 'rgba(81, 25, 183, 0.08)',
    shadowColor: 'rgba(81, 25, 183, 0.24)',
  },
  {
    colorName: 'Red',
    colorCode: '#B71D18',
    borderColor: 'rgba(183, 29, 24, 0.08)',
    shadowColor: 'rgba(183, 29, 24, 0.24)',
  },
  {
    colorName: 'Gray',
    colorCode: '#212B36',
    borderColor: 'rgba(33, 43, 54, 0.08)',
    shadowColor: 'rgba(33, 43, 54, 0.24)',
  },
];

interface TagEditPopoverProps {
  handleTagsUpdated: (event: any, options: ITagData[], reason: string) => void;
  isFromEditPage?: boolean;
  ref: React.RefObject<HTMLDivElement>;
}

export default function TagEditPopover({
  handleTagsUpdated,
  isFromEditPage,
  ref,
}: TagEditPopoverProps) {
  const {
    isTagEditPopoverOpen,
    quickActionsTagPopoverMode,
    tagEditPopoverAnchorEl,
    selectedTagEditItem,
    selectedTagsToAdd,
    quickEditSelectedTags,
    rowsPerPage,
    page,
    searchValue,
    sortModel,
    inventoryFilterModel,
    loading,
  } = useSelector((state: IRootState) => state.inventory);
  const isInCreateMode = useMemo(
    () => quickActionsTagPopoverMode === QuickActionsTagPopoverModeEnum.CREATE,
    [quickActionsTagPopoverMode]
  );

  const isInEditMode = useMemo(
    () => quickActionsTagPopoverMode === QuickActionsTagPopoverModeEnum.EDIT,
    [quickActionsTagPopoverMode]
  );

  const popoverSx: SxProps<Theme> = {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    '& .MuiPaper-root': {
      padding: '16px 8px',
      width: '100%',
      maxWidth: !isFromEditPage ? '310px' : '318px',
      height: 'auto',
      borderRadius: '6px',
      backgroundColor: 'white',
      boxShadow:
        '0px 0px 2px 0px rgba(145, 158, 171, 0.20), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);',
    },
  };

  const showDeleteButton = quickActionsTagPopoverMode === QuickActionsTagPopoverModeEnum.EDIT;

  const [tagName, setTagName] = useState(selectedTagEditItem?.name || '');
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [selectedColor, setSelectedColor] = useState(selectedTagEditItem?.color || '');

  useEffect(() => {
    setTagName(selectedTagEditItem?.name || '');
    setSelectedColor(selectedTagEditItem?.color || '');
  }, [selectedTagEditItem]);

  useEffect(() => {
    if (quickActionsTagPopoverMode === QuickActionsTagPopoverModeEnum.EDIT) {
      setIsSaveDisabled(
        tagName === selectedTagEditItem?.name && selectedColor === selectedTagEditItem?.color
      );
    } else if (quickActionsTagPopoverMode === QuickActionsTagPopoverModeEnum.CREATE) {
      setIsSaveDisabled(tagName === '' || selectedColor === '');
    }
  }, [tagName, selectedColor, selectedTagEditItem, quickActionsTagPopoverMode]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagName(event.target.value);
  };

  const handleColorSelect = (color: string) => {
    setSelectedColor(color);
  };

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setIsTagEditPopoverOpen(false));
    dispatch(setTagEditPopoverAnchorEl(null));
    dispatch(setSelectedTagEditItem(null));
    dispatch(setQuickActionsTagPopoverMode(null));
  };

  const handleDelete = () => {
    dispatch(setTagsDialogData(selectedTagEditItem!));
    dispatch(setTagsOpenDeleteDialog(true));
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async (event: any) => {
    if (!selectedTagEditItem) return;

    switch (quickActionsTagPopoverMode) {
      case QuickActionsTagPopoverModeEnum.CREATE:
        setIsLoading(true);
        const newTag = {
          name: tagName.trim(),
          color: selectedColor,
        };
        // We use axiosInstance directly because dispatch is not async so it won't send tag with the new id
        const result = await axiosInstance.post(crosslistEndpoints.groupsAndTags.GET_TAGS(), {
          ...newTag,
        });
        // We use this only to update the required states
        dispatch(
          addNewTagRequest({
            newTag,
            makeRequest: false,
            getData: {
              page,
              rowsPerPage,
              orderBy: sortModel[0]?.field,
              order: sortModel[0]?.sort,
              filter: getFilterModelShaped(inventoryFilterModel),
              searchValue,
            },
          })
        );
        handleTagsUpdated(
          event,
          [...(quickEditSelectedTags || []), result.data],
          InventoryTagActionReason.SELECTED
        );
        setIsLoading(false);
        break;
      case QuickActionsTagPopoverModeEnum.EDIT:
        const tagData: ITagData = {
          _id: selectedTagEditItem?._id,
          name: tagName.trim(),
          color: selectedColor,
        };
        dispatch(
          editTagRequest({
            tagData,
            quickEditSelectedTags: quickEditSelectedTags ?? [],
            getData: {
              page,
              rowsPerPage,
              orderBy: sortModel[0]?.field,
              order: sortModel[0]?.sort,
              filter: getFilterModelShaped(inventoryFilterModel),
              searchValue,
            },
          })
        );

        break;
      default:
        break;
    }
  };

  const placeholderText = isInEditMode ? 'Name' : 'Create new tag';

  return (
    <Popover
      ref={ref}
      id="tag-edit-popover"
      sx={popoverSx}
      anchorEl={tagEditPopoverAnchorEl}
      open={isTagEditPopoverOpen}
      anchorReference="anchorEl"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      slotProps={{
        paper: {
          sx: {
            left:
              isInCreateMode || isInEditMode
                ? tagEditPopoverAnchorEl.getBoundingClientRect().left - 4 + 'px !important'
                : 'auto',
          },
        },
      }}
    >
      <TextField
        label={placeholderText}
        fullWidth
        size="small"
        placeholder={placeholderText}
        onChange={handleInputChange}
        defaultValue={selectedTagEditItem?.name}
        sx={{
          '& .MuiInputLabel-root:not(.Mui-focused)': {
            color: '#919EAB !important',
            fontSize: '16px !important',
            fontWeight: 400,
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#919EAB !important',
          },
          fontSize: '14px !important',
        }}
      />
      <Box display="flex" gap={2} mt={2}>
        {colorConfig.map((colorItem, index) => (
          <Box
            key={colorItem.colorCode}
            sx={{
              width: '36px',
              height: '36px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => handleColorSelect(colorItem.colorName)}
          >
            <Box
              sx={{
                position: 'relative',
                borderRadius: '50%',
                padding: '9px',
                cursor: 'pointer',
                backgroundColor: colorItem.colorCode,
                width: selectedColor === colorItem.colorName ? '26px' : '18px',
                height: selectedColor === colorItem.colorName ? '26px' : '18px',
                border:
                  selectedColor === colorItem.colorName
                    ? `2px solid ${colorItem.borderColor}`
                    : 'none',
                boxShadow:
                  selectedColor === colorItem.colorName
                    ? `4px 4px 8px 0px ${colorItem.shadowColor} !important`
                    : 'none',
              }}
            >
              {selectedColor === colorItem.colorName && (
                <Iconify
                  icon="eva:checkmark-fill"
                  width={18}
                  height={18}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white', // Adjust color as needed
                  }}
                />
              )}
            </Box>
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: 2,
        }}
      >
        {showDeleteButton && (
          <Box>
            <LoadingButton loading={loading} variant="text" color="error" onClick={handleDelete}>
              Delete
            </LoadingButton>
          </Box>
        )}

        <Box
          sx={{
            ml: 'auto',
          }}
        >
          <Button
            sx={{
              mr: 1,
            }}
            variant="outlined"
            color="inherit"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={isSaveDisabled}
          >
            {isInEditMode ? 'Save' : 'Create Tag'}
          </LoadingButton>
        </Box>
      </Box>
    </Popover>
  );
}
