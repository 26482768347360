import React, { useState, useEffect } from 'react';
import {
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Tooltip,
  useMediaQuery,
  Typography,
  Alert,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { IRootState } from 'src/store';
import {
  setIsConnectionErrorDialogOpen,
  updateAutomationSettingsRequest,
} from 'src/store/automations/slices/automationsSlice';
import { IDataForUpdateAutomationSettings } from 'src/pages/automations/AutomationsPage/types/types';
import { Automation } from '@beta.limited/primelister';
import { getCurrencyInfo } from 'src/pages/automations/AutomationsPage/helpers/getCurrencyInfo';

interface CurrentPriceFilterProps {
  ruleIndex: number;
}

interface PriceRange {
  enabled: boolean;
  min: number;
  max: number;
}

interface Rule {
  filters: {
    priceRange: PriceRange;
  };
}

interface FormValues {
  rules: Rule[];
}

const CurrentPriceFilter: React.FC<CurrentPriceFilterProps> = ({ ruleIndex }) => {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:400px)');

  const { selectedAutomationOption, automationsSettings, activeClosetAutomationPlatform } =
    useSelector((state: IRootState) => state.automations);
  const { activeClosetCredentialsId, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.myCloset
  );

  const { values, setFieldValue, errors, touched } = useFormikContext<FormValues>();

  const [renderError, setRenderError] = useState(false);

  const onChangeTimer = React.useRef<NodeJS.Timeout | null>(null);
  const onChangeAlertTimer = React.useRef<NodeJS.Timeout | null>(null);

  const { currency, currencySymbol } = getCurrencyInfo(activeClosetAutomationPlatform);

  const handlePriceFilterEnableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    if (hasActiveClosetConnection) {
      setFieldValue(`rules.${ruleIndex}.filters.priceRange.enabled`, newValue);
      updateAutomationSettings(newValue);
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
  };

  const handlePriceValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: 'min' | 'max'
  ) => {
    if (hasActiveClosetConnection) {
      const inputValue = event.target.value;
      const numericValue = inputValue === '' ? null : parseInt(inputValue, 10);
      const otherField = field === 'min' ? 'max' : 'min';
      const otherValue = values.rules[ruleIndex].filters.priceRange[otherField];

      setFieldValue(`rules.${ruleIndex}.filters.priceRange.${field}`, numericValue);

      const isValidInput =
        numericValue !== null &&
        numericValue >= 0 &&
        numericValue < 100000 &&
        otherValue !== null &&
        (field === 'min' ? numericValue <= otherValue : numericValue >= otherValue);

      if (!isValidInput) {
        setRenderError(true);
        if (onChangeTimer.current) {
          clearTimeout(onChangeTimer.current);
        }
      } else {
        setRenderError(false);
        if (onChangeTimer.current) {
          clearTimeout(onChangeTimer.current);
        }
        onChangeTimer.current = setTimeout(() => {
          updateAutomationSettings(undefined, field, numericValue);
        }, 700);
      }
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
  };

  const updateAutomationSettings = (
    enabledOverride?: boolean,
    field?: 'min' | 'max',
    value?: number | string
  ) => {
    const shouldUpdate = enabledOverride !== undefined || (value !== null && value !== undefined);

    if (shouldUpdate) {
      const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;

      const updatedRules = automationsSettings[
        Automation.AUTO_SEND_OFFER_TO_LIKERS
      ].config.rules.map((rule, index) =>
        index === ruleIndex
          ? {
              ...rule,
              filters: {
                ...rule.filters,
                priceRange: {
                  ...values.rules[ruleIndex].filters.priceRange,
                  enabled:
                    enabledOverride !== undefined
                      ? enabledOverride
                      : values.rules[ruleIndex].filters.priceRange.enabled,
                  ...(field && value !== undefined ? { [field]: value } : {}),
                },
              },
            }
          : rule
      );

      const dataForUpdating: IDataForUpdateAutomationSettings = {
        settings: {
          [Automation.AUTO_SEND_OFFER_TO_LIKERS]: {
            config: {
              rules: updatedRules,
            },
          },
        },
        toastMessage,
        displayName: selectedAutomationOption.displayName,
        activeClosetAutomationPlatform,
        activeClosetCredentialsId,
      };

      dispatch(updateAutomationSettingsRequest(dataForUpdating));
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(onChangeTimer.current as NodeJS.Timeout);
      clearTimeout(onChangeAlertTimer.current as NodeJS.Timeout);
    };
  }, []);

  const renderAlert = () => {
    const minValue = values.rules[ruleIndex].filters.priceRange.min;
    const maxValue = values.rules[ruleIndex].filters.priceRange.max;
    let errorMessage = '';

    if (minValue === null || maxValue === null) {
      errorMessage = 'Both minimum and maximum price values are required.';
    } else if (minValue > maxValue) {
      errorMessage = 'Minimum price should be less than or equal to maximum price.';
    } else if (minValue < 0 || maxValue < 0 || minValue > 99999 || maxValue > 99999) {
      errorMessage = 'Please enter whole numbers between 0 and 99999 for both fields.';
    }

    if (errorMessage) {
      return (
        <Alert
          sx={{
            color: '#FF5630',
            bgcolor: 'white',
            maxWidth: '360px !important',
            overflow: 'hidden',
            p: 0,
            mt: '-2px !important',
            marginLeft: matches ? '48px !important' : '20px !important',
          }}
          severity="error"
          variant="standard"
          icon={false}
        >
          {errorMessage}
        </Alert>
      );
    }

    return null;
  };

  const handlePreventDefault = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const renderEnablePriceFilterSwitch = () => (
    <Stack direction="row" alignItems="center">
      <FormControlLabel
        control={
          <Switch
            checked={values.rules[ruleIndex].filters.priceRange?.enabled}
            onChange={handlePriceFilterEnableChange}
          />
        }
        label={
          <Stack direction="row" alignItems="center" onClick={handlePreventDefault}>
            Filter by current price of listing
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              arrow
              title="ℹ️ Enable this option to send offers only to liked items within your selected price range."
              placement="top"
              sx={{ textAlign: 'center !important' }}
            >
              <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
            </Tooltip>
          </Stack>
        }
      />
    </Stack>
  );

  const isThereAnError = renderError;

  const renderPriceFilterInputField = () => (
    <>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{
          marginLeft: matches ? '48px !important' : '20px !important',
          fontSize: '14px !important',
          marginTop: '12px !important',
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body2">From</Typography>
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Please enter a whole number between 0-99999."
            placement="top"
          >
            <TextField
              type="number"
              sx={{
                width: '66px !important',
                '& .MuiOutlinedInput-root': {
                  maxHeight: '2.5rem',
                  bgcolor: `${isThereAnError ? '#FFF2EF' : '#eeeff1'}`,
                  color: `${isThereAnError ? '#B71C19' : '#000000'}`,
                },
              }}
              value={values.rules[ruleIndex].filters.priceRange.min}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handlePriceValueChange(e, 'min')
              }
            />
          </Tooltip>
          <Typography variant="body2">{currency}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body2">To</Typography>
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Please enter a whole number between 0-99999."
            placement="top"
          >
            <TextField
              type="number"
              sx={{
                width: '66px !important',
                '& .MuiOutlinedInput-root': {
                  maxHeight: '2.5rem',
                  bgcolor: `${isThereAnError ? '#FFF2EF' : '#eeeff1'}`,
                  color: `${isThereAnError ? '#B71C19' : '#000000'}`,
                },
              }}
              value={values.rules[ruleIndex].filters.priceRange.max}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handlePriceValueChange(e, 'max')
              }
            />
          </Tooltip>
          <Typography variant="body2">{currency}</Typography>
        </Stack>
      </Stack>
      {renderAlert()}
    </>
  );

  return (
    <Stack spacing={2} px={0}>
      {renderEnablePriceFilterSwitch()}
      {values.rules[ruleIndex].filters.priceRange?.enabled && renderPriceFilterInputField()}
    </Stack>
  );
};

export default CurrentPriceFilter;
