// @mui
import { Grid, Stack } from '@mui/material';

import AccountBillingPaymentMethod from './AccountBillingPaymentMethod';
import AccountBillingInvoiceHistory from './AccountBillingInvoiceHistory';
import AccountSubscriptions from './AccountSubscriptions';

import CancelBillingSubscription from './components/dialogs/CancelBillingSubscriptionDialog';
import DeleteCardDialog from './components/dialogs/DeleteCardDialog';
import SetDefaultCardDialog from './components/dialogs/SetDefaultCardDialog';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsCancelBillingSubscriptionDialogOpen } from 'src/store/dashboard/slices/accountSlice';

// ----------------------------------------------------------------------

export default function AccountBilling() {
  const dispatch = useDispatch();
  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (typeof event.data.includes === 'function' && event.data.includes('Tally.FormSubmitted')) {
        dispatch(setIsCancelBillingSubscriptionDialogOpen(true));
      } else {
        return;
      }
    });

    return () => {
      window.removeEventListener('message', () => {});
    };
  }, []);

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} md={8}>
        <Stack spacing={3}>
          <AccountSubscriptions />
          <AccountBillingPaymentMethod />
        </Stack>
      </Grid>

      <Grid item xs={12} md={4}>
        <AccountBillingInvoiceHistory />
      </Grid>
      <CancelBillingSubscription />
      <DeleteCardDialog />
      <SetDefaultCardDialog />
    </Grid>
  );
}
