import axios from 'axios';
import cookie from 'react-cookies';
// config
import { HOST_API_KEY } from '../config';
import * as Sentry from '@sentry/react';

// ----------------------------------------------------------------------
export const abortController = new AbortController();

const axiosInstance = axios.create({
  baseURL: HOST_API_KEY,
  signal: abortController.signal,
});

const axiosNoAuth = axios.create({
  baseURL: HOST_API_KEY,
  signal: abortController.signal,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    Sentry.captureException(error); // Send error to Sentry
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export const setHeader = (newToken: string) => {
  axiosInstance.defaults.headers.Authorization = `Bearer ${newToken}`;
};

export { axiosNoAuth };
export default axiosInstance;
