import { Card, Chip, SvgIconProps, styled } from '@mui/material';
import { ProductCardSelectionEnum, StyledCardProps } from './types';

export const ProductCardCheckedCheckboxIcon = (props: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    {...props}
  >
    <rect x="1" y="1" width="26" height="26" rx="7" fill="white" />
    <rect x="1" y="1" width="26" height="26" rx="7" stroke="white" stroke-width="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2C4.68629 2 2 4.68629 2 8V20C2 23.3137 4.68629 26 8 26H20C23.3137 26 26 23.3137 26 20V8C26 4.68629 23.3137 2 20 2H8ZM13.838 17.894L19.538 12.194C19.946 11.786 19.946 11.126 19.538 10.706C19.13 10.298 18.458 10.298 18.05 10.706L13.094 15.662L10.994 13.562C10.586 13.154 9.914 13.154 9.506 13.562C9.098 13.97 9.098 14.63 9.506 15.05L12.362 17.894C12.566 18.098 12.83 18.194 13.094 18.194C13.37 18.194 13.634 18.098 13.838 17.894Z"
      fill="#3366FF"
    />
  </svg>
);

export const ProductCardDefaultCheckboxIcon = (props: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    {...props}
  >
    <rect x="1" y="1" width="26" height="26" rx="7" fill="#677684" />
    <rect x="1" y="1" width="26" height="26" rx="7" stroke="#677684" stroke-width="2" />
    <path
      d="M2 8C2 4.68629 4.68629 2 8 2H20C23.3137 2 26 4.68629 26 8V20C26 23.3137 23.3137 26 20 26H8C4.68629 26 2 23.3137 2 20V8Z"
      fill="#F6F7F8"
    />
  </svg>
);

export const ProductCardIndeterminateCheckboxIcon = (props: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    {...props}
  >
    <rect x="1" y="1" width="26" height="26" rx="7" fill="white" />
    <rect x="1" y="1" width="26" height="26" rx="7" stroke="white" stroke-width="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2C4.68629 2 2 4.68629 2 8V20C2 23.3137 4.68629 26 8 26H20C23.3137 26 26 23.3137 26 20V8C26 4.68629 23.3137 2 20 2H8ZM10.4 15.2H17.6C18.2627 15.2 18.8 14.6627 18.8 14C18.8 13.3373 18.2627 12.8 17.6 12.8H10.4C9.73726 12.8 9.2 13.3373 9.2 14C9.2 14.6627 9.73726 15.2 10.4 15.2Z"
      fill="#3366FF"
    />
  </svg>
);

export const ProductCardDefaultCheckboxIconMobile = (props: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <rect x="1.33333" y="1.33333" width="17.3333" height="17.3333" rx="4.66667" fill="#677684" />
    <rect
      x="1.33333"
      y="1.33333"
      width="17.3333"
      height="17.3333"
      rx="4.66667"
      stroke="#677684"
      strokeWidth="1.33333"
    />
    <path
      d="M2 6C2 3.79086 3.79086 2 6 2H14C16.2091 2 18 3.79086 18 6V14C18 16.2091 16.2091 18 14 18H6C3.79086 18 2 16.2091 2 14V6Z"
      fill="#F6F7F8"
    />
  </svg>
);

export const ProductCardCheckedCheckboxIconMobile = (props: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <rect x="1.33333" y="1.33333" width="17.3333" height="17.3333" rx="4.66667" fill="white" />
    <rect
      x="1.33333"
      y="1.33333"
      width="17.3333"
      height="17.3333"
      rx="4.66667"
      stroke="white"
      strokeWidth="1.33333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2C3.79086 2 2 3.79086 2 6V14C2 16.2091 3.79086 18 6 18H14C16.2091 18 18 16.2091 18 14V6C18 3.79086 16.2091 2 14 2H6ZM9.892 12.596L13.692 8.796C13.964 8.524 13.964 8.084 13.692 7.804C13.42 7.532 12.972 7.532 12.7 7.804L9.396 11.108L7.996 9.708C7.724 9.436 7.276 9.436 7.004 9.708C6.732 9.98 6.732 10.42 7.004 10.7L8.908 12.596C9.044 12.732 9.22 12.796 9.396 12.796C9.58 12.796 9.756 12.732 9.892 12.596Z"
      fill="#3366FF"
    />
  </svg>
);

export const ProductCardIndeterminateCheckboxIconMobile = (props: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <rect x="1.33333" y="1.33333" width="17.3333" height="17.3333" rx="4.66667" fill="white" />
    <rect
      x="1.33333"
      y="1.33333"
      width="17.3333"
      height="17.3333"
      rx="4.66667"
      stroke="white"
      strokeWidth="1.33333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2C3.79086 2 2 3.79086 2 6V14C2 16.2091 3.79086 18 6 18H14C16.2091 18 18 16.2091 18 14V6C18 3.79086 16.2091 2 14 2H6ZM7.6 10.8H12.4C12.8418 10.8 13.2 10.4418 13.2 10C13.2 9.55817 12.8418 9.2 12.4 9.2H7.6C7.15817 9.2 6.8 9.55817 6.8 10C6.8 10.4418 7.15817 10.8 7.6 10.8Z"
      fill="#3366FF"
    />
  </svg>
);

export const StyledChip = styled(Chip)({
  textTransform: 'uppercase',
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '18px',
  textAlign: 'center',
  color: '#212B36',
  bgcolor: '#EDEFF2BF',
  height: '24px',
  backgroundColor: '#EDEFF2BF',
  borderRadius: '50px',
});

export const StyledCard = styled(Card)<StyledCardProps>(({ isSelected, selection, cardWidth }) => ({
  backgroundColor:
    !isSelected && selection !== ProductCardSelectionEnum.INDETERMINATE ? '#fff' : '#EAF1FF',
  cursor: 'pointer',
  maxWidth: cardWidth ? `${cardWidth}px` : '250px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '16px',
  boxShadow:
    '0px 8.721px 17.442px -2.907px rgba(145, 158, 171, 0.12), 0px 0px 1.453px 0px rgba(145, 158, 171, 0.20)',
}));

export const StyledCardMobile = styled(Card)<StyledCardProps>(
  ({ isSelected, selection, theme, cardWidth, optionalPropsVisibility }) => ({
    backgroundColor:
      !isSelected && selection !== ProductCardSelectionEnum.INDETERMINATE ? '#fff' : '#EAF1FF',
    cursor: 'pointer',
    ...(!cardWidth && {
      [theme.breakpoints.down(321)]: {
        maxWidth: '288px',
        minWidth: '288px',
        flexDirection: 'column',
      },
      [theme.breakpoints.between(321, 376)]: {
        maxWidth: '343px',
        minWidth: '343px',
      },
      [theme.breakpoints.between(376, 391)]: {
        maxWidth: '358px',
        minWidth: '358px',
      },
      [theme.breakpoints.up(391)]: {
        minWidth: '398px',
        maxWidth: '398px',
      },
    }),
    ...(cardWidth && {
      width: cardWidth,
      flexDirection: cardWidth < 320 ? 'column' : 'row',
      maxWidth: cardWidth,
      minWidth: cardWidth,
    }),
    display: 'flex',
    padding: '8px',
    flexDirection: 'column',
    paddingRight: 0,
    gap: optionalPropsVisibility ? '12px' : 0,
    alignItems: 'center',
    borderRadius: '16px',
    boxShadow:
      '0px 8.721px 17.442px -2.907px rgba(145, 158, 171, 0.12), 0px 0px 1.453px 0px rgba(145, 158, 171, 0.20)',
  })
);
