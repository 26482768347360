import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { IRootState } from 'src/store';
import {
  IRemoveData,
  removeClosetRequest,
  setIsRemoveClosetDialogOpen,
} from 'src/store/automations/slices/myClosetSlice';
import { LoadingButton } from '@mui/lab';

const RemoveClosetDialog = () => {
  const dispatch = useDispatch();

  const { isRemoveClosetDialogOpen, removeClosetData, loading } = useSelector(
    (state: IRootState) => state.myCloset
  );

  const confirmationMessage = [
    `Your closet '${removeClosetData.name}' will be removed from your account. Are you sure you want to remove it?`,
    `Removing this closet will stop all enabled automations for '${removeClosetData.name}' from working.`,
    `Please note that once removed, the unit price of this closet will not be deducted from your ongoing subscription automatically. You may want to adjust the available closet limit in your subscription settings accordingly.`,
  ];

  const handleClose = () =>
    dispatch(
      setIsRemoveClosetDialogOpen({ openDialog: false, removeClosetData: {} as IRemoveData })
    );

  const handleRemove = () => {
    dispatch(removeClosetRequest(removeClosetData));
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isRemoveClosetDialogOpen} onClose={handleClose}>
      <DialogTitle sx={{ pb: 3 }}>Remove Closet</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>
        {' '}
        {confirmationMessage.map((message, index) => (
          <Typography
            sx={{
              ...(index < 2 && { pb: 3 }),
              fontSize: '16px',
              fontWeight: '400',
              color: '#637381',
            }}
            key={index}
          >
            {message}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} variant="contained" color="error" onClick={handleRemove}>
          Remove Closet
        </LoadingButton>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RemoveClosetDialog;
