import { Avatar, Box, Input, InputAdornment, TextField, useMediaQuery } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import Iconify from 'src/components/iconify';
import useResponsive from 'src/hooks/useResponsive';
import { IRootState } from 'src/store';
import {
  getMyListingsRequest,
  setHasActiveSearch,
  setIsSearchLoading,
  setMyListings,
  setSearchQuery,
  setSelectedListings,
} from 'src/store/automations/slices/myListingsSlice';
import ListingService from '../services/ListingsService';

export default function ListingsSearch() {
  const dispatch = useDispatch();
  const { activeClosetCredentialsId, closetList, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.myCloset
  );
  const { selectedFilterOption, isPortalOpen, searchQuery, selectedSortOption } = useSelector(
    (state: IRootState) => state.myListings
  );
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const isMobileWeb = useResponsive('down', 'sm'); // < 600 px
  const isMobile = isMobileWeb || isPrimeListerMobileApp;
  const isSmallerScreen = useMediaQuery('(max-width:375px)');

  const [iconColor, setIconColor] = useState('#637381');
  const location = useLocation();

  const [searchParams, setSearchParams] = useState(new URLSearchParams(location.search));
  const [searchParamsDefault] = useSearchParams();

  useEffect(() => {
    setSearchParams(new URLSearchParams(location.search));
  }, [location.search]);

  const getSearchParams = () => ({
    sortValue: searchParams.get('sort') || selectedSortOption.value,
    listingAgeMin: searchParams.get('listingAgeMin') || '',
    listingAgeMax: searchParams.get('listingAgeMax') || '',
    numberOfLikesMin: searchParams.get('numberOfLikesMin') || '',
    numberOfLikesMax: searchParams.get('numberOfLikesMax') || '',
    priceMin: searchParams.get('priceMin') || '',
    priceMax: searchParams.get('priceMax') || '',
    department: searchParams.get('department') || 'all-departments',
    category: searchParams.get('category') || '',
    subcategory: searchParams.get('subcategory') || '',
  });

  const currentShop = searchParams.get('shop');

  const handleClearInput = () => {
    dispatch(setSearchQuery('')); // Clear the search query in the state
  };

  const prevShopRef = useRef<string | null>();
  useEffect(() => {
    prevShopRef.current = currentShop;
  }, [currentShop]);

  useEffect(() => {
    const prevShop = prevShopRef.current;
    if (currentShop !== prevShop && !isPortalOpen) {
      handleClearInput();
    }
  }, [currentShop, isPortalOpen]);

  const fetchListings = ({
    query,
    sortValue,
    listingAgeMin,
    listingAgeMax,
    numberOfLikesMin,
    numberOfLikesMax,
    priceMin,
    priceMax,
    category,
    subcategory,
    department
  }: {
    query?: string;
    sortValue?: string;
    listingAgeMin?: string;
    listingAgeMax?: string;
    numberOfLikesMin?: string;
    numberOfLikesMax?: string;
    priceMin?: string;
    priceMax?: string;
    category?: string;
    subcategory?: string;
    department?: string;
  }) => {
    const availability = searchParams.get('availability') || 'available';

    // Clear the listings here, just before making the request
    dispatch(setMyListings([]));
    dispatch(setSelectedListings(new Set()));

    const shopParam = searchParamsDefault.get('shop');
    const credentialsIdByShopParam = ListingService.getCredentialsIdByShopParam(
      shopParam,
      closetList
    );

    const filters = ListingService.buildFilters({
      nextPageId: undefined,
      searchQuery: query || '',
      availability,
      selectedFilterOption: { value: availability },
      selectedSortOption: { value: sortValue || '' },
      listingAgeMin: listingAgeMin || '',
      listingAgeMax: listingAgeMax || '',
      numberOfLikesMin: numberOfLikesMin || '',
      numberOfLikesMax: numberOfLikesMax || '',
      priceMin: priceMin || '',
      priceMax: priceMax || '',
      department,
      category,
      subcategory,
    });
    dispatch(
      getMyListingsRequest({
        credentialsId: credentialsIdByShopParam || activeClosetCredentialsId,
        filters,
      })
    );
  };

  const debouncedFetchListings = useRef(
    debounce((params) => {
      dispatch(setMyListings([]));
      dispatch(setSelectedListings(new Set()));
      fetchListings(params);
    }, 500)
  ).current;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const params = getSearchParams();

    dispatch(setSearchQuery(value));
    dispatch(setIsSearchLoading(true));
    if (value) {
      dispatch(setHasActiveSearch(true));
      debouncedFetchListings.cancel();
      debouncedFetchListings({
        query: value,
        ...params,
      });
    } else {
      dispatch(setHasActiveSearch(false));
      if (searchQuery) {
        debouncedFetchListings.cancel();
        debouncedFetchListings({
          ...params,
        });
      }
    }
  };

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const params = getSearchParams();

    if (searchQuery === '' && hasActiveClosetConnection) {
      debouncedFetchListings(params);
    }
  }, [searchQuery, hasActiveClosetConnection]);

  const handleOnClearSearch = () => {
    handleClearInput();
    dispatch(setIsSearchLoading(true));
    if (searchQuery) {
      debouncedFetchListings.cancel();
      setTimeout(() => {
        dispatch(setHasActiveSearch(false));
      }, 1000);
    }
  };

  const placeholderText = isSmallerScreen ? 'Search' : 'Search in your closet';

  return (
    <Box>
      <TextField
        InputProps={{
          ...(searchQuery && {
            endAdornment: (
              <InputAdornment
                onClick={handleOnClearSearch}
                sx={{
                  cursor: 'pointer',
                }}
                position="end"
              >
                <Iconify color="#637381" icon="ion:close-circle" sx={{ width: 24, height: 24 }} />
              </InputAdornment>
            ),
          }),
          startAdornment: (
            <InputAdornment position="start">
              <Iconify color={iconColor} icon="eva:search-fill" sx={{ width: 24, height: 24 }} />
            </InputAdornment>
          ),
        }}
        placeholder={placeholderText}
        value={searchQuery}
        sx={{
          width: !isMobile ? '280px' : !isSmallerScreen ? '197px' : '127px',
          '& input::placeholder': {
            fontSize: '14px',
            color: '#919EAB',
            fontWeight: 400,
            lineHeight: '22px',
          },
          '& .MuiOutlinedInput-root': {
            maxHeight: '2.5rem',
            backgroundColor: '#fff',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: '2px solid #212B36',
            },
          },
        }}
        onFocus={() => setIconColor('#212B36')}
        onBlur={() => setIconColor('#637381')} // Reset color on blur
        onChange={handleChange}
      />
    </Box>
  );
}
