import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setSession } from 'src/auth/utils';
import { authLoginSuccess } from 'src/store/dashboard/slices/authSlice';
import axiosInstance, { setHeader } from 'src/utils/axios';
import { PATH_AFTER_LOGIN } from 'src/config';
import { useNavigate } from 'react-router';
import { extensionActionsEndpoints } from 'src/endpoints';
import cookie from 'react-cookies';
// import flagsmith from 'flagsmith';

export const useLoginWithSearchParams = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLoginResponse = (response: AxiosResponse) => {
    setSession(
      response.data.accessToken,
      response.data.refreshToken,
      response.data.email,
      response.data.fullName,
      response.data.proStatus,
      response.data.blockState,
      response.data.id,
      response.data.referrerCode
    );
    setHeader(response.data.accessToken);
    dispatch(
      authLoginSuccess({
        blockState: response.data.blockState,
        userId: response.data.id,
        referrerCode: response.data.referrerCode,
      })
    );
    // flagsmith.identify(response.data.email);
  };

  const loginWithVerificationCode = (verificationCode: string, toastId: any) =>
    axiosInstance
      .post('/auth/login', {
        verificationCode: verificationCode,
      })
      .then((response) => {
        toast.update(toastId, {
          render: 'Your email has been confirmed!',
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        });
        handleLoginResponse(response);
      });

  const loginWithToken = (token: string) =>
    axiosInstance
      .post('/auth/login', {
        accessToken: token,
      })
      .then((response) => {
        handleLoginResponse(response);
        cookie.save('IS_ADMIN_LOGIN', 'true', { path: '/' });
      });

  async function handleExtensionLoginRedirect({
    extensionRedirect,
    accessToken,
    refreshToken,
    extensionV3Redirect,
    email,
    userId,
    extensionVersion,
  }: {
    extensionRedirect: string | null;
    accessToken: string;
    refreshToken: string;
    extensionV3Redirect: string | null;
    email: string | null;
    userId: string | null;
    extensionVersion: string | null;
  }) {
    if (extensionV3Redirect && email) {
      const sendMessageToExtension = () => {
        const start = async () => {
          await Promise.all([
            chrome.runtime.sendMessage(extensionV3Redirect, {
              account: {
                id: userId,
                accessToken,
                refreshToken,
                email,
              },
            }),
            axiosInstance.put(extensionActionsEndpoints.UPDATE_INSTALLATION_STATUS(), {
              installationStatus: true,
              extensionVersion,
            }),
          ]);
          window.close();
        };

        navigate(PATH_AFTER_LOGIN);
        start();
      };
      sendMessageToExtension();
    }
  }

  return { loginWithVerificationCode, loginWithToken, handleExtensionLoginRedirect };
};
