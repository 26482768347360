import React from 'react';
import { CardActions, Box, Typography } from '@mui/material';
import Iconify from '../iconify';
import { ProductCardProps } from './types';

interface ProductActionsProps {
  likeCountVisibility: ProductCardProps['likeCountVisibility'];
  commentCountVisibility: ProductCardProps['commentCountVisibility'];
  likeCount: ProductCardProps['likeCount'];
  commentCount: ProductCardProps['commentCount'];
}

const ProductActions: React.FC<ProductActionsProps> = ({
  likeCountVisibility,
  commentCountVisibility,
  likeCount,
  commentCount,
}) => {
  const actionTextStyle = {
    color: '#454F5B',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '17.14px',
  };

  return (
    <CardActions
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 56px',
      }}
      disableSpacing
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Iconify
          icon="tabler:heart"
          width="24px"
          height="24px"
          color="#E5676B"
          sx={{
            marginRight: '2px',
          }}
        />
        {likeCountVisibility && !!likeCount && (
          <Typography
            sx={{
              ...actionTextStyle,
            }}
          >
            {likeCount}
          </Typography>
        )}
      </Box>

      <Box
        sx={{ display: 'flex', alignItems: 'center', width: '40px', justifyContent: 'flex-start' }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '24px',
            marginRight: '2px',
          }}
        >
          <Iconify icon="octicon:comment-16" width="24px" height="24px" color="#919EAB" />
        </Box>
        {commentCountVisibility && !!commentCount && (
          <Typography
            sx={{
              ...actionTextStyle,
            }}
          >
            {commentCount}
          </Typography>
        )}
      </Box>
    </CardActions>
  );
};

export default ProductActions;
