export function getCurrencyInfo(activeClosetAutomationPlatform: string) {
  let currencySymbol = '$';
  let currency = 'USD';

  switch (true) {
    case activeClosetAutomationPlatform.includes('poshmark-ca'):
      currencySymbol = 'C$';
      currency = 'CAD';
      break;
    default:
      currencySymbol = '$';
      currency = 'USD';
      break;
  }
  return {
    currencySymbol,
    currency,
  };
}
