import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PrimeListerMobileAppData } from 'src/auth/types';

export interface IHomeState {
  open: boolean;
  allCompleted: boolean;
  calculatedProgress?: number;
  expectedSteps?: any[];
  completedSteps?: any[];
  isExtensionConnected: boolean;
  isExtensionConnectedResultLoading: boolean;
  startExtensionAlertAnimation: boolean;
  shouldOpenSwitchPopoverForBoarding: boolean;
  isPrimeListerMobileApp: boolean;
  isCrispHidden: boolean;
  isCrispOpen: boolean;
  primeListerMobileAppData: PrimeListerMobileAppData;
  isAppReviewVersion: boolean;
  isAppReviewVersionLoading: boolean;
  isMobileAppCheckLoading: boolean;
  hasAnyAlerts: boolean;
  hasCheckedConnection: boolean;
  isAdminLogin: boolean;
}

const initialState = {
  open: false,
  allCompleted: false,
  isExtensionConnected: false,
  startExtensionAlertAnimation: false,
  shouldOpenSwitchPopoverForBoarding: false,
  isPrimeListerMobileApp: false,
  isCrispHidden: false,
  isCrispOpen: false,
  primeListerMobileAppData: {},
  isAppReviewVersion: false,
  isAppReviewVersionLoading: false,
  isMobileAppCheckLoading: false,
  hasAnyAlerts: false,
  isExtensionConnectedResultLoading: true,
  hasCheckedConnection: false,
  isAdminLogin: false,
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setIsAdminLogin: (state, action: PayloadAction<boolean>) => {
      state.isAdminLogin = action.payload;
    },
    setHasAnyAlerts: (state, action: PayloadAction<boolean>) => {
      state.hasAnyAlerts = action.payload;
    },
    setAllCompleted: (state, action: PayloadAction<boolean>) => {
      state.allCompleted = action.payload;
    },
    setIsExtensionConnected: (state, action: PayloadAction<boolean>) => {
      state.isExtensionConnected = action.payload;
    },
    setHasCheckedConnection: (state, action: PayloadAction<boolean>) => {
      state.hasCheckedConnection = action.payload;
    },
    setIsExtensionConnectedResultLoading: (state, action: PayloadAction<boolean>) => {
      state.isExtensionConnectedResultLoading = action.payload;
    },
    setStartExtensionAnimationStart: (state, action: PayloadAction<boolean>) => {
      state.startExtensionAlertAnimation = action.payload;
    },
    setShouldOpenSwitchPopoverForBoarding: (state, action: PayloadAction<boolean>) => {
      state.shouldOpenSwitchPopoverForBoarding = action.payload;
    },
    setIsPrimeListerMobileApp: (state, action: PayloadAction<boolean>) => {
      state.isPrimeListerMobileApp = action.payload;
    },
    setIsCrispHidden: (state, action: PayloadAction<boolean>) => {
      state.isCrispHidden = action.payload;
    },
    setIsCrispOpen: (state, action: PayloadAction<boolean>) => {
      state.isCrispOpen = action.payload;
    },
    setPrimeListerMobileAppData: (state, action: PayloadAction<PrimeListerMobileAppData>) => {
      state.primeListerMobileAppData = action.payload;
    },
    setIsAppReviewVersion: (state, action: PayloadAction<boolean>) => {
      state.isAppReviewVersion = action.payload;
    },
    setIsAppReviewVersionLoading: (state, action: PayloadAction<boolean>) => {
      state.isAppReviewVersionLoading = action.payload;
    },
    setIsMobileAppCheckLoading: (state, action: PayloadAction<boolean>) => {
      state.isMobileAppCheckLoading = action.payload;
    },
    resetHomeState: (state) => initialState,
  },
});

export const {
  resetHomeState,
  setIsExtensionConnectedResultLoading,
  setHasCheckedConnection,
  setIsAdminLogin,
  setHasAnyAlerts,
  setOpen,
  setAllCompleted,
  setIsExtensionConnected,
  setStartExtensionAnimationStart,
  setShouldOpenSwitchPopoverForBoarding,
  setIsPrimeListerMobileApp,
  setIsCrispHidden,
  setIsCrispOpen,
  setPrimeListerMobileAppData,
  setIsAppReviewVersion,
  setIsAppReviewVersionLoading,
  setIsMobileAppCheckLoading,
} = homeSlice.actions;

export default homeSlice.reducer;
