import { ReactNode, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLoginWithSearchParams } from 'src/hooks/useLoginWithSearchParams';
import { PATH_AUTOMATIONS, PATH_DASHBOARD } from 'src/routes/paths';

import { IRootState } from 'src/store';

import { isLoggedIn } from 'src/utils/isLoggedIn';
import { resetCrispChatSession } from 'src/utils/crispChatHelpers';
import resetStates from 'src/hooks/useResetStates';
import usePrimeListerMobileApp from 'src/hooks/usePrimeListerMobileApp';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state: IRootState) => state.auth);
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const [searchParams, setSearchParams] = useSearchParams();
  const { hash } = window.location;
  const { loggedIn, accessToken, refreshToken, email, userId } = isLoggedIn();
  const navigate = useNavigate();
  const isMobile = useResponsive('down', 'sm');

  const { handleExtensionLoginRedirect } = useLoginWithSearchParams();
  usePrimeListerMobileApp();

  useEffect(
    () =>
      // Clean up on unmount
      () => {
        resetStates(dispatch);
      },
    []
  );

  if (isAuthenticated && loggedIn && refreshToken) {
    const redirect = searchParams.get('redirect');
    const coupon = searchParams.get('coupon');
    const shop = searchParams.get('shop');
    const extensionRedirect = searchParams.get('extensionRedirect');
    const extensionV3Redirect = searchParams.get('extensionId');
    const extensionVersion = searchParams.get('extensionVersion');
    let redirectUrl =
      isPrimeListerMobileApp || isMobile ? PATH_AUTOMATIONS.root : PATH_DASHBOARD.root;
    if (extensionRedirect || extensionV3Redirect) {
      handleExtensionLoginRedirect({
        extensionRedirect,
        accessToken,
        refreshToken,
        extensionV3Redirect,
        userId,
        email,
        extensionVersion,
      });
    } else if (redirect) {
      const urlShop = shop ? `&shop=${shop}` : '';
      const urlCoupon = coupon ? `&coupon=${coupon}` : '';
      redirectUrl = `${redirect}${urlShop}${urlCoupon}${hash}`;
      navigate(redirectUrl);
    } else {
      navigate(redirectUrl);
    }
    return null;
  }
  if (window.$crisp) {
    resetCrispChatSession();
  }
  return <>{children}</>;
}
