import { useDispatch, useSelector } from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import {
  inventoryMergeListingsRequest,
  setOpenInventoryMergeListingsDialog,
} from 'src/store/dashboard/slices/inventorySlice';
import { IRootState } from 'src/store';
import { getFilterModelShaped } from 'src/pages/dashboard/Inventory/helpers/getFilterModelShaped';
import { LoadingButton } from '@mui/lab';

export default function InventoryMergeListingsDialog() {
  const dispatch = useDispatch();

  const {
    openInventoryMergeListingsDialog,
    selectedCheckboxes,
    page,
    rowsPerPage,
    sortModel,
    inventoryFilterModel,
    searchValue,
    bulkActionsListingList,
    loading,
  } = useSelector((state: IRootState) => state.inventory);

  const handleClose = () => {
    dispatch(setOpenInventoryMergeListingsDialog(false));
  };

  const bulkItems = bulkActionsListingList.map((listing) => listing._id);

  const handleMerge = () => {
    const orderBy = sortModel.length > 0 ? sortModel[0].field : 'added';
    const order = sortModel.length > 0 ? sortModel[0].sort : 'desc';
    dispatch(
      inventoryMergeListingsRequest({
        items: bulkItems,
        getData: {
          page,
          rowsPerPage,
          orderBy,
          order,
          filter: getFilterModelShaped(inventoryFilterModel),
          searchValue,
        },
      })
    );
  };

  const dialogTitle = 'Merge selected items';

  const dialogMessage = `${selectedCheckboxes.length} selected items will be merged. Continue?`;

  return (
    <Dialog fullWidth maxWidth="xs" open={openInventoryMergeListingsDialog} onClose={handleClose}>
      <DialogTitle> {dialogTitle} </DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>{dialogMessage}</DialogContent>
      <DialogActions>
        <Button disabled={loading} variant="text" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          onClick={handleMerge}
          sx={{ textTransform: 'none' }}
        >
          Merge listings
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
