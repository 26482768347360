import { useState, useMemo, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { FilterOptionsState } from '@mui/material';
import { IRootState } from 'src/store';
import { IGroupData } from 'src/store/dashboard/slices/groupsAndTagsSlice';
import {
  QuickActionsGroupPopoverModeEnum,
  setGroupEditPopoverAnchorEl,
  setIsEditFormSaveChangesDisabled,
  setIsGroupAutocompleteOpen,
  setIsGroupEditPopoverOpen,
  setQuickActionsGroupPopoverMode,
  setSelectedGroupEditItem,
} from 'src/store/dashboard/slices/inventorySlice';

export const useGroupAutocomplete = (
  initialSelectedGroup: string | (IGroupData & { _id?: string }) | null
) => {
  const dispatch = useDispatch();
  const { groupsData } = useSelector((state: IRootState) => state.groupsAndTags);
  const { quickActionsGroupPopoverMode } = useSelector((state: IRootState) => state.inventory);

  const [isGroupNoOptionsOpen, setIsGroupNoOptionsOpen] = useState(false);
  const [groupInputValue, setGroupInputValue] = useState<string>('');

  const [selectedGroup, setSelectedGroup] = useState<IGroupData | null>(null);

  useEffect(() => {
    if (typeof initialSelectedGroup === 'string') {
      // Find the group in groupsData that matches the string
      const foundGroup = groupsData.find(
        (group) => group.id === initialSelectedGroup || group._id === initialSelectedGroup
      );
      setSelectedGroup(foundGroup || null);
    } else {
      setSelectedGroup(initialSelectedGroup);
    }
  }, [initialSelectedGroup]);

  const autocompleteRef = useRef<HTMLDivElement>(null);
  const ignoreBlurRef = useRef(false);

  const selectedGroupValue = useMemo(() => selectedGroup, [selectedGroup]);

  const isGroupSelected = (option: IGroupData) =>
    selectedGroup && (selectedGroup.id === option.id || selectedGroup._id === option.id);

  const setSelectedGroupValue = (newSelectedGroup: IGroupData | null) => {
    setSelectedGroup(newSelectedGroup);
  };

  const isGroupInEditMode = useMemo(
    () => quickActionsGroupPopoverMode === QuickActionsGroupPopoverModeEnum.EDIT,
    [quickActionsGroupPopoverMode]
  );

  const isGroupInCreateMode = useMemo(
    () => quickActionsGroupPopoverMode === QuickActionsGroupPopoverModeEnum.CREATE,
    [quickActionsGroupPopoverMode]
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (autocompleteRef.current && !autocompleteRef.current.contains(event.target as Node)) {
        if (!ignoreBlurRef.current) {
          dispatch(setIsGroupAutocompleteOpen(false));
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleEditIconClick = (e: React.MouseEvent, option: IGroupData) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setIsGroupEditPopoverOpen(true));
    dispatch(setGroupEditPopoverAnchorEl(e.currentTarget.parentElement?.parentElement));
    dispatch(setQuickActionsGroupPopoverMode(QuickActionsGroupPopoverModeEnum.EDIT));
    dispatch(setSelectedGroupEditItem(option));
  };

  const handleCreateNewGroup = (e: React.MouseEvent) => {
    dispatch(setGroupEditPopoverAnchorEl(e.currentTarget.parentElement));
    dispatch(setQuickActionsGroupPopoverMode(QuickActionsGroupPopoverModeEnum.CREATE));
    dispatch(setIsGroupEditPopoverOpen(true));
    dispatch(
      setSelectedGroupEditItem({
        name: groupInputValue,
      })
    );
  };

  const filterOptions = (options: IGroupData[], params: FilterOptionsState<IGroupData>) => {
    const { inputValue } = params;
    const filtered = options.filter((option) =>
      _.includes(option.name!.toLowerCase(), inputValue.toLowerCase())
    );

    if (filtered.length === 0 && inputValue !== '') {
      setIsGroupNoOptionsOpen(true);
      return [{ name: '', id: '' }];
    }
    setIsGroupNoOptionsOpen(false);
    return filtered;
  };

  const handlePopoverOpen = () => {
    ignoreBlurRef.current = true;
  };

  const handlePopoverClose = () => {
    ignoreBlurRef.current = false;
  };

  const handleAutocompleteOpen = () => dispatch(setIsGroupAutocompleteOpen(true));

  const handleAutocompleteClose = () => {
    if (!ignoreBlurRef.current) {
      dispatch(setIsGroupAutocompleteOpen(false));
    }
  };

  return {
    groupsData,
    selectedGroupValue,
    isGroupSelected,
    isGroupInEditMode,
    isGroupNoOptionsOpen,
    setIsGroupNoOptionsOpen,
    groupInputValue,
    setGroupInputValue,
    handleEditIconClick,
    handleCreateNewGroup,
    isGroupInCreateMode,
    filterOptions,
    autocompleteRef,
    ignoreBlurRef,
    handleAutocompleteOpen,
    handleAutocompleteClose,
    handlePopoverOpen,
    handlePopoverClose,
    setSelectedGroupValue,
    selectedGroup,
    setSelectedGroup,
  };
};
