import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, BoxProps, SxProps, Theme, Toolbar } from '@mui/material';
// utils
import { bgBlur } from '../../utils/cssStyles';
// components
import { NavSectionHorizontal } from '../../components/nav-section';
//
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { useLocation } from 'react-router';
import { PATH_AUTOMATIONS } from 'src/routes/paths';
// ----------------------------------------------------------------------

export interface INavbarProps {
  navConfig: {
    subheader: string;
    items: {
      title: string;
      path: string;
      icon: JSX.Element;
      tooltip: string;
    }[];
  }[];
}

function NavHorizontal({ navConfig }: INavbarProps) {
  const theme = useTheme();
  const isMobileWeb = useResponsive('down', 'sm');
  const toolbarMobileStyles: SxProps<Theme> = {
    position: 'fixed',
    bottom: '0',
    justifySelf: 'center !important',
    alignSelf: 'center !important',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
  };
  const isDesktop = useResponsive('up', 'sm');
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const { isPortalOpen } = useSelector((state: IRootState) => state.myListings);
  const location = useLocation();
  const isMobile = isMobileWeb || isPrimeListerMobileApp;
  const shouldHaveShadow = isDesktop
    ? true
    : location.pathname.includes(PATH_AUTOMATIONS.account) && isMobile;

  const toolbarDesktopStyles: SxProps<Theme> = {
    minHeight: '48px !important',
    ...bgBlur({
      color: theme.palette.background.default,
    }),
  };

  return (
    <AppBar
      component="nav"
      color="transparent"
      sx={{
        boxShadow: 0,
        // top: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
        top: '60px',
      }}
    >
      <Toolbar sx={!isMobile ? { ...toolbarDesktopStyles } : { ...toolbarMobileStyles }}>
        <NavSectionHorizontal data={navConfig} />
      </Toolbar>

      {shouldHaveShadow && <Shadow />}
    </AppBar>
  );
}

export default memo(NavHorizontal);

// ----------------------------------------------------------------------

export function Shadow({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        width: 1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
