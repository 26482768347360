import React, { Dispatch, SetStateAction } from 'react';
import ClosetSelectionErrorDialog from './ClosetSelectionErrorDialog';
import DowngradeConfirmationDialog from './DowngradeConfirmationDialog';
import UpgradeConfirmationDialog from './UpgradeConfirmationDialog';

const PoshmarkPricingDialogs = () => (
  <>
    <ClosetSelectionErrorDialog />
    <UpgradeConfirmationDialog />
    <DowngradeConfirmationDialog />
  </>
);

export default PoshmarkPricingDialogs;
