import { MixpanelEventTypes, mixpanelTrack } from '../../../utils/mixpanel';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, delay, fork, put, take, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { poshmarkAutomationEndpoints } from 'src/endpoints';
import axiosInstance from 'src/utils/axios';

import {
  getClosetListSuccess,
  getClosetListFail,
  getClosetListRequest,
  connectClosetSuccess,
  connectClosetFail,
  connectClosetRequest,
  setIsConnectDialogOpen,
  setShouldShowConnectFormAlert,
  removeClosetSuccess,
  removeClosetFail,
  removeClosetRequest,
  IRemoveData,
  setActiveCloset,
  ConnectionStatus,
} from '../slices/myClosetSlice';
import { Id, toast } from 'react-toastify';
import { toastTracker } from 'src/utils/toastTracker';
import { sortClosetListAccordingToStatus } from 'src/pages/automations/MyClosetPage/helpers/helpers';
import { setActiveClosetAutomationPlatform } from '../slices/automationsSlice';
import { IClosetDetails } from 'src/pages/automations/MyClosetPage/utils/types';
import { IInitialValues } from 'src/pages/automations/MyClosetPage/components/dialogs/ConnectClosetDialog';
import { SupabaseEventType } from 'src/utils/SupabaseBroadcast';

interface CredentialUpdateEvent extends Event {
  detail: {
    payload: any;
  };
}

enum CredentialUpdateStatusEnum {
  SUCCESS = 'success',
  FAILED = 'failed',
}

function* getClosetListSaga() {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(poshmarkAutomationEndpoints.closet.GET_CLOSETS())
    );

    const closetList = response.data.data;
    const hasCloset =
      closetList.filter((closet: IClosetDetails) => closet.status !== ConnectionStatus.Removed)
        .length > 0;

    yield put(
      getClosetListSuccess(
        sortClosetListAccordingToStatus(closetList as IClosetDetails[]).reverse()
      )
    );

    if (hasCloset) {
      const initialActiveCloset = closetList
        .filter((item: IClosetDetails) => item.status !== ConnectionStatus.Removed)
        .slice(-1)[0];
      yield put(setActiveCloset(initialActiveCloset));
      yield put(setActiveClosetAutomationPlatform(initialActiveCloset.country));
    }
  } catch (error) {
    yield put(getClosetListFail(error.message));
  }
}

/**
 * This function listens for a credential update event from Supabase and resolves or rejects based on the event's success status.
 */
function credentialUpdateEventListener() {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      window.supabase.events.removeEventListener(
        SupabaseEventType.CREDENTIAL_UPDATE,
        eventListener as EventListener
      );
      reject(new Error('Timeout: No response received within 5 minutes.'));
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

    const eventListener: EventListener = (event) => {
      clearTimeout(timeout); // Clear the timeout if the event is received
      window.supabase.events.removeEventListener(
        SupabaseEventType.CREDENTIAL_UPDATE,
        eventListener
      );

      const customEvent = event as CredentialUpdateEvent;
      const customEventPayload = customEvent.detail.payload;

      if (customEventPayload.status === CredentialUpdateStatusEnum.SUCCESS) {
        resolve(customEventPayload); // Resolve with payload for more specific data
      } else {
        const errorCode = customEventPayload.errorCode || 'Unknown error code';
        const errorPayload = {
          error: errorCode.toString(),
        };
        reject(errorPayload);
      }
    };

    window.supabase.events.addEventListener(
      SupabaseEventType.CREDENTIAL_UPDATE,
      eventListener as EventListener
    );
  });
}
// Wait for supabase event and then dispatch the success or failed action
const credentialUpdateSaga = function* (
  userHandle: string,
  toastUpdate: Function,
  intervalId: any,
  timeoutId: any,
  password: string,
  country: string,
  toastId: Id
): Generator {
  try {
    yield call(credentialUpdateEventListener);

    yield call(onSuccessConnection, toastUpdate, intervalId, timeoutId);
    toast.dismiss(toastId);
  } catch (error) {
    // call onFailedConnection
    yield call(
      onFailedConnection,
      error,
      userHandle,
      toastUpdate,
      intervalId,
      timeoutId,
      password,
      country,
      toastId
    );
    // We need to initialize a new toast because we dismiss previous one
    const errorText = handleErrorMessageForConnection(error);
    toast.error(errorText, {
      position: 'top-right',
      closeOnClick: true,
      isLoading: false,
      autoClose: 60000,
    });
  }
};

function* connectClosetSaga(action: PayloadAction<IInitialValues>): Generator {
  // Local variables to store intervalId and timeoutId
  let intervalId: any = null;
  let timeoutId: any = null;

  // This was a tricky bug, this is added to prevent multiple intervals and timeouts from being created PL-4271
  if (typeof window !== 'undefined') {
    window.clearInterval(intervalId);
    window.clearTimeout(timeoutId);
  }

  const toastId = toast.loading('Encrypting your Poshmark credentials...', {
    position: 'top-right',
    autoClose: false,
  });
  const { toastUpdate } = toastTracker(toastId);
  const toastMessages = [
    'Connecting to your Poshmark account...',
    'Connecting could take up to 3 minutes...',
  ];
  let currentIndex = 0;

  timeoutId = setTimeout(() => {
    intervalId = setInterval(() => {
      toastUpdate({ message: toastMessages[currentIndex], isLoading: true });
      currentIndex = (currentIndex + 1) % toastMessages.length;
    }, 2000);
    currentIndex = (currentIndex + 1) % toastMessages.length;
  }, 3000);

  const { userHandle, password, country } = action.payload;
  try {
    yield call(() =>
      axiosInstance.post(poshmarkAutomationEndpoints.closet.CONNECT_CLOSETS(), action.payload)
    );
    yield call(
      credentialUpdateSaga,
      userHandle,
      toastUpdate,
      intervalId,
      timeoutId,
      password,
      country,
      toastId
    );

    clearInterval(intervalId);
    clearTimeout(timeoutId);
    toast.dismiss(toastId); // Dismiss the initial toast
  } catch (error) {
    yield onFailedConnection(
      error,
      userHandle,
      toastUpdate,
      intervalId,
      timeoutId,
      password,
      country,
      toastId
    );
  }
}

// Helper Functions

const onSuccessConnection = function* (toastUpdate: Function, intervalId: any, timeoutId: any) {
  clearInterval(intervalId);
  clearTimeout(timeoutId);
  toast.success('Your closet has been successfully connected', {
    position: 'top-right',
    closeOnClick: true,
    autoClose: 3000,
    isLoading: false,
  });
  yield put(getClosetListRequest());
  yield put(setIsConnectDialogOpen(false));
  yield put(connectClosetSuccess());
};

const onFailedConnection = function* (
  error: any,
  userHandle: string,
  toastUpdate: Function,
  intervalId: any,
  timeoutId: any,
  password: string,
  country: string,
  toastId: Id
) {
  if (error.error) {
    clearInterval(intervalId);
    clearTimeout(timeoutId);
    toast.dismiss(toastId); // Dismiss the initial toast
    toast.error(handleErrorMessageForConnection(error), {
      // Create a new toast for the error
      position: 'top-right',
      closeOnClick: true,
      isLoading: false,
      autoClose: 60000,
    });
    yield put(connectClosetFail(error.error));
    if (error.error.includes('401')) {
      yield put(setShouldShowConnectFormAlert(true));
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    }
  } else {
    // We also need to handle the 520 error case
    yield call(
      credentialUpdateSaga,
      userHandle,
      toastUpdate,
      intervalId,
      timeoutId,
      password,
      country,
      toastId
    );
    toast.dismiss(toastId);
  }
};

function* removeClosetSaga(action: PayloadAction<IRemoveData>) {
  try {
    yield call(() =>
      axiosInstance.delete(poshmarkAutomationEndpoints.closet.REMOVE_CLOSET(action.payload.id))
    );
    yield put(getClosetListRequest());
    yield put(removeClosetSuccess());
    toast.success(`Your closet '${action.payload.name}' successfully removed`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  } catch (error) {
    yield put(removeClosetFail(error.message));
  }
}

export function* myClosetModuleSaga() {
  yield takeLatest(getClosetListRequest.type, getClosetListSaga);
  yield takeLatest(connectClosetRequest.type, connectClosetSaga);
  yield takeLatest(removeClosetRequest.type, removeClosetSaga);
}

const handleErrorMessageForConnection = (error: { error?: string } | string) => {
  const DEFAULT_ERROR_MESSAGE = 'Failed to connect your closet, please try again!';
  const AUTHENTICATION_ERROR_MESSAGE =
    'Authentication failed. Please double-check your Poshmark account info and test your credentials by logging into Poshmark directly, then try again.';
  const LINKED_ERROR_MESSAGE = 'This closet has already been connected to another account.';
  const LOGIN_PROGRESS_MESSAGE = 'Log in progress. Please wait...';
  const PASSWORD_RESET_REQUIRED_MESSAGE = `You need to change your Poshmark password. Once you’ve done that, you should be able to log in without any issues. Please note, this is a Poshmark issue, not a PrimeLister login problem.`;
  const DISABLED_USER_STATUS_MESSAGE = `You can't log in to your Poshmark account. Your account has been disabled by Poshmark. You need to contact Poshmark support.`;
  const SOCIAL_LOGIN_REQUIRED = `Your account was created using your social media. You need to set a password to log in. Please go to the Poshmark website and create password.`;

  if (typeof error === 'string' && error.includes('Something went wrong')) {
    return DEFAULT_ERROR_MESSAGE;
  }
  if (typeof error === 'object' && error?.error) {
    const errorMessage = error.error;

    if (errorMessage.includes('401')) {
      return AUTHENTICATION_ERROR_MESSAGE;
    } else if (errorMessage.includes('already linked')) {
      return LINKED_ERROR_MESSAGE;
    } else if (errorMessage.includes('Log in progress.')) {
      return LOGIN_PROGRESS_MESSAGE;
    } else if (errorMessage.includes('602')) {
      return PASSWORD_RESET_REQUIRED_MESSAGE;
    } else if (errorMessage.includes('603')) {
      return DISABLED_USER_STATUS_MESSAGE;
    } else if (errorMessage.includes('604')) {
      return SOCIAL_LOGIN_REQUIRED;
    }
  }

  return DEFAULT_ERROR_MESSAGE;
};
