import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { setIsExtensionUpdateDialogOpen } from 'src/store/dashboard/slices/inventorySlice';

const ExtensionUpdateRequiredDialog = () => {
  const handleOnOpen = () => {
    window.open(
      'https://chromewebstore.google.com/detail/cross-listing-poshmark-to/eepbhjeldlodgnndnjofcnnoampciipg',
      '_blank'
    );
  };

  const dispatch = useDispatch();
  const { isExtensionUpdateDialogOpen } = useSelector((state: IRootState) => state.inventory);
  const handleOnClose = () => {
    dispatch(setIsExtensionUpdateDialogOpen(false));
  };

  return (
    <Dialog open={isExtensionUpdateDialogOpen} onClose={handleOnClose}>
      <DialogTitle>Extension Update Required</DialogTitle>
      <DialogContent>
        <Typography>
          PrimeLister may release a newer version of the PrimeLister Extension to enable new
          features and improvements. In case you want to use the latest version of the PrimeLister
          Extension, you should follow the steps below to re-install the extension:
        </Typography>
        <Box component="div">
          <Typography
            sx={{
              mt: 2,
            }}
          >
            1) Visit{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://chromewebstore.google.com/detail/cross-listing-poshmark-to/eepbhjeldlodgnndnjofcnnoampciipg"
            >
              this page
            </a>{' '}
            and click the "Remove from Chrome" button on the page opened to remove the old version
            of PrimeLister Extension from your Chrome.
          </Typography>
          <Box mt={2} mb={2}>
            <img
              src="https://docs.primelister.com/~gitbook/image?url=https%3A%2F%2Flh4.googleusercontent.com%2F7HMPspV2l0k_PbtjkFYOryp-DOMj4jhSVabKI7-2Vp6hrzbzfCfcx0-BgR_YtIxwCcD-qjZv5-xaBmkxgjDCLTr4KP8bBrJ49bFmyShKHk_8tPG_RS15PrHlMydAe3HH2UCqbP-g&width=768&dpr=4&quality=100&sign=25d04969&sv=1"
              alt="Remove from Chrome"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Box>
          <Typography>
            2) Visit{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://chromewebstore.google.com/detail/cross-listing-poshmark-to/eepbhjeldlodgnndnjofcnnoampciipg"
            >
              this page
            </a>{' '}
            and click the "Add to Chrome" button on the page opened to install the latest version of
            PrimeLister Extension on your Chrome.
          </Typography>
          <Box mt={2}>
            <img
              src="https://docs.primelister.com/~gitbook/image?url=https%3A%2F%2Flh4.googleusercontent.com%2Fzmd9EWYMQTe2SQ1XT9chqDlJmkTsMczQbGHsI9QGMQ_K8-1N8r6IeUD0C62H_APq7RJB3OEGGLqxyyh1LLIJkJDn7A3_K1JxIUqimdF5UGWIzqgSd36YweRZC91IrNUTXrodH4Ub&width=768&dpr=4&quality=100&sign=1342a4d6&sv=1"
              alt="Add to Chrome"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} color="inherit" variant="outlined">
          Close
        </Button>
        <Button onClick={handleOnOpen} color="primary" variant="contained" autoFocus>
          Open Extension Installation Page
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExtensionUpdateRequiredDialog;
