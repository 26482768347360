import { Helmet } from 'react-helmet-async';
import { useTheme } from '@mui/material/styles';
// @mui
import { Container, Stack, Typography, useMediaQuery } from '@mui/material';
import ClosetCardContainer from './components/ClosetCardContainer';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'src/store';
import MainConnectButton from './components/MainConnectButton';

import { bgBlur } from 'src/utils/cssStyles';
import { useSearchParams } from 'react-router-dom';
import { ConnectionStatus } from 'src/store/automations/slices/myClosetSlice';

import MyClosetDialogs from './components/dialogs/MyClosetDialogs';

import useOnboarding from 'src/utils/onBoardingSteps/useOnboarding';
import { UserTagTypesEnum } from 'src/store/automations/slices/automationsSlice';

import { IClosetDetails } from 'src/pages/automations/MyClosetPage/utils/types';
import useResponsive from 'src/hooks/useResponsive';
import HelpButton from 'src/layouts/LayoutComponents/HelpButton';
import { showCrispChat } from 'src/utils/crispChatHelpers';
import AutomationsPageTitle from 'src/layouts/automations/header/AutomationsPageTitle';
import { StylesByDevice } from '../MyListingsPage/components/ListingsBulkActionsMenu';

const MyClosetPage = () => {
  const dispatch = useDispatch();
  const { hasCloset, hasConnection, closetList } = useSelector(
    (state: IRootState) => state.myCloset
  );

  const {
    isUserBoardedMultipleClosets,
    isUserBoardedPoshmarkAutomation,
    isUserBoardedPoshmarkAutomationFeatures,
  } = useSelector((state: IRootState) => state.automations);
  const { driverObject } = useOnboarding(UserTagTypesEnum.ONBOARDING_MULTIPLE_CLOSETS);
  const [scrollDisplay, setScrollDisplay] = useState(false);

  const stackRef = useRef<HTMLDivElement>(null); // Create a ref to the Stack component
  const matches = useMediaQuery('(min-width:600px)');
  const navbarMatches = useMediaQuery('(min-width:1349px)');
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const titleMatches = useMediaQuery('(max-width:380px)');
  const isMobileWeb = useResponsive('down', 'sm');
  const isMobile = isMobileWeb || isPrimeListerMobileApp;

  useEffect(() => {
    const scrollLimit = () => {
      if (navbarMatches) {
        return 121;
      }
      if (matches) {
        return 108;
      }
      return 115;
    };
    const handleScroll = () => {
      if (window.scrollY <= scrollLimit()) {
        setScrollDisplay(false);
      } else {
        setScrollDisplay(true);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [window.scrollY]);

  useEffect(() => {
    const hasMultipleActiveCloset =
      closetList.filter((closet: IClosetDetails) => closet?.status !== ConnectionStatus.Removed)
        .length > 1;

    const userHasMultipleClosetAndBoardedForOthers =
      isUserBoardedPoshmarkAutomation &&
      isUserBoardedPoshmarkAutomationFeatures &&
      !isUserBoardedMultipleClosets &&
      hasMultipleActiveCloset;

    if (userHasMultipleClosetAndBoardedForOthers) {
      driverObject.drive();
    }
  }, [closetList]);

  const stylesByDevice: StylesByDevice = {
    rootStyles: {
      mobile: {
        position: 'initial',
        boxShadow: '#ffffff',
        mb: '24px',
      },
      desktop: {
        position: 'sticky',
        top: navbarMatches ? 60 : 108,
        boxShadow: scrollDisplay ? '0px 8px 8px -5px #E5E8EB' : '#ffffff',
        mb: '32px',
        ...bgBlur({
          color: theme.palette.background.default,
        }),
      },
    },
  };
  
  const stackStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: matches ? '0px' : '32px',
    py: matches ? 5 : 0,
    mt: matches ? -5 : 0,
    zIndex: 11,
    px: hasConnection ? 0 : '16px',
    ...(isMobile ? stylesByDevice.rootStyles.mobile : stylesByDevice.rootStyles.desktop),
  };

  return (
    <>
      <Helmet>
        <title> My Closet | Primelister</title>
      </Helmet>

      <Container
        disableGutters
        sx={{
          maxWidth: hasCloset && !hasConnection ? '832px !important' : '800px !important',
          p: 0,
        }}
      >
        <Stack
          ref={stackRef}
          direction={matches ? 'row' : 'column'}
          spacing={matches ? 0 : '24px'}
          sx={stackStyles}
        >
          {((isMobile && titleMatches) || !isMobile) && <AutomationsPageTitle title="My Closet" />}

          <MainConnectButton />
        </Stack>
        <ClosetCardContainer />
        <MyClosetDialogs />
      </Container>
    </>
  );
};

export default MyClosetPage;
