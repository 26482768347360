import React, { useEffect } from 'react';
import {
  DateTimeValidationError,
  DateValidationError,
  DesktopDateTimePicker,
  MobileDateTimePicker,
} from '@mui/x-date-pickers';
import useResponsive from 'src/hooks/useResponsive';
import './CustomDateTimePicker.css';
import { Backdrop, Box, Button, Paper, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDateTimePickerHasError,
  setIsDateTimePickerMobileOpen,
} from 'src/store/automations/slices/myListingsSlice';
import { IRootState } from 'src/store';
import moment from 'moment';
import { pickersLayoutClasses } from '@mui/x-date-pickers/PickersLayout';

interface DateTimePickerProps {
  value: Date | null;
  onChange: (date: any) => void;
  minDateTime?: Date;
  label?: string;
  slotProps?: any;
}

const CustomActionBar = ({
  onAccept,
  onClear,
  onCancel,
  error,
  errorMessage,
  wrapperVariant,
  value,
}: any) => {
  const theme = useTheme();
  const isDisabled = !value || !!errorMessage;

  return (
    <Paper
      className="MuiPickersLayout-actionBar"
      sx={{
        [theme.breakpoints.down('sm')]: {
          height: '100%',
          paddingBottom: '8px',
          paddingRight: '8px',
        },
        height: '50px',
        padding: '8px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Typography
        sx={{
          fontSize: '13px',
          color: '#FF5630',
          ml: 1,
          [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            ml: 0,
            mr: '4px',
          },
        }}
      >
        {!!error && errorMessage}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '12px',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{
            height: '36px',
          }}
          variant="outlined"
          color="inherit"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          sx={{
            height: '36px',
            ...(wrapperVariant === 'desktop' && {
              marginRight: '24.45px',
            }),
          }}
          disabled={isDisabled}
          onClick={onAccept}
          variant="contained"
          color="primary"
        >
          Okay
        </Button>
      </Box>
    </Paper>
  );
};

const CustomDateTimePicker: React.FC<DateTimePickerProps> = ({
  value,
  onChange,
  minDateTime = moment(),
  label = 'Start Date & Time',
  slotProps = {},
}) => {
  const isMobileScreen = useResponsive('down', 'sm');
  const dispatch = useDispatch();
  const { userAccountDetail } = useSelector((state: IRootState) => state.account);
  const [error, setError] = React.useState<DateTimeValidationError | null>(null);

  useEffect(() => {
    if (isMobileScreen) {
      document.body.classList.add('overlay-active');
    } else {
      document.body.classList.remove('overlay-active');
    }

    return () => {
      document.body.classList.remove('overlay-active');
    };
  }, [isMobileScreen, value]);

  const errorMessage = React.useMemo(() => {
    switch (error) {
      case 'maxDate':
      case 'minDate':
      case 'minTime':
      case 'maxTime':
      case 'invalidDate':
        return !isMobileScreen
          ? 'Please select a date and time in the future.'
          : 'Select future date-time.';
      default:
        return '';
    }
  }, [error]);

  const commonProps = {
    minDateTime,
    label,
    value,
    onError: (error: any) => {
      dispatch(setDateTimePickerHasError(!!error));
      setError(error);
    },

    onChange,
    slotProps: {
      ...slotProps,
      textField: {
        ...slotProps.textField,
        helperText: errorMessage,
      },
      layout: {
        sx: {
          [`.${pickersLayoutClasses.actionBar}`]: {
            gridColumn: 0,
            gridRow: 0,
            display: 'flex',
          },
          [`.${pickersLayoutClasses.contentWrapper}`]: {
            gridColumn: '1',
            gridRow: '1',
          },
          [`.${pickersLayoutClasses.toolbar}`]: {
            [`@media (max-width: 600px)`]: {
              gridArea: '-2',
            },
            '& .MuiDateTimePickerToolbar-timeDigitsContainer': {
              alignItems: 'center',
            },
          },
          '& .MuiPickersSlideTransition-root': {
            [`@media (max-width: 600px)`]: {
              minHeight: '200px',
            },
          },
          '& .MuiDateCalendar-root': {
            [`@media (max-width: 600px)`]: {
              maxHeight: '300px',
            },
          },
        },
      },
      actionBar: {
        wrapperVariant: isMobileScreen ? 'mobile' : 'desktop',
        error,
        errorMessage,
        value,
      },
      // actionBar: {
      //   actions: ['clear', 'Okay', 'cancel', 'okay'],
      //   sx: { justifyContent: 'flex-end' },
      // },
    },
    slots: {
      actionBar: CustomActionBar,
    },
    disableHighlightToday: true,
    closeOnSelect: false,
  };

  return isMobileScreen ? (
    <MobileDateTimePicker
      {...commonProps}
      onClose={() => {
        document.body.classList.remove('overlay-active');
      }}
      onOpen={() => {
        setTimeout(() => {
          document.body.classList.add('overlay-active');
        }, 100);
      }}
      timezone={userAccountDetail?.timeZone?.name}
    />
  ) : (
    <DesktopDateTimePicker {...commonProps} timezone={userAccountDetail?.timeZone?.name} />
  );
};

export default CustomDateTimePicker;
