import React from 'react';
import { Box, Stack, Typography, Switch, SxProps, Theme } from '@mui/material';
import CommonTitleTooltip from '../shared/CommonTitleTooltip';
import CustomDateTimePicker from '../shared/CustomDateTimePicker';
import { commonDateTimePickerStyles } from '../../CreateNewTaskDialog';

interface ScheduleOptionProps {
  isScheduleEnabled: boolean;
  setIsScheduleEnabled: (value: boolean) => void;
  selectedDateTime: Date | null;
  setSelectedDateTime: (date: Date | null) => void;
  tooltipTitle: string;
  sx?: SxProps<Theme>;
}

const ScheduleOption: React.FC<ScheduleOptionProps> = ({
  isScheduleEnabled,
  setIsScheduleEnabled,
  selectedDateTime,
  setSelectedDateTime,
  tooltipTitle,
  sx,
}) => (
  <Box sx={sx}>
    <Stack direction="row" justifyContent="space-between">
      <Typography
        variant="subtitle1"
        noWrap
        sx={{
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '22px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        Schedule
        <CommonTitleTooltip title={tooltipTitle} />
      </Typography>
      <Switch
        sx={{
          marginRight: '-10px',
        }}
        checked={isScheduleEnabled}
        onChange={(e) => setIsScheduleEnabled(e.target.checked)}
      />
    </Stack>

    {isScheduleEnabled && (
      <CustomDateTimePicker
        value={selectedDateTime}
        onChange={setSelectedDateTime}
        slotProps={{
          textField: {
            ...commonDateTimePickerStyles,
          },
        }}
      />
    )}
  </Box>
);

export default ScheduleOption;
