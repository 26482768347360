import React from 'react';
import { Typography, Box, Divider, CardContent, useTheme } from '@mui/material';
import ShippingChip from './ShippingChip';
import { ProductCardProps, ProductCardSelectionEnum } from './types';
import OverflowTypography from './OverflowTypography';
import Iconify from '../iconify';
import StatusLabel from './StatusLabel';
import ConditionChip from './ConditionChip';
import ProductCheckbox from './ProductCheckbox';
import useResponsive from 'src/hooks/useResponsive';

interface ProductInfoProps {
  title: ProductCardProps['title'];
  size: ProductCardProps['size'];
  brand: ProductCardProps['brand'];
  price: ProductCardProps['price'];
  originalPrice: ProductCardProps['originalPrice'];
  originalPriceVisibility: ProductCardProps['originalPriceVisibility'];
  shippingChipVisibility: ProductCardProps['shippingChipVisibility'];
  shippingType: ProductCardProps['shippingType'];
  likeCountVisibility: ProductCardProps['likeCountVisibility'];
  commentCountVisibility: ProductCardProps['commentCountVisibility'];
  likeCount: ProductCardProps['likeCount'];
  commentCount: ProductCardProps['commentCount'];
  thumbnailUrl: ProductCardProps['thumbnailUrl'];
  selection: ProductCardProps['selection'];
  statusTagVisibility: ProductCardProps['statusTagVisibility'];
  conditionChipVisibility: ProductCardProps['conditionChipVisibility'];
  statusLabel: ProductCardProps['statusLabel'];
  conditionChip: ProductCardProps['conditionChip'];
  droppingSoonDate?: ProductCardProps['droppingSoonDate'];
  cardWidth?: number;
  productCardSelection: ProductCardSelectionEnum;
}

const ProductInfoMobile: React.FC<ProductInfoProps> = ({
  title,
  size,
  brand,
  price,
  originalPrice,
  originalPriceVisibility,
  likeCountVisibility,
  commentCountVisibility,
  likeCount,
  commentCount,
  productCardSelection,
  cardWidth,
}) => {
  const actionTextStyle = {
    color: '#454F5B',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '17.14px',
  };

  const theme = useTheme();
  const isSmallerDevice = useResponsive('down', 321);

  return (
    <CardContent
      sx={{
        padding: '0',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        maxHeight: '87px',
        ml: !isSmallerDevice ? '12px' : '0',
        '&:last-child': {
          paddingBottom: '0',
        },
      }}
    >
      <Box>
        <OverflowTypography
          sx={{
            fontSize: '14px',
            fontWeight: 500,
            fontStyle: 'normal',
            color: '#212B36',
            whiteSpace: 'initial',
            maxHeight: '36px',
            WebkitLineClamp: 2,
            display: '-webkit-box',
            alignSelf: 'stretch',
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
            minWidth: '240px',
            overflow: 'hidden',
            lineHeight: '18px',
            [theme.breakpoints.down(321)]: {
              mt: '12px',
            },
            mt: cardWidth && cardWidth < 321 ? '12px' : '0',
          }}
        >
          {title}
        </OverflowTypography>
        <Box
          sx={{
            color: '#003768',
            textOverflow: 'ellipsis',
            letterSpacing: '0.15px',
            display: 'flex',
            alignItems: 'center',
            mt: '4px',
            mb: '4px',
          }}
        >
          <OverflowTypography
            sxProps={{
              fontSize: '13px',
              fontWeight: 500,
              maxWidth: '76px',
              lineHeight: '18.57px',
              overflow: 'hidden',
              wordBreak: 'break-all',
            }}
          >
            Size: {size}
          </OverflowTypography>
          <Divider
            orientation="vertical"
            flexItem
            variant="middle"
            sx={{ height: '16px', m: '0 5.5px', backgroundColor: '#E5E8EB' }}
          />
          <OverflowTypography
            sxProps={{
              fontSize: '13px',
              fontWeight: 500,
              maxWidth: '138px',
              overflow: 'hidden',
              lineHeight: '18.57px',
              wordBreak: 'break-all',
            }}
          >
            {brand}
          </OverflowTypography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '24px', gap: '16px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 700,
                color: '#212B36',
                marginRight: '4px',
                lineHeight: '17.442px',
              }}
            >
              ${price}
            </Typography>
            {originalPriceVisibility && (
              <Typography
                sx={{
                  textDecoration: 'line-through',
                  fontWeight: 600,
                  color: '#919EAB',
                  fontSize: '14px',
                  lineHeight: '17.442px',
                }}
              >
                ${originalPrice}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Iconify
              icon="tabler:heart"
              width="18px"
              height="18px"
              color="#E5676B"
              sx={{
                marginRight: '2px',
              }}
            />
            {likeCountVisibility && !!likeCount && (
              <Typography
                sx={{
                  ...actionTextStyle,
                }}
              >
                {likeCount}
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'flex-start',
              wordBreak: 'break-word',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '18px',
                marginRight: '4px',
              }}
            >
              <Iconify icon="octicon:comment-16" width="18px" height="18px" color="#919EAB" />
            </Box>
            {commentCountVisibility && !!commentCount && (
              <Typography
                sx={{
                  ...actionTextStyle,
                }}
              >
                {commentCount}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <ProductCheckbox isMobile={true} selection={productCardSelection} />
    </CardContent>
  );
};

export default ProductInfoMobile;
