import { ProductCardStatusLabelEnum } from './types';

export const getProductCardStatusLabelColor = (statusLabel: ProductCardStatusLabelEnum) => {
  const colorMapping: Record<string, string> = {
    [ProductCardStatusLabelEnum.DROPPING_SOON]: '#212B36',
    [ProductCardStatusLabelEnum.NOT_FOR_SALE]: '#212B36',
    [ProductCardStatusLabelEnum.SOLD]: '#fff',
    [ProductCardStatusLabelEnum.SOLD_OUT]: '#fff',
  };

  return colorMapping[statusLabel];
};

export const getProductCardStatusLabelBgColor = (statusLabel: ProductCardStatusLabelEnum) => {
  const backgroundColorMapping: Record<string, string> = {
    [ProductCardStatusLabelEnum.DROPPING_SOON]: '#FFAB00',
    [ProductCardStatusLabelEnum.NOT_FOR_SALE]: '#DFE3E8',
    [ProductCardStatusLabelEnum.SOLD]: '#FF5630',
    [ProductCardStatusLabelEnum.SOLD_OUT]: '#FF5630',
  };

  return backgroundColorMapping[statusLabel];
};
