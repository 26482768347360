import { AlertTypes } from './LayoutAlert';
export const getAlertContent = (type: AlertTypes) => {
  switch (type) {
    case AlertTypes.EXTENSION:
      return {
        message:
          'Attention! Extension is not installed on your browser. To use PrimeLister, please install the extension.',
        buttonText: 'Install Extension',
      };
    case AlertTypes.CONNECTION:
      return {
        message:
          'Attention! Your Poshmark closet is not connected. Connect your closet to use the Poshmark Automation Tool.',
        buttonText: 'Connect My Closet',
      };
    case AlertTypes.RECONNECTION:
      return {
        message:
          'Attention! Your Poshmark closet disconnected. Re-connect to continue using Poshmark Automation Tool.',
        buttonText: 'Re-connect My Closet',
      };
    case AlertTypes.SUBSCRIPTION_TO_POSHMARK:
      return {
        message:
          'Attention! Automations paused due to your expired subscription. Renew your subscription to restart them.',
        buttonText: 'Renew My Subscription',
      };
    case AlertTypes.DUPLICATE:
      return {
        message:
          'Attention! Your account has been temporarily blocked. To reactivate it, please renew your subscription by following this',
        linkMessage: 'link.',
      };
    case AlertTypes.MOBILE_DEVICE:
      return {
        message:
          "Attention! Our system has identified that you're using a mobile device. To use PrimeLister, please switch to the Google Chrome browser on your PC/Mac.",
      };
    case AlertTypes.BROWSER_COMPATIBILITY:
      return {
        message:
          "Notice: To use our Cross-listing Tool, please switch to the Chrome browser. It's required for our extension to work properly.",
        buttonText: 'Install Chrome',
      };
    default:
      return null;
  }
};
