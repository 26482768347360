import {
  Box,
  Stack,
  Chip,
  TextField,
  InputAdornment,
  Skeleton,
  Grid,
  Card,
  TextFieldProps,
} from '@mui/material';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';

const DisabledInputs = () => {
  const { selectedListingDetails, loading } = useSelector((state: IRootState) => state.inventory);

  interface IDefaultValues {
    [key: string]: number | string | any[];
    title: string;
    sku: string;
    description: string;
    tags: string;
    category: string;
    condition: string;
    brand: string;
    status: string;
    color: string[];
    price: number | string;
    hashtags: string[];
  }
  const defaultValues: IDefaultValues = {
    title: selectedListingDetails?.title || '     ',
    sku: selectedListingDetails?.sku || '     ',
    description: selectedListingDetails?.description || '    ',
    tags: 'default',
    category: selectedListingDetails?.category || '     ',
    condition: selectedListingDetails?.condition || '     ',
    brand: selectedListingDetails?.brand || '     ',
    status: selectedListingDetails?.status || '      ',
    color: selectedListingDetails?.color || [],
    price: selectedListingDetails?.price || '    ',
    hashtags: selectedListingDetails?.hashtags || ['    '],
  };

  //----
  const renderField = (value: string, label: string) => (
    <TextField value={value} disabled name={label.toLowerCase()} label={label} />
  );
  const renderName = () =>
    selectedListingDetails?.title && renderField(defaultValues.title, 'Name');

  //----
  const renderNonStandardFields = (
    label: string,
    value: string | undefined,
    options: TextFieldProps
  ) =>
    selectedListingDetails?.[label] && (
      <TextField
        {...options}
        value={value}
        disabled
        name={label}
        label={label[0].toUpperCase() + label.slice(1)}
      >
        <option value={value}>
          {options.children || (
            <Stack direction="row" sx={{ width: '100%', flexWrap: 'wrap' }}>
              {value}
            </Stack>
          )}
        </option>
      </TextField>
    );

  const renderDescription = () =>
    renderNonStandardFields('description', defaultValues.description, {
      placeholder: 'Description',
      multiline: true,
      minRows: 4.75,
      maxRows: 4.75,
    });

  const renderCategory = () =>
    renderNonStandardFields('category', defaultValues.category, {
      select: true,
      placeholder: 'Category',
      children: <option value={defaultValues.category}>{defaultValues.category}</option>,
    });

  const renderTextCaseAdjusted =
    defaultValues.condition.charAt(0).toUpperCase() +
    defaultValues.condition.slice(1).toLowerCase();

  const renderCondition = () =>
    renderNonStandardFields('condition', defaultValues.condition, {
      select: true,
      placeholder: 'Condition',
      children: <option value={defaultValues.condition}>{renderTextCaseAdjusted}</option>,
    });

  const renderBrand = () =>
    renderNonStandardFields('brand', defaultValues.brand, {
      select: true,
      placeholder: 'Brand',
      children: <option value={defaultValues.brand}>{defaultValues.brand}</option>,
    });

  const renderColor = () =>
    renderNonStandardFields('color', defaultValues.color.join(', '), {
      select: true,
      placeholder: 'Color',
    });

  const renderPrice = () =>
    selectedListingDetails?.price?.toString() &&
    (loading ? (
      <Skeleton variant="rounded" sx={{ width: '100', height: '3rem' }} />
    ) : (
      <TextField
        disabled
        value={selectedListingDetails.price}
        label="Price"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box component="span" sx={{ color: 'text.disabled' }}>
                $
              </Box>
            </InputAdornment>
          ),
          type: 'number',
        }}
      />
    ));

  const renderTags = () =>
    selectedListingDetails?.hashtags &&
    selectedListingDetails?.hashtags.length > 0 && (
      <TextField
        disabled
        select
        value={defaultValues.tags}
        name="tags"
        label="Keywords & Tags"
        placeholder="Keywords & Tags"
        fullWidth
      >
        <option value={defaultValues.tags}>
          <Stack direction="row" sx={{ width: '100%', flexWrap: 'wrap' }}>
            {selectedListingDetails.hashtags
              ? selectedListingDetails.hashtags.map((item, index) => (
                  <Chip
                    key={index}
                    variant="soft"
                    label={item}
                    size="small"
                    sx={{ mr: 1, mb: 0.5 }}
                    onDelete={() => {}}
                  />
                ))
              : ''}
          </Stack>
        </option>
      </TextField>
    );
  const addSkeletonToComponents = (component: () => any) =>
    loading ? <Skeleton variant="rounded" sx={{ width: '100', height: '3rem' }} /> : component();

  return (
    <Grid item xs={12} md={8.1}>
      <Card sx={{ p: 3 }}>
        <Box rowGap={3} display="grid">
          {addSkeletonToComponents(renderName)}
          {addSkeletonToComponents(renderDescription)}
          {addSkeletonToComponents(renderCategory)}
          {addSkeletonToComponents(renderCondition)}
          {addSkeletonToComponents(renderBrand)}
          {addSkeletonToComponents(renderColor)}
          {addSkeletonToComponents(renderTags)}
          {addSkeletonToComponents(renderPrice)}
        </Box>
      </Card>
    </Grid>
  );
};

export default DisabledInputs;
