import React from 'react';
import MenuPopover from 'src/components/menu-popover/MenuPopover';
import { MenuItem } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'src/store';
import { setIsBrowserTableActionsMenuOpen } from 'src/store/dashboard/slices/myShopsSlice';
import Iconify from 'src/components/iconify';

const BrowserRowMenuPopover = () => {
  const dispatch = useDispatch();
  const { isBrowserTableActionsMenuOpen, selectedActionRow } = useSelector(
    (state: IRootState) => state.myShops
  );
  const handleClose = () => dispatch(setIsBrowserTableActionsMenuOpen(null));

  const handleConnect = () => {
    window.open(selectedActionRow.connectionLink, '_blank', 'noopener noreferrer');
    handleClose();
  };

  const { isConnected } = selectedActionRow;

  const renderActionMenuOptions = () =>
    !isConnected ? (
      <MenuItem onClick={handleConnect}>
        <Iconify icon="uil:link" /> Connect
      </MenuItem>
    ) : (
      <>
        <MenuItem onClick={handleConnect}>
          <Iconify
            icon="pajamas:retry"
            sx={{ width: '16px !important', height: '16px !important', ml: 0.3 }}
          />
          Re-Connect
        </MenuItem>
        {/* <MenuItem onClick={handleClose} sx={{ color: 'error.main' }}>
          <Iconify icon="uil:link-broken" />
          Disconnect
        </MenuItem> */}
      </>
    );

  return (
    <MenuPopover
      open={isBrowserTableActionsMenuOpen}
      onClose={handleClose}
      arrow="right-top"
      sx={{ minWidth: 140 }}
    >
      {renderActionMenuOptions()}
    </MenuPopover>
  );
};

export default BrowserRowMenuPopover;
