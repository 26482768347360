import { Divider, Button, Stack, Typography, useTheme } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'src/store';
import Iconify from 'src/components/iconify';
import {
  setDeletePopup,
  setDeletePopupData,
  setDeleteType,
} from 'src/store/dashboard/slices/tasksSlice';
import { ITasksResponseData } from '../../../types/types';

const getDeletePopupMessage = (
  isBulkDelete: boolean,
  tasks: ITasksResponseData[],
  selectedCheckboxes: string[]
) => {
  if (isBulkDelete) {
    return `${selectedCheckboxes.length} selected items`;
  }

  const task = tasks.find(({ _id }) => _id === selectedCheckboxes[0]);
  return task?.name || '';
};

const TasksBulkActionsMenu = () => {
  const dispatch = useDispatch();

  const theme = useTheme();

  const { tasks, selectedCheckboxes } = useSelector((state: IRootState) => state.tasks);

  const shouldOpenBulkActionsMenu = selectedCheckboxes.length > 0;

  const isBulkDelete = selectedCheckboxes.length > 1;

  const deleteTasksFromCheckboxes = () => {
    const deletePopupMessage = getDeletePopupMessage(isBulkDelete, tasks, selectedCheckboxes);
    dispatch(setDeletePopupData(deletePopupMessage));
    dispatch(setDeletePopup(true));
    dispatch(setDeleteType(1));
  };

  const stylesForBulkActionMenu = {
    root: {
      bgcolor: 'rgba(244, 246, 248, 0.95)',
      border: '1px solid #C4CDD5',
      boxShadow: '3px 5px 8px rgba(22, 28, 36, 0.21)',
      backdropFilter: 'blur(1.5px)',
      borderRadius: '8px',
      width: '315px',
      p: '8px 24px',
      zIndex: 19,
      top: ' -82px',
      position: 'absolute',
      transform: 'scale(0)',
      '@keyframes zoomOut': {
        '0%': {
          marginTop: '0rem',
        },
        '100%': {
          marginTop: '50rem',
          transform: 'scale(0)',
        },
      },
      '@keyframes zoomIn': {
        '0%': {
          transform: 'scale(0)',
          marginTop: '50rem',
        },
        '100%': {
          transform: 'scale(1)',
          marginTop: '0rem',
        },
      },
      animation: shouldOpenBulkActionsMenu
        ? 'zoomIn 0.25s ease-out forwards'
        : 'zoomOut 0.65s ease-in forwards',
    },
    selectionInfoPart: {
      badge: {
        height: '25px',
        fontWeight: 700,
        fontSize: '15px',
        lineHeight: '25px',
        p: '0px 6px',
        background: '#3564CA',
        textAlign: 'center',
        color: 'white',
        borderRadius: '4px',
        minWidth: '25px',
      },
      text: {
        fontWeight: '700',
        fontSize: '15px',
        lineHeight: '26px',
        color: 'rgba(33, 43, 54, 1)',
        ml: '16px',
      },
    },

    deletePart: {
      button: {
        height: '48px',
        background: '#FFFFFF',
        border: '1px solid #DFE1E6,',
        fontSize: '15px !important',
        borderRadius: ' 8px',
        px: '22px',
        color: '#B71D18',
        width: '116px',
        minWidth: '116px',
        maxWidth: '116px',
        transition: 'width 0.1s ease-in',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: '#ffffff',
          color: '#B71D18',
        },
      },
      icon: {
        minWidth: '21.9px',
        color: '#B71D18',
        width: '21.9px',
        height: '20.6px',
        mr: '8px',
      },
    },

    divider: {
      width: '1px !important',
      height: '48px !important',
    },
  };

  const { root, selectionInfoPart, deletePart, divider } = stylesForBulkActionMenu;

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={3} sx={root}>
      <Stack direction="row" sx={{ p: 0, m: 0 }}>
        <Typography sx={selectionInfoPart.badge}>{selectedCheckboxes.length}</Typography>
        <Typography sx={selectionInfoPart.text}>selected</Typography>
      </Stack>

      <Divider orientation="vertical" sx={divider} />

      <Button color="inherit" sx={deletePart.button} onClick={deleteTasksFromCheckboxes}>
        <Iconify icon={'ant-design:delete-filled'} sx={deletePart.icon} />
        Delete
      </Button>
    </Stack>
  );
};

export default TasksBulkActionsMenu;
