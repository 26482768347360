import { memo } from 'react';
// @mui
import { Stack } from '@mui/material';
// utils
import { hideScrollbarY } from '../../../utils/cssStyles';
//
import { NavSectionProps, NavListProps } from '../types';
import NavList from './NavList';
import useResponsive from 'src/hooks/useResponsive';
import { StyledBottomNavigation, StyledBottomNavigationAction, StyledIcon } from './styles';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';

// ----------------------------------------------------------------------

function NavSectionHorizontal({ data, sx, ...other }: NavSectionProps) {
  const isMobile = useResponsive('down', 'sm');
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const { pathname } = useLocation();
  const showDesktopNav = !isMobile && !isPrimeListerMobileApp;

  return showDesktopNav ? (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        mx: 'auto',
        ...hideScrollbarY,
        ...sx,
      }}
      {...other}
    >
      {data.map((group) => (
        <Items key={group.subheader} items={group.items} />
      ))}
    </Stack>
  ) : (
    <StyledBottomNavigation value={pathname}>
      {data.map((group) =>
        group.items.map((item) => (
          <StyledBottomNavigationAction
            key={item.caption}
            showLabel
            component={RouterLink}
            to={item.path}
            label={item.title}
            value={item.path}
            icon={
              <StyledIcon sx={{ marginRight: 0, width: 24, height: 24 }}>{item.icon}</StyledIcon>
            }
          />
        ))
      )}
    </StyledBottomNavigation>
  );
}
export default memo(NavSectionHorizontal);

// ----------------------------------------------------------------------

type ItemsProps = {
  items: NavListProps[];
};

function Items({ items }: ItemsProps) {
  return (
    <>
      {items.map((list) => (
        <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} />
      ))}
    </>
  );
}
