import { useDispatch, useSelector } from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import {
  inventoryDeleteRequest,
  setBulkActionsListingList,
  setDeletePopup,
  setSelectedCheckboxes,
} from 'src/store/dashboard/slices/inventorySlice';
import { IRootState } from 'src/store';
import { getFilterModelShaped } from 'src/pages/dashboard/Inventory/helpers/getFilterModelShaped';
import { LoadingButton } from '@mui/lab';

export default function InventoryDeleteDialog() {
  const dispatch = useDispatch();
  const {
    deletePopup,
    popoverData,
    deleteType,
    selectedCheckboxes,
    inventory,
    page,
    rowsPerPage,
    sortModel,
    searchValue,
    inventoryFilterModel,
    loading,
    bulkActionsListingList,
  } = useSelector((state: IRootState) => state.inventory);

  const handleClose = () => dispatch(setDeletePopup(false));

  const textLimiter = (text: string | undefined) =>
    text && text.length > 80 ? text?.substring(0, 80) + '..' : text;

  const deletedItemInfo = () => {
    // Check if deleteType is 1 and only one checkbox is selected and if an item with the selected checkbox ID exists in the inventory
    if (
      deleteType === 1 &&
      bulkActionsListingList.length === 1 &&
      inventory.find((item) => item._id === bulkActionsListingList[0]._id)
    ) {
      // If the conditions are met, return the title of the first item in the filtered list of items with the selected checkbox ID
      const [deletedItem] = inventory.filter((item) => item._id === bulkActionsListingList[0]._id);
      return deletedItem.title;
    }
    // Check if deleteType is 1 and more than one checkbox is selected
    if (deleteType === 1 && bulkActionsListingList.length > 1) {
      // If the conditions are met, return the number of selected checkboxes
      return bulkActionsListingList.length;
    }
    // If no other conditions were met, return the result of the textLimiter function applied to the popoverData title or an empty string if the title is null or undefined
    return textLimiter(popoverData?.title ?? '');
  };

  const singleItemDeleteMessage =
    deletedItemInfo() + ' will be deleted from your inventory. Are you sure you want to delete it?';

  const bulkDeleteMessage =
    deletedItemInfo() +
    ' selected items will be deleted from your inventory. Are you sure you want to delete them?';

  const dialogMessage =
    typeof deletedItemInfo() === 'string' ? singleItemDeleteMessage : bulkDeleteMessage;

  const bulkItems = bulkActionsListingList.map((listing) => listing._id);

  const handleDelete = () => {
    const orderBy = sortModel.length > 0 ? sortModel[0].field : 'added';
    const order = sortModel.length > 0 ? sortModel[0].sort : 'desc';
    dispatch(
      inventoryDeleteRequest({
        ids: deleteType === 1 ? bulkItems : [popoverData?.id!],
        getData: {
          page,
          rowsPerPage,
          orderBy,
          order,
          filter: getFilterModelShaped(inventoryFilterModel),
          searchValue,
        },
      })
    );
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={deletePopup} onClose={handleClose}>
      <DialogTitle sx={{ pb: 2 }}>Delete</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>{dialogMessage}</DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} variant="contained" color="error" onClick={handleDelete}>
          Delete
        </LoadingButton>

        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
