import moment from 'moment';

/**
 * Validates if the given string is a positive number.
 *
 * @param {string} value - The string to be validated.
 * @returns {boolean} - Returns true if the string is a positive number, otherwise false.
 */
export const validatePositiveNumber = (value: string): boolean => /^\d*$/.test(value);

/**
 * Checks if the provided date is a valid date object, moment object, or string and not in the past.
 *
 * @param {Date | moment.Moment | string | null} date - The date to be checked.
 * @returns {boolean} - Returns true if the date is valid and not in the past, otherwise false.
 */
export const isValidDate = (date: Date | moment.Moment | string | null): boolean => {
  if (date === null) {
    return false;
  }

  let dateObj: Date;

  if (moment.isMoment(date)) {
    dateObj = date.toDate();
  } else if (typeof date === 'string') {
    dateObj = new Date(date);
  } else {
    dateObj = date;
  }

  if (isNaN(dateObj.getTime())) {
    return false;
  }

  const now = new Date();
  return dateObj >= now;
};

/**
 * Checks if a field is valid based on its value and error state.
 *
 * @param {string | number} fieldValue - The value of the field to check.
 * @param {string} [fieldError] - The error message associated with the field, if any.
 * @returns {boolean} - Returns true if the field is valid, otherwise false.
 */
export const isFieldValid = (fieldValue: string | number, fieldError?: string): boolean =>
  fieldValue !== null && fieldValue !== '' && !fieldError;
