import { Box } from '@mui/material';
import ActivityCardContainer from './ActivityCardContainer';
import ActivityDropDown from './ActivityDropDown';
import useResponsive from 'src/hooks/useResponsive';

export default function ActivityTabComponent() {
  const isMobile = useResponsive('down', 'sm');
  return (
    <Box>
      <Box
        sx={{
          mb: 3,
        }}
      >
        <ActivityDropDown />
      </Box>
      <ActivityCardContainer />
    </Box>
  );
}
