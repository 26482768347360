import { PayloadAction } from '@reduxjs/toolkit';
import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { crosslistEndpoints } from 'src/endpoints';

import axiosInstance from 'src/utils/axios';

import { AxiosResponse } from 'axios';
import {
  getGroupsFail,
  getGroupsRequest,
  getGroupsSuccess,
  addNewGroupFail,
  addNewGroupRequest,
  addNewGroupSuccess,
  editGroupFail,
  editGroupRequest,
  editGroupSuccess,
  deleteGroupFail,
  deleteGroupRequest,
  deleteGroupSuccess,
  getTagsFail,
  getTagsRequest,
  getTagsSuccess,
  addNewTagFail,
  addNewTagRequest,
  addNewTagSuccess,
  editTagSuccess,
  editTagFail,
  editTagRequest,
  deleteTagSuccess,
  deleteTagFail,
  deleteTagRequest,
  IGroupData,
  ITagData,
  setGroupsOpenAddDialog,
  setGroupsOpenAddDialogError,
  setGroupsOpenDeleteDialog,
  setGroupsOpenDeleteDialogError,
  setGroupsOpenEditDialog,
  setGroupsOpenEditDialogError,
  setTagsOpenDeleteDialog,
  setTagsOpenDeleteDialogError,
} from 'src/store/dashboard/slices/groupsAndTagsSlice';
import { toast } from 'react-toastify';
import {
  inventoryGetListRequest,
  setGroupEditPopoverAnchorEl,
  setIsEditFormSaveChangesDisabled,
  setIsGroupAutocompleteOpen,
  setIsGroupEditPopoverOpen,
  setIsTagEditPopoverOpen,
  setIsTagsAutocompleteOpen,
  setQuickActionsGroupPopoverMode,
  setQuickActionsTagPopoverMode,
  setQuickEditSelectedTags,
  setSelectedGroupToAdd,
  setTagEditPopoverAnchorEl,
} from '../slices/inventorySlice';
import { IInventoryRequest } from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';

//--GROUPS
function* getGroupsSaga() {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(crosslistEndpoints.groupsAndTags.GET_GROUPS())
    );
    yield put(getGroupsSuccess(response.data.reverse()));
  } catch (error) {
    yield put(getGroupsFail(error.message));
  }
}
function* addNewGroupSaga(
  action: PayloadAction<{
    groupData: IGroupData;
    getData: IInventoryRequest;
  }>
) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.post(crosslistEndpoints.groupsAndTags.GET_GROUPS(), action.payload.groupData)
    );
    yield put(setGroupsOpenAddDialog(false));
    yield put(addNewGroupSuccess(response.data));
    yield put(getGroupsRequest());
    yield put(setQuickActionsGroupPopoverMode(null));
    yield put(setGroupEditPopoverAnchorEl(null));
    yield put(setIsGroupEditPopoverOpen(false));
    yield put(setSelectedGroupToAdd(response.data.id));
    yield put(
      inventoryGetListRequest({
        page: action.payload.getData.page,
        rowsPerPage: action.payload.getData.rowsPerPage,
        orderBy: action.payload.getData.orderBy,
        order: action.payload.getData.order,
        filter: action.payload.getData.filter,
        searchValue: action.payload.getData.searchValue,
      })
    );

    yield put(setIsEditFormSaveChangesDisabled(false));
    yield put(setIsGroupAutocompleteOpen(false));
    // Show toast
    toast.success(`${response.data.name} successfully created`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  } catch (error) {
    yield put(addNewGroupFail(error.message));
    toast.error(error.message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
}
function* deleteGroupSaga(
  action: PayloadAction<{
    groupData: IGroupData;
    getData: IInventoryRequest;
  }>
) {
  try {
    yield put(setIsGroupEditPopoverOpen(false));
    yield put(setIsGroupAutocompleteOpen(false));
    yield call(() =>
      axiosInstance.delete(crosslistEndpoints.groupsAndTags.GET_GROUPS(), {
        data: { id: action.payload.groupData.id },
      })
    );
    yield put(setGroupsOpenDeleteDialogError(''));
    yield put(deleteGroupSuccess());
    yield put(getGroupsRequest());
    yield put(setGroupsOpenDeleteDialog(false));
    yield put(setQuickActionsGroupPopoverMode(null));
    yield put(setGroupEditPopoverAnchorEl(null));
    yield put(setIsGroupAutocompleteOpen(false));
    yield put(
      inventoryGetListRequest({
        page: action.payload.getData.page,
        rowsPerPage: action.payload.getData.rowsPerPage,
        orderBy: action.payload.getData.orderBy,
        order: action.payload.getData.order,
        filter: action.payload.getData.filter,
        searchValue: action.payload.getData.searchValue,
      })
    );
    // Show toast
    toast.success(`${action.payload.groupData.name} successfully deleted`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  } catch (error) {
    yield put(deleteGroupFail(error.message));
    yield put(setGroupsOpenDeleteDialogError(error.message));
  }
}
function* editGroupSaga(
  action: PayloadAction<{
    groupData: IGroupData;
    getData: IInventoryRequest;
  }>
) {
  try {
    yield call(() =>
      axiosInstance.patch(crosslistEndpoints.groupsAndTags.GET_GROUPS(), action.payload.groupData)
    );
    yield put(setGroupsOpenEditDialogError(''));
    yield put(setGroupsOpenAddDialogError(''));
    yield put(editGroupSuccess());
    yield put(getGroupsRequest());
    yield put(setSelectedGroupToAdd(action.payload.groupData.id));
    yield put(setQuickActionsGroupPopoverMode(null));
    yield put(setIsGroupEditPopoverOpen(false));
    yield put(setGroupEditPopoverAnchorEl(null));
    yield put(
      inventoryGetListRequest({
        page: action.payload.getData.page,
        rowsPerPage: action.payload.getData.rowsPerPage,
        orderBy: action.payload.getData.orderBy,
        order: action.payload.getData.order,
        filter: action.payload.getData.filter,
        searchValue: action.payload.getData.searchValue,
      })
    );

    yield put(setIsGroupAutocompleteOpen(false));

    // Show toast
    toast.success(`${action.payload.groupData.name} successfully updated`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  } catch (error) {
    yield put(editGroupFail(error.message));
    toast.error(error.message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
}
//--TAGS
function* getTagsSaga() {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(crosslistEndpoints.groupsAndTags.GET_TAGS())
    );
    yield put(getTagsSuccess(response.data.reverse()));
  } catch (error) {
    yield put(getTagsFail(error.message));
  }
}
function* addNewTagSaga(
  action: PayloadAction<{
    newTag: ITagData;
    makeRequest?: boolean;
  }>
) {
  try {
    if (action.payload.makeRequest) {
      yield call(() =>
        axiosInstance.post(crosslistEndpoints.groupsAndTags.GET_TAGS(), action.payload.newTag)
      );
    }
    yield put(addNewTagSuccess());
    yield put(getTagsRequest());
    yield put(setQuickActionsTagPopoverMode(null));
    yield put(setTagEditPopoverAnchorEl(null));
    yield put(setIsTagEditPopoverOpen(false));

    // Show toast
    toast.success(`${action.payload.newTag.name} successfully created`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    yield put(setIsEditFormSaveChangesDisabled(false));
    yield put(setIsTagsAutocompleteOpen(false));
  } catch (error) {
    yield put(addNewTagFail(error.message));
    toast.error(error.message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
}
function* deleteTagSaga(
  action: PayloadAction<{
    tagData: ITagData;
    quickEditSelectedTags: ITagData[];
    getData: IInventoryRequest;
  }>
) {
  try {
    yield call(() =>
      axiosInstance.delete(crosslistEndpoints.groupsAndTags.GET_TAGS(), {
        data: { id: action.payload.tagData._id },
      })
    );
    yield put(setTagsOpenDeleteDialog(false));
    yield put(setTagsOpenDeleteDialogError(''));
    yield put(deleteTagSuccess());
    yield put(getTagsRequest());
    yield put(setQuickActionsTagPopoverMode(null));
    yield put(setTagEditPopoverAnchorEl(null));
    yield put(setIsTagEditPopoverOpen(false));
    const updatedTags = action.payload.quickEditSelectedTags.filter(
      (tag) => tag._id !== action.payload.tagData._id
    );
    yield put(setQuickEditSelectedTags(updatedTags));
    yield put(
      inventoryGetListRequest({
        page: action.payload.getData.page,
        rowsPerPage: action.payload.getData.rowsPerPage,
        orderBy: action.payload.getData.orderBy,
        order: action.payload.getData.order,
        filter: action.payload.getData.filter,
        searchValue: action.payload.getData.searchValue,
      })
    );

    // Show toast
    toast.success(`${action.payload.tagData.name} successfully deleted`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  } catch (error) {
    yield put(deleteTagFail(error.message));
    yield put(setTagsOpenDeleteDialogError(error.message));
  }
}
function* editTagSaga(
  action: PayloadAction<{
    tagData: ITagData;
    quickEditSelectedTags: ITagData[];
    getData: IInventoryRequest;
  }>
) {
  try {
    yield call(() =>
      axiosInstance.put(crosslistEndpoints.groupsAndTags.GET_TAGS(), {
        name: action.payload.tagData.name,
        color: action.payload.tagData.color,
        id: action.payload.tagData._id,
      })
    );
    yield put(editTagSuccess());
    yield put(getTagsRequest());

    const updatedTags = action.payload.quickEditSelectedTags.map((tag) =>
      tag._id === action.payload.tagData._id ? action.payload.tagData : tag
    );
    yield put(setQuickEditSelectedTags(updatedTags));

    yield put(setIsTagEditPopoverOpen(false));
    yield put(setQuickActionsTagPopoverMode(null));
    yield put(
      inventoryGetListRequest({
        page: action.payload.getData.page,
        rowsPerPage: action.payload.getData.rowsPerPage,
        orderBy: action.payload.getData.orderBy,
        order: action.payload.getData.order,
        filter: action.payload.getData.filter,
        searchValue: action.payload.getData.searchValue,
      })
    );
    yield put(setIsTagsAutocompleteOpen(false));
    // Show toast
    toast.success(`${action.payload.tagData.name} successfully updated`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  } catch (error) {
    yield put(editTagFail(error.message));
    toast.error(error.message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
}

export function* groupsAndTagsModuleSaga() {
  //--groups
  yield takeLatest(getGroupsRequest.type, getGroupsSaga);
  yield takeLatest(addNewGroupRequest.type, addNewGroupSaga);
  yield takeLatest(deleteGroupRequest.type, deleteGroupSaga);
  yield takeLatest(editGroupRequest.type, editGroupSaga);
  //--tags
  yield takeLatest(getTagsRequest.type, getTagsSaga);
  yield takeLatest(addNewTagRequest.type, addNewTagSaga);
  yield takeLatest(deleteTagRequest.type, deleteTagSaga);
  yield takeLatest(editTagRequest.type, editTagSaga);
}
