import { Avatar, Box, IconButton, Tooltip } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Label from 'src/components/label/Label';
import { DataGridStatusColumnInterface } from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import {
  setCurrentSelectedStatus,
  setIsInventoryQuickEditPopoverOpen,
  setQuickEditAnchorPosition,
  setQuickEditSelectedListing,
  setSelectedQuickEditItemType,
} from 'src/store/dashboard/slices/inventorySlice';
import { InventoryQuickEditPopoverEnum } from '../../others/InventoryQuickEditPopover';

export default function DataGridStatusColumn({ status, rowData }: DataGridStatusColumnInterface) {
  status = status.toLowerCase();
  const color2 = status === 'listed' ? 'primary' : 'success';

  const { added, id, refs, title, image } = rowData;

  const listingData = useMemo(() => ({ id, refs, title, image }), [id, refs, title, image]);
  const dispatch = useDispatch();
  const [showEditIcon, setShowEditIcon] = useState(false);

  const handleEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setQuickEditAnchorPosition({ top: event.clientY, left: event.clientX }));
    dispatch(setCurrentSelectedStatus(status));
    dispatch(setQuickEditSelectedListing(listingData));
    dispatch(setSelectedQuickEditItemType(InventoryQuickEditPopoverEnum.STATUS));
    dispatch(setIsInventoryQuickEditPopoverOpen(true));
  };

  const renderEditIcon = () => (
    <Tooltip title="Edit Status">
      <Box
        sx={{
          width: '24px',
          height: '24px',
          padding: '0px',
          position: 'absolute',
          right: '0px',
          zIndex: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          backgroundColor: '#ECEDEF',
          ':hover': {
            backgroundColor: '#DFE1E6',
          },
        }}
      >
        <IconButton disableRipple sx={{}} onClick={handleEdit}>
          <Avatar src="/icons/editIcon.svg" sx={{ width: 16, height: 16 }} />
        </IconButton>
      </Box>
    </Tooltip>
  );

  return (
    <Box
      onMouseEnter={() => setShowEditIcon(true)}
      onMouseLeave={() => setShowEditIcon(false)}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        textAlign: 'center',
        padding: '4px',
        position: 'relative',
      }}
    >
      <Label color={color2} variant="soft" sx={{ textTransform: 'capitalize' }}>
        {status}
      </Label>
      {showEditIcon && renderEditIcon()}
      <style
        dangerouslySetInnerHTML={{
          __html: `
        .datagrid-cell-status {
          padding-right: 0 !important;
        }
      `,
        }}
      />
    </Box>
  );
}
