import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/dashboard/Auth/SignIn/LoginPage')));

export const SignUpPage = Loadable(lazy(() => import('../pages/dashboard/Auth/SignUp/SignUpPage')));

export const PageOne = Loadable(lazy(() => import('../pages/PageOne')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
