import React from 'react';
import { MenuItem, IconButton, Avatar } from '@mui/material';
import { IGroupData } from 'src/store/dashboard/slices/groupsAndTagsSlice';

interface GroupMenuItemProps {
  option: IGroupData;
  onSelect: (option: IGroupData) => void;
  onEditClick: (e: React.MouseEvent, option: IGroupData) => void;
  onClose: () => void;
}

const GroupMenuItem: React.FC<GroupMenuItemProps> = ({
  option,
  onSelect,
  onEditClick,
  onClose,
}) => (
  <MenuItem
    key={option.id}
    value={option.name}
    disableRipple
    onMouseDown={(e) => {
      e.stopPropagation();
      onSelect(option);
      onClose();
    }}
    sx={{
      p: 0,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&:hover': {
        backgroundColor: 'transparent',
        '.edit-icon-button': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    }}
  >
    {option.name}
    <IconButton
      className="edit-icon-button"
      sx={{ display: 'none', width: '24px', height: '24px' }}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onEditClick(e, option);
      }}
    >
      <Avatar src="/icons/editIcon.svg" sx={{ width: 16, height: 16 }} />
    </IconButton>
  </MenuItem>
);

export default GroupMenuItem;
