import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Link, Typography } from '@mui/material';
// routes

// components

// sections
import NewPasswordForm from './ResetPasswordForm';
// assets

import ResetRequestDialog from './ResetRequestDialog';

import { useDispatch } from 'react-redux';
import { resetAuthError } from 'src/store/dashboard/slices/authSlice';
import { PATH_AUTH } from 'src/routes/paths';
import Iconify from 'src/components/iconify';
import { SentIcon } from 'src/assets/icons';

// ----------------------------------------------------------------------

export default function ResetPasswordPage() {
  const dispatch = useDispatch();
  return (
    <>
      <Helmet>
        <title> New Password | PrimeLister</title>
      </Helmet>

      <SentIcon sx={{ mb: 5, height: 96 }} />

      <Typography variant="h3" paragraph>
        Reset Your Password
      </Typography>

      <Typography sx={{ color: 'text.secondary', mb: 5 }}>
        We've sent a 6-digit confirmation email to your email. Please enter the code in below box to
        verify your email.
      </Typography>

      <NewPasswordForm />

      <Link
        to={PATH_AUTH.login}
        component={RouterLink}
        color="inherit"
        variant="subtitle2"
        mt={3}
        sx={{
          mx: 'auto',
          alignItems: 'center',
          display: 'inline-flex',
        }}
        onClick={() => dispatch(resetAuthError())}
      >
        <Iconify icon="eva:chevron-left-fill" width={16} />
        Return to sign in
      </Link>
      <ResetRequestDialog />
    </>
  );
}
