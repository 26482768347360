import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { Automation } from '@beta.limited/primelister';
import CommunityShareSettings from './CommunityShare/CommunityShareSettings';
import FollowNewClosetsSettings from './FollowNewClosetsSettings';
import OfferToLikersSettings from './OfferToLikersSettings/OfferToLikersSettings';
import PartyShareSettings from './PartyShareSettings/PartyShareSettings';
import RelistSettings from './RelistSettings/RelistSettings';
import ReturnFollowSettings from './ReturnFollowSettings';
import ReturnShareSettings from './ReturnShareSettings/ReturnShareSettings';
import DailyShareSettings from './DailyShareSettings/DailyShareSettings';
import { useEffect } from 'react';
import { getFilterCategoriesRequest } from 'src/store/automations/slices/automationsSlice';

const DrawerSettings = () => {
  const { selectedAutomationOption } = useSelector((state: IRootState) => state.automations);
  const { activeClosetCredentialsId } = useSelector((state: IRootState) => state.myCloset);

  const dispatch = useDispatch();
  useEffect(() => {
    if (activeClosetCredentialsId) {
      dispatch(
        getFilterCategoriesRequest({
          department: 'all',
          credentialsId: activeClosetCredentialsId,
        })
      );
    }
  }, [activeClosetCredentialsId]);

  const renderAutomationSettingsBySelection = () => {
    switch (selectedAutomationOption.name) {
      case Automation.AUTO_FOLLOW_NEW_CLOSETS:
        return <FollowNewClosetsSettings />;
      case Automation.RETURN_SHARE:
        return <ReturnShareSettings />;
      case Automation.AUTO_CLOSET_SHARE:
        return <DailyShareSettings />;
      case Automation.RETURN_FOLLOW:
        return <ReturnFollowSettings />;
      case Automation.AUTO_COMMUNITY_SHARE:
        return <CommunityShareSettings />;
      case Automation.AUTO_RELIST:
        return <RelistSettings />;
      case Automation.AUTO_PARTY_SHARE:
        return <PartyShareSettings />;
      case Automation.AUTO_SEND_OFFER_TO_LIKERS:
        return <OfferToLikersSettings />;
      default:
        return null;
    }
  };

  return <Stack>{renderAutomationSettingsBySelection()}</Stack>;
};

export default DrawerSettings;
