import { useDispatch, useSelector } from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import {
  setDeletePopup,
  setDeletePopupData,
  setPopoverData,
  setLoadingEffect,
  tasksBulkDeleteRequest,
  setSelectedCheckboxes,
} from 'src/store/dashboard/slices/tasksSlice';
import { IRootState } from 'src/store';
import { getTasksFilterModelShaped } from '../../../helpers/getTasksFilterModelShaped';

export default function TaskDeleteDialog() {
  const dispatch = useDispatch();
  const {
    deletePopup,
    deletePopupData,
    deleteType,
    popoverData,
    page,
    rowsPerPage,
    sortModel,
    selectedTab,
    tasksFilterModel,
    selectedCheckboxes,
  } = useSelector((state: IRootState) => state.tasks);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={deletePopup}
      onClose={() => {
        dispatch(setDeletePopup(false));
        dispatch(setPopoverData(null));
        dispatch(setDeletePopupData(''));
      }}
    >
      <DialogTitle sx={{ pb: 2 }}>Delete</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>
        {deletePopupData} will be deleted from your task records. Are you sure you want to delete
        it?
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            dispatch(setDeletePopup(false));
            dispatch(setLoadingEffect(true));
            dispatch(
              tasksBulkDeleteRequest({
                deleteData:
                  deleteType === 1 ? selectedCheckboxes : [popoverData ? popoverData.id : ''],
                getData: {
                  page,
                  rowsPerPage,
                  orderBy: sortModel[0].field || 'createdAt',
                  order: sortModel[0].sort || 'desc',
                  filter: getTasksFilterModelShaped(tasksFilterModel),
                  selectedTab,
                },
              })
            );
            dispatch(setSelectedCheckboxes([]));
          }}
        >
          Delete
        </Button>

        <Button
          variant="outlined"
          color="inherit"
          onClick={() => {
            dispatch(setDeletePopup(false));
            dispatch(setPopoverData(null));
            setTimeout(() => {
              dispatch(setDeletePopupData(''));
            }, 500);
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
