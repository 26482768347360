import { Marketplace } from '@beta.limited/primelister';

export enum ServiceTypesEnum {
  SHOPIFY = 'shopify',
  EBAY = 'ebay',
  // any new service types go here
}
export interface IUserActionQueueJobListing {
  id: string;
  image: string;
  title: string;
}

export interface IUserActionQueueJobBase {
  action: string;
  marketplace: Marketplace;
  shop: string;
  listings: IUserActionQueueJobListing[];
  targetTime?: EpochTimeStamp;
  selectedCheckboxes?: string[];
  selectedImportCheckboxes?: string[];
  inProgressList?: string[];
}

export interface IUserImportJob extends IUserActionQueueJobBase {
  selectedImportCheckboxes: string[];
}
