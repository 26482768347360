import { Box } from '@mui/material';

import TasksDataGrid from './DataGrid/TasksDataGrid';

import ActionMenuPopover from './Others/ActionMenuPopover';
import TaskDeleteDialog from './dialogs/TaskDeleteDialog';
import TaskErrorDetailDialog from './dialogs/TaskErrorDetailDialog';

export default function TasksTable() {
  return (
    <Box sx={{ width: '100%' }}>
      <TasksDataGrid />
      <ActionMenuPopover />
      <TaskDeleteDialog />
      <TaskErrorDetailDialog />
    </Box>
  );
}
