import { Typography } from '@mui/material';
import { DataGridNameColumnInterface } from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';

export default function ImportNameColumn({ title }: DataGridNameColumnInterface) {
  return (
    <Typography variant="subtitle2" style={{ whiteSpace: 'pre-wrap' }}>
      {title}
    </Typography>
  );
}
