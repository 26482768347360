import moment from 'moment';

export function getDateOptionsForStatistics(timezone?: string) {
  const now = timezone ? moment().tz(timezone) : moment();

  const formatDate = (days: number) => now.clone().subtract(days, 'days').format('YYYY-MM-DD');

  return {
    today: now.format('YYYY-MM-DD'),
    tomorrow: formatDate(-1),
    yesterday: formatDate(1),
    sevenDaysAgo: formatDate(6),
    fourteenDaysAgo: formatDate(13),
    thirtyDaysAgo: formatDate(29),
  };
}
