import React, { useEffect, useId, useMemo, useState } from 'react';
import { Popover, SxProps, TextField, Theme, Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  QuickActionsGroupPopoverModeEnum,
  setGroupEditPopoverAnchorEl,
  setIsEditFormSaveChangesDisabled,
  setIsGroupEditPopoverOpen,
  setQuickActionsGroupPopoverMode,
  setSelectedGroupEditItem,
} from 'src/store/dashboard/slices/inventorySlice';
import { IRootState } from 'src/store';
import { LoadingButton } from '@mui/lab';
import {
  IGroupData,
  addNewGroupRequest,
  editGroupRequest,
  setGroupsDialogData,
  setGroupsOpenDeleteDialog,
} from 'src/store/dashboard/slices/groupsAndTagsSlice';
import { getFilterModelShaped } from 'src/pages/dashboard/Inventory/helpers/getFilterModelShaped';

interface GroupEditPopoverProps {
  handleGroupSelect: (selectedGroup: IGroupData | null) => void;
  selectedGroupToAdd: string;
  isFromEditPage?: boolean;
  onPopoverOpen?: () => void;
  onPopoverClose?: () => void;
  setSelectedGroupValue: (value: IGroupData | null) => void;
}

export default function GroupEditPopover({
  handleGroupSelect,
  selectedGroupToAdd,
  isFromEditPage,
  onPopoverOpen,
  onPopoverClose,
  setSelectedGroupValue,
}: GroupEditPopoverProps) {
  const {
    isGroupEditPopoverOpen,
    quickActionsGroupPopoverMode,
    groupEditPopoverAnchorEl,
    selectedGroupEditItem,
    rowsPerPage,
    page,
    searchValue,
    inventoryFilterModel,
    sortModel,
  } = useSelector((state: IRootState) => state.inventory);
  const { loading, lastAddedGroup } = useSelector((state: IRootState) => state.groupsAndTags);

  const isInCreateMode = useMemo(
    () => quickActionsGroupPopoverMode === QuickActionsGroupPopoverModeEnum.CREATE,
    [quickActionsGroupPopoverMode]
  );

  const isInEditMode = useMemo(
    () => quickActionsGroupPopoverMode === QuickActionsGroupPopoverModeEnum.EDIT,
    [quickActionsGroupPopoverMode]
  );

  useEffect(() => {
    if (isGroupEditPopoverOpen) {
      onPopoverOpen?.();
    } else {
      onPopoverClose?.();
    }
  }, [isGroupEditPopoverOpen, onPopoverOpen, onPopoverClose]);

  const popoverSx: SxProps<Theme> = {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    '& .MuiPaper-root': {
      padding: '16px 8px',
      width: '100%',
      maxWidth: !isFromEditPage ? '310px' : '318px',
      height: 'auto',
      borderRadius: '6px',
      backgroundColor: 'white',
      boxShadow:
        '0px 0px 2px 0px rgba(145, 158, 171, 0.20), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);',
    },
  };

  const showDeleteButton = quickActionsGroupPopoverMode === QuickActionsGroupPopoverModeEnum.EDIT;

  const [groupName, setGroupName] = useState(selectedGroupEditItem?.name || '');
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  useEffect(() => {
    setGroupName(selectedGroupEditItem?.name || '');
  }, [selectedGroupEditItem]);

  useEffect(() => {
    if (quickActionsGroupPopoverMode === QuickActionsGroupPopoverModeEnum.EDIT) {
      setIsSaveDisabled(groupName === selectedGroupEditItem?.name);
    } else if (quickActionsGroupPopoverMode === QuickActionsGroupPopoverModeEnum.CREATE) {
      setIsSaveDisabled(groupName === '');
    }
  }, [groupName, selectedGroupEditItem, quickActionsGroupPopoverMode]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(event.target.value);
  };

  const dispatch = useDispatch();
  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(setIsGroupEditPopoverOpen(false));
    dispatch(setGroupEditPopoverAnchorEl(null));
    dispatch(setSelectedGroupEditItem(null));
    dispatch(setQuickActionsGroupPopoverMode(null));
  };

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(setGroupsDialogData(selectedGroupEditItem!));
    dispatch(setGroupsOpenDeleteDialog(true));
  };

  useEffect(() => {
    if (lastAddedGroup) {
      handleGroupSelect(lastAddedGroup);
      setSelectedGroupValue(lastAddedGroup);
    }
  }, [lastAddedGroup]);

  const handleSave = async (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!selectedGroupEditItem) return;

    switch (quickActionsGroupPopoverMode) {
      case QuickActionsGroupPopoverModeEnum.CREATE:
        const newGroup = {
          name: groupName.trim(),
        };

        // We use this only to update the required states
        dispatch(
          addNewGroupRequest({
            groupData: {
              ...newGroup,
            },
            getData: {
              page,
              rowsPerPage,
              orderBy: sortModel[0]?.field,
              order: sortModel[0]?.sort,
              filter: getFilterModelShaped(inventoryFilterModel),
              searchValue,
            },
          })
        );

        break;
      case QuickActionsGroupPopoverModeEnum.EDIT:
        const groupData: IGroupData = {
          id: selectedGroupEditItem.id,
          name: groupName.trim(),
        };
        dispatch(
          editGroupRequest({
            groupData,
            handleGroupSelect,
            getData: {
              page,
              rowsPerPage,
              orderBy: sortModel[0]?.field,
              order: sortModel[0]?.sort,
              filter: getFilterModelShaped(inventoryFilterModel),
              searchValue,
            },
          })
        );
        handleGroupSelect(groupData);
        break;
      default:
        break;
    }
  };
  const placeholderText = isInEditMode ? 'Name' : 'Create new group';

  return (
    <Popover
      id="group-edit-popover"
      sx={popoverSx}
      anchorEl={groupEditPopoverAnchorEl}
      open={isGroupEditPopoverOpen}
      anchorReference="anchorEl"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClick={(event) => event.stopPropagation()}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      slotProps={{
        paper: {
          sx: {
            left:
              isInCreateMode || isInEditMode
                ? groupEditPopoverAnchorEl.getBoundingClientRect().left - 4 + 'px !important'
                : 'auto',
          },
        },
      }}
    >
      <TextField
        label={placeholderText}
        fullWidth
        size="small"
        onChange={handleInputChange}
        defaultValue={selectedGroupEditItem?.name}
        placeholder={placeholderText}
        sx={{
          '& .MuiInputLabel-root:not(.Mui-focused)': {
            color: '#919EAB !important',
            fontSize: '16px !important',
            fontWeight: 400,
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#919EAB !important',
          },
          fontSize: '14px !important',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: 2,
        }}
      >
        {showDeleteButton && (
          <Box>
            <LoadingButton loading={loading} variant="text" color="error" onClick={handleDelete}>
              Delete
            </LoadingButton>
          </Box>
        )}

        <Box
          sx={{
            ml: 'auto',
          }}
        >
          <Button
            sx={{
              mr: 1,
            }}
            variant="outlined"
            color="inherit"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={isSaveDisabled}
          >
            {isInEditMode ? 'Save' : 'Create Group'}
          </LoadingButton>
        </Box>
      </Box>
    </Popover>
  );
}
