import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  TextField,
  Chip,
  Autocomplete,
} from '@mui/material';

import {
  inventoryAddToTagRequest,
  setOpenInventoryAddTagDialog,
  setSelectedTagsToAdd,
} from 'src/store/dashboard/slices/inventorySlice';
import { IRootState } from 'src/store';
import { useMemo } from 'react';
import { getFilterModelShaped } from 'src/pages/dashboard/Inventory/helpers/getFilterModelShaped';
import { LoadingButton } from '@mui/lab';
import { useTagUtilities } from 'src/pages/dashboard/Inventory/helpers/useTagUtilities';

export default function InventoryAddTagsDialog() {
  const dispatch = useDispatch();

  const {
    openInventoryAddTagDialog,
    loading,
    page,
    rowsPerPage,
    sortModel,
    inventoryFilterModel,
    searchValue,
    selectedTagsToAdd,
    bulkActionsListingList,
  } = useSelector((state: IRootState) => state.inventory);

  const handleClose = () => {
    dispatch(setOpenInventoryAddTagDialog(false));
    dispatch(setSelectedTagsToAdd([]));
  };

  const { getTagColor, handleOnChange, renderTagMenuItem, tagsOptions } = useTagUtilities();

  const bulkItems = bulkActionsListingList.map((listing) => listing._id);

  const handleAddTag = () => {
    const orderBy = sortModel.length > 0 ? sortModel[0].field : 'added';
    const order = sortModel.length > 0 ? sortModel[0].sort : 'desc';
    dispatch(
      inventoryAddToTagRequest({
        tagIds: selectedTagsToAdd,
        items: bulkItems,
        getData: {
          page,
          rowsPerPage,
          orderBy,
          order,
          filter: getFilterModelShaped(inventoryFilterModel),
          searchValue,
        },
      })
    );
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={openInventoryAddTagDialog} onClose={handleClose}>
      <DialogTitle>Add Tag to Listings</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>
        <Stack spacing={2}>
          <Typography variant="body2">
            Please select a tag to add to the selected listings.
          </Typography>

          <Autocomplete
            fullWidth
            multiple
            onChange={handleOnChange}
            options={tagsOptions}
            sx={{
              mt: '24px !important',
            }}
            getOptionLabel={(option) => option.name!}
            renderInput={(params) => (
              <TextField {...params} label="Select tags" placeholder="Select tags" />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option._id}>
                {renderTagMenuItem(option)}
              </li>
            )}
            renderTags={(selected, getTagProps) =>
              selected.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option._id}
                  label={option.name}
                  size="small"
                  color={getTagColor(option.name!) || 'inherit'}
                  variant="soft"
                  sx={{ fontWeight: 700, fontSize: '12px !important', borderRadius: '8px' }}
                />
              ))
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} variant="text" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          onClick={handleAddTag}
          disabled={!selectedTagsToAdd.length}
        >
          Add Tag
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
