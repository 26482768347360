import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { ProductCardProps, ProductCardSelectionEnum } from './types';
import { StyledCardMobile } from './styles';
import ProductImageMobile from './ProductImageMobile';
import ProductInfoMobile from './ProductInfoMobile';
import { Box } from '@mui/system';
import StatusLabel from './StatusLabel';
import ConditionChip from './ConditionChip';
import ShippingChip from './ShippingChip';

const ProductCardMobile: React.FC<ProductCardProps> = ({
  thumbnailUrl,
  title,
  size,
  brand,
  price,
  originalPrice,
  likeCount,
  commentCount,
  statusTagVisibility,
  conditionChipVisibility,
  shippingChipVisibility,
  originalPriceVisibility,
  likeCountVisibility,
  commentCountVisibility,
  shippingType,
  statusLabel,
  conditionChip,
  onCardClick,
  droppingSoonDate,
  isSelected,
  cardWidth,
  optionalPropsVisibility = true,
  ...rest
}) => {
  const selection = isSelected ? ProductCardSelectionEnum.ALL : ProductCardSelectionEnum.DEFAULT;

  const handleOnClick = () => {
    onCardClick && onCardClick();
  };

  const theme = useTheme();
  return (
    <StyledCardMobile
      {...rest}
      onClick={handleOnClick}
      selection={selection}
      optionalPropsVisibility={optionalPropsVisibility}
      theme={theme}
      isSelected={isSelected}
    >
      <Box
        sx={{
          display: 'flex',
          [theme.breakpoints.down(321)]: {
            flexDirection: 'column',
            alignItems: 'flex-end',
          },
          flexDirection: cardWidth && cardWidth < 321 ? 'column' : 'row',
          alignItems: cardWidth && cardWidth < 321 ? 'flex-end' : 'center',
        }}
      >
        <ProductImageMobile thumbnailUrl={thumbnailUrl} />
        <ProductInfoMobile
          brand={brand}
          originalPrice={originalPrice}
          originalPriceVisibility={originalPriceVisibility}
          price={price}
          shippingChipVisibility={shippingChipVisibility}
          shippingType={shippingType}
          size={size}
          title={title}
          commentCount={commentCount}
          commentCountVisibility={commentCountVisibility}
          likeCount={likeCount}
          likeCountVisibility={likeCountVisibility}
          conditionChip={conditionChip}
          conditionChipVisibility={conditionChipVisibility}
          selection={selection}
          thumbnailUrl={thumbnailUrl}
          statusLabel={statusLabel}
          statusTagVisibility={statusTagVisibility}
          droppingSoonDate={droppingSoonDate}
          productCardSelection={selection}
          cardWidth={cardWidth}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          flexWrap: 'wrap',
          alignSelf: 'flex-start',
          paddingLeft: cardWidth && cardWidth < 321 ? '0' : '60px',
          [theme.breakpoints.down(321)]: {
            paddingLeft: '0',
          },
        }}
      >
        {optionalPropsVisibility && (
          <>
            {statusTagVisibility && (
              <StatusLabel statusLabel={statusLabel} droppingSoonDate={droppingSoonDate} />
            )}
            {conditionChipVisibility && <ConditionChip conditionChip={conditionChip} />}
            {shippingChipVisibility && <ShippingChip shippingType={shippingType} />}
          </>
        )}
      </Box>
    </StyledCardMobile>
  );
};

export default ProductCardMobile;
