import { CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';

const HideImportedLoading: React.FC = () => (
  <Stack
    sx={{
      display: 'flex',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999999999999999,
    }}
  >
    <CircularProgress />
    <Typography
      sx={{
        mt: 4,
        zIndex: 9999999,
        opacity: 1,
        textAlign: 'center'
      }}
    >
      Hang tight, it's worth the wait. Scanning all your listings now to find unimported ones...
    </Typography>
  </Stack>
);

export default HideImportedLoading;
