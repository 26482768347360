import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  SxProps,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useResponsive from 'src/hooks/useResponsive';
import { IRootState } from 'src/store';
import {
  setIsCreateNewTaskDialogOpen,
  setIsListingBulkActionsMenuOpen,
  setSelectedListings,
} from 'src/store/automations/slices/myListingsSlice';

type DeviceStyles = {
  mobile: SxProps;
  desktop: SxProps;
};

export type StylesByDevice = Record<string, DeviceStyles>;

export default function ListingsQuickActionsMenu() {
  const { selectedListings, myListings } = useSelector((state: IRootState) => state.myListings);
  const shouldOpenBulkActionsMenu = selectedListings.size > 0;
  const dispatch = useDispatch();
  const handleOnClose = () => {
    dispatch(setSelectedListings(new Set()));
    dispatch(setIsListingBulkActionsMenuOpen(false));
  };

  const handleSelectAllListings = () => {
    dispatch(setSelectedListings(new Set(myListings.map((listing) => listing.id))));
  };

  const isAllListingsSelected = myListings.length === selectedListings.size;
  const handleCreateNewTask = () => {
    dispatch(setIsCreateNewTaskDialogOpen(true));
  };

  const isMobileWeb = useResponsive('down', 'sm');
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const isMobile = isMobileWeb || isPrimeListerMobileApp;
  const selectedTextMatches = useMediaQuery('(max-width:340px)');

  const stylesByDevice: StylesByDevice = {
    rootStyles: {
      mobile: {
        height: '52px',
        bgcolor: '#FFF',
        border: 'none',
        borderTop: '1px solid rgba(145, 158, 171, 0.16)',
        borderRadius: '0',
        minWidth: '100%',
        maxWidth: '100%',
        p: '8px 16px 8px 16px',
        bottom: '56px',
        justifyContent: 'space-between',
      },
      desktop: {
        height: '64px',
        bgcolor: 'rgba(244, 246, 248, 0.95)',
        border: '1px solid #C4CDD5',
        borderTop: 'none',
        borderRadius: '8px',
        minWidth: isAllListingsSelected ? '541px' : '694px',
        maxWidth: isAllListingsSelected ? '561px' : '752px',
        p: '8px 24px 8px 24px',
        bottom: '24px',
        justifyContent: 'center',
      },
    },
    selectedListingTextStyles: {
      mobile: {
        fontSize: '12px',
        mr: '4px',
        fontWeight: '700',
      },
      desktop: {
        fontSize: '15px',
        mr: '8px',
        fontWeight: '600',
      },
    },
    listingsTextStyles: {
      mobile: {
        color: '#637381',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
      },
      desktop: {
        color: '#212B36',
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '26px',
      },
    },
    buttonStyles: {
      mobile: {
        minWidth: '64px',
        height: '36px',
      },
      desktop: {
        minWidth: '191px',
        height: '48px',
      },
    },
    buttonIconStyles: {
      mobile: {
        width: '20px',
        height: '20px',
      },
      desktop: {
        width: '24px',
        height: '24px',
      },
    },
    buttonTextStyles: {
      mobile: {
        fontSize: '14px',
        lineHeight: '24px',
      },
      desktop: {
        fontSize: '15px',
        lineHeight: '26px',
      },
    },
  };

  const showDesktopBulkActions = !isMobile && myListings.length !== selectedListings.size;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        spacing={3}
        sx={{
          boxShadow: '3px 5px 8px 0px rgba(22, 28, 36, 0.21)',
          backdropFilter: 'blur(1.5px)',
          zIndex: 19,
          position: 'fixed',
          transform: 'scale(0)',
          display: 'flex',
          width: '100%',
          ...(isMobile ? stylesByDevice.rootStyles.mobile : stylesByDevice.rootStyles.desktop),
          '@keyframes zoomOut': {
            '0%': {
              marginTop: '0rem',
            },
            '100%': {
              marginTop: '50rem',
              transform: 'scale(0)',
            },
          },
          '@keyframes zoomIn': {
            '0%': {
              transform: 'scale(0)',
              marginTop: '50rem',
            },
            '100%': {
              transform: 'scale(1)',
              marginTop: '0rem',
            },
          },
          animation: shouldOpenBulkActionsMenu
            ? 'zoomIn 0.25s ease-out forwards'
            : 'zoomOut 0.65s ease-in forwards',
        }}
      >
        {!isMobile && (
          <>
            <IconButton
              onClick={handleOnClose}
              sx={{ cursor: 'pointer', borderRadius: '4px', p: '6px', bgcolor: '#DFE3E8' }}
            >
              <Avatar
                src="/icons/closeIcon.svg"
                sx={{
                  width: '18px',
                  height: '18px',
                  borderRadius: '0!important',
                }}
              />
            </IconButton>

            <Divider
              orientation="vertical"
              sx={{
                width: '1px !important',
                height: '48px !important',
              }}
            />
          </>
        )}

        <Stack direction="row" gap="24px">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexShrink: 0,
            }}
          >
            <Typography
              sx={{
                padding: '9.5px 6px',
                minWidth: '25px',
                height: '25px',
                textAlign: 'center',
                color: '#fff',
                bgcolor: '#3564CA',
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...(isMobile
                  ? stylesByDevice.selectedListingTextStyles.mobile
                  : stylesByDevice.selectedListingTextStyles.desktop),
              }}
            >
              {selectedListings.size}
            </Typography>
            <Typography
              sx={{
                fontFamily: '"Public Sans"',
                fontStyle: 'normal',
                ...(isMobile
                  ? stylesByDevice.listingsTextStyles.mobile
                  : stylesByDevice.listingsTextStyles.desktop),
              }}
            >
              {!selectedTextMatches && 'listings'} selected
            </Typography>
          </Box>

          {showDesktopBulkActions && (
            <>
              <Button
                color="primary"
                variant="text"
                sx={{
                  color: '#3564CA',
                  lineHeight: '26px',
                  fontWeight: '600',
                  fontSize: '15px',
                  flexShrink: 0,
                }}
                onClick={handleSelectAllListings}
              >
                Select all {myListings.length} listings
              </Button>

              <Divider
                orientation="vertical"
                sx={{
                  width: '1px !important',
                  height: '48px !important',
                }}
              />
            </>
          )}
        </Stack>

        <Button
          variant="contained"
          sx={{
            display: 'flex',
            alignItems: 'center',
            background: '#36F',
            ...(isMobile
              ? stylesByDevice.buttonStyles.mobile
              : stylesByDevice.buttonStyles.desktop),
          }}
          onClick={handleCreateNewTask}
        >
          <Box>
            <Avatar
              variant="square"
              sx={{
                mr: '8px',
                ...(isMobile
                  ? stylesByDevice.buttonIconStyles.mobile
                  : stylesByDevice.buttonIconStyles.desktop),
              }}
              src={'/icons/createIcon.svg'}
            />
          </Box>
          <Typography
            sx={{
              fontWeight: '700',
              ...(isMobile
                ? stylesByDevice.buttonTextStyles.mobile
                : stylesByDevice.buttonTextStyles.desktop),
            }}
          >
            Create new task
          </Typography>
        </Button>
      </Stack>
    </Box>
  );
}
