import { StyledChip } from './styles';
import { ProductCardProps } from './types';

const ConditionChip = ({ conditionChip }: { conditionChip: ProductCardProps['conditionChip'] }) => (
  <StyledChip
    variant="filled"
    label={conditionChip}
    sx={{
      '& .MuiChip-label': {
        paddingLeft: 0,
        paddingRight: 0,
        padding: '3px 8px',
        fontSize: '13px',
        mt: '1px',
      },
    }}
  />
);

export default ConditionChip;
