import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
} from '@mui/material';
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';

import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getTasksFilterModelShaped } from 'src/pages/dashboard/Tasks/helpers/getTasksFilterModelShaped';
import { IRootState } from 'src/store';
import {
  setDense,
  setLoadingEffect,
  setPage,
  setRowsPerPage,
  tasksGetRequest,
} from 'src/store/dashboard/slices/tasksSlice';

export default function TaskPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const {
    page: reduxPage,
    rowsPerPage,
    sortModel,
    dense,
    selectedTab,
    tasksFilterModel,
    taskCount,
  } = useSelector((state: IRootState) => state.tasks);

  const onChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDense());
  };

  // Handles the rows per page change and fetchs new data from the API.
  const handleRowsPerPageChange = (event: SelectChangeEvent) => {
    dispatch(setPage(0));
    dispatch(setLoadingEffect(true));

    const orderBy = sortModel.length > 0 ? sortModel[0].field : 'createdDate';
    const order = sortModel.length > 0 ? sortModel[0].sort : 'desc';
    searchParams.set('rowsPerPage', event.target.value);
    searchParams.set('page', '1');
    setSearchParams(searchParams);
    dispatch(
      tasksGetRequest({
        page: 0,
        rowsPerPage: Number(event.target.value),
        orderBy,
        order: order!,
        filter: getTasksFilterModelShaped(tasksFilterModel),
        selectedTab,
      })
    );
  };

  const onRowsPerPageChange = (event: SelectChangeEvent) => {
    dispatch(setRowsPerPage(Number(event.target.value)));
    handleRowsPerPageChange(event);
  };

  const itemStart = rowsPerPage * reduxPage + 1;
  const itemEnd = Math.min(rowsPerPage * (reduxPage + 1), taskCount);
  const itemCountInfo = taskCount === 0 ? '0 of 0' : `${itemStart} - ${itemEnd} of ${taskCount}`;

  return (
    <Box
      sx={{
        width: 1,
      }}
    >
      <Pagination
        shape="rounded"
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
        sx={{
          float: 'right',
          pr: 2,
          py: 1.5,
          top: 0,
        }}
      />
      <Typography
        sx={{
          lineHeight: 3,
          fontSize: '0.875rem',
          fontFamily: 'Public Sans, sans-serif',
          fontWeight: 400,
          flexShrink: 0,
          float: 'right',
          alignSelf: 'center',
          m: 1,
          ml: 2,
        }}
      >
        {itemCountInfo}
      </Typography>
      <FormControl sx={{ m: 1, minWidth: 120, float: 'right' }} size="small">
        <InputLabel>Rows per page</InputLabel>
        <Select value={rowsPerPage.toString()} label="Rows Per Page" onChange={onRowsPerPageChange}>
          <MenuItem value="25">25</MenuItem>
          <MenuItem value="50">50</MenuItem>
          <MenuItem value="100">100</MenuItem>
          <MenuItem value="250">250</MenuItem>
          <MenuItem value="500">500</MenuItem>
        </Select>
      </FormControl>

      <FormControlLabel
        control={<Switch color="primary" checked={dense} onChange={onChangeDense} />}
        label="Condense Mode"
        labelPlacement="end"
        sx={{
          float: 'left',
          pl: 2,
          py: 1.5,
          top: 0,
        }}
      />
    </Box>
  );
}
