import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import HelpMenuPopover from 'src/layouts/LayoutComponents/MenuDropDown';
import { SxProps } from '@mui/material';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import useResponsive from 'src/hooks/useResponsive';

interface AutomationsPageTitleProps {
  title: string;
  sxProps?: SxProps;
}

const AutomationsPageTitle: FC<AutomationsPageTitleProps> = ({ title, sxProps }) => {
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const isMobileWeb = useResponsive('down', 'sm');
  const isMobile = isMobileWeb || isPrimeListerMobileApp;

  return (
    <Typography
      variant="h4"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: !isMobile ? '1.25rem' : '18px',
        ...sxProps,
      }}
    >
      {title}
    </Typography>
  );
};

export default AutomationsPageTitle;
