import { Stack, Button, Avatar, Typography } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useCheckSubscriptionStatus } from 'src/hooks/useCheckSubscriptionStatus';
import { IRootState } from 'src/store';

import {
  setIsConnectDialogOpen,
  setIsUpgradeSubscriptionDialogOpen,
  setShouldOpenExpiredSubscriptionDialog,
} from 'src/store/automations/slices/myClosetSlice';

const MainConnectButton = () => {
  const dispatch = useDispatch();
  const { hasCloset, connectedShopsCount } = useSelector((state: IRootState) => state.myCloset);
  const { poshmarkShopCapacity } = useSelector((state: IRootState) => state.account);

  const { isSubscriptionExpired } = useCheckSubscriptionStatus();

  const handleClosetConnect = () => {
    window.$crisp.push(['do', 'chat:close']);
    if (!isSubscriptionExpired) {
      if (connectedShopsCount < poshmarkShopCapacity) {
        dispatch(setIsConnectDialogOpen(true));
      } else {
        dispatch(setIsUpgradeSubscriptionDialogOpen(true));
      }
    } else {
      dispatch(setShouldOpenExpiredSubscriptionDialog(true));
    }
  };

  const buttonText = hasCloset ? 'Connect Additional Closets' : 'Connect My Closet';

  return (
    <Stack direction="row" spacing={1}>
      <Button
        color="primary"
        variant="contained"
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: '4px 16px',
          borderRadius: '8px',
        }}
        onClick={handleClosetConnect}
      >
        <Avatar
          src={`/icons/myClosetIcons/connectIcon.svg`}
          sx={{ fontSize: '1rem', maxWidth: '20px', maxHeight: '20px', mr: 1 }}
        />
        <Typography
          sx={{
            fontSize: '14px !important',
            lineHeight: '24px',
            fontWeight: 700,
          }}
        >
          {buttonText}
        </Typography>
      </Button>
    </Stack>
  );
};

export default MainConnectButton;
