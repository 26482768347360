import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useParams } from 'react-router-dom';

import {
  inventoryEditSelectedItemRequest,
  setIsInventoryLinkDeleteDialogOpen,
} from 'src/store/dashboard/slices/inventorySlice';
import { IRootState } from 'src/store';

const InventoryLinkDeleteDialog = () => {
  const dispatch = useDispatch();
  const { linkDeleteDialogData } = useSelector((state: IRootState) => state.inventory);
  const { id } = useParams();
  const platformData: { [key: string]: {} } = {};
  platformData[linkDeleteDialogData.name] = {};
  const updatedRefs = { refs: platformData };

  const { isInventoryLinkDeleteDialogOpen } = useSelector((state: IRootState) => state.inventory);

  const handleClose = () => dispatch(setIsInventoryLinkDeleteDialogOpen(false));

  const linkRemoveMessage = `${linkDeleteDialogData.displayName} link will be removed from your listings. Are you sure you want to remove it?`;

  const handleRemove = () => {
    dispatch(
      inventoryEditSelectedItemRequest({ id: id, editData: updatedRefs, editType: 'removeLink' })
    );
    handleClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={isInventoryLinkDeleteDialogOpen} onClose={handleClose}>
      <DialogTitle sx={{ pb: 2 }}>Remove Listing Link</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>{linkRemoveMessage}</DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={handleRemove}>
          Remove
        </Button>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default InventoryLinkDeleteDialog;
