import { ErrorOption } from 'react-hook-form';
import { NavigateFunction } from 'react-router';

export interface IResetPasswordPayload {
  email: string;
}
export interface IResetPasswordRequest {
  payload: IResetPasswordPayload;
  navigate: NavigateFunction;
}

export interface INewPasswordRequestPayload {
  email: string;
  code: string;
  password: string;
}
export interface INewPasswordRequest {
  payload: INewPasswordRequestPayload;
  navigate: NavigateFunction;
}
export interface ILoginRequest {
  loginData: {
    email: string;
    password: string;
  };
  isFromVerifyDialog?: boolean;
  setError?: (
    name: 'email' | 'password' | 'afterSubmit',
    error: ErrorOption,
    options?:
      | {
          shouldFocus: boolean;
        }
      | undefined
  ) => void;
  reset?: () => void;
}
export interface ISignUpData {
  email: string;
  password: string;
  name: string;
  referrerCode?: string;
  timeZone?: { name: string };
  geoData?: string;
  appInstalled?: boolean;
  isTrusted?: boolean;
  referrerAttributes?: {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
  };
}
export interface ISignUpRequest {
  signUpData: ISignUpData;
  setError: (
    name: 'email' | 'password' | 'afterSubmit',
    error: ErrorOption,
    options?:
      | {
          shouldFocus: boolean;
        }
      | undefined
  ) => void;
  reset: () => void;
}

export enum AccountRequestErrorCodeEnum {
  UNAUTHORIZED = 'unauthorized',
}
