import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { ConnectionStatus } from 'src/store/automations/slices/myClosetSlice';
import { SubscriptionStatus } from 'src/store/dashboard/slices/accountSlice';

export const useCheckSubscriptionStatus = () => {
  const { billingSubscriptionList } = useSelector((state: IRootState) => state.account);
  const { activeCloset, hasCloset } = useSelector((state: IRootState) => state.myCloset);

  const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(false);
  const [shouldUpgradeSubscriptionDialogOpen, setShouldUpgradeSubscriptionDialogOpen] =
    useState(false);
  const [shouldConnectionErrorDialogOpen, setShouldConnectionErrorDialogOpen] = useState(false);

  useEffect(() => {
    const isExpired =
      billingSubscriptionList?.[1]?.status?.toLowerCase() === SubscriptionStatus.EXPIRED ||
      billingSubscriptionList?.[1]?.status?.toLowerCase() === SubscriptionStatus.PAST_DUE;
    setIsSubscriptionExpired(isExpired);
    setShouldUpgradeSubscriptionDialogOpen(isExpired);
  }, [billingSubscriptionList?.[1]?.status]);

  useEffect(() => {
    const isActiveClosetDisconnected = activeCloset?.status === ConnectionStatus.Disconnected;
    const shouldShowConnectionError =
      !isSubscriptionExpired && (!hasCloset || isActiveClosetDisconnected);
    setShouldConnectionErrorDialogOpen(shouldShowConnectionError);
  }, [isSubscriptionExpired, activeCloset?.status, hasCloset]);

  return {
    isSubscriptionExpired,
    shouldUpgradeSubscriptionDialogOpen,
    shouldConnectionErrorDialogOpen,
  };
};
