import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { setIsLastLinkRemoveErrorDialogOpen } from 'src/store/dashboard/slices/inventorySlice';
import { IRootState } from 'src/store';

const LastLinkRemoveErrorDialog = () => {
  const dispatch = useDispatch();
  const { linkDeleteDialogData } = useSelector((state: IRootState) => state.inventory);

  const platformData: { [key: string]: {} } = {};
  platformData[linkDeleteDialogData.name] = {};

  const { isLastLinkRemoveErrorDialogOpen } = useSelector((state: IRootState) => state.inventory);

  const handleClose = () => dispatch(setIsLastLinkRemoveErrorDialogOpen(false));

  const linkRemoveMessage = `The item should have at least one listing link. If you want to delete the item from your inventory, simply click the 'Delete Item' button located at the top right corner of the page.`;

  return (
    <Dialog fullWidth maxWidth="xs" open={isLastLinkRemoveErrorDialogOpen} onClose={handleClose}>
      <DialogTitle sx={{ pb: 2 }}>Action Restricted</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>{linkRemoveMessage}</DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default LastLinkRemoveErrorDialog;
