import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';

import Iconify from 'src/components/iconify';
import { Container, Tab, Tabs, Box, Typography, Tooltip, useMediaQuery } from '@mui/material';

import { useSettingsContext } from 'src/components/settings';

import { IRootState } from 'src/store';
import { setCurrentTab } from 'src/store/dashboard/slices/accountSlice';

import {
  AccountBilling,
  AccountChangePassword,
  AccountGeneral,
  AccountNotifications,
  AccountAffiliate,
} from './components';

import { useLocation } from 'react-router-dom';
import { SyntheticEvent, useEffect } from 'react';
import HelpButton from 'src/layouts/LayoutComponents/HelpButton';
import { showCrispChat } from 'src/utils/crispChatHelpers';
import AutomationsPageTitle from 'src/layouts/automations/header/AutomationsPageTitle';
import useResponsive from 'src/hooks/useResponsive';

const AccountPage = () => {
  const dispatch = useDispatch();
  const { currentTab } = useSelector((state: IRootState) => state.account);
  const { themeStretch } = useSettingsContext();
  const { isPrimeListerMobileApp, isAppReviewVersion } = useSelector(
    (state: IRootState) => state.home
  );

  const location = useLocation();
  const titleMatches = useMediaQuery('(max-width:380px)');
  const isMobileWeb = useResponsive('down', 'sm');
  const isMobile = isMobileWeb || isPrimeListerMobileApp;
  // Update the current tab when the URL hash changes
  useEffect(() => {
    const hashValue = location.hash.substring(1); // remove the # from the hash value
    const matchedTab = TABS.find((tab) => tab.value === hashValue);
    if (matchedTab) {
      dispatch(setCurrentTab(matchedTab.value));
    }
  }, [location.hash]);

  useEffect(() => {
    window.history.replaceState(null, '', `#${currentTab}`);
  }, [currentTab]);

  const TABS = [
    {
      value: 'general',
      label: 'General',
      icon: <Iconify icon="ic:round-account-box" />,
      component: <AccountGeneral />,
      tooltipContent: 'Manage Your Account Details',
    },
    ...(!isAppReviewVersion || !isPrimeListerMobileApp
      ? [
          {
            value: 'billing',
            label: 'Plans & Billing',
            icon: <Iconify icon="ic:round-receipt" />,
            component: <AccountBilling />,
            tooltipContent: 'Manage Your Subscriptions and Billing',
          },
        ]
      : []),
    ...(!isPrimeListerMobileApp
      ? [
          {
            value: 'notification',
            label: 'Notifications',
            icon: <Iconify icon="eva:bell-fill" />,
            component: <AccountNotifications />,
            tooltipContent: 'Manage Your Notifications',
          },
          {
            value: 'change-password',
            label: 'Change password',
            icon: <Iconify icon="ic:round-vpn-key" />,
            component: <AccountChangePassword />,
            tooltipContent: 'Change Your Password',
          },
          {
            value: 'affiliate',
            label: 'Affiliate Program',
            icon: <Iconify icon="ri:money-dollar-circle-fill" />,
            component: <AccountAffiliate />,
            tooltipContent: 'Invite Your Friends & Earn Money',
          },
        ]
      : []),
  ];

  const handleTabChange = (event: SyntheticEvent<Element, Event>, newValue: string) => {
    dispatch(setCurrentTab(newValue));
  };

  return (
    <>
      <Helmet>
        <title> User: Account Settings | PrimeLister </title>
      </Helmet>
      <Container maxWidth={themeStretch ? false : 'lg'} disableGutters={true}>
        {((isMobile && titleMatches) || !isMobile) && (
          <AutomationsPageTitle
            title="Account"
            sxProps={{
              mb: !isMobile ? '2.5rem' : '24px',
            }}
          />
        )}

        <Tabs value={currentTab} onChange={handleTabChange}>
          {TABS.map((tab, index) => (
            <Tab
              key={tab.value}
              label={
                <Tooltip key={index} title={tab.tooltipContent} arrow placement="top">
                  <span>{tab.label}</span>
                </Tooltip>
              }
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>
        {TABS.map(
          (tab) =>
            tab.value === currentTab && (
              <Box key={tab.value} sx={{ mt: 5 }}>
                {tab.component}
              </Box>
            )
        )}
      </Container>
    </>
  );
};

export default AccountPage;
