import {
  Autocomplete,
  AutocompleteRenderOptionState,
  Avatar,
  Box,
  FilterOptionsState,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from 'src/components/iconify';
import { IRootState } from 'src/store';
import { IGroupData } from 'src/store/dashboard/slices/groupsAndTagsSlice';
import {
  QuickActionsGroupPopoverModeEnum,
  setGroupEditPopoverAnchorEl,
  setIsGroupEditPopoverOpen,
  setQuickActionsGroupPopoverMode,
  setSelectedGroupEditItem,
  setSelectedGroupToAdd,
} from 'src/store/dashboard/slices/inventorySlice';
import GroupEditPopover from './GroupEditPopover';
import GroupsDeleteDialog from 'src/pages/dashboard/GroupsAndTags/Groups/groupsTable/GroupsDeleteDialog';
import { useGroupAutocomplete } from 'src/hooks/useGroupAutocomplete';
import NoOptionsFound from './NoOptionsFound';
import GroupMenuItem from './GroupMenuItem';
interface QuickEditPopoverGroupContentProps {
  handleGroupSelect: (selectedGroup: IGroupData | null) => void;
  selectedGroupToAdd: string;
}

export default function QuickEditPopoverGroupContent({
  handleGroupSelect,
  selectedGroupToAdd,
}: QuickEditPopoverGroupContentProps) {
  const { isGroupAutocompleteOpen } = useSelector((state: IRootState) => state.inventory);
  const dispatch = useDispatch();
  const {
    groupsData,
    selectedGroupValue,
    isGroupInEditMode,
    isGroupNoOptionsOpen,
    setIsGroupNoOptionsOpen,
    groupInputValue,
    setGroupInputValue,
    handleEditIconClick,
    handleCreateNewGroup,
    filterOptions,
    autocompleteRef,
    ignoreBlurRef,
    handleAutocompleteClose,
    handleAutocompleteOpen,
    handlePopoverClose,
    handlePopoverOpen,
    isGroupInCreateMode,
    setSelectedGroupValue,
  } = useGroupAutocomplete(selectedGroupToAdd);

  const renderGroupMenuItem = (option: IGroupData) => (
    <GroupMenuItem
      option={option}
      onSelect={handleGroupSelect}
      onEditClick={handleEditIconClick}
      onClose={handleAutocompleteClose}
    />
  );

  const renderNoOptions = () => (
    <NoOptionsFound
      type="group"
      inputValue={groupInputValue}
      onCreateClick={handleCreateNewGroup}
      onMouseDown={(e) => {
        e.preventDefault();
        ignoreBlurRef.current = true;
      }}
    />
  );

  const selectedGroup = useMemo(
    () => groupsData.find((group) => group.id === selectedGroupToAdd) || null,
    [groupsData, selectedGroupToAdd]
  );

  const handleGroupSelection = (newValue: string | IGroupData | null) => {
    if (typeof newValue === 'string') {
      handleGroupSelect({ name: newValue, id: '' });
    } else {
      handleGroupSelect(newValue);
    }
  };

  const isGroupSelected = (option: IGroupData) => option.id === selectedGroupToAdd;

  return (
    <>
      <Autocomplete
        key={selectedGroupToAdd}
        ref={autocompleteRef}
        clearOnBlur
        blurOnSelect={false}
        open={isGroupAutocompleteOpen}
        onOpen={handleAutocompleteOpen}
        onClose={handleAutocompleteClose}
        handleHomeEndKeys
        isOptionEqualToValue={(option, value) => {
          if (!option || !value) return false;
          return option.id === value.id;
        }}
        ListboxProps={{
          sx: {
            overflow: isGroupInEditMode || isGroupInCreateMode ? 'hidden' : 'auto',
            '& .MuiAutocomplete-option[aria-selected="true"]': {
              backgroundColor: 'rgba(32, 101, 209, 0.16)',
              '&.Mui-focused': {
                backgroundColor: 'rgba(32, 101, 209, 0.16)',
              },
            },
            '& .MuiAutocomplete-option.Mui-focused': {
              backgroundColor: 'rgba(32, 101, 209, 0.08)',
            },
          },
        }}
        slotProps={{
          paper: {
            sx: {
              '& .MuiAutocomplete-noOptions': {
                height: '94px',
                overflow: 'hidden',
                padding: '4px 4px 8px 4px',
              },
            },
          },
        }}
        selectOnFocus
        onBlurCapture={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onInputChange={(event, newInputValue) => {
          setGroupInputValue(newInputValue);
        }}
        onChange={(event, newValue: string | IGroupData | null) => {
          handleGroupSelection(newValue);
        }}
        freeSolo
        options={groupsData}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return option.name || '';
        }}
        filterOptions={filterOptions}
        renderOption={(props, option: IGroupData, state: AutocompleteRenderOptionState) => {
          if (isGroupNoOptionsOpen) {
            return renderNoOptions();
          }
          return (
            <li
              style={{
                ...(isGroupSelected(option) && {
                  backgroundColor: 'rgba(32, 101, 209, 0.16)',
                }),
                ...(isGroupInEditMode || isGroupInCreateMode
                  ? {
                      opacity: 0.48,
                    }
                  : {}),
              }}
              key={option.id}
              {...props}
            >
              {renderGroupMenuItem(option)}
            </li>
          );
        }}
        value={selectedGroup}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            InputProps={{
              ...params.InputProps,
              sx: {
                ...(isGroupInEditMode || isGroupInCreateMode
                  ? {
                      color: '#919EAB',
                    }
                  : {}),
              },
            }}
            sx={{
              fontSize: '16px !important',
              width: '100%',
              '& .MuiInputLabel-root.Mui-focused': {
                borderColor: '#E5E8EB',
                fontSize: '16px !important',
              },
              '& .MuiInputLabel-root:not(.Mui-focused)': {
                fontSize: '16px !important',
                fontWeight: 400,
                color: '#919EAB !important',
              },
            }}
            placeholder="Select a group"
            label="Select a group"
          />
        )}
      />

      <GroupEditPopover
        setSelectedGroupValue={setSelectedGroupValue}
        handleGroupSelect={handleGroupSelect}
        selectedGroupToAdd={selectedGroupToAdd}
        onPopoverOpen={handlePopoverOpen}
        onPopoverClose={handlePopoverClose}
      />
      <GroupsDeleteDialog
        inputValue={selectedGroupToAdd}
        setInputValue={() => dispatch(setSelectedGroupToAdd(''))}
      />
    </>
  );
}
