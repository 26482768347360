import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// components
import { CustomAvatar } from '../../../components/custom-avatar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
// cookie

import { authLogout } from 'src/store/dashboard/slices/authSlice';
import { setCurrentTab } from 'src/store/dashboard/slices/accountSlice';

import { isLoggedIn } from 'src/utils/isLoggedIn';
import { IRootState } from 'src/store';

import cookie from 'react-cookies';
// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Settings',
    value: 'general',
    linkTo: PATH_DASHBOARD.user.root,
  },
  {
    label: 'Plans/Billing',
    value: 'billing',
    linkTo: PATH_DASHBOARD.user.root,
  },
  {
    label: 'Affiliate',
    value: 'affiliate',
    linkTo: PATH_DASHBOARD.user.root,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { fullName, email } = isLoggedIn();
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const { userAccountDetail } = useSelector((state: IRootState) => state.account);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    cookie.remove('IS_ADMIN_LOGIN');
    dispatch(authLogout({ navigate }));
    handleClosePopover();
  };

  const handleClickItem = (path: string, value: string) => {
    handleClosePopover();
    navigate(path);
    dispatch(setCurrentTab(value));
  };

  return (
    <Box paddingLeft={'8px'}>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar
          src={'/avatar_default.jpg'}
          alt={userAccountDetail.name}
          name={userAccountDetail.name}
        />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userAccountDetail.name}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => handleClickItem(option.linkTo, option.value)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </Box>
  );
}
