export const connectInfo = {
  description:
    'To connect your closet, simply provide the country, username or email address and password associated with your Poshmark account. We encrypt and securely store your credentials.',
  buttonText: 'Connect',
  title: 'Connect Your Closet',
};

export const reconnectInfo = {
  description:
    'To re-connect your closet, simply provide the password associated with your Poshmark account. We encrypt and securely store your credentials.',
  buttonText: 'Re-connect',
  title: 'Re-connect Your Closet',
};

export const alertContent = {
  connectionAlertContent: {
    text: 'Attention! Your Poshmark closet is not connected. Connect your closet to use the Poshmark Automation Tool.',
    buttonText: 'Connect My Closet',
  },
  reconnectionAlertContent: {
    text: 'Attention! Your Poshmark closet disconnected. Re-connect to continue using Poshmark Automation Tool.',
    buttonText: 'Re-connect My Closet',
  },
  subscriptionAlertContent: {
    text: 'Attention! Automations paused due to your expired subscription. Renew your subscription to restart them.',
    buttonText: 'Renew My Subscription',
  },
};
