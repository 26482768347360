import React from 'react';
import { IconButtonAnimate } from 'src/components/animate';
import HelpIcon from '@mui/icons-material/Help';

type HelpButtonProps = {
  handleOpenPopover: (event: React.MouseEvent<HTMLElement>) => void;
  handleClosePopover?: (event: React.MouseEvent<HTMLElement>) => void;
  isOpen: boolean;
};

export default function HelpButton({
  handleOpenPopover,
  handleClosePopover,
  isOpen,
}: HelpButtonProps) {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    !isOpen ? handleOpenPopover(e) : handleClosePopover?.(e);
  };
  return (
    <IconButtonAnimate
      sx={{
        height: '32px',
        borderRadius: '8px',
        fontSize: '14px !important',
        fontWeight: '700 !important',
        lineHeight: '22px !important',
        px: '8px',
      }}
      color="inherit"
      onClick={handleClick}
      onMouseEnter={handleOpenPopover}
      onMouseLeave={handleClosePopover}
    >
      <HelpIcon
        color={'action'}
        fontSize={'small'}
        sx={{
          width: '24px',
          height: '24px',
          mr: '4px',
        }}
      />
      Help
    </IconButtonAnimate>
  );
}
