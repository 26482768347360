import { TaskHourConfig, TimeOfDay } from '@beta.limited/primelister';

export const DUMMY_HOURLY_RELIST_LIMIT = 13;
export const DUMMY_ENABLED_TASK_COUNT = '0';

export const mockAutoRelistHours: TaskHourConfig[] = [
  { timeOfDay: TimeOfDay.AM_12, taskLimit: 0, enabled: false },
  { timeOfDay: TimeOfDay.AM_1, taskLimit: 0, enabled: false },
  { timeOfDay: TimeOfDay.AM_2, taskLimit: 0, enabled: false },
  { timeOfDay: TimeOfDay.AM_3, taskLimit: 0, enabled: false },
  { timeOfDay: TimeOfDay.AM_4, taskLimit: 0, enabled: false },
  { timeOfDay: TimeOfDay.AM_5, taskLimit: 0, enabled: false },
  { timeOfDay: TimeOfDay.AM_6, taskLimit: 0, enabled: false },
  { timeOfDay: TimeOfDay.AM_7, taskLimit: 0, enabled: false },
  { timeOfDay: TimeOfDay.AM_8, taskLimit: 437, enabled: false },
  { timeOfDay: TimeOfDay.AM_9, taskLimit: 437, enabled: true },
  { timeOfDay: TimeOfDay.AM_10, taskLimit: 437, enabled: true },
  { timeOfDay: TimeOfDay.AM_11, taskLimit: 437, enabled: false },
  { timeOfDay: TimeOfDay.PM_12, taskLimit: 437, enabled: true },
  { timeOfDay: TimeOfDay.PM_1, taskLimit: 437, enabled: true },
  { timeOfDay: TimeOfDay.PM_2, taskLimit: 437, enabled: false },
  { timeOfDay: TimeOfDay.PM_3, taskLimit: 437, enabled: false },
  { timeOfDay: TimeOfDay.PM_4, taskLimit: 437, enabled: true },
  { timeOfDay: TimeOfDay.PM_5, taskLimit: 437, enabled: true },
  { timeOfDay: TimeOfDay.PM_6, taskLimit: 437, enabled: false },
  { timeOfDay: TimeOfDay.PM_7, taskLimit: 437, enabled: true },
  { timeOfDay: TimeOfDay.PM_8, taskLimit: 437, enabled: true },
  { timeOfDay: TimeOfDay.PM_9, taskLimit: 437, enabled: false },
  { timeOfDay: TimeOfDay.PM_10, taskLimit: 437, enabled: false },
  { timeOfDay: TimeOfDay.PM_11, taskLimit: 437, enabled: false },
];
