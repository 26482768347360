import { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import { Link, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';
// sections
import ForgotPasswordForm from './ForgotPasswordForm';
import { useDispatch } from 'react-redux';
import { resetAuthError } from 'src/store/dashboard/slices/authSlice';
import { PasswordIcon } from 'src/assets/icons';

// ----------------------------------------------------------------------

export default function ForgotPasswordPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const emailFromUrl = params.get('recover');
  const code = params.get('code');

  useEffect(() => {
    if (emailFromUrl && code) {
      navigate(PATH_AUTH.resetPassword + '?email=' + emailFromUrl + '&code=' + code);
    } else if (emailFromUrl) {
      navigate(PATH_AUTH.resetPassword + '?email=' + emailFromUrl);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title> Reset Password | PrimeLister</title>
      </Helmet>

      <PasswordIcon sx={{ mb: 5, height: 96 }} />

      <Typography variant="h3" paragraph>
        Forgot your password?
      </Typography>

      <Typography sx={{ color: 'text.secondary', mb: 5 }}>
        Please enter the email address associated with your account and we will send you a link to
        reset your password.
      </Typography>

      <ForgotPasswordForm />

      <Link
        to={PATH_AUTH.login}
        component={RouterLink}
        color="inherit"
        variant="subtitle2"
        sx={{
          mt: 3,
          mx: 'auto',
          alignItems: 'center',
          display: 'inline-flex',
        }}
        onClick={() => dispatch(resetAuthError())}
      >
        <Iconify icon="eva:chevron-left-fill" width={16} />
        Return to sign in
      </Link>
    </>
  );
}
