import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AutomationsPageTitle from 'src/layouts/automations/header/AutomationsPageTitle';
import ListingCardsContainer from './components/ListingCardsContainer';
import { setInitialLoading } from 'src/store/automations/slices/myListingsSlice';
import CreateNewTaskDialog from './dialogs/CreateNewTaskDialog';
import useResponsive from 'src/hooks/useResponsive';
import { IRootState } from 'src/store';
import { useLocation } from 'react-router';
import ExpiredSubscriptionDialog from '../MyClosetPage/components/dialogs/ExpiredSubscriptionDialog';
import ListingsFilterDrawer from './components/ListingsFilterDrawer';

const ListingsPage = () => {
  const dispatch = useDispatch();

  const matches = useResponsive('up', 'sm'); // Desktop
  const isMobileWeb = useResponsive('down', 'sm');
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const isMobile = isMobileWeb || isPrimeListerMobileApp;
  useEffect(() => {
    dispatch(setInitialLoading(false));
  }, []);

  const expiredSubscriptionDialogContent =
    "You don't have the subscription required to create this task. Subscribe to Poshmark Automation Tool and unlock its powerful features with full access.";

  return (
    <>
      <Helmet>
        <title> My Listings | Primelister</title>
      </Helmet>

      <Container
        disableGutters
        sx={{
          maxWidth: '1072px !important',
        }}
      >
        {!isMobile && (
          <Stack
            direction={matches ? 'row' : 'column'}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              zIndex: 11,
              py: matches ? 5 : 2,
              mt: matches ? -5 : -2,
            }}
            spacing={matches ? 0 : '40px'}
          >
            <AutomationsPageTitle title="My Listings" />
          </Stack>
        )}
        <Stack>
          <ListingCardsContainer />
        </Stack>
        <CreateNewTaskDialog />
        <ExpiredSubscriptionDialog
          cancelButtonType="outlined"
          titleSx={{
            fontSize: '18px',
          }}
          contentSx={{
            marginTop: '8px',
            fontSize: '16px',
            color: '#637381',
          }}
          content={expiredSubscriptionDialogContent}
        />
      </Container>
    </>
  );
};

export default ListingsPage;
