import {
  ITasksResponseData,
  TaskItemInterface,
  ITaskErrorItem,
  ITasksGetAction,
  ITasksBulkDeleteAction,
} from 'src/pages/dashboard/Tasks/types/types';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ITasksState {
  loading: boolean;
  error: string;
  dense: boolean;
  deletePopup: boolean;
  deletePopupData: string;
  deleteType: number;
  openPopoverMenu: HTMLElement | null;
  popoverData: TaskItemInterface | null;
  tasks: ITasksResponseData[];
  taskCount: number;
  page: number;
  rowsPerPage: number;
  loadingEffect: true;
  selectedCheckboxes: string[];
  sortModel: GridSortModel;
  selectedTab: string;
  errorDetailDialogOpen: boolean;
  taskErrorList: ITaskErrorItem[];
  tasksFilterModel: GridFilterModel;
}

const initialState = {
  loading: false,
  error: '',
  dense: false,
  deletePopup: false,
  deletePopupData: '',
  deleteType: 0, // initial value is 0, 1 is for bulk delete, 2 is for single delete
  openPopoverMenu: null,
  popoverData: null,
  tasks: [],
  taskCount: 0,
  page: 0,
  rowsPerPage: 25,
  loadingEffect: true,
  selectedCheckboxes: [],
  sortModel: [{ field: 'createdDate', sort: 'desc' }],
  selectedTab: 'COMPLETED',
  errorDetailDialogOpen: false,
  taskErrorList: [],
  tasksFilterModel: {
    items: [],
    linkOperator: 'and',
    quickFilterLogicOperator: 'and',
    quickFilterValues: [],
  },
};

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setDense: (state) => {
      state.dense = !state.dense;
    },
    setDeletePopup: (state, action) => {
      state.deletePopup = action.payload;
    },
    setDeletePopupData: (state, action) => {
      state.deletePopupData = action.payload;
    },
    setDeleteType: (state, action) => {
      // initial value is 0, 1 is for bulk delete, 2 is for single delete
      state.deleteType = action.payload;
    },
    setOpenPopoverMenu: (state, action) => {
      state.openPopoverMenu = action.payload;
    },
    setPopoverData: (state, action) => {
      state.popoverData = action.payload;
    },
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
    setTaskCount: (state, action) => {
      state.taskCount = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setLoadingEffect: (state, action) => {
      state.loadingEffect = action.payload;
    },
    setSelectedCheckboxes: (state, action) => {
      state.selectedCheckboxes = action.payload;
    },
    setSortModel: (state, action) => {
      state.sortModel = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setErrorDetailDialogOpen: (state, action) => {
      state.errorDetailDialogOpen = action.payload;
    },
    setTasksFilterModel: (state, action) => {
      state.tasksFilterModel = action.payload;
    },
    //--
    tasksGetRequest: (state, action: PayloadAction<ITasksGetAction>) => {
      state.loading = true;
    },
    tasksGetSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    tasksGetFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    tasksBulkDeleteRequest: (state, action: PayloadAction<ITasksBulkDeleteAction>) => {
      state.loading = true;
    },
    tasksBulkDeleteSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    tasksBulkDeleteFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    getTaskErrorList: (state) => {
      state.loading = true;
    },
    setTaskErrorList: (state, action) => {
      state.loading = false;
      state.taskErrorList = action.payload;
    },
    getTaskErrorListFail: (state) => {
      state.loading = false;
    },
    resetTasksState: (state) => initialState,
  },
});

export const {
  resetTasksState,
  setDense,
  setDeletePopup,
  setDeletePopupData,
  setDeleteType,
  setOpenPopoverMenu,
  setPopoverData,
  setTasks,
  setTaskCount,
  setPage,
  setRowsPerPage,
  setLoadingEffect,
  setSelectedCheckboxes,
  setSortModel,
  setSelectedTab,
  setErrorDetailDialogOpen,
  setTasksFilterModel,
  //--
  tasksGetRequest,
  tasksGetSuccess,
  tasksGetFail,
  //--
  tasksBulkDeleteRequest,
  tasksBulkDeleteSuccess,
  tasksBulkDeleteFail,
  //--
  getTaskErrorList,
  getTaskErrorListFail,
  setTaskErrorList,
} = tasksSlice.actions;

export default tasksSlice.reducer;
