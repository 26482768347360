// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, Button, Badge, BadgeProps, useMediaQuery } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Logo from './Logo';
//
import AccountPopover from './AccountPopover';
import { useLocation } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { Shadow } from '../../LayoutComponents/NavHorizontal';

import NavInHeader from 'src/layouts/LayoutComponents/NavInHeader';
import HelpMenuPopover from 'src/layouts/LayoutComponents/MenuDropDown';
import createNavConfig from '../nav/config';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();
  const location = useLocation();
  const navbarMatches = useMediaQuery('(min-width:1349px)');
  const { isExtensionConnected, isPrimeListerMobileApp } = useSelector(
    (state: IRootState) => state.home
  );

  const isMobile = useResponsive('down', 'sm');
  const shouldHideAccountPopover = location.pathname.includes(PATH_DASHBOARD.subscription.root);
  const shouldHaveNavbar = navbarMatches && !shouldHideAccountPopover;

  const badgeColor = isExtensionConnected ? 'success' : 'error';
  const navConfig = createNavConfig(isMobile, isPrimeListerMobileApp);
  const renderContent = (
    <Stack
      direction="row"
      sx={{
        width: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '60px !important',
        maxHeight: '60px !important',
        position: 'relative',
      }}
    >
      {!isMobile && <Logo sx={{ mr: 2.5 }} />}

      {shouldHaveNavbar && <NavInHeader navConfig={navConfig} />}

      {!shouldHideAccountPopover && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            width: isMobile ? '100%' : 'auto',
          }}
          spacing={{ xs: 0.5, sm: 1.5 }}
          color={theme.palette.grey[800]}
        >
          <HelpMenuPopover />

          {/*TODO: Change color when websocket implemented*/}
          <StyledBadge badgeContent={''} color={badgeColor}>
            <AccountPopover />
          </StyledBadge>
        </Stack>
      )}
    </Stack>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...((shouldHaveNavbar || !shouldHideAccountPopover) && {
          borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
        }),
        width: 1,
        bgcolor: 'background.default',
        minHeight: '60px !important',
        maxHeight: '60px !important',
      }}
    >
      <Toolbar
        sx={{
          minHeight: '60px !important',
          maxHeight: '60px !important',
          px: { lg: 5 },
          ...bgBlur({
            color: theme.palette.background.default,
          }),
          ...(!shouldHaveNavbar &&
            !shouldHideAccountPopover && {
              borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
            }),
        }}
      >
        {renderContent}
      </Toolbar>
      {(shouldHaveNavbar || shouldHideAccountPopover) && <Shadow />}
    </AppBar>
  );
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 3,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));
