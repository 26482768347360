// @mui
import { Stack, Typography, Avatar, useMediaQuery, Chip, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import { IRootState } from 'src/store';

import { IActivityItem } from '../../ActivityPage/utils/types';

export default function ActivityCard({ widget }: { widget: IActivityItem }) {
  const isDesktop = useResponsive('up', 'md');
  const matches = useMediaQuery('(min-width:835px)');

  const { taskCountsObject } = useSelector((state: IRootState) => state.statistics);
  const cardImage =
    `/icons/automationIcons/${widget.autoIcon}.svg` ??
    'https://r2.primelister.com/image_thumbnail.svg';

  const taskCount = taskCountsObject[widget.widgetMatcher!] ?? 0;
  const theme = useTheme();
  
  return (
    <Stack
      key={widget.displayName}
      alignItems={isDesktop ? 'center' : 'flex-start'}
      direction="row"
      spacing="12px"
      sx={{
        borderRadius: '8px',
        border: '1px solid rgba(145, 158, 171, 0.24)',
        '&:hover': {
          boxShadow: (theme) => theme.customShadows.z20,
        },
        p: 0,
        cursor: 'default',
        minHeight: '70px',
        width: matches ? '800px' : 'auto',
        minWidth: '260px',
        maxWidth: '380px',
        position: 'relative',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        background:
          'linear-gradient(148deg, rgba(118, 176, 241, 0.20) 0%, rgba(32, 101, 209, 0.20) 100%), #FFF',
        [theme.breakpoints.down('sm')]: {
          minWidth: '100%',
        },
      }}
    >
      <img
        src={cardImage}
        style={{
          minWidth: '40px',
          maxWidth: '40px',
          minHeight: '40px',
          maxHeight: '40px',
          zIndex: 1,
          borderRadius: '8px !important',
          marginLeft: '12px',
        }}
        alt={'Test'}
      />

      <Stack
        direction="row"
        sx={{
          width: 1,
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: '54px',
          pr: '25px',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          style={{
            color: '#53649A',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '24px',
          }}
        >
          {widget.displayName}
        </Typography>
        <Typography
          style={{
            color: '#000',
            fontSize: '24px',
            fontWeight: '700',
            lineHeight: '36px',
          }}
        >
          {taskCount.toLocaleString()}
        </Typography>
      </Stack>
    </Stack>
  );
}
