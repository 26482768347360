import _ from 'lodash';
import moment from 'moment-timezone';

export function getTimezones() {
  return _.orderBy(
    moment.tz
      .names()
      .filter((name) => !((name.indexOf('/') < 0 && name !== 'UTC') || name.startsWith('Etc/')))
      .map((name) => {
        // const fixedName = name.replace(/_/g, ' ');
        const tz = moment.tz(name).format('Z');
        return {
          name: name,
          gmt: tz,
          offset: moment.duration(tz).asMinutes(),
        };
      }),
    (x) => x.offset
  );
}
