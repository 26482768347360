import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Typography, useMediaQuery } from '@mui/material';
import AutomationOptionsContainer from './components/AutomationOptionsContainer';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAutomationListRequest,
  getActiveClosetPlatformShippingOffersRequest,
  setInitialLoading,
  UserTagTypesEnum,
} from 'src/store/automations/slices/automationsSlice';
import DisableAutomationDialog from './components/dialogs/DisableAutomationDialog';
import AutomationOptionDrawer from './components/drawer/AutomationOptionDrawer';
import { IRootState } from 'src/store';
import ConnectionOrSubscriptionMissingDialog from './components/dialogs/ConnectionOrSubscriptionMissingDialog';
import useOnboarding from 'src/utils/onBoardingSteps/useOnboarding';
import ShareHourDialog from './components/dialogs/ShareHourDialog';
import RelistHourDialog from './components/dialogs/RelistHourDialog';
import HelpButton from 'src/layouts/LayoutComponents/HelpButton';
import { showCrispChat } from 'src/utils/crispChatHelpers';
import AutomationsPageTitle from 'src/layouts/automations/header/AutomationsPageTitle';
import ExpiredSubscriptionDialog from '../MyClosetPage/components/dialogs/ExpiredSubscriptionDialog';
import RatingPopup from '../../../components/rating-popup/RatingPopup';
import useResponsive from 'src/hooks/useResponsive';

const AutomationsPage = () => {
  const dispatch = useDispatch();
  const {
    activeClosetAutomationPlatform,
    loading,
    isUserBoardedPoshmarkAutomationFeatures,
    isUserBoardedPoshmarkAutomation,
  } = useSelector((state: IRootState) => state.automations);
  const { activeClosetCredentialsId, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.myCloset
  );
  const { driverObject } = useOnboarding(UserTagTypesEnum.ONBOARDING_POSHMARK_AUTOMATION_FEATURES);
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const titleMatches = useMediaQuery('(max-width:380px)');
  const isMobileWeb = useResponsive('down', 'sm');
  const isMobile = isMobileWeb || isPrimeListerMobileApp;
  const isDesktop = useResponsive('up', 'sm');

  useEffect(() => {
    dispatch(setInitialLoading(true));
    if (activeClosetCredentialsId) {
      dispatch(
        getAutomationListRequest({
          marketplace: activeClosetAutomationPlatform,
          activeClosetCredentialsId,
        })
      );
      dispatch(getActiveClosetPlatformShippingOffersRequest(activeClosetAutomationPlatform));
    } else {
      dispatch(setInitialLoading(false));
    }
  }, [activeClosetCredentialsId, activeClosetAutomationPlatform]);

  useEffect(() => {
    if (isUserBoardedPoshmarkAutomation && !isUserBoardedPoshmarkAutomationFeatures && !loading) {
      driverObject.drive();
    }
  }, [isUserBoardedPoshmarkAutomationFeatures]);

  const isShowingPageTitle = (isMobile && titleMatches) || !isMobile;
  return (
    <>
      <Helmet>
        <title> Automations | Primelister</title>
      </Helmet>

      <Container disableGutters maxWidth={'md'} sx={{ maxWidth: '800px !important' }}>
        {isShowingPageTitle && (
          <AutomationsPageTitle
            title="Automations"
            sxProps={{
              mb: !isMobile ? '2.5rem' : '24px',
              ...(isShowingPageTitle && !isDesktop ? { pt: '12px' } : {}),
            }}
          />
        )}

        <AutomationOptionsContainer />
      </Container>
      <DisableAutomationDialog />
      <ConnectionOrSubscriptionMissingDialog />
      <ExpiredSubscriptionDialog />
      <ShareHourDialog />
      <RelistHourDialog />
      <AutomationOptionDrawer />
    </>
  );
};

export default AutomationsPage;
