import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { crosslistEndpoints } from 'src/endpoints';
import axiosInstance from 'src/utils/axios';

import {
  getTaskErrorList,
  getTaskErrorListFail,
  setLoadingEffect,
  setTaskCount,
  setTaskErrorList,
  setTasks,
  tasksBulkDeleteFail,
  tasksBulkDeleteRequest,
  tasksBulkDeleteSuccess,
  tasksGetFail,
  tasksGetRequest,
  tasksGetSuccess,
} from '../slices/tasksSlice';

import { ITasksBulkDeleteAction, ITasksGetAction } from 'src/pages/dashboard/Tasks/types/types';

function* tasksGetRequestSaga(action: PayloadAction<ITasksGetAction>) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(
        crosslistEndpoints.tasks.GET_TASKS(
          action.payload.page,
          action.payload.rowsPerPage,
          action.payload.orderBy,
          action.payload.order,
          action.payload.filter,
        )
      )
    );
    yield put(setTaskCount(response.data.taskCount));
    yield put(setTasks(response.data.data));
    yield put(setLoadingEffect(false));
    yield put(tasksGetSuccess());
  } catch (error) {
    yield put(tasksGetFail(error.message));
  }
}

function* tasksBulkDeleteRequestSaga(action: PayloadAction<ITasksBulkDeleteAction>) {
  try {
    yield call(() =>
      axiosInstance.delete(crosslistEndpoints.tasks.GET_TASKS_BULK_DELETE(), {
        data: { tasks: action.payload.deleteData },
      })
    );
    yield put(tasksGetRequest(action.payload.getData));
    yield put(setLoadingEffect(false));
    yield put(tasksBulkDeleteSuccess());
  } catch (error) {
    yield put(tasksBulkDeleteFail(error.message));
  }
}

function* getTaskErrorListSaga() {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(crosslistEndpoints.tasks.GET_TASK_ERRORS())
    );
    yield put(setTaskErrorList(response.data));
  } catch (error) {
    yield put(getTaskErrorListFail());
    console.log(error);
  }
}

export function* tasksModuleSaga() {
  yield takeLatest(tasksGetRequest.type, tasksGetRequestSaga);
  yield takeLatest(tasksBulkDeleteRequest.type, tasksBulkDeleteRequestSaga);
  yield takeLatest(getTaskErrorList.type, getTaskErrorListSaga);
}
