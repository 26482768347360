import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import TasksTable from './Table/TasksTable';

export default function TasksCard() {
  return (
    <>
      <Card>
        <TasksTable />
      </Card>
    </>
  );
}
