import { Stack, Typography } from '@mui/material';

import { DataGridCreatedDateColumnInterface } from '../../../../types/types';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../../../store';
import { getDateWithTimeZone } from '../../../../../../../utils/getDateWithTimezone';

export default function DataGridCreatedDateColumn({
  createdDate,
}: DataGridCreatedDateColumnInterface) {
  const { userAccountDetail } = useSelector((state: IRootState) => state.account);
  return (
    <Stack direction="row" spacing={1}>
      <Typography variant="subtitle2" sx={{ fontWeight: 'normal' }}>
        {getDateWithTimeZone(
          createdDate,
          userAccountDetail?.timeZone?.name,
          'DD MMM YYYY, hh:mm A'
        )}
      </Typography>
    </Stack>
  );
}
