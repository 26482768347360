import { Box, Typography, TextField, FormHelperText } from '@mui/material';
import { FormikProps, useFormik } from 'formik';
import { FormValues } from './ListingsFilterDrawer';

interface NumericFilterProps {
  formik: FormikProps<FormValues>;
  isMinValid: boolean;
  isMaxValid: boolean;
  onClear: () => void;
  label: string;
  minName: keyof FormValues;
  maxName: keyof FormValues;
}

const getErrorMessage = (minError: any, maxError: any): string | null => {
  if (typeof minError === 'string') {
    return minError;
  }
  if (typeof maxError === 'string') {
    return maxError;
  }
  return null;
};

const NumericFilter = ({
  formik,
  isMinValid,
  isMaxValid,
  onClear,
  label,
  minName,
  maxName,
}: NumericFilterProps) => (
  <Box>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography
        variant="subtitle1"
        noWrap
        sx={{
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '22px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {label}
      </Typography>

      <Box>
        <Box>
          {!(formik.errors[minName] || formik.errors[maxName]) && (isMinValid || isMaxValid) && (
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '22px',
                cursor: 'pointer',
                color: '#637381',
              }}
              onClick={onClear}
            >
              Clear
            </Typography>
          )}
        </Box>
      </Box>
    </Box>

    <Box
      sx={{
        mt: '16px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '24px',
            flex: '1 0 0',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              flex: '1 0 0',
            }}
          >
            <Typography
              sx={{
                color: '#919EAB',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '22px',
                width: '52px',
              }}
            >
              Min
            </Typography>

            <TextField
              type="number"
              inputProps={{
                onKeyDown: (e) => {
                  if (e.key === '-' || e.key === 'e') {
                    e.preventDefault();
                  }
                },
                style: {
                  padding: '6px 8px',
                  textAlign: 'right',
                  height: '34px',
                  color: '#212B36',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '22px',
                },
              }}
              InputProps={{
                sx: {
                  '&:hover': {
                    backgroundColor: 'rgba(145, 158, 171, 0.16)',
                  },
                  '&.Mui-focused': {
                    backgroundColor: 'rgba(145, 158, 171, 0.16)',
                  },
                  height: '34px',
                  background:
                    formik.touched[minName] && formik.errors[minName]
                      ? 'rgba(255, 86, 48, 0.08)'
                      : 'rgba(145, 158, 171, 0.08)',
                  border:
                    formik.touched[minName] && formik.errors[minName]
                      ? '1px solid #FF5630'
                      : '1px solid transparent',

                  borderRadius: '6px',
                },
              }}
              size="small"
              placeholder="0"
              sx={{
                width: '48px',
                height: '34px',
                '& fieldset': { border: 'none' },
              }}
              name={minName}
              onBlur={formik.handleBlur}
              value={formik.values[minName]}
              onChange={formik.handleChange}
              error={formik.touched[minName] && Boolean(formik.errors[minName])}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              flex: '1 0 0',
            }}
          >
            <Typography
              sx={{
                color: '#919EAB',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '22px',
                width: '52px',
              }}
            >
              Max
            </Typography>

            <TextField
              type="number"
              inputProps={{
                onKeyDown: (e) => {
                  if (e.key === '-' || e.key === 'e') {
                    e.preventDefault();
                  }
                },
                style: {
                  padding: '6px 8px',
                  textAlign: 'right',
                  height: '34px',
                  color: '#212B36',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '22px',
                },
              }}
              InputProps={{
                sx: {
                  '&:hover': {
                    backgroundColor: 'rgba(145, 158, 171, 0.16)',
                  },
                  '&.Mui-focused': {
                    backgroundColor: 'rgba(145, 158, 171, 0.16)',
                  },
                  height: '34px',
                  background:
                    formik.touched[maxName] && formik.errors[maxName]
                      ? 'rgba(255, 86, 48, 0.08)'
                      : 'rgba(145, 158, 171, 0.08)',
                  border:
                    formik.touched[maxName] && formik.errors[maxName]
                      ? '1px solid #FF5630'
                      : '1px solid transparent',

                  borderRadius: '6px',
                },
              }}
              size="small"
              placeholder="0"
              sx={{
                width: '48px',
                height: '34px',
                '& fieldset': { border: 'none' },
              }}
              name={maxName}
              onBlur={formik.handleBlur}
              value={formik.values[maxName]}
              onChange={formik.handleChange}
              error={formik.touched[maxName] && Boolean(formik.errors[maxName])}
            />
          </Box>
        </Box>
      </Box>
      {(formik.touched[minName] && formik.errors[minName]) ||
      (formik.touched[maxName] && formik.errors[maxName]) ? (
        <FormHelperText
          sx={{
            color: '#FF5630',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '18px',
          }}
        >
          {getErrorMessage(formik.errors[minName], formik.errors[maxName])}
        </FormHelperText>
      ) : null}
    </Box>
  </Box>
);

export default NumericFilter;
