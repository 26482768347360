import { IFeatureFlag } from '@openreplay/tracker/lib/modules/featureFlags';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router';

import {
  ILoginRequest,
  INewPasswordRequest,
  IResetPasswordRequest,
  ISignUpRequest,
} from 'src/pages/dashboard/Auth/types/types';

export interface IAuthState {
  isVerifyDialogOpen: boolean;
  isResetRequestDialogOpen: boolean;
  isResetSuccessfulDialogOpen: boolean;
  loading: boolean;
  error: string;
  emailFromResetPage: string;
  blockState: number | null;
  userId: string;
  isAuthenticated: boolean;
  isInitialized: boolean;
  referrerCode: string;
  appVersions: any | null;
  loginData: {
    email: string;
    password: string;
  } | null;
  signUpResponse: {
    accessToken: string;
    refreshToken: string;
    message: string;
  } | null;
  isUserNotVerifiedDialogOpen: boolean;
  featureFlags: IFeatureFlag[] | null;
  extensionVersion: string | null;
}

const initialState: IAuthState = {
  loading: false,
  error: '',
  isVerifyDialogOpen: false,
  isResetRequestDialogOpen: false,
  isResetSuccessfulDialogOpen: false,
  emailFromResetPage: '',
  blockState: null as number | null,
  userId: '',
  isAuthenticated: false,
  isInitialized: false,
  referrerCode: '',
  appVersions: null,
  signUpResponse: null,
  loginData: null,
  isUserNotVerifiedDialogOpen: false,
  featureFlags: null,
  extensionVersion: null,
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setIsUserNotVerifiedDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isUserNotVerifiedDialogOpen = action.payload;
    },
    setFeatureFlags: (state, action: PayloadAction<IFeatureFlag[] | null>) => {
      state.featureFlags = action.payload;
    },
    setIsVerifyDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isVerifyDialogOpen = action.payload;
    },
    setSignUpResponse: (state, action: PayloadAction<IAuthState['signUpResponse']>) => {
      state.signUpResponse = action.payload;
    },
    setIsResetRequestDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isResetRequestDialogOpen = action.payload;
    },
    setIsResetSuccessfulDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isResetSuccessfulDialogOpen = action.payload;
    },
    setEmailfromResetPage: (state, action: PayloadAction<string>) => {
      state.emailFromResetPage = action.payload;
    },
    setUserBlockState: (state, action: PayloadAction<number | null>) => {
      state.blockState = action.payload;
    },
    setLoginData: (state, action: PayloadAction<IAuthState['loginData']>) => {
      state.loginData = action.payload;
    },
    resetAuthError: (state) => {
      state.error = '';
    },
    setInitializedState: (
      state,
      action: PayloadAction<{ blockState: number | null; userId: string }>
    ) => {
      state.blockState = action.payload.blockState;
      state.userId = action.payload.userId;
    },
    //Initialize
    authInitialize: (state) => {
      state.isInitialized = true;
    },
    authInitializeResult: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    //--
    authLoginRequest: (state, action: PayloadAction<ILoginRequest>) => {
      state.loading = true;
      state.error = '';
    },
    authLoginSuccess: (
      state,
      action: PayloadAction<{
        blockState: number | null;
        userId: string;
        referrerCode: string;
      }>
    ) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.isInitialized = true;
      state.blockState = action.payload.blockState;
      state.userId = action.payload.userId;
      state.referrerCode = action.payload.referrerCode;
      state.error = '';
    },
    authLoginFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    authSignUpRequest: (state, action: PayloadAction<ISignUpRequest>) => {
      state.loading = true;
      state.error = '';
    },
    authSignUpSuccess: (state) => {
      state.loading = false;
      state.isVerifyDialogOpen = true;
      state.error = '';
    },
    authSignUpFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    authLogout: (state, action: PayloadAction<{ navigate: NavigateFunction }>) => {
      state.isAuthenticated = false;
      state.isInitialized = false;
    },
    //--
    authResetPasswordRequest: (state, action: PayloadAction<IResetPasswordRequest>) => {
      state.loading = true;
      state.error = '';
    },
    authResetPasswordSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    authResetPasswordFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    getAppVersionsRequest: (state) => {
      state.loading = true;
      state.error = '';
    },
    getAppVersionsSuccess: (state, action) => {
      state.loading = false;
      state.appVersions = action.payload;
      state.error = '';
    },
    getAppVersionsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    sendVerificationRequest: (
      state,
      action: PayloadAction<{
        email: string;
      }>
    ) => {
      state.loading = true;
      state.error = '';
    },
    sendVerificationSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    sendVerificationFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    authNewPasswordRequest: (state, action: PayloadAction<INewPasswordRequest>) => {
      state.loading = true;
      state.error = '';
    },
    authNewPasswordSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    authNewPasswordFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setExtensionVersion: (state, action) => {
      state.extensionVersion = action.payload;
    },
    getExtensionVersionRequest: (state) => {
      state.loading = true;
      state.error = '';
    },
    getExtensionVersionSuccess: (state, action) => {
      state.loading = false;
      state.error = '';
      state.extensionVersion = action.payload;
    },
    getExtensionVersionFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetUserId: (state) => {
      state.userId = '';
    },
    resetAuthState: (state) => initialState,
  },
});

export const {
  setIsUserNotVerifiedDialogOpen,
  resetAuthState,
  setIsVerifyDialogOpen,
  setIsResetRequestDialogOpen,
  setIsResetSuccessfulDialogOpen,
  setEmailfromResetPage,
  resetAuthError,
  setInitializedState,
  setUserBlockState,
  //--
  sendVerificationFail,
  sendVerificationRequest,
  sendVerificationSuccess,
  //--
  authInitialize,
  authInitializeResult,

  resetUserId,
  //--
  authLoginRequest,
  authLoginSuccess,
  authLoginFail,
  //--
  getAppVersionsRequest,
  getAppVersionsFail,
  getAppVersionsSuccess,
  //--
  authSignUpRequest,
  authSignUpSuccess,
  authSignUpFail,
  //--
  authLogout,
  //--
  authResetPasswordRequest,
  authResetPasswordSuccess,
  authResetPasswordFail,
  //--
  authNewPasswordRequest,
  authNewPasswordSuccess,
  authNewPasswordFail,

  setSignUpResponse,
  setLoginData,

  setFeatureFlags,
  setExtensionVersion,

  getExtensionVersionRequest,
  getExtensionVersionSuccess,
  getExtensionVersionFail,
} = accountSlice.actions;
export default accountSlice.reducer;
