interface FilterParams {
  nextPageId?: string;
  searchQuery: string;
  availability: string;
  selectedFilterOption: any;
  selectedSortOption: any;
  listingAgeMin: string;
  listingAgeMax: string;
  numberOfLikesMin: string;
  numberOfLikesMax: string;
  priceMin: string;
  priceMax: string;
  department?: string;
  category?: string;
  subcategory?: string;
}

class ListingService {
  static buildFilters(params: FilterParams) {
    const {
      nextPageId,
      searchQuery,
      availability,
      selectedFilterOption,
      selectedSortOption,
      listingAgeMin,
      listingAgeMax,
      numberOfLikesMin,
      numberOfLikesMax,
      priceMin,
      priceMax,
      category,
      subcategory,
      department,
    } = params;

    const filters: any = {
      ...(nextPageId && { maxId: nextPageId }),
      ...(searchQuery && { query: searchQuery }),
      inventoryStatus: searchQuery ? 'all' : availability,
      ...(selectedSortOption && { sort: selectedSortOption.value }),
    };

    if (!searchQuery) {
      Object.assign(filters, {
        ...(listingAgeMin != null && listingAgeMin !== '' && { minAge: Number(listingAgeMin) }),
        ...(listingAgeMax != null && listingAgeMax !== '' && { maxAge: Number(listingAgeMax) }),
        ...(numberOfLikesMin != null &&
          numberOfLikesMin !== '' && { minLikes: Number(numberOfLikesMin) }),
        ...(numberOfLikesMax != null &&
          numberOfLikesMax !== '' && { maxLikes: Number(numberOfLikesMax) }),
        ...(priceMin != null && priceMin !== '' && { minPrice: Number(priceMin) }),
        ...(priceMax != null && priceMax !== '' && { maxPrice: Number(priceMax) }),
      });

      if (department !== 'all-departments') {
        filters.department = department;
        if (category) {
          filters.category = category;
          if (subcategory) {
            filters.subcategory = subcategory;
          }
        }
      }
    }

    return filters;
  }

  static getCredentialsIdByShopParam(shopParam: string | null, closetList: any[]) {
    return closetList.find((item) => item.poshmarkUserName === shopParam)?._id;
  }
}

export default ListingService;
