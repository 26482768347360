import {
  Avatar,
  Chip,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'src/store';
import {
  setIsConnectionErrorDialogOpen,
  setIsRelistHourDialogOpen,
} from 'src/store/automations/slices/automationsSlice';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Iconify from 'src/components/iconify';

import { Automation, TaskHourConfig } from '@beta.limited/primelister';
import { DUMMY_ENABLED_TASK_COUNT, mockAutoRelistHours } from './constants';
import { sortTimeOfDay } from 'src/utils/sortTimeOfDay';
import { useCheckSubscriptionStatus } from 'src/hooks/useCheckSubscriptionStatus';

const RelistingHours = () => {
  const dispatch = useDispatch();
  const mobileEdgeMatches = useMediaQuery('(min-width:370px)');
  const { automationsSettings, hourlyRelistLimit } = useSelector(
    (state: IRootState) => state.automations
  );
  const { activeClosetCredentialsId, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.myCloset
  );

  const [isRelistingHoursToggled, setIsRelistingHoursToggled] = useState(true);

  const relistingHoursArray = hasActiveClosetConnection
    ? sortTimeOfDay(
        automationsSettings[Automation.AUTO_RELIST]?.config?.taskHours as TaskHourConfig[]
      )
    : mockAutoRelistHours;
  const hourlyTaskLimit = automationsSettings[Automation.AUTO_RELIST]?.config?.hourlyTaskLimit;
  const { shouldConnectionErrorDialogOpen } = useCheckSubscriptionStatus();

  const renderSharingHours = () => (
    <>
      <Stack
        direction="row"
        sx={{
          width: '100%',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: '-8px !important',
        }}
      >
        {relistingHoursArray.map((item, index) => (
          <Tooltip
            placement="top"
            key={item.timeOfDay}
            title="ℹ️ Click to switch re-listing on/off for this hour."
          >
            <Chip
              variant="soft"
              deleteIcon={
                <Avatar
                  sx={{ width: '24px', height: '24px' }}
                  src={`/icons/automationIcons/sharingHour${
                    item.enabled ? 'Enabled' : 'Disabled'
                  }.svg`}
                />
              }
              onClick={() => handleHourClick(item)}
              onDelete={() => handleHourClick(item)}
              label={item.timeOfDay}
              color={item.enabled ? 'success' : 'warning'}
              sx={{
                fontWeight: 400,
                fontSize: '13px !important',
                cursor: 'pointer',
                minWidth: mobileEdgeMatches ? '82px' : '90px',
                maxWidth: mobileEdgeMatches ? '82px' : '90px',
                mb: '8px',
                justifyContent: 'flex-end ',
                '& .MuiChip-label': {
                  paddingRight: '0',
                  paddingLeft: '8px',
                  width: '100%',
                  textAlign: 'center',
                },
              }}
            />
          </Tooltip>
        ))}
      </Stack>
      <Typography variant="body2" sx={{ alignSelf: 'center' }}>
        ℹ️ You've enabled {calculateEnabledTasksLimit()} out of 200 daily re-lists.
      </Typography>
    </>
  );

  const handleHourClick = (hour: TaskHourConfig) => {
    if (!hasActiveClosetConnection || !activeClosetCredentialsId) {
      dispatch(setIsConnectionErrorDialogOpen(true));
    } else {
      dispatch(setIsRelistHourDialogOpen({ isOpen: true, relistingHourDialogData: hour }));
    }
  };

  const calculateEnabledTasksLimit = () => {
    if (!hasActiveClosetConnection) {
      return DUMMY_ENABLED_TASK_COUNT;
    }

    const enabledCount = relistingHoursArray.filter((hourObj) => hourObj?.enabled).length;
    return (hourlyRelistLimit * enabledCount).toLocaleString();
  };

  return (
    <Stack
      spacing={2}
      sx={{
        mt: '20px !important',
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" noWrap sx={{ display: 'flex', alignItems: 'center' }}>
          Re-listing Hours
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Determine the hours during which your listings will be automatically re-listed."
            placement={'top'}
            sx={{ textAlign: 'center !important' }}
          >
            <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
          </Tooltip>
        </Typography>
        <IconButton
          size="small"
          onClick={() => setIsRelistingHoursToggled(!isRelistingHoursToggled)}
          sx={{ cursor: 'pointer' }}
        >
          <Iconify
            icon={isRelistingHoursToggled ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}
          />
        </IconButton>
      </Stack>
      {isRelistingHoursToggled && renderSharingHours()}
    </Stack>
  );
};

export default RelistingHours;
