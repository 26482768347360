import { Tooltip } from '@mui/material';
import Iconify from 'src/components/iconify';

interface CommonTitleTooltipProps {
  title: string;
}
export default function CommonTitleTooltip({ title }: CommonTitleTooltipProps) {
  return (
    <Tooltip
      enterTouchDelay={0}
      leaveTouchDelay={3000}
      arrow
      title={title}
      placement={'top'}
      sx={{ marginLeft: '4px', color: '#919EAB', textAlign: 'center !important' }}
    >
      <Iconify icon="eva:info-outline" />
    </Tooltip>
  );
}
