import { Helmet } from 'react-helmet-async';
import { useTheme } from '@mui/material/styles';
// @mui
import { Container, Stack, Typography, useMediaQuery } from '@mui/material';
import ActivityCardContainer from './components/ActivityCardContainer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'src/store';
import ActivityDropDown from './components/ActivityDropDown';
import {
  getActivityListRequest,
  setActivityList,
  setInitialLoading,
  setSelectedActivity,
} from 'src/store/automations/slices/activitySlice';
import {
  ACTIVITY_REQUEST_TYPE,
  automationItemsList,
  completedTabItemsList,
} from './utils/constants';
import { bgBlur } from 'src/utils/cssStyles';
import { useLocation } from 'react-router-dom';
import { checkValueForRelist } from './utils/helpers';
import useResponsive from 'src/hooks/useResponsive';
import { showCrispChat } from 'src/utils/crispChatHelpers';
import HelpButton from 'src/layouts/LayoutComponents/HelpButton';
import AutomationsPageTitle from 'src/layouts/automations/header/AutomationsPageTitle';
import { StylesByDevice } from '../MyListingsPage/components/ListingsBulkActionsMenu';
import ActivityTabs from './components/ActivityTabs';

const ActivityPage = () => {
  const dispatch = useDispatch();
  const { activeClosetAutomationPlatform } = useSelector((state: IRootState) => state.automations);
  const { activeClosetCredentialsId } = useSelector((state: IRootState) => state.myCloset);
  const { selectedActivity } = useSelector((state: IRootState) => state.activity);
  const [scrollDisplay, setScrollDisplay] = useState(false);
  const isMobileWeb = useResponsive('down', 'sm');
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const isMobile = isMobileWeb || isPrimeListerMobileApp;
  const matches = useMediaQuery('(min-width:600px)');
  const navbarMatches = useMediaQuery('(min-width:1349px)');
  const titleMatches = useMediaQuery('(max-width:380px)');
  const isDesktop = useResponsive('up', 'md');
  const theme = useTheme();

  useEffect(() => {
    const scrollLimit = () => {
      if (navbarMatches) {
        return 121;
      }
      if (matches) {
        return 108;
      }
      return 115;
    };

    const handleScroll = () => {
      if (window.scrollY <= scrollLimit()) {
        setScrollDisplay(false);
      } else {
        setScrollDisplay(true);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [window.scrollY]);

  const stylesByDevice: StylesByDevice = {
    rootStyles: {
      mobile: {
        position: 'initial',
        boxShadow: '#ffffff',
        mb: '0',
      },
      desktop: {
        position: 'sticky',
        top: navbarMatches ? 60 : 108,
        boxShadow: scrollDisplay ? '0px 8px 8px -5px #E5E8EB' : '#ffffff',
        mb: '0',
        ...bgBlur({
          color: theme.palette.background.default,
        }),
      },
    },
  };

  const isShowingPageTitle = (isMobile && titleMatches) || !isMobile;

  const rootStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: matches ? '0px' : '32px',
    py: matches ? 5 : 0,
    mt: matches ? -5 : 0,
    pb: isShowingPageTitle && isDesktop ? '16px !important' : '0 !important',
    zIndex: 11,
    ...(isMobile ? stylesByDevice.rootStyles.mobile : stylesByDevice.rootStyles.desktop),
  };

  return (
    <>
      <Helmet>
        <title> Activity | Primelister</title>
      </Helmet>

      <Container disableGutters maxWidth={'md'} sx={{ maxWidth: '800px !important' }}>
        <Stack
          direction={matches ? 'row' : 'column'}
          sx={rootStyles}
          spacing={matches ? 0 : '24px'}
        >
          {isShowingPageTitle && (
            <AutomationsPageTitle
              sxProps={{
                ...(isShowingPageTitle && !isDesktop ? { pt: '12px', pb: '16px' } : {}),
              }}
              title="Activity"
            />
          )}
        </Stack>
        <Stack gap={3}>
          <ActivityTabs />
        </Stack>
      </Container>
    </>
  );
};

export default ActivityPage;
