import { useDispatch, useSelector } from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link } from '@mui/material';

import { setOpenInventoryMergeListingsFailDialog } from 'src/store/dashboard/slices/inventorySlice';
import { IRootState } from 'src/store';

export default function InventoryMergeListingsFailDialog() {
  const dispatch = useDispatch();

  const { openInventoryMergeListingsFailDialog, error } = useSelector(
    (state: IRootState) => state.inventory
  );

  const handleClose = () => {
    dispatch(setOpenInventoryMergeListingsFailDialog(false));
  };

  const dialogTitle = 'Merge selected items';

  const dialogMessage = error;

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={openInventoryMergeListingsFailDialog}
      onClose={handleClose}
    >
      <DialogTitle> {dialogTitle} </DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>
        {dialogMessage}{' '}
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.primelister.com/features/cross-lister-tool/import/how-to-merge-listings-on-inventory-panel"
          fontWeight={700}
        >
          Learn more
        </Link>{' '}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClose}
          sx={{ textTransform: 'none' }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
