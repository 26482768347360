import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, IconButton, MenuItem, Popover, SxProps, Theme } from '@mui/material';
import Label, { LabelColor } from 'src/components/label';
import { ITagData } from 'src/store/dashboard/slices/groupsAndTagsSlice';
import { cardRules } from '../../GroupsAndTags/utils/cardRules';
import {
  setSelectedTagsToAdd,
  removeSelectedTagsToAdd,
  setTagEditPopoverAnchorEl,
  setIsTagEditPopoverOpen,
  setSelectedTagEditItem,
  QuickActionsTagPopoverModeEnum,
  setQuickActionsTagPopoverMode,
  setQuickEditSelectedTags,
} from 'src/store/dashboard/slices/inventorySlice';
import { IRootState } from 'src/store';
import MenuPopover from 'src/components/menu-popover/MenuPopover';

export enum InventoryTagActionReason {
  SELECTED = 'selectOption',
  REMOVED = 'removeOption',
}

export const useTagUtilities = () => {
  const dispatch = useDispatch();
  const { tagsData } = useSelector((state: IRootState) => state.groupsAndTags);
  const { quickActionsTagPopoverMode, selectedTagsToAdd } = useSelector(
    (state: IRootState) => state.inventory
  );

  const getTagColor = useCallback(
    (tagName: string) => {
      const matchingTagColor = tagsData.find((item) => item.name === tagName)?.color;
      const matchingColor = cardRules.tags.colorRules.find(
        (color) => color.colorName === matchingTagColor
      )?.colorCode;
      return matchingColor || 'default';
    },
    [tagsData]
  );

  const handleCreateNewTag = (event: React.MouseEvent<HTMLDivElement>, tagInputValue: string) => {
    event.stopPropagation();
    dispatch(setTagEditPopoverAnchorEl(event.currentTarget.parentElement));
    dispatch(setQuickActionsTagPopoverMode(QuickActionsTagPopoverModeEnum.CREATE));
    dispatch(setIsTagEditPopoverOpen(true));
    dispatch(
      setSelectedTagEditItem({
        name: tagInputValue,
        color: '',
      })
    );
  };

  const handleTagsUpdated = (event: any, options: ITagData[], reason: string) => {
    handleOnChange(event, options, reason);
    // Only get unique tag options
    const uniqueOptions = options.filter(
      (option, index, self) => index === self.findIndex((t) => t._id === option._id)
    );
    const validOptions = uniqueOptions.map((option) => ({
      name: option.name || '', // Provide a default value for name
      _id: option._id || '', // Provide a default value for _id
      color: (option.color as LabelColor) || 'default', // Provide a default value for color
    }));

    dispatch(setQuickEditSelectedTags(validOptions));
  };

  const tagsOptions = useMemo(
    () =>
      tagsData.map((tag) => ({
        name: tag.name,
        _id: tag._id,
        color: tag.color,
      })),
    [tagsData]
  );

  const isInEditMode = useMemo(
    () => quickActionsTagPopoverMode === QuickActionsTagPopoverModeEnum.EDIT,
    [quickActionsTagPopoverMode]
  );

  const renderTagMenuItem = useCallback(
    (option: ITagData) => (
      <MenuItem
        key={option._id}
        value={option._id}
        disabled={isInEditMode}
        sx={{
          p: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          '&:hover': {
            backgroundColor: 'transparent',
            '.edit-icon-button': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          },
        }}
        disableRipple
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Label
          variant="soft"
          color={getTagColor(option.name!) || ('default' as LabelColor)}
          sx={{
            textTransform: 'none',
            cursor: 'pointer',
            ...(isInEditMode
              ? {
                  color: '#919EAB',
                  backgroundColor: 'rgba(145, 158, 171, 0.16)',
                }
              : {}),
          }}
        >
          {option.name}
        </Label>
        <IconButton
          className="edit-icon-button"
          sx={{ display: 'none', width: '24px', height: '24px' }}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(setIsTagEditPopoverOpen(true));
            dispatch(setTagEditPopoverAnchorEl(e.currentTarget.parentElement?.parentElement));
            dispatch(setQuickActionsTagPopoverMode(QuickActionsTagPopoverModeEnum.EDIT));
            dispatch(setSelectedTagEditItem(option));
          }}
        >
          <Avatar src="/icons/editIcon.svg" sx={{ width: 16, height: 16 }} />
        </IconButton>
      </MenuItem>
    ),
    [getTagColor, isInEditMode]
  );

  const handleOnChange = useCallback(
    (event: any, options: ITagData[], reason: string) => {
      if (reason === InventoryTagActionReason.SELECTED) {
        const allSelectedIds = options.map((option) => option._id);
        dispatch(setSelectedTagsToAdd(allSelectedIds));
      } else if (reason === InventoryTagActionReason.REMOVED) {
        const remainingSelectedIds = options.map((option) => option._id);
        dispatch(removeSelectedTagsToAdd(remainingSelectedIds));
      }
    },
    [dispatch]
  );

  return {
    getTagColor,
    handleTagsUpdated,
    renderTagMenuItem,
    handleOnChange,
    tagsOptions,
    handleCreateNewTag,
  };
};
