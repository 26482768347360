import { Container, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import ImportButtonDropDown from './components/ImportButtonDropDown';
import InventoryCard from './mainComponents/InventoryCard';
import useOnboarding from 'src/utils/onBoardingSteps/useOnboarding';
import { UserTagTypesEnum } from 'src/store/automations/slices/automationsSlice';
import useIsDocumentLoaded from 'src/hooks/useIsDocumentLoaded';

export default function InventoryPage() {
  const { selectedCheckboxes, isInFullscreenMode, loading, inventoryCount } = useSelector(
    (state: IRootState) => state.inventory
  );
  const { isUserBoardedInventoryFullscreen } = useSelector(
    (state: IRootState) => state.automations
  );
  const { driverObject } = useOnboarding(UserTagTypesEnum.ONBOARDING_INVENTORY_FULLSCREEN);
  const isDocumentLoaded = useIsDocumentLoaded();
  const INVENTORY_COUNT_THRESHOLD = 30;
  useEffect(() => {
    if (
      isDocumentLoaded &&
      !isUserBoardedInventoryFullscreen &&
      !loading &&
      inventoryCount >= INVENTORY_COUNT_THRESHOLD
    ) {
      driverObject.drive();
      driverObject.refresh();
    }
  }, [isUserBoardedInventoryFullscreen, inventoryCount, loading, isDocumentLoaded]);

  useEffect(() => {
    const isBulkActionsMenuOpen = selectedCheckboxes.length > 0;
    if (isBulkActionsMenuOpen) {
      window.scroll({
        top: 500,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [selectedCheckboxes]);

  return (
    <>
      <Helmet>
        <title>Inventory | PrimeLister</title>
      </Helmet>

      <Container disableGutters={true} maxWidth={!isInFullscreenMode ? 'xl' : false}>
        {!isInFullscreenMode && (
          <Stack
            direction="row"
            sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '32px' }}
          >
            <Typography variant="h4">Inventory</Typography>
          </Stack>
        )}
        <Grid item xs={12}>
          <InventoryCard />
        </Grid>
      </Container>
    </>
  );
}
