import { Box, Skeleton, Stack, useTheme } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';

export default function ListingSkeletonCard() {
  const isMobile = useResponsive('down', 'sm');
  return !isMobile ? <SkeletonCardDesktopVariant /> : <SkeletonCardMobileVariant />;
}

const SkeletonCardMobileVariant = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: '#F4F6F8',
        borderRadius: '16px',
        display: 'flex',
        alignItems: 'flex-start',
        p: '8px',
        pb: '0',
        [theme.breakpoints.down(321)]: {
          flexDirection: 'column',
        },
      }}
    >
      <Box>
        <Skeleton
          sx={{
            borderRadius: '12px',
            background: '#DFE3E8',
          }}
          variant="rounded"
          width={48}
          height={48}
        />
      </Box>
      <Stack
        gap={'12px'}
        sx={{
          ml: '12px',
          pb: '23px',
          [theme.breakpoints.down(321)]: {
            ml: 0,
            pb: '39px',
            mt: '16px',
            gap: '16px',
          },
        }}
      >
        <Skeleton
          variant="rounded"
          sx={{
            borderRadius: '100px',
            background: '#DFE3E8',
          }}
          width={265}
          height={36}
        />
        <Skeleton
          sx={{
            borderRadius: '100px',
            background: '#DFE3E8',
          }}
          variant="rounded"
          width={199}
          height={24}
        />
        <Skeleton
          sx={{
            borderRadius: '100px',
            background: '#DFE3E8',
          }}
          variant="rounded"
          width={133}
          height={24}
        />
      </Stack>
    </Box>
  );
};

const SkeletonCardDesktopVariant = () => (
  <Box
    sx={{
      backgroundColor: '#F4F6F8',
      borderRadius: '16px',
      p: '8px 8px 36px 8px',
      width: 'min-content',
    }}
  >
    <Stack>
      <Skeleton
        sx={{
          mb: '16px',
          background: '#DFE3E8',
          borderRadius: '12px'
        }}
        variant="rounded"
        width={234}
        height={234}
      />
      <Stack
        gap={'12px'}
        sx={{
          paddingLeft: '8px',
          paddingRight: '8px',
        }}
      >
        <Skeleton
          sx={{
            background: '#DFE3E8',
            borderRadius: '100px',
          }}
          variant="rounded"
          width={218}
          height={40}
        />
        <Skeleton
          sx={{
            background: '#DFE3E8',
            borderRadius: '100px',
          }}
          variant="rounded"
          width={163.5}
          height={24}
        />
        <Skeleton
          sx={{
            background: '#DFE3E8',
            borderRadius: '100px',
          }}
          variant="rounded"
          width={109}
          height={24}
        />
      </Stack>
    </Stack>
  </Box>
);
