// @mui
import {
  Stack,
  Typography,
  Avatar,
  useMediaQuery,
  Chip,
  Box,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  SvgIcon,
} from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import Iconify from 'src/components/iconify';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { IActivityData, IOngoingJobData } from '../utils/types';
import { getActivityTypeProps, getOngoingActivityTypeProps } from '../helpers/getActivityTypeProps';
import { IRootState } from 'src/store';
import { useDispatch, useSelector } from 'react-redux';
import { getDateWithTimeZone } from '../../../../utils/getDateWithTimezone';
import { UserAction } from '@beta.limited/primelister';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import { useRef, useState } from 'react';
import MenuPopover from 'src/components/menu-popover/MenuPopover';
import {
  setIsRemoveTaskDialogOpen,
  setRemoveTaskDialogType,
  setToBeRemovedTask,
} from 'src/store/automations/slices/activitySlice';
import { RemoveTaskDialogType } from './OngoingTabComponent';
import { isValid } from 'date-fns';

export default function OngoingTaskCard({ ongoingTask }: { ongoingTask: IOngoingJobData }) {
  const isDesktop = useResponsive('up', 'md');
  const matches = useMediaQuery('(min-width:835px)');
  const mobileMatches = useMediaQuery('(min-width:340px)');
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const iconButtonRef = useRef(null);

  const { userAccountDetail } = useSelector((state: IRootState) => state.account);
  const { activityIcon, activityDisplayName } = getOngoingActivityTypeProps(
    ongoingTask.action as UserAction
  );

  const cardImage = ongoingTask.image ?? 'https://r2.primelister.com/image_thumbnail.svg';
  const activityTime = getDateWithTimeZone(
    ongoingTask.scheduledAt ? ongoingTask.scheduledAt : ongoingTask.createdAt,
    userAccountDetail?.timeZone?.name,
    'DD MMM YYYY, hh:mm A'
  );

  const handleClosePopover = () => setOpenPopover(false);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    setOpenPopover(true);

  const theme = useTheme();

  const dispatch = useDispatch();

  const handleRemoveTask = (task: IOngoingJobData) => {
    dispatch(setRemoveTaskDialogType(RemoveTaskDialogType.SINGLE));
    dispatch(setToBeRemovedTask(task));
    dispatch(setIsRemoveTaskDialogOpen(true));
  };

  const displayTime = isValid(new Date(activityTime)) ? activityTime : 'Now';

  return (
    <Stack
      alignItems={isDesktop ? 'center' : 'flex-start'}
      sx={{
        borderRadius: '8px',
        border: (theme) => `solid 1px ${theme.palette.divider}`,
        '&:hover': {
          bgcolor: 'background.paper',
          boxShadow: (theme) => theme.customShadows.z20,
        },
        cursor: 'default',
        minHeight: '110px',
        width: matches ? '800px' : 'auto',
        minWidth: '260px',
        maxWidth: '800px',
        mb: '16px',
      }}
    >
      <Stack
        direction={'row'}
        sx={{
          width: 1,
          bgcolor: '#F4F6F8',
          alignItems: 'center',
          minHeight: '54px',
          pt: '24px',
          pb: '8px',
          position: 'relative',
          paddingLeft: '112px',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          paddingRight: '15px',
        }}
      >
        {' '}
        <Avatar
          sx={{
            position: 'absolute',
            top: '16px',
            left: '16px',
            minWidth: '80px',
            maxWidth: '80px',
            minHeight: '80px',
            maxHeight: '80px',
            zIndex: 1,
            borderRadius: '8px !important',
          }}
          src={cardImage}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              [theme.breakpoints.down(381)]: {
                maxWidth: '140px',
              },
            }}
          >
            {ongoingTask.title}
          </Typography>
          <IconButton
            sx={{
              [theme.breakpoints.down(381)]: {
                position: 'absolute',
                top: 0,
                right: 0,
                p: '16px',
              },
            }}
            ref={iconButtonRef}
            onClick={handleOpen}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
          <MenuPopover
            open={openPopover ? iconButtonRef.current : null}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            arrow="right-center"
          >
            <MenuItem
              sx={{
                color: '#FF5630',
                [theme.breakpoints.down(381)]: {
                  minHeight: '34px',
                },
              }}
              onClick={() => handleRemoveTask(ongoingTask)}
            >
              <SvgIcon sx={{ width: 20, height: 20 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M16.4828 1.66498H3.51726C3.02602 1.66498 2.5549 1.86012 2.20754 2.20748C1.86018 2.55484 1.66504 3.02596 1.66504 3.5172V16.4828C1.66504 16.974 1.86018 17.4451 2.20754 17.7925C2.5549 18.1398 3.02602 18.335 3.51726 18.335H16.4828C16.9741 18.335 17.4452 18.1398 17.7925 17.7925C18.1399 17.4451 18.335 16.974 18.335 16.4828V3.5172C18.335 3.02596 18.1399 2.55484 17.7925 2.20748C17.4452 1.86012 16.9741 1.66498 16.4828 1.66498ZM13.334 14.6305L10 11.2965L6.66604 14.6305L5.36948 13.334L8.70348 9.99998L5.36948 6.66598L6.66604 5.36942L10 8.70342L13.334 5.36942L14.6306 6.66598L11.2966 9.99998L14.6306 13.334L13.334 14.6305Z"
                    fill="#FF5630"
                  />
                </svg>
              </SvgIcon>
              Remove
            </MenuItem>
          </MenuPopover>
        </Box>
      </Stack>
      <Stack
        direction={matches ? 'row' : 'column'}
        sx={{
          width: 1,
          alignItems: matches ? 'center' : 'flex-start',
          justifyContent: matches ? 'space-between' : 'space-evenly',
          minHeight: matches ? '56px' : '136px',
          position: 'relative',
          paddingLeft: '112px',
          paddingRight: matches ? '27px' : '0px',
        }}
      >
        <Stack
          direction={'row'}
          spacing={matches ? '12px' : '0px'}
          sx={{
            alignItems: 'center',
            minWidth: '240px',
            [theme.breakpoints.down(381)]: { minWidth: '0' },
          }}
        >
          <Box
            sx={{
              minWidth: isDesktop ? '250px' : '0',
            }}
          >
            <Chip
              variant={'soft'}
              icon={
                <Avatar
                  sx={{ width: '24px', height: '24px' }}
                  src={`/icons/automationIcons/${activityIcon}.svg`}
                />
              }
              label={activityDisplayName}
              color="primary"
              sx={{
                width: 'min-content',
                height: '32px',
                fontWeight: 400,
                fontSize: '13px !important',
                color: '#2065D1',
                borderRadius: '50px',
                backgroundColor: 'rgba(32, 101, 209, 0.16)',
                [theme.breakpoints.down(381)]: {
                  minHeight: '32px',
                  height: '100%',
                  width: '100%',
                  '& .MuiChip-label': {
                    whiteSpace: 'break-spaces',
                  },
                },
              }}
            />
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing={'12px'}
          sx={{
            maxWidth: '200px',
            minWidth: '200px',
            alignItems: 'center',
            color: 'rgba(99, 115, 129, 1)',
          }}
        >
          <Iconify icon="mdi:store" width={'24px'} height={'24px'} />
          <Typography
            variant="body2"
            sx={{
              [theme.breakpoints.down(381)]: {
                ml: '8px !important',
              },
            }}
          >
            {ongoingTask.shop}
          </Typography>
        </Stack>
        <Stack direction="row" sx={{ alignItems: 'center', color: 'rgba(99, 115, 129, 1)' }}>
          <AccessTimeFilledIcon />
          <Typography
            variant="body2"
            ml={'12px'}
            sx={{
              fontSize: '14px !important',
              lineHeight: '18px',
              [theme.breakpoints.down(381)]: {
                ml: '8px !important',
              },
            }}
          >
            {displayTime}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
