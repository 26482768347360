import { Card } from '@mui/material';

import InventoryTable from './InventoryPage/InventoryTable';
import InventoryDialogs from '../components/dialogs/inventory/InventoryDialogs';

export default function InventoryCard() {
  return (
    <>
      <Card>
        <InventoryTable />
        <InventoryDialogs />
      </Card>
    </>
  );
}
