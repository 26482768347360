// @mui
import { Card, Button, Typography, Stack, CardProps } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { IRootState } from 'src/store';
import {
  checkCouponCodeValidationRequest,
  checkCouponCodeViaComparison,
  setIsDowngradeConfirmationDialogOpen,
  setIsUpgradeConfirmationDialogOpen,
  setSelectedSubscription,
  updateSubscriptionRequest,
} from 'src/store/dashboard/slices/pricingSlice';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { isLoggedIn } from 'src/utils/isLoggedIn';
import { SubscriptionStatus } from 'src/store/dashboard/slices/accountSlice';
// components

// assets

// ----------------------------------------------------------------------

interface Props extends CardProps {
  card: {
    subscription: string;
    price: number;
    labelAction: string;
    caption?: string;
    planId: string;
    stripePlanId: string;
    planName: string;
    lists: {
      text: string;
      isAvailable: boolean;
    }[];
  };
  index: number;
}

export default function PoshmarkAutoPricingCard({ card, index, sx, ...other }: Props) {
  const { billingSubscriptionList, coupon, poshmarkShopCapacity } = useSelector(
    (state: IRootState) => state.account
  );
  const {
    loading,
    selectedSubscription,
    hasUserChangedTheSliderValue,
    sliderValue,
    stripeReferrerUrl,
  } = useSelector((state: IRootState) => state.pricing);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { subscription, price, lists, labelAction, caption, planId, stripePlanId, planName } = card;
  const { referrerCode } = isLoggedIn();

  const isCurrent =
    billingSubscriptionList[1]?.status?.toLowerCase().includes(SubscriptionStatus.ACTIVE) &&
    planName === billingSubscriptionList[1]?.planName;

  const isSubscribeButton = labelAction.toLowerCase().includes('subscribe');
  const isYearly = planId.toLowerCase().includes('annual');

  const handleUpdateSubscription = () => {
    if (isSubscribeButton) {
      dispatch(setSelectedSubscription(card));

      const navigateToPaymentSummary = () => {
        const paymentSummaryPath =
          PATH_DASHBOARD.subscription.paymentSummary + `?planId=${planId}&closet=${sliderValue}`;
        navigate(paymentSummaryPath);
      };

      if (referrerCode && coupon && !isYearly) {
        dispatch(
          checkCouponCodeViaComparison({
            plan: planId,
            coupon: coupon,
            referrerCode: referrerCode,
            typeReferrer: 'referrerCode',
            typeCoupon: 'coupon',
            navigate,
            sliderValue,
          })
        );
      } else if (referrerCode && !coupon && !isYearly) {
        const code = referrerCode;
        const type = 'referrerCode';
        dispatch(
          checkCouponCodeValidationRequest({
            plan: planId,
            code,
            type,
            navigate,
            sliderValue,
          })
        );
      } else if (coupon && (!referrerCode || isYearly)) {
        const code = coupon;
        const type = 'coupon';

        dispatch(
          checkCouponCodeValidationRequest({
            plan: planId,
            code,
            type,
            navigate,
            sliderValue,
          })
        );
      } else {
        navigateToPaymentSummary();
      }
    } else {
      if (sliderValue > poshmarkShopCapacity) {
        dispatch(setIsUpgradeConfirmationDialogOpen({ openDialog: true, stripePlanId }));
      } else if (sliderValue < poshmarkShopCapacity) {
        dispatch(setIsDowngradeConfirmationDialogOpen({ openDialog: true, stripePlanId }));
      } else {
        dispatch(
          updateSubscriptionRequest({
            returnUrl: stripeReferrerUrl,
            flowType: 'subscription_update_confirm',
            subscriptionId: billingSubscriptionList[1].subscriptionId,
            subscriptionItemId: billingSubscriptionList[1].subscriptionItemId,
            planId: stripePlanId,
            shopCapacity: sliderValue,
          })
        );
      }
    }
  };

  const shouldShowCurrentButton = !hasUserChangedTheSliderValue && isCurrent;
  const shouldShowUpdateOrSubscribeButton = hasUserChangedTheSliderValue || !isCurrent;

  const renderButton = (
    shouldShowCurrentButton: boolean,
    shouldShowUpdateOrSubscribeButton: boolean
  ) => (
    <>
      {shouldShowCurrentButton && (
        <Button
          fullWidth
          size="large"
          variant="contained"
          disabled
          sx={{ bgcolor: '#108674 !important', color: 'white !important' }}
        >
          Current Plan
        </Button>
      )}
      {shouldShowUpdateOrSubscribeButton && (
        <LoadingButton
          loading={loading && selectedSubscription?.planId === planId}
          fullWidth
          size="large"
          variant="contained"
          onClick={handleUpdateSubscription}
        >
          {isCurrent && hasUserChangedTheSliderValue ? 'Update Plan' : labelAction}
        </LoadingButton>
      )}
    </>
  );

  return (
    <Card
      sx={{
        p: 5,
        maxWidth: { xxs: '100% ', xs: '350px' },
        width: '100%',
        '&:hover': {
          boxShadow: (theme) => theme.customShadows.z24,
          bgcolor: 'background.default',
        },
        ...sx,
      }}
      {...other}
    >
      {index === 2 && (
        <Label color="info" sx={{ top: 16, right: 16, position: 'absolute' }}>
          POPULAR - SAVE 20%
        </Label>
      )}

      <Typography variant="overline" sx={{ color: 'text.secondary' }}>
        {subscription}
      </Typography>

      <Stack spacing={1} direction="row" sx={{ my: 2 }}>
        {(index === 1 || index === 2) && <Typography variant="h5">$</Typography>}

        <Typography variant="h2">{price.toFixed(2)}</Typography>

        {(index === 1 || index === 2) && (
          <Typography component="span" sx={{ alignSelf: 'center', color: 'text.secondary' }}>
            /month
          </Typography>
        )}
      </Stack>
      <Typography
        variant="caption"
        sx={{
          color: 'primary.main',
          textTransform: 'capitalize',
        }}
      >
        {caption}
      </Typography>
      <Stack component="ul" spacing={2} sx={{ p: 0 }} mb={4} mt={caption ? 3 : 8}>
        {lists.map((item) => (
          <Stack
            key={item.text}
            component="li"
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              typography: 'body2',
              color: item.isAvailable ? 'text.primary' : 'text.disabled',
            }}
          >
            <Iconify
              icon={item.isAvailable ? 'eva:checkmark-fill' : 'eva:close-fill'}
              width={16}
              sx={{
                color: item.isAvailable ? 'primary.main' : 'inherit',
              }}
            />
            <Typography variant="body2">{item.text}</Typography>
          </Stack>
        ))}
      </Stack>

      {renderButton(shouldShowCurrentButton, shouldShowUpdateOrSubscribeButton)}
    </Card>
  );
}
