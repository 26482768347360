import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IFilterCategory } from './types/CategoryTreeView';
import { Box, Paper, CircularProgress } from '@mui/material';
import OverflowTypography from 'src/components/product-card/OverflowTypography';
import { IRootState } from 'src/store';
import {
  getFilterCategoriesRequest,
  setIsCategoriesLoading,
  setLoadingNodeId,
  setSelectedFilterCategory,
  setSelectedFilterDepartment,
  setSelectedFilterSubcategory,
} from 'src/store/automations/slices/myListingsSlice';
import { useLocation } from 'react-router-dom';
import { useTreeView } from 'src/hooks/useTreeView';

interface CategoryListTreeViewProps {
  onSelect: (department: string, category: string, subcategory: string) => void;
}

const CategoryListTreeView: React.FC<CategoryListTreeViewProps> = ({ onSelect }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    selectedFilterCategory,
    selectedFilterDepartment,
    selectedFilterSubcategory,
    filterCategories,
    loadingNodeId,
    isCategoriesLoading,
  } = useSelector((state: IRootState) => state.myListings);

  const {
    selectedIds,
    setSelectedIds,
    handleSelectionLogic,
    findInitialSelectedIds,
    generateNodeId,
    isNodeSelected,
  } = useTreeView();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const department = params.get('department') || 'all-departments';
    const category = params.get('category') || '';
    const subcategory = params.get('subcategory');

    dispatch(setSelectedFilterDepartment(department));
    if (category) {
      dispatch(setSelectedFilterCategory(category));
      if (subcategory) {
        dispatch(setSelectedFilterSubcategory(subcategory));
      } else {
        dispatch(setSelectedFilterSubcategory(''));
      }
    } else {
      dispatch(setSelectedFilterCategory(''));
      dispatch(setSelectedFilterSubcategory(''));
    }
  }, [location.search, dispatch]);

  useEffect(() => {
    if (selectedFilterDepartment) {
      const initialSelectedIds = findInitialSelectedIds(
        filterCategories,
        selectedFilterDepartment,
        selectedFilterCategory,
        selectedFilterSubcategory
      );
      setSelectedIds(initialSelectedIds);
    }
  }, [filterCategories]);

  useEffect(() => {
    dispatch(setIsCategoriesLoading(!!loadingNodeId));
  }, [loadingNodeId]);

  const handleSelect = async (id: string, parentIds: string[] = []) => {
    dispatch(setLoadingNodeId(id));
    const newSelectedIds = handleSelectionLogic(id, parentIds, onSelect);

    if (newSelectedIds) {
      setSelectedIds(newSelectedIds);
    }
  };

  useEffect(() => {
    if (selectedFilterDepartment === 'all-departments') {
      setSelectedIds([]); // Reset selected IDs when category is reset to "all-departments"
    }
  }, [selectedFilterDepartment]);

  const renderLoadingIndicator = (nodeId: string, parentIds: string[]) =>
    isCategoriesLoading && loadingNodeId === nodeId && parentIds.length < 2 ? (
      <CircularProgress size={14} sx={{ marginLeft: '8px' }} />
    ) : (
      ''
    );

  const renderTree = (nodes: IFilterCategory[], parentIds: string[] = []) => (
    <Box>
      {nodes.map((node) => {
        const nodeId = generateNodeId(node.id, parentIds, selectedFilterDepartment);
        const isSelected = isNodeSelected({
          nodeId,
          nodeIdAlternative: node.id,
          selectedIds,
          parentIds,
        });

        return (
          <Box key={node.id} sx={{ pl: parentIds.length * 1 }}>
            <Paper
              onClick={() => handleSelect(node.id, parentIds)}
              sx={{
                cursor: 'pointer',
                width: '100%',
                justifyContent: 'flex-start',
                textTransform: 'none',
                color: isSelected ? '#2065D1' : '#212B36',
                paddingTop: '7px',
                paddingRight: '16px',
                paddingBottom: '7px',
              }}
            >
              <OverflowTypography
                sx={{
                  fontSize: '14px',
                  lineHeight: '22px',
                  fontWeight: isSelected ? '500 !important' : '400 !important',
                }}
              >
                {node.name}
                {renderLoadingIndicator(node.id, parentIds)}
              </OverflowTypography>
            </Paper>
            {Array.isArray(node.children) && node.children.length > 0 && (
              <Box>{renderTree(node.children, [...parentIds, node.id])}</Box>
            )}
          </Box>
        );
      })}
    </Box>
  );

  return (
    <Box
      sx={{
        maxHeight: '216px',
        overflowY: 'auto',
      }}
    >
      {renderTree(filterCategories)}
    </Box>
  );
};

export default CategoryListTreeView;
