import { Alert, Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useResponsive from 'src/hooks/useResponsive';
import OngoingTabDropdown from './OngoingTabDropdown';
import OngoingTabCardContainer from './OngoingCardContainer';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { LoadingButton } from '@mui/lab';
import Iconify from 'src/components/iconify';
import {
  setIsRemoveTaskDialogOpen,
  setRemoveTaskDialogType,
  setSelectedOngoingTask,
} from 'src/store/automations/slices/activitySlice';
import RemoveTaskDialog from './dialogs/RemoveTaskDialog';
import { useLocation } from 'react-router';
import { ongoingTabItemsList } from '../utils/constants';

export enum RemoveTaskDialogType {
  ALL = 'all',
  SINGLE = 'selected',
}

const OngoingTabComponent = () => {
  const isMobile = useResponsive('down', 'sm');
  const { ongoingList, removeTaskDialogType, toBeRemovedTask, selectedOngoingTask } = useSelector(
    (state: IRootState) => state.activity
  );
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    const alertClosed = localStorage.getItem('alertClosed');
    if (alertClosed === 'true') {
      setShowAlert(false);
    }
  }, []);

  const dispatch = useDispatch();

  const theme = useTheme();

  const showRemoveAllButton = ongoingList.length > 0;
  const showTotalTasksCount = ongoingList.length > 0;

  const openRemoveAllDialog = () => {
    dispatch(setRemoveTaskDialogType(RemoveTaskDialogType.ALL));
    dispatch(setIsRemoveTaskDialogOpen(true));
  };

  return (
    <Box>
      {showAlert && (
        <Box sx={{ mb: 3 }}>
          <Alert
            severity="info"
            sx={{
              backgroundColor: 'rgba(0, 184, 217, 0.08)',
              border: '1px solid rgba(0, 184, 217, 0.16)',
              borderRadius: '8px',
              minHeight: '50px',
            }}
            action={
              <IconButton
                color="inherit"
                size="small"
                onClick={() => {
                  localStorage.setItem('alertClosed', 'true');
                  setShowAlert(false);
                }}
              >
                <Iconify icon="mingcute:close-line" color={'#006C9C'} />
              </IconButton>
            }
          >
            <Typography
              sx={{
                color: '#006C9C',
                height: '100%',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '22px',
              }}
            >
              This section displays the ongoing custom tasks you created for your listings.
            </Typography>
          </Alert>
        </Box>
      )}
      <Box
        sx={{
          mb: 3,
          display: 'flex',
          justifyContent: 'space-between',
          [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              alignItems: 'flex-start',
            },
            // flexShrink: 1,
          }}
        >
          <OngoingTabDropdown />
          {showTotalTasksCount && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexShrink: 0,
                ml: '12px',
                [theme.breakpoints.down('sm')]: {
                  ml: 0,
                  mt: '8px',
                },
              }}
            >
              <Typography
                sx={{
                  padding: '9.5px 6px',
                  minWidth: '25px',
                  height: '25px',
                  textAlign: 'center',
                  color: '#fff',
                  bgcolor: '#3564CA',
                  borderRadius: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: '8px',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '15px',
                }}
              >
                {ongoingList.length}
              </Typography>
              <Typography
                sx={{
                  fontFamily: '"Public Sans"',
                  fontStyle: 'normal',
                  fontSize: '14px',
                  lineHeight: '26px',
                  fontWeight: 400,
                  color: '#637381',
                }}
              >
                Tasks in Total
              </Typography>
            </Box>
          )}
        </Box>
        {showRemoveAllButton && (
          <LoadingButton
            onClick={openRemoveAllDialog}
            variant="text"
            color="error"
            sx={{
              fontSize: '13px',
              lineHeight: '22px',
            }}
          >
            Remove All
          </LoadingButton>
        )}
      </Box>
      <OngoingTabCardContainer />
      <RemoveTaskDialog
        isMulti={removeTaskDialogType === RemoveTaskDialogType.ALL}
        toBeRemovedTask={toBeRemovedTask ?? undefined}
      />
    </Box>
  );
};

export default OngoingTabComponent;
