import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { IRootState } from 'src/store';
import { useDispatch, useSelector } from 'react-redux';
import { setIsImportErrorDialogOpen } from 'src/store/dashboard/slices/importTableSlice';

const ImportErrorDialog = () => {
  const { isImportErrorDialogOpen } = useSelector((state: IRootState) => state.importTable);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setIsImportErrorDialogOpen(false));
  };
  return (
    <Dialog open={isImportErrorDialogOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Unable to Retrieve Listings</DialogTitle>
      <DialogContent>
        <Typography>
          We can't get your listings. Please reach out to our customer support team for assistance.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportErrorDialog;
