import cookie from 'react-cookies';
import { isValidToken } from 'src/auth/utils';

export const isLoggedIn = () => {
  const userData = cookie.load('userData') || {};
  const {
    accessToken,
    refreshToken,
    email,
    fullName,
    proStatus,
    blockState,
    userId,
    referrerCode,
  } = userData;

  return {
    ...userData,
    loggedIn: isValidToken(accessToken) && refreshToken,
  };
};
