import {
  Alert,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'src/store';
import {
  setIsConnectionErrorDialogOpen,
  updateAutomationSettingsRequest,
} from 'src/store/automations/slices/automationsSlice';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Iconify from 'src/components/iconify';
import { IDataForUpdateAutomationSettings } from 'src/pages/automations/AutomationsPage/types/types';
import { Automation } from '@beta.limited/primelister';

const ReturnShareLimit = () => {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:400px)');

  const {
    selectedAutomationOption,
    automationsSettings,
    isAutomationOptionDrawerOpen,
    activeClosetAutomationPlatform,
  } = useSelector((state: IRootState) => state.automations);
  const { activeClosetCredentialsId, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.myCloset
  );

  const onChangeTimer = React.useRef<any>();
  const onChangeAlertTimer = React.useRef<any>();

  const [isReturnShareLimitToggled, setIsReturnShareLimitToggled] = useState(true);
  const [renderError, setRenderError] = useState(false);

  const returnShareLimitInitialValue =
    automationsSettings[Automation.RETURN_SHARE]?.config.dailyTaskLimit || 0;

  const validationSchema = Yup.object().shape({
    returnShareLimitInitialValue: Yup.number()
      .required('Please enter a number that is between 1 and 1000.')
      .min(1, 'Please enter a number that is between 1 and 1000.')
      .max(1000, 'Please enter a number that is between 1 and 1000.')
      .test(
        'is-multiple-of-10',
        'Invalid input. Please enter a whole number without decimals.',
        (value) => Number.isInteger(value)
      ),
  });

  const handleReturnShareLimitFormik = useFormik({
    enableReinitialize: true,
    initialValues: { returnShareLimitInitialValue },
    validationSchema,
    onSubmit: () => {},
  });

  const handleAutomationSettingsChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (hasActiveClosetConnection) {
      clearTimeout(onChangeTimer.current);
      clearTimeout(onChangeAlertTimer.current);

      handleReturnShareLimitFormik.handleChange(event);

      onChangeAlertTimer.current = setTimeout(() => setRenderError(true), 500);

      const inputValue = Number(event.target.value);
      const isValidInput = inputValue >= 1 && inputValue < 1001;
      const hasValidInputValue = Number.isInteger(inputValue) && isValidInput;

      if (hasValidInputValue) {
        const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
        const dataForUpdating: IDataForUpdateAutomationSettings = {
          settings: {
            [Automation.RETURN_SHARE]: {
              config: {
                dailyTaskLimit: parseInt(event.target.value),
              },
            },
          },
          toastMessage,
          displayName: selectedAutomationOption.displayName,
          activeClosetAutomationPlatform,
          activeClosetCredentialsId,
        };

        const isInputValueDifferentFromInitial =
          Number(event.target.value) !==
          Number(handleReturnShareLimitFormik.initialValues.returnShareLimitInitialValue);

        onChangeTimer.current = setTimeout(() => {
          isInputValueDifferentFromInitial &&
            dispatch(updateAutomationSettingsRequest(dataForUpdating));
        }, 700);
      }
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
    setRenderError(false);
  };

  useEffect(
    () => () => {
      clearTimeout(onChangeTimer.current);
    },
    []
  );

  useEffect(() => {
    handleReturnShareLimitFormik.resetForm();
  }, [isAutomationOptionDrawerOpen]);

  const renderAlert = () => {
    if (renderError) {
      return (
        <Alert
          sx={{
            color: '#FF5630',
            bgcolor: '#F4F6F8',
            maxWidth: '360px !important',
            overflow: 'hidden',
            p: 0,
            mt: '-2px !important',
          }}
          severity="error"
          variant="standard"
          icon={false}
        >
          {handleReturnShareLimitFormik.errors.returnShareLimitInitialValue as string}
        </Alert>
      );
    }
  };

  const isThereAnError =
    handleReturnShareLimitFormik.errors.returnShareLimitInitialValue && renderError;

  const renderReturnSharelimitCountInputField = () =>
    isReturnShareLimitToggled && (
      <>
        <Stack direction="row" alignItems="center" sx={{ fontSize: '14px !important' }}>
          Share
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Please enter a whole number between 1-1000."
            placement={'top'}
          >
            <TextField
              type="number"
              name="returnShareLimitInitialValue"
              sx={{
                width: 0.19,
                mx: 1,
                '& .MuiOutlinedInput-root': {
                  maxHeight: '2.5rem',
                  bgcolor: `${isThereAnError ? '#FFF2EF' : '#eeeff1'}`,
                  color: `${isThereAnError ? '#B71C19' : '#000000'}`,
                },
              }}
              value={handleReturnShareLimitFormik.values.returnShareLimitInitialValue}
              onChange={handleAutomationSettingsChange}
            />
          </Tooltip>
          items everyday
        </Stack>
        {handleReturnShareLimitFormik.errors.returnShareLimitInitialValue && renderAlert()}
      </>
    );

  return (
    <Stack spacing={2.125} px={matches ? 0 : 1.5}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" noWrap sx={{ display: 'flex', alignItems: 'center' }}>
          Daily Share Limit
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Configure the number of listings that can be shared daily using the return share feature."
            placement={'top'}
            sx={{ textAlign: 'center !important' }}
          >
            <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
          </Tooltip>
        </Typography>
        <IconButton
          size="small"
          onClick={() => setIsReturnShareLimitToggled(!isReturnShareLimitToggled)}
          sx={{ cursor: 'pointer' }}
        >
          <Iconify
            icon={isReturnShareLimitToggled ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}
          />
        </IconButton>
      </Stack>
      {renderReturnSharelimitCountInputField()}
    </Stack>
  );
};

export default ReturnShareLimit;
