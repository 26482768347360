import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  IPlatforms,
  setAllPlatformsInitialLoading,
  setIsPlatformLoading,
  IPlatformStateFinder,
  setIsPlatformLoggedIn,
  setPlatformData,
} from 'src/store/dashboard/slices/myShopsSlice';

import { toast } from 'react-toastify';
import { IRootState } from 'src/store';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { setCrosslistSourceLoginErrorPlatformsBulk } from 'src/store/dashboard/slices/inventorySlice';
import { MixpanelEventTypes, mixpanelTrack } from 'src/utils/mixpanel';
import { BackgroundActionsEnum } from 'src/utils/ExtensionMessageChannel';
import { Marketplace } from '@beta.limited/primelister';

/**
 * Determines the appropriate shopperId based on the marketplace.
 *
 * For Mercari and Depop, the userId is used as the shopperId.
 * For all other marketplaces, the username is used as the shopperId.
 *
 * @param {Marketplace} marketplace - The marketplace for which to determine the shopperId.
 * @param {string | null} userId - The user's ID, used for Mercari and Depop.
 * @param {string | null} username - The user's username, used for other marketplaces.
 * @returns {string} The determined shopperId.
 */
export function determineShopperId(
  marketplace: Marketplace,
  userId: string | null,
  username: string | null
): string | null {
  const isUserIdMarketplace = [Marketplace.MERCARI, Marketplace.DEPOP].includes(marketplace);
  if (isUserIdMarketplace) {
    return userId;
  } else {
    return username;
  }
}

const usePlatformChecker = () => {
  const dispatch = useDispatch();
  const { isExtensionConnected } = useSelector((state: IRootState) => state.home);

  const location = useLocation();

  const shouldRenderToast = location.pathname.includes(PATH_DASHBOARD.myShops);

  // Functions to check individual platforms
  const checkPlatform = async (platform: IPlatforms) => {
    try {
      dispatch(
        setIsPlatformLoading({ isLoading: true, platform: platform as IPlatformStateFinder })
      );
      setTimeout(() => {
        dispatch(
          setIsPlatformLoading({ isLoading: false, platform: platform as IPlatformStateFinder })
        );
      }, 3000);

      /**
       * Special handling for Depop platform
       *
       * Depop requires updating cookies before checking login status.
       * This ensures accurate login detection on initial refresh.
       * Without this step, the login status can't be fetched.
       *
       * @param {IPlatforms} platform - The current platform being checked
       */
      if (platform === IPlatforms.DEPOP) {
        await window.extension.sendCommand(BackgroundActionsEnum.UPDATE_COOKIES, {
          marketplace: platform,
        });
      }

      const { isLoggedIn, username, userId } = await (window.extension.sendCommand(
        BackgroundActionsEnum.CHECK_LOGIN_STATUS,
        {
          platform,
        }
      ) as Promise<{
        isLoggedIn: boolean;
        username: string | null;
        userId: string | number | null;
      }>);

      dispatch(setIsPlatformLoggedIn({ isLoggedIn, platform: platform as IPlatformStateFinder }));
      const userNameForShop = username ?? '';
      const userIdForShop = userId ?? '';
      dispatch(
        setPlatformData({
          username: userNameForShop,
          userId: userIdForShop,
          platform: platform as IPlatformStateFinder,
        })
      );

      // It's okay to not check isLoggedIn here because this won't work at all in ext v2 side if the user is not logged in
      window.extension.sendCommand(BackgroundActionsEnum.UPDATE_COOKIES, {
        marketplace: platform,
        shopperId: determineShopperId(
          platform as unknown as Marketplace,
          userId ? userId.toString() : null,
          username
        ),
      });

      if (isLoggedIn) {
        mixpanelTrack(MixpanelEventTypes.MARKETPLACE_CONNECTION_ESTABLISHED, {
          marketplace: platform as IPlatforms,
          shop: userNameForShop,
        });
      }
    } catch (error) {
      console.error(`Error checking platform ${platform}:`, error);
      dispatch(
        setIsPlatformLoading({ isLoading: false, platform: platform as IPlatformStateFinder })
      );
      dispatch(
        setIsPlatformLoggedIn({ isLoggedIn: false, platform: platform as IPlatformStateFinder })
      );
    }
  };

  // run checkPlatforms for all platforms
  const checkAllPlatformsConnection = async () => {
    const platformsToCheck = [
      IPlatforms.DEPOP,
      IPlatforms.MERCARI,
      IPlatforms.POSHMARKUS,
      IPlatforms.POSHMARKCA,
      IPlatforms.FACEBOOK,
      IPlatforms.EBAYUS,
      IPlatforms.EBAYCA,
      IPlatforms.EBAYAU,
      IPlatforms.ETSY,
      IPlatforms.SHOPIFY,
      IPlatforms.GRAILED,
    ];

    if (isExtensionConnected) {
      try {
        await Promise.allSettled(platformsToCheck.map((platform) => checkPlatform(platform)));

        dispatch(setAllPlatformsInitialLoading(false));
        // This reseting is for to sync array when connect all shops runs
        dispatch(setCrosslistSourceLoginErrorPlatformsBulk([]));
        if (shouldRenderToast) {
          toast.success('All connections successfully checked', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      } catch (error) {
        console.error('Unexpected error during platform checks:', error);
        dispatch(setAllPlatformsInitialLoading(false));
      }
    } else {
      dispatch(setAllPlatformsInitialLoading(false));
    }
  };

  return { checkAllPlatformsConnection };
};

export default usePlatformChecker;
