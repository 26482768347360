import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  TextField,
  Autocomplete,
  Chip,
} from '@mui/material';

import {
  inventoryRemoveTagsRequest,
  setIsRemoveTagsDialogOpen,
  setSelectedTagsToAdd,
} from 'src/store/dashboard/slices/inventorySlice';
import { IRootState } from 'src/store';
import { getFilterModelShaped } from 'src/pages/dashboard/Inventory/helpers/getFilterModelShaped';
import { LoadingButton } from '@mui/lab';
import { useTagUtilities } from 'src/pages/dashboard/Inventory/helpers/useTagUtilities';
import { useMemo } from 'react';

export default function InventoryRemoveTagsDialog() {
  const dispatch = useDispatch();

  const { getTagColor, handleOnChange, renderTagMenuItem } = useTagUtilities();

  const {
    isRemoveTagsDialogOpen,
    loading,
    page,
    rowsPerPage,
    sortModel,
    inventoryFilterModel,
    searchValue,
    selectedTagsToAdd,
    bulkActionsListingList,
  } = useSelector((state: IRootState) => state.inventory);

  const { tagsData } = useSelector((state: IRootState) => state.groupsAndTags);

  const handleClose = () => {
    dispatch(setIsRemoveTagsDialogOpen(false));
    dispatch(setSelectedTagsToAdd([]));
  };

  const bulkItems = bulkActionsListingList.map((listing) => listing._id);

  const handleRemoveTags = () => {
    const orderBy = sortModel.length > 0 ? sortModel[0].field : 'added';
    const order = sortModel.length > 0 ? sortModel[0].sort : 'desc';
    dispatch(
      inventoryRemoveTagsRequest({
        tagIds: selectedTagsToAdd,
        items: bulkItems,
        getData: {
          page,
          rowsPerPage,
          orderBy,
          order,
          filter: getFilterModelShaped(inventoryFilterModel),
          searchValue,
        },
      })
    );
  };

  const tagsOptionsFiltered = useMemo(() => {
    const uniqueTagIds = bulkActionsListingList
      .flatMap((listing) => listing.tags || [])
      .map((tag) => tag._id)
      .filter((value, index, self) => self.indexOf(value) === index);

    return tagsData
      .filter((tag) => uniqueTagIds.includes(tag._id))
      .map((tag) => ({
        name: tag.name,
        _id: tag._id,
        color: tag.color,
      }));
  }, [tagsData, bulkActionsListingList]);

  const isRemoveTagsButtonDisabled = !selectedTagsToAdd.length;

  return (
    <Dialog fullWidth maxWidth="xs" open={isRemoveTagsDialogOpen} onClose={handleClose}>
      <DialogTitle>Remove Tags</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>
        <Stack spacing={2}>
          <Typography variant="body2">
            Please select the tags to remove from your listings.
          </Typography>

          <Autocomplete
            fullWidth
            multiple
            onChange={handleOnChange}
            options={tagsOptionsFiltered}
            sx={{
              mt: '24px !important',
            }}
            getOptionLabel={(option) => option.name!}
            renderInput={(params) => (
              <TextField {...params} label="Select tags" placeholder="Select tags" />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option._id}>
                {renderTagMenuItem(option)}
              </li>
            )}
            renderTags={(selected, getTagProps) =>
              selected.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option._id}
                  label={option.name}
                  size="small"
                  color={getTagColor(option.name!) || 'inherit'}
                  variant="soft"
                  sx={{ fontWeight: 700, fontSize: '12px !important', borderRadius: '8px' }}
                />
              ))
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="error"
          onClick={handleRemoveTags}
          disabled={isRemoveTagsButtonDisabled}
        >
          Remove
        </LoadingButton>
        <Button disabled={loading} variant="text" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
