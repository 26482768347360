import {
  Select,
  MenuItem,
  Stack,
  useMediaQuery,
  TextField,
  InputAdornment,
  InputLabel,
  FormControl,
  SelectChangeEvent,
  Tooltip,
  Alert,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'src/store';
import {
  setIsConnectionErrorDialogOpen,
  updateAutomationSettingsRequest,
} from 'src/store/automations/slices/automationsSlice';

import { useFormikContext } from 'formik';
import { IDataForUpdateAutomationSettings } from 'src/pages/automations/AutomationsPage/types/types';
import { Automation, AutoSendOfferToLikersRule } from '@beta.limited/primelister';
import { shippingOffersDummyData } from './constants';

interface ShippingOfferWithSelectProps {
  ruleIndex: number;
}

const ShippingOfferWithSelect: React.FC<ShippingOfferWithSelectProps> = ({ ruleIndex }) => {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:400px)');
  const [renderError, setRenderError] = useState(false);
  const [localError, setLocalError] = useState('');

  const {
    selectedAutomationOption,
    automationsSettings,
    activeClosetAutomationPlatform,
    activeClosetPlatformShippingOffers,
  } = useSelector((state: IRootState) => state.automations);
  const { activeClosetCredentialsId, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.myCloset
  );

  const { values, setFieldValue, errors, touched } = useFormikContext<{
    rules: AutoSendOfferToLikersRule[];
  }>();

  useEffect(() => {
    if (renderError) {
      const numericValue = Number(values.rules[ruleIndex].offer?.value);
      if (numericValue < 10 || numericValue > 100) {
        setLocalError('Please enter a number that is between 10 and 100.');
      } else {
        setLocalError('');
      }
    }
  }, [values.rules[ruleIndex].offer?.value, renderError, ruleIndex]);

  const handleShippingOfferChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value as string;
    if (hasActiveClosetConnection) {
      setFieldValue(`rules.${ruleIndex}.shipping.option`, newValue);

      const isCustom = newValue !== 'original' && newValue !== 'free';

      const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;

      const adjustedOptionProp = isCustom ? 'custom' : newValue;
      const adjustedCustomValueProp = isCustom ? newValue : '';

      setFieldValue(`rules.${ruleIndex}.shipping.option`, adjustedOptionProp);
      setFieldValue(`rules.${ruleIndex}.shipping.customValue`, adjustedCustomValueProp);

      const updatedRules = [...values.rules];
      updatedRules[ruleIndex] = {
        ...updatedRules[ruleIndex],
        shipping: {
          option: adjustedOptionProp,
          customValue: adjustedCustomValueProp,
        },
      };

      const dataForUpdating: IDataForUpdateAutomationSettings = {
        settings: {
          [Automation.AUTO_SEND_OFFER_TO_LIKERS]: {
            config: {
              rules: updatedRules,
            },
          },
        },
        toastMessage,
        displayName: selectedAutomationOption.displayName,
        activeClosetAutomationPlatform,
        activeClosetCredentialsId,
      };

      dispatch(updateAutomationSettingsRequest(dataForUpdating));
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
  };
  const handleOfferChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasActiveClosetConnection) {
      dispatch(setIsConnectionErrorDialogOpen(true));
      return;
    }
    const newValue = event.target.value;
    const numericValue = Number(newValue);

    setFieldValue(`rules.${ruleIndex}.offer.value`, newValue);
    setRenderError(true);

    if (numericValue >= 10 && numericValue <= 100) {
      const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;

      const updatedRules = [...values.rules];
      updatedRules[ruleIndex] = {
        ...updatedRules[ruleIndex],
        offer: {
          value: numericValue,
          unit: 'percentage',
        },
      };

      const dataForUpdating: IDataForUpdateAutomationSettings = {
        settings: {
          [Automation.AUTO_SEND_OFFER_TO_LIKERS]: {
            config: {
              rules: updatedRules,
            },
          },
        },
        toastMessage,
        displayName: selectedAutomationOption.displayName,
        activeClosetAutomationPlatform,
        activeClosetCredentialsId,
      };

      dispatch(updateAutomationSettingsRequest(dataForUpdating));
      setLocalError('');
    } else {
      // Add this else block to handle invalid input
      setFieldValue(`rules.${ruleIndex}.offer.value`, newValue);
      // Don't dispatch updateAutomationSettingsRequest for invalid values
      setLocalError('Please enter a number that is between 10 and 100.');
    }
  };

  const shippingOffers =
    activeClosetPlatformShippingOffers && automationsSettings[Automation.AUTO_SEND_OFFER_TO_LIKERS]
      ? activeClosetPlatformShippingOffers
      : shippingOffersDummyData;

  return (
    <Stack>
      <Stack direction={'row'} spacing={2} alignItems="flex-start">
        <FormControl>
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Please enter a number between 10-100."
            placement="top"
          >
            <TextField
              id={`offer-input-${ruleIndex}`}
              variant="outlined"
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              value={values.rules[ruleIndex].offer?.value || ''}
              onChange={handleOfferChange}
              label="Discount"
              error={!!localError}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  bgcolor: localError ? '#FFF2EF' : 'white',
                },
                width: '90px',
              }}
            />
          </Tooltip>
        </FormControl>
        <FormControl>
          <InputLabel id={`shipping-offer-label-${ruleIndex}`}>Shipping Offer</InputLabel>
          <Select
            labelId={`shipping-offer-label-${ruleIndex}`}
            id={`shipping-offer-select-${ruleIndex}`}
            value={
              values.rules[ruleIndex].shipping?.option === 'custom'
                ? values.rules[ruleIndex].shipping?.customValue
                : values.rules[ruleIndex].shipping?.option
            }
            onChange={handleShippingOfferChange}
            variant="outlined"
            label="Shipping Offer"
            sx={{
              borderRadius: '8px',
              width: '234px',
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  '& .MuiList-root': {
                    padding: '4px !important',
                  },
                },
              },
            }}
          >
            {shippingOffers.map((option) => (
              <MenuItem
                sx={{
                  p: '6px 8px',
                  borderRadius: '8px',
                  '&.Mui-selected': {
                    backgroundColor: 'rgba(32, 101, 209, 0.16)',
                    '&:hover': {
                      backgroundColor: 'rgba(32, 101, 209, 0.16)',
                    },
                  },
                  '&:hover': {
                    backgroundColor: '#F2F3F5',
                  },
                }}
                key={option.label}
                value={option.customValue || option.option}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      {localError && (
        <Alert
          sx={{
            color: '#FF5630',
            bgcolor: 'white',
            overflow: 'hidden',
            p: 0,
            mt: '2px !important',
          }}
          severity="error"
          variant="standard"
          icon={false}
        >
          {localError}
        </Alert>
      )}
    </Stack>
  );
};

export default ShippingOfferWithSelect;
