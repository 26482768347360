import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { IS_SANDBOX, SUPABASE_KEY, SUPABASE_URL } from 'src/config';

export enum SupabaseChannelName {
  GENERAL = 'general',
}

export enum SupabaseEventType {
  TASK_COMPLETE = 'task_complete',
  CREDENTIAL_UPDATE = 'credential_update',
}

export default class SupabaseBroadcast {
  private userId: string;

  public events = new EventTarget();

  private client: SupabaseClient;

  constructor(userId: string) {
    this.userId = userId;
    this.client = createClient(SUPABASE_URL, SUPABASE_KEY);
  }

  async listenEvents() {
    const channels = [
      {
        channelId: SupabaseChannelName.GENERAL,
        events: [SupabaseEventType.TASK_COMPLETE, SupabaseEventType.CREDENTIAL_UPDATE],
      },
      // Add more channels as needed
    ];

    for (const channelData of channels) {
      const channelId = `${this.userId}-${channelData.channelId}`;
      const channel = this.client.channel(channelId);

      channel.subscribe((status) => {
        if (status === 'SUBSCRIBED') {
          for (const event of channelData.events) {
            channel.on('broadcast', { event: event }, (payload) => {
              this.logEvent(`Received ${event}:`, payload);
              this.events.dispatchEvent(new CustomEvent(event, { detail: payload }));
            });
          }
        }
      });
    }
  }

  logEvent(message: string, event: any): void {
    if (IS_SANDBOX) {
      console.log(message, event);
    }
  }
}
