import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import {
  setIsDowngradeConfirmationDialogOpen,
  updateSubscriptionRequest,
} from 'src/store/dashboard/slices/pricingSlice';
import { IRootState } from 'src/store';
import moment from 'moment';

const DowngradeConfirmationDialog = () => {
  const dispatch = useDispatch();

  const {
    isDowngradeConfirmationDialogOpen,
    sliderValue,
    stripeReferrerUrl,
    stripePlanIdForUpdate,
  } = useSelector((state: IRootState) => state.pricing);

  const { poshmarkShopCapacity, billingSubscriptionList } = useSelector(
    (state: IRootState) => state.account
  );

  const handleClose = () =>
    dispatch(setIsDowngradeConfirmationDialogOpen({ openDialog: false, stripePlanId: '' }));

  const confirmationMessage = [
    `Your current subscription allows up to ${poshmarkShopCapacity} closet connections. By downgrading your subscription, you will switch to a plan that allows up to ${sliderValue} closet connections only.`,

    `Please note that these changes will take effect immediately.`,
    `
  Are you sure you want to proceed with downgrading your subscription?
  `,
  ];

  const handleDowngrade = () => {
    const stripeReferrerUrlControlled = stripeReferrerUrl
      ? stripeReferrerUrl
      : window.location.host + '/automations/user#gbilling';

    dispatch(
      updateSubscriptionRequest({
        returnUrl: stripeReferrerUrlControlled,
        flowType: 'subscription_update_confirm',
        subscriptionId: billingSubscriptionList[1].subscriptionId,
        subscriptionItemId: billingSubscriptionList[1].subscriptionItemId,
        planId: stripePlanIdForUpdate,
        shopCapacity: sliderValue,
      })
    );
  };
  return (
    <Dialog fullWidth maxWidth="sm" open={isDowngradeConfirmationDialogOpen} onClose={handleClose}>
      <DialogTitle sx={{ pb: 3 }}>Downgrade Your Subscription: Confirmation Required</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>
        {' '}
        {confirmationMessage.map((message, index) => (
          <Typography
            sx={{
              ...(index < 2 && { pb: 3 }),
              fontSize: '16px',
              fontWeight: '400',
              color: '#637381',
            }}
            key={index}
          >
            {message}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={handleDowngrade}>
          Downgrade My Subscription
        </Button>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DowngradeConfirmationDialog;
