import { Stack, useMediaQuery } from '@mui/material';

const CommunityShareText = () => {
  const matches = useMediaQuery('(min-width:400px)');

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        userSelect: 'none',
        width: matches ? '360px' : '95%',
        height: 'max-content',
        border: '1px dashed rgba(212, 218, 223, 1)',
        borderRadius: '8px',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '14px',
        p: '8px 8px',
        lineHeight: '22px',
        alignSelf: 'center',
      }}
    >
      <p>
        ℹ️
        <br /> PrimeLister uses a smart algorithm to optimize your sharing activity on Poshmark,
        increasing engagement within the community while keeping your closet safe.
        <br /> <br />
        This is achieved by optimizing share speed, frequency, and the number of shares in the most
        efficient manner.
      </p>
    </Stack>
  );
};

export default CommunityShareText;
