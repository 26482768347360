import React, { useEffect, useMemo, useState } from 'react';
import { Tabs, Tab, Box, useTheme } from '@mui/material';
import {
  ActivityCheckIcon,
  ListNumbersIcon,
  ProgressLineIcon,
} from 'src/theme/overrides/CustomIcons';
import CustomTabPanel from './CustomTabPanel';
import StatisticsWidgetContainer from '../../StatisticsPage/components/StatisticWidgetContainer';
import OngoingTabComponent from './OngoingTabComponent';
import useResponsive from 'src/hooks/useResponsive';
import ActivityTabComponent from './ActivityTabComponent';
import { useDispatch } from 'react-redux';
import {
  setSelectedActivity,
  setSelectedOngoingTask,
} from 'src/store/automations/slices/activitySlice';
import { automationItemsList, ongoingTabItemsList } from '../utils/constants';

const ActivityTabs = () => {
  const isMobile = useResponsive('down', 321);
  const [isShowingAlert, setIsShowingAlert] = useState(true);

  const tabConfig = useMemo(
    () => [
      {
        label: !isMobile ? 'Statistics' : 'Stats',
        urlParam: 'statistics',
        icon: <ListNumbersIcon style={{ width: 24, height: 24 }} />,
        content: <StatisticsWidgetContainer />, // Replace with your actual component
      },
      {
        label: 'Completed',
        urlParam: 'completed',
        icon: <ActivityCheckIcon style={{ width: 24, height: 24 }} />,
        content: <ActivityTabComponent />, // Replace with your actual component
      },
      {
        label: 'Ongoing',
        urlParam: 'ongoing',
        icon: <ProgressLineIcon style={{ width: 24, height: 24 }} />,
        content: <OngoingTabComponent />, // Replace with your actual component
      },
    ],
    [isMobile]
  );

  const getInitialTabIndex = () => {
    const params = new URLSearchParams(window.location.search);
    const tabParam = params.get('tab');
    const tabIndex = tabConfig.findIndex((tab) => tab.urlParam === tabParam);
    return tabIndex !== -1 ? tabIndex : 0;
  };

  const dispatch = useDispatch();

  const [value, setValue] = useState(getInitialTabIndex);

  useEffect(() => {
    const alertClosed = localStorage.getItem('alertClosed');
    if (alertClosed === 'true') {
      setIsShowingAlert(false);
    }
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    dispatch(setSelectedActivity(automationItemsList[0]));
    dispatch(setSelectedOngoingTask(ongoingTabItemsList[0]));
    const selectedTab = tabConfig[newValue];
    if (selectedTab) {
      const url = new URL(window.location.href);
      const currentParams = new URLSearchParams(window.location.search);

      // Preserve existing query parameters
      currentParams.forEach((value, key) => {
        if (key !== 'tab') {
          url.searchParams.set(key, value);
        }
      });

      // Set the new tab parameter
      url.searchParams.set('tab', selectedTab.urlParam);

      // Clear the hash
      url.hash = '';
      window.location.hash = '';

      window.history.pushState({}, '', url.toString());
    }
  };

  const calculatedMarginTop = value === 2 && isShowingAlert ? 2 : 3;

  const theme = useTheme();
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{}}>
        <Tabs
          scrollButtons={false}
          value={value}
          onChange={handleChange}
          aria-label="activity tabs"
        >
          {tabConfig.map((tab, index) => (
            <Tab
              sx={{
                '&:not(:last-of-type)': {
                  marginRight: '40px',
                  [theme.breakpoints.down('sm')]: {
                    marginRight: '12px',
                  },
                },
                '&:not(.Mui-selected)': {
                  '.MuiSvgIcon-root': {
                    fill: '#637381',
                  },
                  color: '#637381',
                },
                [theme.breakpoints.down('sm')]: {
                  '& .MuiSvgIcon-root': {
                    marginRight: '4px',
                  },
                },
              }}
              key={index}
              icon={tab.icon}
              label={tab.label}
            />
          ))}
        </Tabs>
      </Box>
      <Box
        sx={{
          mt: calculatedMarginTop,
        }}
      >
        {tabConfig.map((tab, index) => (
          <CustomTabPanel value={value} index={index} key={index}>
            {tab.content}
          </CustomTabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default ActivityTabs;
