import React from 'react';
import { Box, CircularProgress } from '@mui/material';

import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';

const ListingCardsFooter: React.FC = () => {
  const listingsNextPageId = useSelector(
    (state: IRootState) => state.myListings.listingsNextPageId
  );

  return (
    <>
      {listingsNextPageId ? (
        <Box sx={{ textAlign: 'center', p: 2, width: '100%', height: '100%' }}>
          <CircularProgress color="inherit" size={40} thickness={4} />
        </Box>
      ) : (
        <Box
          textAlign={'center'}
          sx={{
            marginTop: '24px',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#212B36',
            fontWeight: '400',
            height: '100%',
            width: '100%',
          }}
        >
          You've reached the end of the listings.
        </Box>
      )}
    </>
  );
};
export default ListingCardsFooter;
