import { forwardRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';

// @mui
import {
  Slide,
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Alert,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import {
  cancelBillingSubscriptionRequest,
  setIsCancelBillingSubscriptionDialogOpen,
} from 'src/store/dashboard/slices/accountSlice';
import { IRootState } from 'src/store';

import moment from 'moment';
import { LoadingButton } from '@mui/lab';

// -- Transition effect for Dialog
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CancelBillingSubscriptionDialog = () => {
  const dispatch = useDispatch();

  const { selectedSubscriptionToCancel, isCancelBillingSubscriptionDialogOpen, loading } =
    useSelector((state: IRootState) => state.account);

  const handleModalClose = () => {
    dispatch(setIsCancelBillingSubscriptionDialogOpen(false));
  };

  const handleCancelSubscriptionButton = () => {
    dispatch(cancelBillingSubscriptionRequest(selectedSubscriptionToCancel.subscriptionId));
  };

  const handleCrispChatOpen = () => window.$crisp.push(['do', 'chat:open']);

  const expireDate = moment(selectedSubscriptionToCancel.expires).format('MMM Do, YYYY');

  return (
    <Dialog
      open={isCancelBillingSubscriptionDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleModalClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      sx={{ textAlign: 'center' }}
    >
      <DialogTitle id="alert-dialog-slide-title">
        {' '}
        Do you really want to cancel your subscription?
      </DialogTitle>

      <DialogContent>
        <Alert severity="error" sx={{ mb: '1.5rem' }}>
          We are sorry to see you go. Is there anything we can do to keep you as a subscriber?{' '}
          Please
          <b style={{ cursor: 'pointer', margin: '0 4px' }} onClick={handleCrispChatOpen}>
            click here and start chat
          </b>
          with us.
        </Alert>

        <DialogContentText id="alert-dialog-slide-description">
          Your subscription will remain active until the end of your current billing cycle, which is{' '}
          <b style={{ margin: '0 4px' }}>{expireDate}.</b>
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <LoadingButton
          loading={loading}
          color="error"
          variant="text"
          sx={{ textTransform: 'none' }}
          onClick={handleCancelSubscriptionButton}
        >
          Yes, Cancel it
        </LoadingButton>

        <Button variant="contained" color="primary" onClick={handleModalClose}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelBillingSubscriptionDialog;
