import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 40,
          height: 40,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1_2)">
            <rect width="40" height="40" fill="white"/>
            <rect width="40" height="40" rx="8" fill="url(#paint0_linear_1_2)"/>
            <path d="M6 12H11.8648C12.6825 12 13.4556 12.0743 14.184 12.223C14.9125 12.3717 15.5443 12.6318 16.0795 13.0035C16.6296 13.3603 17.0607 13.8509 17.3729 14.4753C17.6999 15.0848 17.8635 15.8579 17.8635 16.7945C17.8635 17.7162 17.7148 18.4892 17.4175 19.1136C17.1202 19.738 16.7113 20.236 16.191 20.6077C15.6707 20.9794 15.0537 21.247 14.3401 21.4105C13.6265 21.5592 12.8535 21.6335 12.0209 21.6335H9.47876V27.7882H6V12ZM9.47876 18.6899H11.7979C12.1101 18.6899 12.4075 18.6602 12.6899 18.6007C12.9873 18.5413 13.2474 18.4446 13.4704 18.3108C13.7083 18.1622 13.8941 17.9689 14.0279 17.731C14.1766 17.4783 14.2509 17.1661 14.2509 16.7945C14.2509 16.3931 14.1543 16.0734 13.961 15.8356C13.7826 15.5828 13.5448 15.3896 13.2474 15.2558C12.965 15.122 12.6453 15.0402 12.2885 15.0105C11.9317 14.9659 11.5898 14.9436 11.2627 14.9436H9.47876V18.6899Z" fill="white"/>
            <path d="M21.295 12H24.7738V24.5771H31.2184V27.7882H21.295V12Z" fill="white"/>
            <path d="M33.8968 25.8705C33.8968 25.588 33.9489 25.3204 34.0529 25.0677C34.157 24.8149 34.2982 24.5994 34.4766 24.421C34.6699 24.2277 34.8929 24.079 35.1456 23.975C35.3984 23.8709 35.666 23.8189 35.9484 23.8189C36.2309 23.8189 36.4985 23.8709 36.7512 23.975C37.0039 24.079 37.2195 24.2277 37.3979 24.421C37.5912 24.5994 37.7398 24.8149 37.8439 25.0677C37.948 25.3204 38 25.588 38 25.8705C38 26.1529 37.948 26.4205 37.8439 26.6733C37.7398 26.926 37.5912 27.149 37.3979 27.3422C37.2195 27.5206 37.0039 27.6619 36.7512 27.7659C36.4985 27.87 36.2309 27.922 35.9484 27.922C35.666 27.922 35.3984 27.87 35.1456 27.7659C34.8929 27.6619 34.6699 27.5206 34.4766 27.3422C34.2982 27.149 34.157 26.926 34.0529 26.6733C33.9489 26.4205 33.8968 26.1529 33.8968 25.8705Z" fill="white"/>
          </g>
          <defs>
            <linearGradient id="paint0_linear_1_2" x1="0" y1="40" x2="40" y2="0" gradientUnits="userSpaceOnUse">
              <stop stop-color="#3564CA"/>
              <stop offset="1" stop-color="#6592DF"/>
            </linearGradient>
            <clipPath id="clip0_1_2">
              <rect width="40" height="40" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </Box>
    );

    if (disabledLink) {
      return <>{logo}</>;
    }

    return (
      <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
