import moment from 'moment';

/**
 * Converts a given date to the specified timezone and formats it.
 *
 * This function takes a date input, a timezone string, and an optional format string.
 * It then uses the moment.js library to convert the date to the specified timezone.
 * If the timezone is invalid or not provided, it defaults to the browser's timezone.
 * The date is then formatted according to the given format or defaults to 'YYYY-MM-DD HH:mm:ss'.
 */
export const getDateWithTimeZone = (
  date: moment.MomentInput,
  timezone?: string,
  format?: string
) => {
  const momentDate = moment(date);
  const isValid = timezone && moment.tz.names().includes(timezone);

  if (!timezone || !isValid) momentDate.tz(moment.tz.guess());
  else momentDate.tz(timezone);

  return momentDate.format(format || 'YYYY-MM-DD HH:mm:ss');
};
