import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { IRootState } from 'src/store';

import {
  setAutomationError,
  setIsShareHourDialogOpen,
  updateAutomationSettingsRequest,
} from 'src/store/automations/slices/automationsSlice';
import { LoadingButton } from '@mui/lab';

import { Automation } from '@beta.limited/primelister';
import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { IDataForUpdateAutomationSettings } from '../../types/types';
import { formatTimeZone, getHourRange } from '../../helpers/hourHelpers';

export default function ShareHourDialog() {
  const dispatch = useDispatch();
  //Store states
  const { isShareHourDialogOpen, selectedShareHourData, loading, error } = useSelector(
    (state: IRootState) => state.automations
  );
  const { userAccountDetail } = useSelector((state: IRootState) => state.account);
  const { activeClosetCredentialsId } = useSelector((state: IRootState) => state.myCloset);
  const { activeClosetAutomationPlatform, selectedAutomationOption } = useSelector(
    (state: IRootState) => state.automations
  );

  const [hourEnabled, setHourEnabled] = useState(selectedShareHourData?.enabled);
  const [shouldDisableDefault, setShouldDisableDefault] = useState(true);
  const [hourLimit, setHourLimit] = useState(selectedShareHourData?.taskLimit?.toString());
  const [isChanging, setIsChanging] = useState(false);
  const [hasError, setHasError] = useState(false);

  let timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const defaultTaskLimit =
    selectedShareHourData?.taskLimit && selectedShareHourData?.taskLimit !== 0
      ? selectedShareHourData?.taskLimit.toString()
      : '';

  //Change handlers
  const handleSwitchChange = () => {
    setHourLimit(defaultTaskLimit);
    setHasError(false);
    setHourEnabled(!hourEnabled);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputVal = event.target.value;

    if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);

    if (inputVal !== '' && !/^\d+$/.test(inputVal)) {
      return;
    }

    setIsChanging(true);
    setHourLimit(event.target.value);

    const value = Number(event.target.value);

    if (error?.length) {
      dispatch(setAutomationError(''));
    }

    if (event.target.value === '') {
      setHasError(false);
    }

    timeoutIdRef.current = setTimeout(() => {
      const isValid =
        (!isNaN(value) && value > 0 && value <= 1000 && value % 1 === 0) ||
        event.target.value === '';
      setHasError(!isValid);
      setIsChanging(false);
    }, 500);
  };

  //Dialog Closer
  const handleClose = () => {
    const closingTaskLimit =
      selectedShareHourData?.taskLimit !== 0 ? selectedShareHourData?.taskLimit.toString() : '';
    dispatch(setAutomationError(''));
    setHasError(false);
    setHourLimit(closingTaskLimit);
    setHourEnabled(selectedShareHourData?.enabled);
    dispatch(
      setIsShareHourDialogOpen({
        isOpen: false,
        sharingHourDialogData: selectedShareHourData,
      })
    );
  };
  // Request handler
  const handleSaveChanges = () => {
    const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
    const dataForUpdating: IDataForUpdateAutomationSettings = {
      settings: {
        [Automation.AUTO_CLOSET_SHARE]: {
          config: {
            taskHours: [
              {
                timeOfDay: selectedShareHourData?.timeOfDay,
                taskLimit: Number(hourLimit),
                enabled: hourEnabled,
              },
            ],
          },
        },
      },
      toastMessage,
      displayName: selectedAutomationOption.displayName,
      activeClosetAutomationPlatform,
      activeClosetCredentialsId,
    };
    dispatch(updateAutomationSettingsRequest(dataForUpdating));
  };
  //Update itself for different hour ranges
  useEffect(() => {
    setHourEnabled(selectedShareHourData?.enabled);
    setHourLimit(defaultTaskLimit);
    setShouldDisableDefault(false);
  }, [selectedShareHourData]);

  //Checks for Api error
  useEffect(() => {
    if (error) {
      setHourLimit(defaultTaskLimit);
    }
  }, [error]);

  const hourRange = getHourRange(selectedShareHourData?.timeOfDay || '1 AM');

  //Button disable conditions
  const isEnabledAndLimitNotChanged =
    selectedShareHourData?.enabled &&
    selectedShareHourData?.enabled === hourEnabled &&
    selectedShareHourData?.taskLimit === Number(hourLimit);

  const isDisabledAndNotChanged =
    !selectedShareHourData?.enabled && selectedShareHourData?.enabled === hourEnabled;

  const isEnabledButNoLimit = hourEnabled && !hourLimit;

  const shouldDisableSaveChangesButton = () =>
    isEnabledAndLimitNotChanged ||
    isDisabledAndNotChanged ||
    isEnabledButNoLimit ||
    shouldDisableDefault ||
    hasError ||
    isChanging;

  const renderEnableHourSwitch = () => (
    <Stack direction="row" alignItems="center" justifyContent={'space-between'}>
      <FormControlLabel
        labelPlacement="start"
        checked={hourEnabled}
        label={
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '24px',
              fontWeight: '400',
              color: '#637381',
            }}
          >
            Share my closet during <b>{hourRange}</b> (GMT{' '}
            {formatTimeZone(userAccountDetail.timeZone.gmt)})
          </Typography>
        }
        control={<Switch sx={{ mr: -1.3 }} onChange={handleSwitchChange} />}
        sx={{ px: '0', mx: 'auto', justifyContent: 'space-between', width: '100%' }}
      />
    </Stack>
  );

  const renderHourlyShareLimitInput = () => (
    <>
      <Tooltip placement="top" arrow title="ℹ️ Please enter a whole number between 1 and 1000.">
        <TextField
          value={hourLimit}
          label="Hourly Share Limit"
          onChange={handleLimitChange}
          sx={{
            '& .MuiOutlinedInput-root': {
              bgcolor: `${hasError ? '#FFF2EF' : '#ffffff'}`,
              color: `${hasError ? '#B71C19' : '#000000'}`,
            },
            width: '100%',
            mt: '24px',
          }}
        />
      </Tooltip>
      {renderAlert()}
      {renderAPIAlert()}
    </>
  );

  const renderAlert = () => {
    if (hasError) {
      return (
        <Alert
          sx={{
            color: '#FF5630',
            bgcolor: '#ffffff',
            maxWidth: '360px !important',
            overflow: 'hidden',
            p: 'none !important',
            fontSize: '12px',
            '&.MuiAlert-standard .MuiAlert-message': {
              pb: '1px !important',
            },
          }}
          severity="error"
          variant="standard"
          icon={false}
        >
          Please enter a whole number that is between 1 and 1000.
        </Alert>
      );
    }
  };

  const renderAPIAlert = () => {
    if (error) {
      return (
        <Alert severity="error" variant="standard" sx={{ mt: '24px !important', width: '100%' }}>
          {error}
        </Alert>
      );
    }
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={isShareHourDialogOpen} onClose={handleClose}>
      <DialogTitle sx={{ pb: '24px' }}>Hourly Sharing Preferences</DialogTitle>
      <DialogContent>
        {renderEnableHourSwitch()}
        {hourEnabled && renderHourlyShareLimitInput()}
      </DialogContent>
      <DialogActions sx={{ mt: hasError ? '-7px' : 'none' }}>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          disabled={shouldDisableSaveChangesButton()}
          loading={loading}
          variant="contained"
          color="primary"
          onClick={handleSaveChanges}
          sx={{
            transition: 'none !important',
          }}
        >
          Save Changes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
