import { forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// @mui
import {
  Slide,
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import { IRootState } from 'src/store';
import {
  accountChangePasswordRequest,
  setIsChangePasswordDialogOpen,
} from 'src/store/dashboard/slices/accountSlice';
import { UseFormReset } from 'react-hook-form';

// -- Transition effect for Dialog
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IDeactivateDialog {
  reset: UseFormReset<{
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
  }>;
}

const DeactivateDialog = ({ reset }: IDeactivateDialog) => {
  const dispatch = useDispatch();

  const { isChangePasswordDialogOpen, passwordDetails } = useSelector(
    (state: IRootState) => state.account
  );

  const handleModalClose = () => {
    dispatch(setIsChangePasswordDialogOpen(false));
  };
  const handleOnSave = () => {
    dispatch(accountChangePasswordRequest(passwordDetails));
    reset();
  };

  return (
    <Dialog
      open={isChangePasswordDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleModalClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      sx={{ padding: '0 15px' }}
    >
      <DialogTitle id="alert-dialog-slide-title"> Change password?</DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description" sx={{ mb: '1.5rem' }}>
          Your password will be changed. Are you sure you want to change it?
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <Button variant="text" color="inherit" onClick={handleModalClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleOnSave}>
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeactivateDialog;
