import Image from 'src/components/image';
import { DataGridImageColumnInterface } from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';

export default function ImportAvatartColumn({ url }: DataGridImageColumnInterface) {
  let imageStyle = {
    borderRadius: 1.5,
    width: 48,
    height: 48,
    backgroundSize: 'cover',
  };

  return (
    <Image
      src={url ? url : 'https://r2.primelister.com/image_thumbnail.svg'}
      disabledEffect
      visibleByDefault
      sx={imageStyle}
    />
  );
}
