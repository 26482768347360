import { Stack, Button, MenuItem, Typography, Avatar, useMediaQuery } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Iconify from 'src/components/iconify';
import MenuPopover from 'src/components/menu-popover';
import { IRootState } from 'src/store';
import { UserTagTypesEnum } from 'src/store/automations/slices/automationsSlice';
import { ConnectionStatus } from 'src/store/automations/slices/myClosetSlice';
import useOnboarding from 'src/utils/onBoardingSteps/useOnboarding';

import { IClosetDetails } from '../utils/types';

const ClosetSelectionDropDown = () => {
  const { closetList, activeCloset } = useSelector((state: IRootState) => state.myCloset);
  const { loading, isUserBoardedMultipleClosets } = useSelector(
    (state: IRootState) => state.automations
  );

  const [openDropdown, setOpenDropdown] = useState<HTMLElement | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const dropdownMenu = useRef(null);
  const { driverObject } = useOnboarding(UserTagTypesEnum.ONBOARDING_MULTIPLE_CLOSETS);
  const handlePopoverClose = () => setOpenDropdown(null);

  const handleDropdown = (e: React.MouseEvent<HTMLElement>) => {
    if (hasMultipleClosets) setOpenDropdown(e.currentTarget.firstElementChild as HTMLElement);
  };

  const handleClosetSelection = (closet: IClosetDetails) => {
    handlePopoverClose();
    searchParams.set('shop', closet?.poshmarkUserName);
    setSearchParams(searchParams);
  };

  const hasSpaceInHeader = useMediaQuery('(min-width: 700px)');
  const dropDownOptions = closetList.filter(
    (closet) => closet?.status !== ConnectionStatus.Removed
  );
  const hasMultipleClosets = dropDownOptions.length > 1;
  const dummyImage = 'https://primelister-files.s3.us-west-1.amazonaws.com/image_thumbnail.svg';

  useEffect(() => {
    const dropdown = dropdownMenu?.current;
    const isHighlightOnTheSecondStep = driverObject.isActive();
    if (dropdown && isHighlightOnTheSecondStep && !loading) {
      setOpenDropdown(dropdown);
    } else {
      setOpenDropdown(null);
    }
  }, [driverObject.isActive()]);

  return (
    <Stack
      className="multipleClosetSelectMenu"
      sx={{
        opacity: 1,
      }}
    >
      <Button
        color="inherit"
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: '4px 8px',
          borderRadius: '8px',
        }}
        onClick={handleDropdown}
      >
        <Avatar
          ref={dropdownMenu}
          sx={{
            width: '30px',
            height: '30px',
            mr: '8px',
          }}
          src={activeCloset?.poshmarkAvatar || dummyImage}
        />
        <Typography
          sx={{
            fontSize: '14px !important',
            lineHeight: '24px',
            fontWeight: 600,
            textTransform: 'none',
            color: '#637381',
          }}
        >
          {activeCloset?.poshmarkUserName}
        </Typography>
        {hasMultipleClosets && (
          <Iconify
            icon={openDropdown ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}
            sx={{ ml: '3px', color: '#637381' }}
          />
        )}
      </Button>
      <MenuPopover
        open={openDropdown}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ mt: '6px !important', ml: '50px', maxHeight: '430px', overflowY: 'auto' }}
        disabledArrow={true}
      >
        <Stack className="multipleClosetDropdown">
          {dropDownOptions.map((closet) => (
            <MenuItem
              onClick={() => handleClosetSelection(closet)}
              key={closet?.poshmarkUserName}
              sx={{
                fontWeight:
                  closet?.poshmarkUserName === activeCloset?.poshmarkUserName
                    ? '600 !important'
                    : '400 !important',
                backgroundColor:
                  closet?.poshmarkUserName === activeCloset?.poshmarkUserName ? '#EDEFF2' : 'white',
                p: '6px 16px 6px 8px !important',
                maxHeight: '42px !important',
                minHeight: '42px !important',
              }}
            >
              <Avatar
                sx={{
                  width: '30px',
                  height: '30px',
                  mr: '8px',
                }}
                src={closet?.poshmarkAvatar || dummyImage}
              />
              {closet?.poshmarkUserName}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </Stack>
  );
};

export default ClosetSelectionDropDown;
