import { Container, Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import TasksCard from './components/TasksCard';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { useEffect } from 'react';

export default function TasksPage() {
  const selectedCheckboxes = useSelector((state: IRootState) => state.tasks.selectedCheckboxes);

  useEffect(() => {
    const isBulkActionsMenuOpen = selectedCheckboxes.length > 0;
    if (isBulkActionsMenuOpen) {
      window.scroll({
        top: 500,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [selectedCheckboxes]);
  return (
    <>
      <Helmet>
        <title>Tasks | PrimeLister</title>
      </Helmet>
      <Container maxWidth={'xl'} disableGutters={true}>
        <Typography variant="h4" sx={{ marginBottom: '2.5rem' }}>
          Tasks
        </Typography>

        <Grid item xs={12}>
          <TasksCard />
        </Grid>
      </Container>
    </>
  );
}
