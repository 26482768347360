import {
  Box,
  CircularProgress,
  AppBar,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Theme,
} from '@mui/material';
import React, { useEffect, useRef, useState, forwardRef } from 'react';
import ProductCard, {
  ProductCardProps,
  ProductCardSelectionEnum,
} from 'src/components/product-card';
import ListingCardsFilterContainer from './ListingCardsFilterContainer';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import {
  getMyListingsRequest,
  setIsFetchingMoreListings,
  setIsListingBulkActionsMenuOpen,
  setMyListings,
  setResetFiltersAndQueries,
  setSelectedListings,
} from 'src/store/automations/slices/myListingsSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import ListingsBulkActionsMenu from './ListingsBulkActionsMenu';
import Image from 'src/components/image';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useSearchParams } from 'react-router-dom';
import { block } from 'million/react';
import useResponsive from 'src/hooks/useResponsive';
import ReactDOM from 'react-dom';
import ProductCardMobile from 'src/components/product-card/ProductCardMobile';
import { ConnectionStatus } from 'src/store/automations/slices/myClosetSlice';
import useListingsScrollHandler from 'src/hooks/useListingsScrollHandler';
import ListingsFilterDrawer from './ListingsFilterDrawer';
import ListingSkeletonCard from './ListingSkeletonCard';
import { VirtuosoGrid, GridComponents, Virtuoso } from 'react-virtuoso';
import ListingCardsItemWrapper from './ListingCardsItemWrapper';
import ListingCardsFooter from './ListingCardsFooter';
import { createGridComponents } from './shared/GridComponents';
import { useCheckSubscriptionStatus } from 'src/hooks/useCheckSubscriptionStatus';
import ListingService from '../services/ListingsService';

function ListingCardsContainer() {
  const {
    hasCloset,
    activeClosetCredentialsId,
    hasPoshmarkSubscription,
    closetList,
    hasActiveClosetConnection,
  } = useSelector((state: IRootState) => state.myCloset);

  const {
    listingsNextPageId,
    isListingBulkActionsMenuOpen,
    loading,
    searchQuery,
    isFetchingMoreListings,
    selectedFilterOption,
    selectedSortOption,
    selectedListings,
    myListings,
    hasActiveSearch,
    isPortalOpen,
  } = useSelector((state: IRootState) => state.myListings);
  const { isPrimeListerMobileApp, hasAnyAlerts } = useSelector((state: IRootState) => state.home);

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const currentShop = searchParams.get('shop');
  const isMobileWeb = useResponsive('down', 'sm'); // < 600 px
  const listingAgeMin = searchParams.get('listingAgeMin') || '';
  const listingAgeMax = searchParams.get('listingAgeMax') || '';
  const numberOfLikesMin = searchParams.get('numberOfLikesMin') || '';
  const numberOfLikesMax = searchParams.get('numberOfLikesMax') || '';
  const priceMin = searchParams.get('priceMin') || '';
  const priceMax = searchParams.get('priceMax') || '';
  const availability = searchParams.get('availability') || 'available';
  const department = searchParams.get('department') || 'all-departments';
  const category = searchParams.get('category') || '';
  const subcategory = searchParams.get('subcategory') || '';

  const isMobile = isMobileWeb || isPrimeListerMobileApp;
  const isSmallerScreen = useMediaQuery('(max-width: 375px)');

  const theme = useTheme();
  const shouldCreatePortal = !isMobile && hasCloset;

  const portalDiv = document.querySelector('#portal-div');

  useListingsScrollHandler(shouldCreatePortal);
  const fetchListings = (nextPageId?: string) => {
    const sort = searchParams.get('sort') || selectedSortOption.value;

    const filters = ListingService.buildFilters({
      nextPageId,
      searchQuery,
      availability,
      selectedFilterOption,
      selectedSortOption: { value: sort },
      listingAgeMin,
      listingAgeMax,
      numberOfLikesMin,
      numberOfLikesMax,
      priceMin,
      priceMax,
      category,
      subcategory,
      department,
    });

    const shopParam = searchParams.get('shop');
    const credentialsIdByShopParam = ListingService.getCredentialsIdByShopParam(
      shopParam,
      closetList
    );

    dispatch(
      getMyListingsRequest({
        credentialsId: credentialsIdByShopParam || activeClosetCredentialsId,
        filters,
      })
    );
  };

  useEffect(() => {
    dispatch(setResetFiltersAndQueries());
  }, [currentShop]);

  useEffect(() => {
    // Reset listings when shop changes or on initial load
    dispatch(setMyListings([]));
    dispatch(setSelectedListings(new Set()));
    if (activeClosetCredentialsId && hasActiveClosetConnection && hasPoshmarkSubscription) {
      fetchListings();
    }
  }, [activeClosetCredentialsId, hasActiveClosetConnection, hasPoshmarkSubscription]);

  // Independent useEffect for handling the display of bulk actions menu
  useEffect(() => {
    dispatch(setIsListingBulkActionsMenuOpen(selectedListings.size > 0));
  }, [selectedListings]);

  const fetchMoreListings = () => {
    if (!listingsNextPageId) return;
    dispatch(setIsFetchingMoreListings(true));
    fetchListings(listingsNextPageId);
    dispatch(setIsFetchingMoreListings(false));
  };

  const handleOnCardClick = (product: ProductCardProps) => {
    // Create a new Set from the current selectedListings
    const updatedSelectedListings = new Set(selectedListings);

    if (product.id) {
      if (updatedSelectedListings.has(product.id)) {
        updatedSelectedListings.delete(product.id);
      } else {
        updatedSelectedListings.add(product.id);
      }

      // Dispatch the updated Set to the state
      dispatch(setSelectedListings(updatedSelectedListings));
    }
  };

  const renderEmptyContent = () => (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        mt: -6,
        height: 1,
        textAlign: 'center',
        p: (theme) => theme.spacing(8, 2),
      }}
    >
      <Image
        disabledEffect
        alt="empty content"
        src={'/assets/illustrations/illustration_empty_content.svg'}
        sx={{ maxWidth: '214.8px', mb: 2 }}
      />

      <Typography variant="body2">
        {hasActiveSearch
          ? 'Oops! No listings found. 😕 Please adjust your search or filters and try again.'
          : 'Nothing to see yet. Your listings will appear here.'}
      </Typography>
    </Stack>
  );

  const renderedListingCards = React.useMemo(
    () =>
      isMobile ? (
        <Virtuoso
          style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          data={myListings}
          useWindowScroll
          increaseViewportBy={200}
          components={{
            Footer: ListingCardsFooter,
            List: forwardRef(({ style, children, ...props }, ref) => (
              <Stack
                gap={'8px'}
                sx={{
                  mt: !isMobile ? '16px' : !isSmallerScreen ? '12px' : '4px',
                }}
                {...props}
                ref={ref}
                style={{
                  ...style,
                }}
              >
                {children}
              </Stack>
            )),
          }}
          endReached={fetchMoreListings}
          itemContent={(index) => {
            const product = myListings[index];
            return (
              <ListingCardsItemWrapper>
                <ProductCardMobile
                  onCardClick={() => handleOnCardClick(product)}
                  {...product}
                  isSelected={selectedListings.has(product.id)}
                />
              </ListingCardsItemWrapper>
            );
          }}
        />
      ) : (
        <VirtuosoGrid
          // overscan={900}
          // style={{ height: '100%' }}
          data={myListings}
          components={{ ...createGridComponents(), Footer: ListingCardsFooter }}
          totalCount={myListings.length}
          useWindowScroll
          endReached={fetchMoreListings}
          itemContent={(index) => {
            const product = myListings[index];
            return (
              <ProductCard
                onCardClick={() => handleOnCardClick(product)}
                {...product}
                isSelected={selectedListings.has(product.id)}
              />
            );
          }}
        />
      ),
    [myListings, selectedListings, isMobile]
  );

  const hasMultipleClosets =
    closetList.filter((closet) => closet?.status !== ConnectionStatus.Removed).length > 1;

  const listingCardsFilterContainer =
    !isMobile && !hasAnyAlerts ? (
      <Box
        sx={{
          mb: '40px',
          px: '8px',
          py: '8px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ListingCardsFilterContainer />
      </Box>
    ) : (
      !hasAnyAlerts && (
        <Box
          sx={{
            position: 'fixed',
            top: hasMultipleClosets ? '48px' : '0',
            left: '0',
            right: '0',
            zIndex: 2,
            backgroundColor: '#fff',
          }}
        >
          <ListingCardsFilterContainer />
        </Box>
      )
    );

  const isDesktop = useResponsive('up', 'sm');

  const renderSkeletonLoader = () => (
    <Stack
      sx={{
        paddingLeft: '16px',
        paddingRight: '16px',
      }}
    >
      {isDesktop ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '24px',
          }}
        >
          {Array.from({ length: 4 }).map((_, index) => (
            <ListingSkeletonCard key={index} />
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            flexDirection: 'column',
          }}
        >
          {Array.from({ length: 3 }).map((_, index) => (
            <ListingSkeletonCard key={index} />
          ))}
        </Box>
      )}
    </Stack>
  );

  const listingCardsFilterContent =
    shouldCreatePortal && isPortalOpen && portalDiv
      ? ReactDOM.createPortal(listingCardsFilterContainer, portalDiv)
      : listingCardsFilterContainer;

  return (
    <Stack sx={{ position: 'relative' }}>
      <Box>{listingCardsFilterContent}</Box>

      {loading ? (
        renderSkeletonLoader()
      ) : (
        <>
          {myListings.length > 0 ? (
            <Box
              sx={{
                paddingTop: isPortalOpen ? '32px' : '0px',
              }}
            >
              {renderedListingCards}
            </Box>
          ) : (
            renderEmptyContent()
          )}
        </>
      )}

      {isListingBulkActionsMenuOpen && <ListingsBulkActionsMenu />}
      <ListingsFilterDrawer />
    </Stack>
  );
}

export default block(() => ListingCardsContainer());
