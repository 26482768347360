import React, { useEffect, useState } from 'react';
import { Rating } from '@mui/material';

interface AnimatedRatingProps {
  rating: number;
  setRating: React.Dispatch<React.SetStateAction<number | null>>;
  setHoverEffectDone: React.Dispatch<React.SetStateAction<boolean>>;
  handleRatingChange: (event: React.ChangeEvent<{}>, newValue: number | null) => void;
}

/**
 * The AnimatedRating component creates an animated hover effect on the rating stars.
 * It programmatically simulates hovering over each star in sequence before allowing user interaction.
 *
 * @param rating - The current rating value.
 * @param setRating - A function to set the rating value.
 * @param setHoverEffectDone - A function to indicate if the hover effect animation is done.
 * @param handleRatingChange - A function to handle rating change events.
 * @returns The AnimatedRating component.
 */
const AnimatedRating: React.FC<AnimatedRatingProps> = ({
  rating,
  setRating,
  setHoverEffectDone,
  handleRatingChange,
}) => {
  // State to keep track of the current hovered index
  const [currentHoverIndex, setCurrentHoverIndex] = useState<number>(0);

  const STAR_COUNT = 5;

  const initialDelay = 500; // Delay before the sequence starts
  const starDelay = 200; // Delay between each "hover" effect
  useEffect(() => {
    setHoverEffectDone(false); // <--- Set hoverEffectDone to false here
    setRating(null);
    // Stores timeout IDs for each hover effect to allow for clean up.
    const hoverEffects: ReturnType<typeof setTimeout>[] = [];
    // Stores timeout IDs used for resetting the scale effect on each star.
    const cleanupTimeouts: ReturnType<typeof setTimeout>[] = [];
    const stars = document.querySelectorAll('.MuiRating-icon');

    // Combined animation sequence
    setTimeout(() => {
      for (let i = 1; i <= 5; i++) {
        hoverEffects.push(
          setTimeout(() => {
            setRating(i);
            setCurrentHoverIndex(i); // Set the current star as "hovered"
            // Directly apply a scaling transformation to the current star.
            const star = stars[i - 1] as HTMLElement;
            if (star) {
              star.style.transform = 'scale(1.2)';
              star.style.transition = 'transform 0.2s ease-in-out';
              cleanupTimeouts.push(
                setTimeout(() => {
                  star.style.transform = 'scale(1)';
                }, starDelay)
              );
            }
          }, initialDelay + starDelay * i)
        );
      }

      // Reset to allow user interaction and clean up scale after the sequence
      hoverEffects.push(
        setTimeout(() => {
          setRating(null);
          setCurrentHoverIndex(0); // Reset hover index
          setHoverEffectDone(true);
          // Clean up the scale effect for all stars
          stars.forEach((star) => {
            (star as HTMLElement).style.transform = '';
          });
          /* Important: The number 7 is used to provide an additional time buffer 
            after the last star's animation before resetting the rating.
          */
        }, initialDelay + starDelay * 7)
      );
    }, initialDelay);

    // Cleanup function
    return () => {
      hoverEffects.forEach(clearTimeout);
      cleanupTimeouts.forEach(clearTimeout);
    };
  }, []);

  return (
    <Rating
      name="hover-feedback"
      value={rating}
      sx={{
        '& .MuiSvgIcon-root': {
          width: '35px',
          height: '35px',
        },
      }}
      onChange={(event, newValue) => {
        handleRatingChange(event, newValue);
      }}
    />
  );
};

export default AnimatedRating;
