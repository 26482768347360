import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// @mui
import {
  Slide,
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Alert,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import {
  accountDeactivateRequest,
  setIsInitialDeactivateOpen,
} from 'src/store/dashboard/slices/accountSlice';
import { IRootState } from 'src/store';

import { PATH_AUTH } from 'src/routes/paths';
import { openCrispChat } from 'src/utils/crispChatHelpers';

// -- Transition effect for Dialog
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const DeactivateDialog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isInitialDeactivateOpen, loading } = useSelector((state: IRootState) => state.account);

  const handleModalClose = () => {
    dispatch(setIsInitialDeactivateOpen(false));
  };

  const handleDeactivateButton = async () => {
    dispatch(accountDeactivateRequest({ navigate }));
    !loading && dispatch(setIsInitialDeactivateOpen(false));
    !loading && navigate(PATH_AUTH.login, { replace: true });
  };

  return (
    <Dialog
      open={isInitialDeactivateOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleModalClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title"> Deactivate your account?</DialogTitle>

      <DialogContent>
        <Alert severity="error" sx={{ mb: '1.5rem' }}>
          Please let us know if there's anything we can do before you cancel. We value your feedback
          and would love to hear from you via{' '}
          <b
            style={{ cursor: 'pointer' }}
            onClick={() => window.open('mailto:support@primelister.com')}
          >
            email
          </b>{' '}
          or{' '}
          <b style={{ cursor: 'pointer' }} onClick={openCrispChat}>
            chat
          </b>
          .
        </Alert>

        <DialogContentText id="alert-dialog-slide-description" sx={{ mb: '1.5rem' }}>
          Your account will be deactivated. Are you sure you want to deactivate your account? Doing
          so will also cancel any active subscriptions you may have.
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <Button color="error" onClick={handleDeactivateButton}>
          Deactivate
        </Button>

        <Button variant="contained" color="primary" onClick={handleModalClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeactivateDialog;
