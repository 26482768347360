import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cardRules } from 'src/pages/dashboard/GroupsAndTags/utils/cardRules';
import { IInventoryRequest } from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';

export interface IGroupData {
  id?: string;
  name?: string;
  _id?: string;
}
export interface ITagData {
  _id?: string;
  name?: string;
  color?: string;
}
export interface IGroupsAndTagsState {
  loading: boolean;
  groupsAndTagsInitialLoading: boolean;
  error: string;
  //--Groups
  groupsData: IGroupData[];
  lastAddedGroup: IGroupData | null;
  groupsFilteredData: any;
  groupsOpenAddDialog: boolean;
  groupsOpenAddDialogError: string;
  groupsAddGroupName: string;
  groupsFilterName: string;
  groupsDense: boolean;
  groupsPage: number;
  groupsRowsPerPage: number;
  groupsOrderBy: string;
  groupsOrder: 'asc' | 'desc';
  groupsOpenPopoverMenu: HTMLElement | null;
  groupsDialogData: IGroupData;
  groupsOpenEditDialog: boolean;
  groupsOpenEditDialogError: string;
  groupsOpenDeleteDialog: boolean;
  groupsOpenDeleteDialogError: string;
  groupsNewGroupName: string;
  //--Tags
  tagsData: ITagData[];
  tagsOpenAddDialog: boolean;
  tagsOpenAddDialogError: string;
  tagsAddTagName: string;
  tagsAddTagColor: string;
  tagsFilterName: string;
  tagsDense: boolean;
  tagsPage: number;
  tagsRowsPerPage: number;
  tagsOrderBy: string;
  tagsOrder: 'asc' | 'desc';
  tagsOpenPopoverMenu: HTMLElement | null;
  tagsDialogData: ITagData;
  tagsOpenEditDialog: boolean;
  tagsOpenEditDialogError: string;
  tagsOpenDeleteDialog: boolean;
  tagsOpenDeleteDialogError: string;
  tagsNewTagName: string;
  tagsNewTagColor: string;
}

const initialState: IGroupsAndTagsState = {
  loading: false,
  groupsFilteredData: [],
  groupsAndTagsInitialLoading: false,
  error: '',
  //--Groups
  groupsData: [],
  groupsOpenAddDialog: false,
  groupsOpenAddDialogError: '',
  groupsAddGroupName: '',
  groupsFilterName: '',
  groupsDense: cardRules.groups.defaultDense,
  groupsPage: cardRules.groups.paginationRules.defaultPage,
  groupsRowsPerPage: 5,
  groupsOrderBy: '',
  groupsOrder: 'asc',
  groupsOpenPopoverMenu: null,
  groupsDialogData: {},
  groupsOpenEditDialog: false,
  groupsOpenEditDialogError: '',
  groupsOpenDeleteDialog: false,
  groupsOpenDeleteDialogError: '',
  groupsNewGroupName: '',
  lastAddedGroup: null,
  //--Tags
  tagsData: [],
  tagsOpenAddDialog: false,
  tagsOpenAddDialogError: '',
  tagsAddTagName: '',
  tagsAddTagColor: '',
  tagsFilterName: '',
  tagsDense: cardRules.tags.defaultDense,
  tagsPage: cardRules.tags.paginationRules.defaultPage,
  tagsRowsPerPage: 5,
  tagsOrderBy: '',
  tagsOrder: 'asc',
  tagsOpenPopoverMenu: null,
  tagsDialogData: {},
  tagsOpenEditDialog: false,
  tagsOpenEditDialogError: '',
  tagsOpenDeleteDialog: false,
  tagsOpenDeleteDialogError: '',
  tagsNewTagName: '',
  tagsNewTagColor: '',
};

const groupsAndTagsSlice = createSlice({
  name: 'groupsAndTags',
  initialState,
  reducers: {
    // GROUPS

    setGroupsAndTagsInitialLoading: (state, action: PayloadAction<boolean>) => {
      state.groupsAndTagsInitialLoading = action.payload;
    },
    setGroupsOpenAddDialog: (state, action: PayloadAction<boolean>) => {
      state.groupsOpenAddDialog = action.payload;
    },
    setGroupsOpenAddDialogError: (state, action) => {
      state.groupsOpenAddDialogError = action.payload;
    },
    setGroupsAddGroupName: (state, action: PayloadAction<string>) => {
      state.groupsAddGroupName = action.payload;
    },
    setGroupsFilterName: (state, action: PayloadAction<string>) => {
      state.groupsFilterName = action.payload;
    },
    setGroupsDense: (state, action: PayloadAction<boolean>) => {
      state.groupsDense = action.payload;
    },
    setGroupsPage: (state, action: PayloadAction<number>) => {
      state.groupsPage = action.payload;
    },
    setGroupsRowsPerPage: (state, action: PayloadAction<number>) => {
      state.groupsRowsPerPage = action.payload;
    },
    setGroupsOrderBy: (state, action: PayloadAction<string>) => {
      state.groupsOrderBy = action.payload;
    },
    setGroupsOrder: (state, action: PayloadAction<'asc' | 'desc'>) => {
      state.groupsOrder = action.payload;
    },
    setGroupsOpenPopoverMenu: (state, action) => {
      state.groupsOpenPopoverMenu = action.payload;
    },
    setGroupsDialogData: (state, action) => {
      state.groupsDialogData = action.payload;
    },
    setGroupsOpenEditDialog: (state, action: PayloadAction<boolean>) => {
      state.groupsOpenEditDialog = action.payload;
    },
    setGroupsOpenEditDialogError: (state, action) => {
      state.groupsOpenDeleteDialogError = action.payload;
    },
    setGroupsOpenDeleteDialog: (state, action: PayloadAction<boolean>) => {
      state.groupsOpenDeleteDialog = action.payload;
    },
    setGroupsOpenDeleteDialogError: (state, action) => {
      state.groupsOpenDeleteDialogError = action.payload;
    },
    setGroupsNewGroupName: (state, action: PayloadAction<string>) => {
      state.groupsNewGroupName = action.payload;
    },
    //--TAGS
    setTagsOpenAddDialog: (state, action: PayloadAction<boolean>) => {
      state.tagsOpenAddDialog = action.payload;
    },
    setTagsOpenAddDialogError: (state, action) => {
      state.tagsOpenAddDialogError = action.payload;
    },
    setTagsAddTagName: (state, action: PayloadAction<string>) => {
      state.tagsAddTagName = action.payload;
    },
    setTagsAddTagColor: (state, action: PayloadAction<string>) => {
      state.tagsAddTagColor = action.payload;
    },
    setTagsFilterName: (state, action) => {
      state.tagsFilterName = action.payload;
    },
    setTagsDense: (state, action: PayloadAction<boolean>) => {
      state.tagsDense = action.payload;
    },
    setTagsPage: (state, action: PayloadAction<number>) => {
      state.tagsPage = action.payload;
    },
    setTagsRowsPerPage: (state, action: PayloadAction<number>) => {
      state.tagsRowsPerPage = action.payload;
    },
    setTagsOrderBy: (state, action: PayloadAction<string>) => {
      state.tagsOrderBy = action.payload;
    },
    setTagsOrder: (state, action: PayloadAction<'asc' | 'desc'>) => {
      state.tagsOrder = action.payload;
    },
    setTagsOpenPopoverMenu: (state, action) => {
      state.tagsOpenPopoverMenu = action.payload;
    },
    setTagsDialogData: (state, action) => {
      state.tagsDialogData = action.payload;
    },
    setTagsOpenEditDialog: (state, action: PayloadAction<boolean>) => {
      state.tagsOpenEditDialog = action.payload;
    },
    setTagsOpenEditDialogError: (state, action) => {
      state.tagsOpenEditDialogError = action.payload;
    },
    setTagsOpenDeleteDialog: (state, action: PayloadAction<boolean>) => {
      state.tagsOpenDeleteDialog = action.payload;
    },
    setTagsOpenDeleteDialogError: (state, action) => {
      state.tagsOpenDeleteDialogError = action.payload;
    },
    setTagsNewTagName: (state, action: PayloadAction<string>) => {
      state.tagsNewTagName = action.payload;
    },
    setTagsNewTagColor: (state, action: PayloadAction<string>) => {
      state.tagsNewTagColor = action.payload;
    },
    //----GROUPS----\\
    getGroupsRequest: (state) => {
      state.loading = true;
    },
    getGroupsSuccess: (state, action) => {
      state.loading = false;
      state.groupsAndTagsInitialLoading = false;
      state.error = '';
      state.groupsData = action.payload;
    },
    getGroupsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.groupsAndTagsInitialLoading = false;
    },
    //--
    addNewGroupRequest: (
      state,
      action: PayloadAction<{
        groupData: IGroupData;
        getData: IInventoryRequest;
      }>
    ) => {
      state.loading = true;
    },
    addNewGroupSuccess: (state, action: PayloadAction<IGroupData>) => {
      state.loading = false;
      state.lastAddedGroup = action.payload;
      state.error = '';
    },
    addNewGroupFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    clearLastAddedGroup: (state) => {
      state.lastAddedGroup = null;
    },
    //--
    deleteGroupRequest: (
      state,
      action: PayloadAction<{
        groupData: IGroupData;
        getData: IInventoryRequest;
        setIsAutocompleteOpen?: (value: boolean) => void;
      }>
    ) => {
      state.loading = true;
    },
    deleteGroupSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    deleteGroupFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    editGroupRequest: (
      state,
      action: PayloadAction<{
        groupData: IGroupData;
        getData: IInventoryRequest;
        handleGroupSelect: (group: IGroupData | null) => void;
      }>
    ) => {
      state.loading = true;
    },
    editGroupSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    editGroupFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    //----TAGS----\\
    getTagsRequest: (state) => {
      state.loading = true;
    },
    getTagsSuccess: (state, action) => {
      state.loading = false;
      state.groupsAndTagsInitialLoading = false;
      state.error = '';
      state.tagsData = action.payload;
    },
    getTagsFail: (state, action) => {
      state.loading = false;
      state.groupsAndTagsInitialLoading = false;
      state.error = action.payload;
    },
    //--
    addNewTagRequest: (
      state,
      action: PayloadAction<{
        newTag: ITagData;
        makeRequest?: boolean;
        getData: IInventoryRequest;
      }>
    ) => {
      state.loading = true;
    },
    addNewTagSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    addNewTagFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    deleteTagRequest: (
      state,
      action: PayloadAction<{
        tagData: ITagData;
        quickEditSelectedTags: ITagData[];
        getData: IInventoryRequest;
      }>
    ) => {
      state.loading = true;
    },
    deleteTagSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    deleteTagFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    editTagRequest: (
      state,
      action: PayloadAction<{
        tagData: ITagData;
        quickEditSelectedTags: ITagData[];
        getData: IInventoryRequest;
      }>
    ) => {
      state.loading = true;
    },
    editTagSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    editTagFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetGroupsAndTagsState: (state) => initialState,
  },
});

export const {
  resetGroupsAndTagsState,
  setGroupsAndTagsInitialLoading,
  //--Groups
  setGroupsOpenAddDialog,
  setGroupsOpenAddDialogError,
  setGroupsAddGroupName,
  setGroupsFilterName,
  setGroupsDense,
  setGroupsPage,
  setGroupsRowsPerPage,
  setGroupsOrderBy,
  setGroupsOrder,
  setGroupsOpenPopoverMenu,
  setGroupsDialogData,
  setGroupsOpenEditDialog,
  setGroupsOpenEditDialogError,
  setGroupsOpenDeleteDialog,
  setGroupsOpenDeleteDialogError,
  setGroupsNewGroupName,
  //--Tags
  setTagsOpenAddDialog,
  setTagsOpenAddDialogError,
  setTagsAddTagName,
  setTagsAddTagColor,
  setTagsFilterName,
  setTagsDense,
  setTagsPage,
  setTagsRowsPerPage,
  setTagsOrderBy,
  setTagsOrder,
  setTagsOpenPopoverMenu,
  setTagsDialogData,
  setTagsOpenEditDialog,
  setTagsOpenEditDialogError,
  setTagsOpenDeleteDialog,
  setTagsOpenDeleteDialogError,
  setTagsNewTagName,
  setTagsNewTagColor,
  //--GROUPS
  getGroupsRequest,
  getGroupsSuccess,
  getGroupsFail,
  addNewGroupRequest,
  addNewGroupSuccess,
  clearLastAddedGroup,
  addNewGroupFail,
  deleteGroupRequest,
  deleteGroupSuccess,
  deleteGroupFail,
  editGroupRequest,
  editGroupSuccess,
  editGroupFail,
  //--TAGS
  getTagsRequest,
  getTagsSuccess,
  getTagsFail,
  addNewTagRequest,
  addNewTagSuccess,
  addNewTagFail,
  deleteTagRequest,
  deleteTagSuccess,
  deleteTagFail,
  editTagRequest,
  editTagSuccess,
  editTagFail,
} = groupsAndTagsSlice.actions;

export default groupsAndTagsSlice.reducer;
