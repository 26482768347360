import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FilterOptionsState,
  Paper,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material';
import {
  InventoryTagActionReason,
  useTagUtilities,
} from 'src/pages/dashboard/Inventory/helpers/useTagUtilities';
import { DataGridTagsColumnInterface } from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import TagEditPopover from './TagEditPopover';
import { IRootState } from 'src/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  QuickActionsTagPopoverModeEnum,
  setIsTagEditPopoverOpen,
  setIsTagsAutocompleteOpen,
  setQuickActionsTagPopoverMode,
  setTagEditPopoverAnchorEl,
} from 'src/store/dashboard/slices/inventorySlice';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ITagData } from 'src/store/dashboard/slices/groupsAndTagsSlice';
import { LabelColor } from 'src/components/label';
import _ from 'lodash';
import TagsDeleteDialog from 'src/pages/dashboard/GroupsAndTags/Tags/TagsTable/TagsDeleteDialog';
import NoOptionsFound from './NoOptionsFound';
import { useTagAutocompleteClickOutside } from 'src/hooks/useTagAutocompleteClickOutside';

interface QuickEditPopoverTagsContentProps {
  currentTags?: DataGridTagsColumnInterface['tags'];
  handleTagsUpdated: (event: any, options: ITagData[], reason: string) => void;
  handleDeleteTag: (tagId: string) => void;
}

export default function QuickEditPopoverTagsContent({
  handleTagsUpdated,
  currentTags,
  handleDeleteTag,
}: QuickEditPopoverTagsContentProps) {
  const { getTagColor, renderTagMenuItem, tagsOptions, handleCreateNewTag } = useTagUtilities();
  const {
    quickActionsTagPopoverMode,
    quickEditSelectedTags,
    isTagsAutocompleteOpen,
    isTagEditPopoverOpen,
  } = useSelector((state: IRootState) => state.inventory);
  const [isNoOptionsOpen, setIsNoOptionsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { autocompleteRef, tagEditPopoverRef } = useTagAutocompleteClickOutside(
    isTagsAutocompleteOpen,
    isTagEditPopoverOpen,
    isNoOptionsOpen
  );

  const isInEditMode = useMemo(
    () => quickActionsTagPopoverMode === QuickActionsTagPopoverModeEnum.EDIT,
    [quickActionsTagPopoverMode]
  );

  const isInCreateMode = useMemo(
    () => quickActionsTagPopoverMode === QuickActionsTagPopoverModeEnum.CREATE,
    [quickActionsTagPopoverMode]
  );

  const [inputValue, setInputValue] = useState('');

  const renderNoOptions = () => (
    <NoOptionsFound
      type="group"
      inputValue={inputValue}
      onCreateClick={(e) => {
        handleCreateNewTag(e, inputValue);
      }}
    />
  );

  const isTagSelected = (option: ITagData) =>
    quickEditSelectedTags.some((tag) => tag._id === option._id);

  const dispatch = useDispatch();
  return (
    <>
      <Autocomplete
        fullWidth
        multiple
        freeSolo
        ref={autocompleteRef}
        open={isTagsAutocompleteOpen}
        limitTags={3}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onOpen={(event) => {
          setAnchorEl(event.currentTarget as HTMLElement);
          dispatch(setIsTagsAutocompleteOpen(true));
        }}
        onClose={() => {
          setAnchorEl(null);
          dispatch(setIsTagsAutocompleteOpen(false));
        }}
        ListboxProps={{
          sx: {
            overflow: isInEditMode || isInCreateMode ? 'hidden' : 'auto',
          },
        }}
        value={quickEditSelectedTags}
        onChange={(event, newValue) => {
          const updatedTags: ITagData[] = newValue.map((option) => {
            if (typeof option === 'string') {
              return { name: option, _id: '', color: '' };
            } else {
              return option;
            }
          });
          handleTagsUpdated(event, updatedTags, InventoryTagActionReason.SELECTED);
        }}
        filterOptions={(options: ITagData[], params: FilterOptionsState<ITagData>) => {
          const { inputValue } = params;
          // Use Lodash for fuzzy matching
          const filtered = options.filter((option) =>
            _.includes(option.name!.toLowerCase(), inputValue.toLowerCase())
          );

          if (filtered.length === 0 && inputValue !== '') {
            setIsNoOptionsOpen(true);
            return [{ name: '', _id: '', color: '' }]; // Dummy option to trigger renderOption
          }
          setIsNoOptionsOpen(false);
          return filtered;
        }}
        selectOnFocus
        onBlurCapture={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        clearOnBlur
        blurOnSelect={false}
        handleHomeEndKeys
        options={tagsOptions}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return option.name || '';
        }}
        renderOption={(props, option) => {
          if (isNoOptionsOpen) {
            return renderNoOptions();
          }
          const { ...optionProps } = props;
          return (
            <li
              style={{
                ...(isTagSelected(option) && {
                  backgroundColor:
                    isInEditMode || isInCreateMode ? 'transparent' : 'rgba(32, 101, 209, 0.16)',
                }),
              }}
              key={option._id!}
              {...optionProps}
            >
              {renderTagMenuItem(option)}
            </li>
          );
        }}
        sx={{
          '& .MuiInputLabel-root': {
            fontWeight: 600,
            color: '#212B36',
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#212B36',
          },
          '& .MuiInputLabel-root:not(.Mui-focused)': {
            color: '#637381 !important',
          },
          '& .MuiInputBase-input': {
            fontSize: '14px !important',
            fontWeight: 400,
            lineHeight: '22px',
          },
          '& .MuiInputBase-root': {
            minHeight: '40px',
          },
        }}
        slotProps={{
          paper: {
            sx: {
              '& .MuiAutocomplete-noOptions': {
                height: '94px',
                overflow: 'hidden',
                padding: '4px 4px 8px 4px',
              },
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            multiline
            size="small"
            sx={{
              '& .MuiInputLabel-root:not(.Mui-focused)': {
                color: '#919EAB !important',
                fontSize: '16px !important',
                fontWeight: 400,
              },
              '& .MuiInputBase-input::placeholder': {
                color: '#919EAB !important',
              },
              fontSize: '14px !important',
            }}
            label={'Select tags'}
            placeholder={'Select tags'}
          />
        )}
        renderTags={(selected, getTagProps, ownerState) =>
          selected.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={option._id || option.name}
              label={option.name}
              onDelete={() => handleDeleteTag(option._id!)}
              size="small"
              color={getTagColor(option.name!) || ('default' as LabelColor)}
              variant="soft"
              sx={{
                fontWeight: 700,
                fontSize: '12px !important',
                borderRadius: '8px',
                cursor: 'pointer',
                transition: 'none',
                ...(isInEditMode
                  ? {
                      color: '#919EAB',
                      backgroundColor: 'rgba(145, 158, 171, 0.16)',
                    }
                  : {}),
                ...(isInEditMode && {
                  '& .MuiChip-deleteIcon': {
                    color: '#919EAB',
                  },
                }),
              }}
            />
          ))
        }
      />

      <TagEditPopover handleTagsUpdated={handleTagsUpdated} ref={tagEditPopoverRef} />
      <TagsDeleteDialog handleTagsUpdated={handleTagsUpdated} />
    </>
  );
}
