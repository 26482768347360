import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Switch, Container, Typography, Stack, useMediaQuery } from '@mui/material';

import { ChangeEvent, useEffect, useState } from 'react';
import CrosslistPricingCard from './CrosslistPricingCard';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { getCrosslistAnnualPlans, getCrosslistMonthlyPlans } from '../../utils/constants';
// _mock_

// sections

// ----------------------------------------------------------------------

export default function CrosslistPricingPage() {
  const matches = useMediaQuery('(min-width:757px)');
  const [isYearly, setIsYearly] = useState(false);
  const [isThereAnyActivePlan, setIsThereAnyActivePlan] = useState(false);
  const { billingSubscriptionList } = useSelector((state: IRootState) => state.account);

  useEffect(() => {
    const isThereAnySubscriptions = Boolean(billingSubscriptionList[0]);

    if (isThereAnySubscriptions) {
      const checkIfThePlanIsActive = billingSubscriptionList[0]?.status
        .toLowerCase()
        .includes('active');

      const checkIfCurrentIsYearly = billingSubscriptionList[0]?.planId
        ?.toLowerCase()
        .includes('annual');

      checkIfThePlanIsActive && setIsThereAnyActivePlan(true);
      checkIfCurrentIsYearly && setIsYearly(true);
    }
  }, [billingSubscriptionList]);

  const handleChangeTimeOption = (e: ChangeEvent<HTMLInputElement>, newValue: boolean) => {
    setIsYearly(newValue);
  };

  const plansToRender = isYearly
    ? getCrosslistAnnualPlans(isThereAnyActivePlan)
    : getCrosslistMonthlyPlans(isThereAnyActivePlan);

  return (
    <>
      <Helmet>
        <title> Pricing | Primelister</title>
      </Helmet>

      <Container disableGutters maxWidth={'lg'}>
        <Typography variant="h3" align="center" paragraph>
          Choose the right plan that&apos;s for your business
          <br />
        </Typography>

        <Typography align="center" sx={{ color: 'text.secondary' }}>
          Pay by the month or year, and cancel at any time
        </Typography>

        <Box sx={{ my: 5 }}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <Typography variant="overline" sx={{ mr: 1.5 }}>
              MONTHLY
            </Typography>

            <Switch checked={isYearly} value={isYearly} onChange={handleChangeTimeOption} />
            <Typography variant="overline" sx={{ ml: 1.5 }}>
              YEARLY
            </Typography>
          </Stack>
        </Box>

        <Stack
          direction={matches ? 'row' : 'column'}
          flexWrap="wrap"
          spacing={3}
          justifyContent="center"
          alignItems="center"
        >
          {plansToRender.map((card, index) => (
            <CrosslistPricingCard key={card.subscription} card={card} index={index + 1} />
          ))}
        </Stack>
      </Container>
    </>
  );
}
