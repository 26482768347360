import { LabelColor } from 'src/components/label';

export interface ICardRules {
  groups: {
    title: string;
    newButtonText: string;
    tableHeadText: string;
    searchBoxPlaceholder: string;
    defaultDense: boolean;
    paginationRules: {
      defaultPage: number;
      rowsPerPage: number[];
    };
  };
  tags: {
    title: string;
    newButtonText: string;
    tableHeadText: string;
    searchBoxPlaceholder: string;
    defaultDense: boolean;
    paginationRules: {
      defaultPage: number;
      rowsPerPage: number[];
    };
    colorRules: [
      {
        colorName: string;
        colorCode: LabelColor;
      },
      {
        colorName: string;
        colorCode: LabelColor;
      },
      {
        colorName: string;
        colorCode: LabelColor;
      },
      {
        colorName: string;
        colorCode: LabelColor;
      },
      {
        colorName: string;
        colorCode: LabelColor;
      },
      {
        colorName: string;
        colorCode: LabelColor;
      }
    ];
  };
}
export const cardRules: ICardRules = {
  groups: {
    title: 'Groups',
    newButtonText: 'New Group',
    tableHeadText: 'Name',
    searchBoxPlaceholder: 'Search...',
    defaultDense: false,
    paginationRules: {
      defaultPage: 0,
      rowsPerPage: [5, 10, 25],
    },
  },
  tags: {
    title: 'Tags',
    newButtonText: 'New Tag',
    tableHeadText: 'Name',
    searchBoxPlaceholder: 'Search...',
    defaultDense: false,
    paginationRules: {
      defaultPage: 0,
      rowsPerPage: [5, 10, 25],
    },
    colorRules: [
      {
        colorName: 'Green',
        colorCode: 'success',
      },
      {
        colorName: 'Gray',
        colorCode: 'default',
      },
      {
        colorName: 'Blue',
        colorCode: 'info',
      },
      {
        colorName: 'Purple',
        colorCode: 'purple',
      },
      {
        colorName: 'Orange',
        colorCode: 'warning',
      },
      {
        colorName: 'Red',
        colorCode: 'error',
      },
    ],
  },
};
