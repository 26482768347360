import React from 'react';
import InventoryDeleteViaDetailDialog from './InventoryDeleteViaDetailDialog';
import InventoryLinkDeleteDialog from './InventoryLinkDeleteDialog';
import LastLinkRemoveErrorDialog from './LastLinkRemoveErrorDialog';

const EditPageDialogs = () => (
  <>
    <InventoryDeleteViaDetailDialog />
    <InventoryLinkDeleteDialog />
    <LastLinkRemoveErrorDialog />
  </>
);

export default EditPageDialogs;
