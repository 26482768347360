// @mui
import { Box, Skeleton, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
// hooks

import PlatfromLinksForm from './PlatformLinksForm';
import { IPlatforms } from 'src/store/dashboard/slices/myShopsSlice';

const platformIcons = [
  {
    name: IPlatforms.DEPOP,
    url: '/icons/editLinkIcons/depop.svg',
    displayName: 'Depop',
    linkValidation: 'depop.com/products/',
  },
  {
    name: IPlatforms.EBAYAU,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (AU)',
    linkValidation: 'ebay.com.au/itm/',
  },
  {
    name: IPlatforms.EBAYCA,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (CA)',
    linkValidation: 'ebay.ca/itm/',
  },
  {
    name: IPlatforms.EBAYUS,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (US)',
    linkValidation: 'ebay.com/itm/',
  },
  {
    name: IPlatforms.ETSY,
    url: '/icons/editLinkIcons/etsy.svg',
    displayName: 'Etsy',
    linkValidation: 'etsy.com/',
  },
  {
    name: IPlatforms.FACEBOOK,
    url: '/icons/editLinkIcons/facebook.svg',
    displayName: 'Facebook',
    linkValidation: 'facebook.com/marketplace/item/',
  },
  {
    name: IPlatforms.GRAILED,
    url: '/icons/editLinkIcons/grailed.svg',
    displayName: 'Grailed',
    linkValidation: 'grailed.com/listings/',
  },
  {
    name: IPlatforms.MERCARI,
    url: '/icons/editLinkIcons/mercari.svg',
    displayName: 'Mercari',
    linkValidation: 'mercari.com/us/item/',
  },
  {
    name: IPlatforms.POSHMARKCA,
    url: '/icons/editLinkIcons/poshmark.svg',
    displayName: 'Poshmark (CA)',
    linkValidation: 'poshmark.ca/listing/',
  },
  {
    name: IPlatforms.POSHMARKUS,
    url: '/icons/editLinkIcons/poshmark.svg',
    displayName: 'Poshmark (US)',
    linkValidation: 'poshmark.com/listing/',
  },
  {
    name: IPlatforms.SHOPIFY,
    url: '/icons/editLinkIcons/shopify.svg',
    displayName: 'Shopify',
    linkValidation: `admin.shopify.com/store/`,
  },
];

export default function PlatfromLinksContainer() {
  const { loading, selectedListingDetails } = useSelector((state: IRootState) => state.inventory);

  const addSkeletonToComponents = (component: any) =>
    loading ? <Skeleton variant="rounded" sx={{ width: '100', height: '5rem' }} /> : component;

  // Sort the platform icons by display name, prioritizing the ones that exist in the selected listing details.
  // Use alphabetical order inside both groups.
  const sortedPlatforms = platformIcons.sort((a, b) => {
    const refs = selectedListingDetails?.refs;
    const aHasKey = refs && refs.hasOwnProperty(a.name);
    const bHasKey = refs && refs.hasOwnProperty(b.name);
    const aName = a.displayName.toLowerCase();
    const bName = b.displayName.toLowerCase();

    if (aHasKey && !bHasKey) {
      return -1;
    } else if (!aHasKey && bHasKey) {
      return 1;
    } else {
      return aName.localeCompare(bName);
    }
  });

  return (
    <Stack spacing={2}>
      {sortedPlatforms.map((platform) => (
        <Box key={platform.displayName}>
          {addSkeletonToComponents(<PlatfromLinksForm key={platform.displayName} platform={platform} />)}
        </Box>
      ))}
    </Stack>
  );
}
