import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAPIMarketplaces } from './myShopsAPITableSlice';
import {
  isEbay,
  isShopify,
} from 'src/pages/dashboard/Inventory/mainComponents/ImportTable/importDataGrid/helpers';

export interface IMyShopsTableRow {
  platformName: string;
  name: string;
  icon: string;
  shop: string;
  isLoading: boolean;
  isConnected: boolean;
  connectionLink: string;
  username: string;
}

export enum IPlatforms {
  MERCARI = 'mercari',
  POSHMARKUS = 'poshmark-us',
  POSHMARKCA = 'poshmark-ca',
  EBAYUS = 'ebay-us',
  EBAYAU = 'ebay-au',
  EBAYCA = 'ebay-ca',
  FACEBOOK = 'facebook',
  SHOPIFY = 'shopify',
  ETSY = 'etsy',
  GRAILED = 'grailed',
  DEPOP = 'depop',
}

export type IPlatformStateFinder =
  | IPlatforms.MERCARI
  | IPlatforms.POSHMARKUS
  | IPlatforms.POSHMARKCA
  | IPlatforms.FACEBOOK
  | IPlatforms.SHOPIFY
  | IPlatforms.ETSY
  | IPlatforms.GRAILED
  | IPlatforms.DEPOP
  | IPlatforms.EBAYUS
  | IPlatforms.EBAYAU
  | IPlatforms.EBAYCA;

export const platformStateFinder: { [key: string]: string } = {
  [IPlatforms.MERCARI]: 'Mercari',
  [IPlatforms.POSHMARKUS]: 'PoshmarkUs',
  [IPlatforms.POSHMARKCA]: 'PoshmarkCa',
  [IPlatforms.FACEBOOK]: 'Facebook',
  [IPlatforms.SHOPIFY]: 'Shopify',
  [IPlatforms.ETSY]: 'Etsy',
  [IPlatforms.GRAILED]: 'Grailed',
  [IPlatforms.DEPOP]: 'Depop',
  [IPlatforms.EBAYUS]: 'EbayUs',
  [IPlatforms.EBAYAU]: 'EbayAu',
  [IPlatforms.EBAYCA]: 'EbayCa',
};

export interface IMarketPlaceProps {
  isLoggedIn: boolean;
  isLoading: boolean;
  username: string;
  userId: string | number | null;
}

const defaultStateForMarketplaces: IMarketPlaceProps = {
  isLoggedIn: false,
  isLoading: false,
  username: '',
  userId: null,
};

export interface IMyShopsState {
  selectedActionRow: IMyShopsTableRow;
  isBrowserTableActionsMenuOpen: HTMLElement | null;
  isAPITableActionsMenuOpen: HTMLElement | null;
  isAllPlatformsLoading: boolean;
  isNotLoggedInDialogOpen: boolean;
  //--
  [IPlatforms.DEPOP]: IMarketPlaceProps;
  [IPlatforms.ETSY]: IMarketPlaceProps;
  [IPlatforms.FACEBOOK]: IMarketPlaceProps;
  [IPlatforms.GRAILED]: IMarketPlaceProps;
  [IPlatforms.MERCARI]: IMarketPlaceProps;
  [IPlatforms.POSHMARKCA]: IMarketPlaceProps;
  [IPlatforms.POSHMARKUS]: IMarketPlaceProps;
  [IPlatforms.EBAYCA]: IMarketPlaceProps;
  [IPlatforms.EBAYUS]: IMarketPlaceProps;
  [IPlatforms.EBAYAU]: IMarketPlaceProps;
  [IPlatforms.SHOPIFY]: IMarketPlaceProps;
  isOfficialAPIShopify: boolean;
  isOfficialAPIEbay: boolean;
  //--
  [key: string]: boolean | IMyShopsTableRow | HTMLElement | null | IMarketPlaceProps;
}

const initialState = {
  selectedActionRow: {},
  isBrowserTableActionsMenuOpen: null,
  isAPITableActionsMenuOpen: null,
  isAllPlatformsLoading: false,
  isNotLoggedInDialogOpen: false,
  //--
  [IPlatforms.DEPOP]: defaultStateForMarketplaces,
  [IPlatforms.ETSY]: defaultStateForMarketplaces,
  [IPlatforms.FACEBOOK]: defaultStateForMarketplaces,
  [IPlatforms.GRAILED]: defaultStateForMarketplaces,
  [IPlatforms.MERCARI]: defaultStateForMarketplaces,
  [IPlatforms.EBAYUS]: defaultStateForMarketplaces,
  [IPlatforms.EBAYCA]: defaultStateForMarketplaces,
  [IPlatforms.EBAYAU]: defaultStateForMarketplaces,
  [IPlatforms.POSHMARKCA]: defaultStateForMarketplaces,
  [IPlatforms.POSHMARKUS]: defaultStateForMarketplaces,
  [IPlatforms.SHOPIFY]: defaultStateForMarketplaces,
  isOfficialAPIShopify: false,
  isOfficialAPIEbay: false,
};

const myShopsSlice = createSlice({
  name: 'myShops',
  initialState,
  reducers: {
    setSelectedActionRow: (state, action: PayloadAction<IMyShopsTableRow>) => {
      state.selectedActionRow = action.payload;
      if (action.payload.platformName) {
        state.isOfficialAPIShopify = isShopify(action.payload.platformName);
        state.isOfficialAPIEbay = isEbay(action.payload.platformName);
      }
    },
    setIsBrowserTableActionsMenuOpen: (state, action) => {
      state.isBrowserTableActionsMenuOpen = action.payload;
    },
    setIsAPITableActionsMenuOpen: (state, action) => {
      state.isAPITableActionsMenuOpen = action.payload;
    },
    setIsNotLoggedInDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isNotLoggedInDialogOpen = action.payload;
    },
    setIsPlatformLoading: (
      state,
      action: PayloadAction<{ isLoading: boolean; platform: IPlatformStateFinder }>
    ) => {
      const platformKey = action.payload.platform;
      state[platformKey].isLoading = action.payload.isLoading;
    },

    setIsPlatformLoggedIn: (
      state,
      action: PayloadAction<{ isLoggedIn: boolean; platform: IPlatformStateFinder }>
    ) => {
      const platformKey = action.payload.platform;
      state[platformKey].isLoggedIn = action.payload.isLoggedIn;
    },
    setPlatformData: (
      state,
      action: PayloadAction<{
        username: string;
        userId: string | number;
        platform: IPlatformStateFinder;
      }>
    ) => {
      const platformKey = action.payload.platform;
      state[platformKey].username = action.payload.username;
      state[platformKey].userId = action.payload.userId;
    },
    setAllPlatformsLoading: (state, action) => {
      state.isAllPlatformsLoading = action.payload;
    },
    setAllPlatformsInitialLoading: (state, action: PayloadAction<boolean>) => {
      state.isAllPlatformsLoading = action.payload;
      state[IPlatforms.DEPOP].isLoading = action.payload;
      state[IPlatforms.ETSY].isLoading = action.payload;
      state[IPlatforms.FACEBOOK].isLoading = action.payload;
      state[IPlatforms.GRAILED].isLoading = action.payload;
      state[IPlatforms.EBAYAU].isLoading = action.payload;
      state[IPlatforms.EBAYUS].isLoading = action.payload;
      state[IPlatforms.EBAYCA].isLoading = action.payload;
      state[IPlatforms.MERCARI].isLoading = action.payload;
      state[IPlatforms.POSHMARKCA].isLoading = action.payload;
      state[IPlatforms.POSHMARKUS].isLoading = action.payload;
      state[IPlatforms.SHOPIFY].isLoading = action.payload;
    },
    resetMyShopsState: (state) => initialState,
  },
});

export const {
  resetMyShopsState,
  setSelectedActionRow,
  setIsBrowserTableActionsMenuOpen,
  setIsAPITableActionsMenuOpen,
  setIsNotLoggedInDialogOpen,
  //--
  setAllPlatformsInitialLoading,
  setIsPlatformLoading,
  setIsPlatformLoggedIn,
  setPlatformData,
} = myShopsSlice.actions;

export default myShopsSlice.reducer;
