import { useCallback } from 'react';
// @mui
import { Card, Stack, Paper, Typography, IconButton, useMediaQuery, Skeleton } from '@mui/material';
// @types

// components
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
// section
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'src/store';
import Label from 'src/components/label';
import {
  ICardItem,
  setActionsSelectedCard,
  setIsCardActionsMenuOpen,
} from 'src/store/dashboard/slices/accountSlice';
import CardActionsPopoverMenu from './components/CardActionsPopoverMenu';
import {
  setStripeReferrerUrl,
  updateSubscriptionRequest,
} from 'src/store/dashboard/slices/pricingSlice';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export default function AccountBillingPaymentMethod() {
  const midMatched = useMediaQuery('(min-width:1200px)');
  const mobileMatched = useMediaQuery('(min-width:600px)');

  const dispatch = useDispatch();

  const { cardList, isCardActionsMenuOpen } = useSelector((state: IRootState) => state.account);
  const { loading, stripeReferrerUrl } = useSelector((state: IRootState) => state.pricing);
  const { initialLoading } = useSelector((state: IRootState) => state.account);

  const convertedCardNumber = (cardNumber: string) => '**** **** **** ' + cardNumber;

  const getCardText = (card: ICardItem) => {
    if (card.email) {
      return card.email;
    }
    return convertedCardNumber(card.last4 || '');
  };

  const cardIcon = (brand: string) => `/icons/cardIcons/${brand.toLowerCase()}.svg`;

  const handleCardActions = (event: React.MouseEvent<HTMLElement>, card: ICardItem) => {
    dispatch(setIsCardActionsMenuOpen(isCardActionsMenuOpen === null ? event.currentTarget : null));
    dispatch(setActionsSelectedCard(card));
  };

  const url = window.location.href;

  const handleAddNewCard = () => {
    dispatch(setStripeReferrerUrl(url));
    dispatch(
      updateSubscriptionRequest({
        flowType: 'payment_method_update',
        returnUrl: url,
      })
    );
  };

  const addSkeletonToComponents = (component: any) => {
    if (initialLoading) {
      const skeletonItems = Array.from({ length: 2 }).map((_, index) => (
        <Skeleton
          key={index}
          variant="rounded"
          sx={{ width: midMatched ? 0.47 : 1, height: '106px', my: 1 }}
        />
      ));
      return <>{skeletonItems}</>;
    } else {
      return component;
    }
  };

  const renderOrderedCardList = () =>
    cardList.map((card, index) => (
      <Paper
        key={card && card.id ? card.id : Math.random() * 10000}
        variant="outlined"
        sx={{
          p: 3,
          maxWidth: midMatched ? 0.47 : 1,
          maxHeight: '106px !important',
          width: 1,
          position: 'relative',
          m: 1,
        }}
      >
        <Image
          alt="icon"
          src={cardIcon(card && card.brand ? card.brand : '')}
          sx={{ mb: 1, maxWidth: 36, maxHeight: 30 }}
        />

        <Typography variant="subtitle2">{getCardText(card)}</Typography>

        {card && !card.isDefault && index !== 0 && (
          <IconButton
            sx={{
              top: 8,
              right: 8,
              position: 'absolute',
            }}
            onClick={(event) => handleCardActions(event, card)}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        )}

        {(card.isDefault || index === 0) && (
          <Label
            color="success"
            sx={{
              bottom: 8,
              right: 16,
              position: 'absolute',
            }}
          >
            Default
          </Label>
        )}
      </Paper>
    ));

  const isUserHasAnyCard = cardList.length > 0;

  const renderCardSection = () =>
    isUserHasAnyCard ? (
      renderOrderedCardList()
    ) : (
      <Typography variant="subtitle2" ml={0.5} mt={-1} fontWeight={400}>
        You don't have any credit cards yet
      </Typography>
    );

  return (
    <>
      <Card sx={{ p: 3, px: mobileMatched ? 3 : 1.5 }}>
        <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
          <Typography
            variant="overline"
            sx={{
              flexGrow: 1,
              color: 'text.secondary',
            }}
          >
            Payment Method
          </Typography>

          <LoadingButton
            loading={loading}
            size="small"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleAddNewCard}
          >
            New card
          </LoadingButton>
        </Stack>

        <Stack
          direction={{
            xs: 'column',
            lg: 'row',
          }}
          sx={{
            alignItems: 'center',
            justifyContent: midMatched ? 'space-between' : 'flex-start',
            flexWrap: midMatched ? 'wrap' : 'nowrap',
          }}
        >
          {addSkeletonToComponents(renderCardSection())}
        </Stack>
      </Card>
      <CardActionsPopoverMenu />
    </>
  );
}
