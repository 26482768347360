import { IRootState } from 'src/store';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import { Skeleton, Stack, Theme, Typography, useMediaQuery } from '@mui/material';

import Image from 'src/components/image';
import ClosetCard from './ClosetCard';

export default function ClosetCardContainer() {
  const matches = useMediaQuery('(min-width:700px)');
  const { closetList, initialLoading, hasConnection } = useSelector(
    (state: IRootState) => state.myCloset
  );

  const styles = {
    emptyContent: {
      root: {
        textAlign: 'center',
        p: '16px 14px',
        border: (theme: Theme) => `1px dashed ${theme.palette.divider}`,
        borderRadius: '8px',
      },
      image: { width: matches ? 0.313 : 0.626, mb: '16px' },
      text: {
        fontSize: '14px !important',
        color: '#212B36',
        textAlign: 'center',
      },
    },
    connectButton: {
      root: {
        display: 'flex',
        alignItems: 'center',
        p: '4px 16px',
        borderRadius: '8px',
      },
      icon: { fontSize: '1rem', maxWidth: '20px', maxHeight: '20px', mr: 1 },
      text: {
        fontSize: '14px !important',
        lineHeight: '24px',
        fontWeight: 700,
      },
    },
    pageContent: {
      card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        p: hasConnection ? '0px' : '16px',
        borderRadius: '8px',
      },
    },
    skeleton: { width: '100%', height: '8rem', my: '16px !important' },
  };

  const { emptyContent, skeleton } = styles;

  const renderEmptyContent = () => (
    <Stack alignItems="center" justifyContent="center" sx={emptyContent.root}>
      <Image
        disabledEffect
        alt="empty content"
        src={'/assets/illustrations/mainConnectImage.svg'}
        sx={emptyContent.image}
      />

      <Typography variant="body2" gutterBottom sx={emptyContent.text}>
        Ready to level up your Poshmark<sup>™</sup> game? Look no further! <br />
        <br />
        <b>Connect your closet now</b> and unlock a world of seamless automation.
        <br />
        <br />
        Our cutting-edge software prioritizes your security, utilizing advanced encryption to
        safeguard your closet information as it effortlessly handles your daily tasks in the cloud.
        <br />
        <br />
        Don't miss out on this opportunity and experience the power of automation firsthand!
      </Typography>
    </Stack>
  );

  const renderPageContent = () =>
    closetList && closetList.length > 0 ? (
      <Stack spacing={'16px'} justifyContent="center" alignItems="center" sx={{ width: 1 }}>
        {closetList.map((closet, index) => (
          <ClosetCard key={index} closet={closet} />
        ))}
      </Stack>
    ) : (
      renderEmptyContent()
    );

  if (!initialLoading) {
    return renderPageContent();
  } else {
    const skeletonItems = Array.from({ length: 1 }).map((_, index) => (
      <Skeleton key={index} variant="rounded" sx={skeleton} />
    ));
    return <>{skeletonItems}</>;
  }
}
