import { useDispatch, useSelector } from 'react-redux';
import { InputAdornment, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Iconify from 'src/components/iconify';
import SearchIcon from '@mui/icons-material/Search';

import { ChangeEvent, useState } from 'react';
import {
  importGetListingsRequest,
  setCurrentInventoryAction,
  setFetchedListings,
  setHasActiveSearch,
  setHasMoreImportableListings,
  setHideImportedItems,
  setImportClearButtonLoading,
  setImportNextPageID,
  setImportSearchButtonLoading,
  setImportSearchText,
  setImportedListingsList,
  setIsClearButtonVisible,
  setListingsForImport,
} from 'src/store/dashboard/slices/importTableSlice';
import { IRootState } from 'src/store';
import { CurrentInventoryActionEnum } from '../../../types/dataGridInterfaces';
import { getCurrentShopForMarketplace } from '../importDataGrid/helpers';
import { Marketplace } from '@beta.limited/primelister';

export default function ImportSearchBar() {
  const dispatch = useDispatch();
  const {
    importSearchText,
    importSearchButtonLoading,
    importClearButtonLoading,
    isClearButtonVisible,
    isLoadMoreLoading,
    importLoading,
    importPlatform,
  } = useSelector((state: IRootState) => state.importTable);
  const myShops = useSelector((state: IRootState) => state.myShops);
  const [searchText, setSearchText] = useState(importSearchText);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchText(e.target.value);
    dispatch(setImportSearchText(e.target.value));
  };

  const handleClear = async () => {
    dispatch(setHasActiveSearch(false));
    dispatch(setCurrentInventoryAction(CurrentInventoryActionEnum.CLEAR));
    dispatch(setHasMoreImportableListings(false));
    dispatch(setImportedListingsList([]));
    dispatch(setImportNextPageID(null));
    dispatch(setListingsForImport([]));
    dispatch(setFetchedListings([]));
    dispatch(setHideImportedItems(false));
    dispatch(setImportSearchText(''));
    setSearchText('');
    setButtonLoadingAndGetListings(setImportClearButtonLoading);
    dispatch(setIsClearButtonVisible(false));
  };

  const setButtonLoadingAndGetListings = (setLoadingFunction: Function) => {
    dispatch(setLoadingFunction(true));
    dispatch(
      importGetListingsRequest({
        importPlatform,
        importSearchText,
        myShopsState: myShops,
      })
    );
    dispatch(setLoadingFunction(false));
  };

  const handleSearch = async () => {
    dispatch(setHasActiveSearch(true));
    dispatch(setCurrentInventoryAction(CurrentInventoryActionEnum.SEARCH));
    dispatch(setListingsForImport([]));
    dispatch(setImportedListingsList([]));
    dispatch(setImportNextPageID(null));
    dispatch(setHasMoreImportableListings(false));
    dispatch(setHideImportedItems(false));
    setButtonLoadingAndGetListings(setImportSearchButtonLoading);
    dispatch(setImportSearchText(searchText));
    dispatch(setIsClearButtonVisible(true));
    dispatch(setHasMoreImportableListings(false));
    setSearchText(importSearchText);
  };

  const searchButtonText = importSearchButtonLoading ? 'Searching Listings' : 'Search Listings';
  const clearButtonText = importClearButtonLoading ? 'Resetting Listing' : 'Clear';
  const isSearchButtonVisible = searchText.length > 1;

  return (
    <Stack
      spacing={1}
      direction={{ xs: 'column', md: 'row' }}
      alignItems={{ md: 'center' }}
      sx={{ width: 1, px: 1.5, mt: 2 }}
    >
      <TextField
        size="small"
        value={searchText || importSearchText}
        onChange={handleSearchInputChange}
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />

      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
        {isSearchButtonVisible && (
          <LoadingButton
            loading={importSearchButtonLoading}
            variant="soft"
            disabled={isLoadMoreLoading || importLoading}
            color="primary"
            loadingPosition="start"
            onClick={handleSearch}
            startIcon={<SearchIcon />}
          >
            {searchButtonText}
          </LoadingButton>
        )}

        {isClearButtonVisible && (
          <LoadingButton
            loading={importClearButtonLoading}
            variant="soft"
            disabled={isLoadMoreLoading || importLoading}
            color="error"
            loadingPosition="start"
            onClick={handleClear}
            startIcon={<Iconify icon="eva:trash-2-outline" />}
          >
            {clearButtonText}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
}
