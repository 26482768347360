// routes
import { PATH_AUTOMATIONS } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';
import { memoize } from 'lodash';
import { IFeatureFlag } from '@openreplay/tracker/lib/modules/featureFlags';
import { checkIfOpenReplayFlagIsEnabled } from 'src/utils/checkIfFlagIsEnabled';
import { IS_SANDBOX } from 'src/config';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);
const customMenuIcon = (name: string, isIconify = false) =>
  isIconify ? (
    <Iconify icon={name} sx={{ width: 22, height: 22 }} />
  ) : (
    <SvgColor src={`/icons/${name}MenuIcon.svg`} sx={{ width: 1, height: 1 }} />
  );

const ICONS = {
  automations: customMenuIcon('automations'),
  activity: customMenuIcon('statistics'),
  account: icon('ic_user'),
  myCloset: customMenuIcon('myCloset'),
  myListings: customMenuIcon('solar:clipboard-list-bold-duotone', true),
};

const createNavConfig = memoize(
  (isWebMobile: boolean, isPrimeListerMobileApp: boolean, featureFlags: IFeatureFlag[]) => [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: 'Navigation',
      items: [
        {
          title: 'My Closet',
          path: PATH_AUTOMATIONS.myCloset,
          icon: ICONS.myCloset,
          tooltip: 'Connect your closet',
        },
        {
          title: 'Listings',
          path: PATH_AUTOMATIONS.listings,
          icon: ICONS.myListings,
          tooltip: 'Listings',
        },
        {
          title: 'Automations',
          path: PATH_AUTOMATIONS.automations,
          icon: ICONS.automations,
          tooltip: 'Automate Poshmark tasks',
        },
        {
          title: 'Activity',
          path: PATH_AUTOMATIONS.activity,
          icon: ICONS.activity,
          tooltip: 'Activity',
        },
        {
          title: 'Account',
          path: PATH_AUTOMATIONS.account,
          icon: ICONS.account,
          tooltip: 'Account & Subscription',
        },
      ],
    },
  ]
);

export default createNavConfig;
