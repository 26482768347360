// @mui
import { LoadingButton } from '@mui/lab';
import { Card, Button, Typography, Stack, CardProps } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { IRootState } from 'src/store';
import { SubscriptionStatus } from 'src/store/dashboard/slices/accountSlice';
import {
  checkCouponCodeValidationRequest,
  checkCouponCodeViaComparison,
  setSelectedSubscription,
  updateSubscriptionRequest,
} from 'src/store/dashboard/slices/pricingSlice';
import { isLoggedIn } from 'src/utils/isLoggedIn';

// components

// assets

// ----------------------------------------------------------------------

interface Props extends CardProps {
  card: {
    subscription: string;
    price: number;
    labelAction: string;
    caption: string;
    planId: string;
    stripePlanId: string;
    lists: {
      text: string;
      isAvailable: boolean;
    }[];
  };
  index: number;
}

export default function CrosslistPricingCard({ card, index, sx, ...other }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subscription, price, lists, labelAction, caption, planId, stripePlanId } = card;
  const { billingSubscriptionList, coupon } = useSelector((state: IRootState) => state.account);
  const { loading, selectedSubscription, stripeReferrerUrl } = useSelector(
    (state: IRootState) => state.pricing
  );
  const { referrerCode } = isLoggedIn();
  const isCurrent =
    billingSubscriptionList[0]?.status?.toLowerCase().includes(SubscriptionStatus.ACTIVE) &&
    planId === billingSubscriptionList[0]?.planId;

  const isYearly = planId.toLowerCase().includes('annual');

  const handleUpdateSubscription = () => {
    const isSubscribeButton = labelAction.toLowerCase().includes('subscribe');

    if (isSubscribeButton) {
      dispatch(setSelectedSubscription(card));

      const navigateToPaymentSummary = () => {
        navigate(PATH_DASHBOARD.subscription.paymentSummary + `?planId=${planId}`);
      };

      if (referrerCode && coupon && !isYearly) {
        dispatch(
          checkCouponCodeViaComparison({
            plan: planId,
            coupon: coupon,
            referrerCode: referrerCode,
            typeReferrer: 'referrerCode',
            typeCoupon: 'coupon',
            navigate,
          })
        );
      } else if (referrerCode && !coupon && !isYearly) {
        const code = referrerCode;
        const type = 'referrerCode';

        dispatch(
          checkCouponCodeValidationRequest({
            plan: planId,
            code,
            type,
            navigate,
          })
        );
      } else if (coupon && (!referrerCode || isYearly)) {
        const code = coupon;
        const type = 'coupon';

        dispatch(
          checkCouponCodeValidationRequest({
            plan: planId,
            code,
            type,
            navigate,
          })
        );
      } else {
        navigateToPaymentSummary();
      }
    } else {
      const isThereAnyActivePlan = labelAction.toLowerCase().includes('update');
      if (isThereAnyActivePlan) {
        dispatch(
          updateSubscriptionRequest({
            returnUrl: stripeReferrerUrl,
            flowType: 'subscription_update_confirm',
            subscriptionId: billingSubscriptionList[0].subscriptionId,
            subscriptionItemId: billingSubscriptionList[0].subscriptionItemId,
            planId: stripePlanId,
          })
        );
      }
    }
  };

  const renderButton = () => (
    <>
      {isCurrent && (
        <Button
          fullWidth
          size="large"
          variant="contained"
          disabled
          sx={{
            bgcolor: '#108674 !important',
            color: 'white !important',
            cursor: 'default !important',
          }}
        >
          Current Plan
        </Button>
      )}
      {!isCurrent && (
        <LoadingButton
          loading={loading && selectedSubscription?.planId === planId}
          fullWidth
          size="large"
          variant="contained"
          onClick={handleUpdateSubscription}
        >
          {labelAction}
        </LoadingButton>
      )}
    </>
  );

  return (
    <Card
      sx={{
        p: 5,
        maxWidth: { xxs: '100% ', xs: '350px' },
        width: '100%',
        '&:hover': {
          boxShadow: (theme) => theme.customShadows.z24,
          bgcolor: 'background.default',
        },
        ...sx,
      }}
      {...other}
    >
      {index === 2 && (
        <Label color="info" sx={{ top: 16, right: 16, position: 'absolute' }}>
          POPULAR - SAVE 30%
        </Label>
      )}

      <Typography variant="overline" sx={{ color: 'text.secondary' }}>
        {subscription}
      </Typography>

      <Stack spacing={1} direction="row" sx={{ my: 2 }}>
        {(index === 1 || index === 2) && <Typography variant="h5">$</Typography>}

        <Typography variant="h2">{price.toFixed(2)}</Typography>

        {(index === 1 || index === 2) && (
          <Typography component="span" sx={{ alignSelf: 'center', color: 'text.secondary' }}>
            /month
          </Typography>
        )}
      </Stack>
      <Typography
        variant="caption"
        sx={{
          color: 'primary.main',
          textTransform: 'capitalize',
        }}
      >
        {caption}
      </Typography>
      <Stack component="ul" spacing={2} sx={{ p: 0, my: 4 }}>
        {lists.map((item) => (
          <Stack
            key={item.text}
            component="li"
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              typography: 'body2',
              color: item.isAvailable ? 'text.primary' : 'text.disabled',
            }}
          >
            <Iconify
              icon={item.isAvailable ? 'eva:checkmark-fill' : 'eva:close-fill'}
              width={16}
              sx={{
                color: item.isAvailable ? 'primary.main' : 'inherit',
              }}
            />
            <Typography variant="body2">{item.text}</Typography>
          </Stack>
        ))}
      </Stack>
      {renderButton()}
    </Card>
  );
}
