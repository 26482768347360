import { useSelector, useDispatch } from 'react-redux';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';

import {
  deleteGroupRequest,
  setGroupsDialogData,
  setGroupsOpenDeleteDialog,
} from 'src/store/dashboard/slices/groupsAndTagsSlice';
import { IRootState } from 'src/store';
import { LoadingButton } from '@mui/lab';
import { getFilterModelShaped } from 'src/pages/dashboard/Inventory/helpers/getFilterModelShaped';

interface GroupsDeleteDialogProps {
  inputValue: string;
  setInputValue: (value: string) => void;
}
function GroupsDeleteDialog({ inputValue, setInputValue }: GroupsDeleteDialogProps) {
  const dispatch = useDispatch();

  const { groupsDialogData, groupsOpenDeleteDialog, groupsOpenDeleteDialogError, loading } =
    useSelector((state: IRootState) => state.groupsAndTags);
  const { inventoryFilterModel, page, rowsPerPage, sortModel, searchValue } = useSelector(
    (state: IRootState) => state.inventory
  );

  const handleClose = () => {
    dispatch(setGroupsOpenDeleteDialog(false));
  };

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(
      deleteGroupRequest({
        groupData: {
          ...groupsDialogData,
        },
        getData: {
          page,
          rowsPerPage,
          orderBy: sortModel[0]?.field,
          order: sortModel[0]?.sort,
          filter: getFilterModelShaped(inventoryFilterModel),
          searchValue,
        },
      })
    );
    setInputValue('');
  };

  const handleCancel = () => {
    dispatch(setGroupsOpenDeleteDialog(false));
    dispatch(setGroupsDialogData({}));
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={groupsOpenDeleteDialog} onClose={handleClose}>
      <DialogTitle sx={{ pb: 2 }}>Delete Group</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>
        {groupsDialogData.name} will be deleted from your groups. Are you sure you want to delete?
        <DialogContentText sx={{ color: 'error.main', mt: 1 }}>
          {groupsOpenDeleteDialogError ? groupsOpenDeleteDialogError : ''}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} variant="contained" color="error" onClick={handleDelete}>
          Delete
        </LoadingButton>

        <Button variant="outlined" color="inherit" onClick={handleCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default GroupsDeleteDialog;
