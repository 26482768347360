export const getLabelProps = (isEnabled: boolean) => {
  const topLabel = {
    color: isEnabled ? 'success' : 'default',
    text: isEnabled ? 'Enabled' : 'Disabled',
    icon: isEnabled
      ? '/icons/automationIcons/enableIcon.svg'
      : '/icons/automationIcons/disableIcon.svg',
  };
  const bottomLabel = {
    color: !isEnabled ? 'success' : 'error',
    text: !isEnabled ? 'Enable' : 'Disable',
    icon: !isEnabled
      ? '/icons/automationIcons/enableEmptyIcon.svg'
      : '/icons/automationIcons/disabledRedIcon.svg',
  };
  return { topLabel, bottomLabel };
};
