import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import cookie from 'react-cookies';

import {
  accountChangePasswordFail,
  accountChangePasswordRequest,
  accountAffiliateRequest,
  accountAffiliateSuccess,
  accountDeactivateFail,
  accountDeactivateRequest,
  accountDetailFail,
  accountDetailRequest,
  accountDetailSuccess,
  accountGetSubscriptionsFail,
  accountGetSubscriptionsRequest,
  accountGetSubscriptionsSuccess,
  accountSubscriptionChangeFail,
  accountSubscriptionChangeRequest,
  accountSubscriptionChangeSuccess,
  accountUpdateFail,
  accountUpdateRequest,
  setIsChangePasswordDialogOpen,
  accountUpdateSuccess,
  getBillingSubscriptionsRequest,
  getBillingSubscriptionsSuccess,
  getBillingSubscriptionsFail,
  setBillingSubscriptionList,
  cancelBillingSubscriptionFail,
  cancelBillingSubscriptionRequest,
  cancelBillingSubscriptionSuccess,
  setIsCancelBillingSubscriptionDialogOpen,
  setBillingInvoiceList,
  getBillingInvoiceListSuccess,
  getBillingInvoiceListFail,
  getBillingInvoiceListRequest,
  getCardsInfoSuccess,
  getCardsInfoFail,
  getCardsInfoRequest,
  deleteCardRequest,
  deleteCardSuccess,
  deleteCardFail,
  updateCardSuccess,
  updateCardFail,
  updateCardRequest,
  setIsDeleteCardDialogOpen,
  setIsSetDefaultCardDialogOpen,
  setInitialLoading,
  setInvoicesHasMore,
  setInvoicesRequestLimit,
  accountChangePasswordSuccess,
  SubscriptionStatus,
  affiliateSummarySuccess,
  affiliateSummaryFail,
  affiliateBalanceHistorySuccess,
  affiliateBalanceHistoryFail,
  affiliateSummaryRequest,
  affiliateBalanceHistoryRequest,
  updatePayoutRequestDateFail,
  updatePayoutRequestDateSuccess,
  updatePayoutRequestDateRequest,
} from '../slices/accountSlice';

import axiosInstance from 'src/utils/axios';
import {
  IAccountUpdateData,
  IChangePassword,
  INotificationRequest,
} from 'src/pages/dashboard/Account/types/accountTypes';

import {
  IAuthState,
  authInitialize,
  authLoginRequest,
  authLogout,
  setIsVerifyDialogOpen,
  setLoginData,
  setSignUpResponse,
  setUserBlockState,
} from '../slices/authSlice';
import {
  setHasPoshmarkSubscription,
  setHasSubscriptionChecked,
} from 'src/store/automations/slices/myClosetSlice';
import { getExpiresDateForCookies } from 'src/auth/utils';
import { NavigateFunction } from 'react-router';
import { crosslistEndpoints } from 'src/endpoints';
import { setDateOptionsForStatistics } from 'src/store/automations/slices/statisticsSlice';
import moment from 'moment';
import { AccountRequestErrorCodeEnum } from 'src/pages/dashboard/Auth/types/types';
import { format } from 'date-fns';

function* accountDetailSaga(
  action: PayloadAction<{
    navigate: NavigateFunction;
    isFromVerifyDialog?: boolean;
    loginData?: IAuthState['loginData'];
  }>
) {
  try {
    cookie.remove('referrerCode');
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(crosslistEndpoints.account.GET_ACCOUNT())
    );
    yield put(accountDetailSuccess(response?.data));
    let userData = cookie.load('userData') || {};
    userData.referrerCode = response.data.referrerCode || '';
    userData.blockState = response.data.blockState;
    yield put(
      setDateOptionsForStatistics({
        buttonText: 'Today',
        dateOptions: {
          startDate: moment().tz(response.data.timeZone.name).format('YYYY-MM-DD'),
          endDate: moment().tz(response.data.timeZone.name).format('YYYY-MM-DD'),
        },
        urlParam: 'today',
      })
    );
    cookie.save('userData', userData, { path: '/', expires: getExpiresDateForCookies() });
    yield put(setUserBlockState(response.data.blockState));
    yield put(authInitialize());

    if (action.payload.isFromVerifyDialog && action.payload.loginData) {
      if (response.data.emailVerified) {
        yield put(
          authLoginRequest({
            loginData: action.payload.loginData,
          })
        );
        yield put(setIsVerifyDialogOpen(false));
        yield put(setSignUpResponse(null));
        yield put(setLoginData(null));
      }
    }
  } catch (error) {
    if (error.code === AccountRequestErrorCodeEnum.UNAUTHORIZED) {
      yield put(authLogout({ navigate: action.payload.navigate }));
    }
    yield put(accountDetailFail(error.message));
  }
}

function* accountUpdateSaga(action: PayloadAction<IAccountUpdateData>) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.patch(crosslistEndpoints.account.GET_ACCOUNT(), action.payload)
    );
    if (action.payload.timeZone) {
      yield put(
        setDateOptionsForStatistics({
          buttonText: 'Today',
          dateOptions: {
            startDate: moment().tz(action.payload.timeZone.name).format('YYYY-MM-DD'),
            endDate: moment().tz(action.payload.timeZone.name).format('YYYY-MM-DD'),
          },
          urlParam: 'today',
        })
      );
    }

    yield put(accountUpdateSuccess(response.data));
    toast.success('Update success!', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  } catch (error) {
    yield put(accountUpdateFail(error.message));
    toast.error(error.message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
}

function* accountDeactivateSaga(action: PayloadAction<{ navigate: NavigateFunction }>) {
  try {
    yield call(() =>
      axiosInstance.patch(crosslistEndpoints.account.GET_ACCOUNT(), {
        blockState: 3,
      })
    );
    yield put(authLogout({ navigate: action.payload.navigate }));
  } catch (error) {
    yield put(accountDeactivateFail(error.message));
  }
}

function* accountAffiliateSaga() {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(crosslistEndpoints.account.GET_AFFILIATE())
    );
    yield put(accountAffiliateSuccess());
    window.open(response.data.link);
  } catch (error) {
    toast.error(`Something went wrong, please try again`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
}

function* accountChangePasswordSaga(action: PayloadAction<IChangePassword>) {
  try {
    yield put(setIsChangePasswordDialogOpen(false));
    yield call(() =>
      axiosInstance.post(crosslistEndpoints.auth.GET_PASSWORD(), {
        oldPassword: action.payload.oldPassword,
        newPassword: action.payload.newPassword,
      })
    );
    toast.success(`Your password successfully changed`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    yield put(accountChangePasswordSuccess());
  } catch (error) {
    yield put(accountChangePasswordFail(error.message));
  }
}

function* accountGetSubscriptionsSaga() {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(crosslistEndpoints.account.GET_NOTIFICATIONS())
    );
    yield put(accountGetSubscriptionsSuccess(response?.data.notifications));
  } catch (error) {
    yield put(accountGetSubscriptionsFail(error.message));
  }
}

function* accountSubscriptionChangeSaga(action: PayloadAction<INotificationRequest>) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.post(crosslistEndpoints.account.GET_NOTIFICATIONS(), action.payload)
    );
    yield put(accountSubscriptionChangeSuccess(response?.data.notifications));
    toast.success(`Notification preference successfully updated`, {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  } catch (error) {
    yield put(accountSubscriptionChangeFail(error.message));
  }
}

//--Billing
function* getBillingSubscriptionsSaga() {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(crosslistEndpoints.billing.GET_BILLING_SUBSCRIPTIONS())
    );
    yield put(setBillingSubscriptionList(response.data));
    yield put(getBillingSubscriptionsSuccess());
    yield put(
      setHasPoshmarkSubscription(
        !(
          response.data[1].status.toLowerCase() === SubscriptionStatus.EXPIRED ||
          response.data[1].status.toLowerCase() === SubscriptionStatus.PAST_DUE
        )
      )
    );
    yield put(setInitialLoading(false));
    yield put(setHasSubscriptionChecked(true));
  } catch (error) {
    yield put(getBillingSubscriptionsFail(error.message));
  }
}

function* cancelBillingSubscriptionSaga(action: PayloadAction<string>) {
  try {
    yield toast.promise(
      axiosInstance.delete(
        crosslistEndpoints.billing.GET_BILLING_SUBSCRIPTIONS() + '/' + action.payload
      ),
      {
        pending: 'Processing your request, please wait...',
        success: 'Your subscription successfully canceled',
        error: 'Something went wrong',
      }
    );
    yield put(getBillingSubscriptionsRequest());
    yield put(cancelBillingSubscriptionSuccess());
    yield put(setIsCancelBillingSubscriptionDialogOpen(false));
  } catch (error) {
    yield put(cancelBillingSubscriptionFail(error.message));
  }
}

function* getBillingInvoiceListSaga(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(crosslistEndpoints.billing.GET_BILLING_INVOICES(), {
        params: { limit: action.payload },
      })
    );
    yield put(setBillingInvoiceList(response.data.data));
    yield put(setInvoicesHasMore(response.data.has_more));
    yield put(setInvoicesRequestLimit(action.payload));
    yield put(getBillingInvoiceListSuccess());
    yield put(setInitialLoading(false));
  } catch (error) {
    yield put(getBillingInvoiceListFail(error.message));
  }
}

function* getCardsInfoSaga() {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(crosslistEndpoints.billing.GET_CARDS_INFO())
    );
    yield put(getCardsInfoSuccess(response.data));
    yield put(setInitialLoading(false));
  } catch (error) {
    yield put(getCardsInfoFail(error.message));
  }
}

function* deleteCardSaga(action: PayloadAction<string>) {
  try {
    yield call(() =>
      axiosInstance.delete(crosslistEndpoints.billing.GET_CARDS_INFO() + `/${action.payload}`)
    );
    yield put(getCardsInfoRequest());
    yield put(deleteCardSuccess());
    yield put(setIsDeleteCardDialogOpen(false));
    toast.success(`Your card succesfully deleted`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  } catch (error) {
    yield put(deleteCardFail(error.message));
  }
}

function* updateCardSaga(action: PayloadAction<string>) {
  try {
    yield call(() =>
      axiosInstance.put(crosslistEndpoints.billing.GET_CARDS_INFO(), { cardId: action.payload })
    );
    yield put(getCardsInfoRequest());
    yield put(updateCardSuccess());
    yield put(setIsSetDefaultCardDialogOpen(false));
    toast.success(`Your card succesfully set default`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  } catch (error) {
    yield put(updateCardFail(error.message));
  }
}

function* fetchAffiliateSummaryAndBalanceHistory(
  action: PayloadAction<{ page: number; size: number }>
) {
  try {
    const summaryResponse: AxiosResponse = yield call(() =>
      axiosInstance.get(crosslistEndpoints.affiliate.GET_AFFILIATE_SUMMARY())
    );
    yield put(affiliateSummarySuccess(summaryResponse.data));

    const balanceHistoryResponse: AxiosResponse = yield call(() =>
      axiosInstance.get(
        crosslistEndpoints.affiliate.GET_AFFILIATE_BALANCE_HISTORY({
          page: action.payload.page,
          size: action.payload.size,
          order: 'desc',
          orderBy: 'createdAt',
        })
      )
    );
    const mappedData = balanceHistoryResponse.data.data.map((item: any) => ({
      id: item._id,
      customer: item.buyerId,
      date: format(new Date(item.createdAt), 'dd MMM yyyy'),
      event: item.type,
      reward: item.amount,
    }));
    yield put(
      affiliateBalanceHistorySuccess({
        pageCount: balanceHistoryResponse.data.pageCount,
        count: balanceHistoryResponse.data.count,
        data: mappedData,
      })
    );
  } catch (error) {
    yield put(affiliateSummaryFail(error.message));
    yield put(affiliateBalanceHistoryFail(error.message));
  }
}

function* updatePayoutRequestDateSaga(
  action: PayloadAction<{
    affiliateBalanceHistoryPage: number;
    affiliateBalanceHistoryPageSize: number;
  }>
) {
  try {
    yield call(() => axiosInstance.get(crosslistEndpoints.affiliate.UPDATE_PAYOUT_REQUEST_DATE()));
    yield put(updatePayoutRequestDateSuccess());
    yield put(
      affiliateSummaryRequest({
        page: action.payload.affiliateBalanceHistoryPage,
        size: action.payload.affiliateBalanceHistoryPageSize,
      })
    );
    toast.success('Payout request date updated successfully', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  } catch (error) {
    yield put(updatePayoutRequestDateFail(error.message));
    toast.error('Failed to update payout request date', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
}

export function* accountModuleSaga() {
  yield takeLatest(accountDetailRequest.type, accountDetailSaga);
  yield takeLatest(accountUpdateRequest.type, accountUpdateSaga);
  yield takeLatest(accountDeactivateRequest.type, accountDeactivateSaga);
  yield takeLatest(accountAffiliateRequest.type, accountAffiliateSaga);
  yield takeLatest(accountSubscriptionChangeRequest.type, accountSubscriptionChangeSaga);
  yield takeLatest(accountGetSubscriptionsRequest.type, accountGetSubscriptionsSaga);
  yield takeLatest(accountChangePasswordRequest.type, accountChangePasswordSaga);
  yield takeLatest(getBillingSubscriptionsRequest.type, getBillingSubscriptionsSaga);
  yield takeLatest(cancelBillingSubscriptionRequest.type, cancelBillingSubscriptionSaga);
  yield takeLatest(getBillingInvoiceListRequest.type, getBillingInvoiceListSaga);
  yield takeLatest(getCardsInfoRequest.type, getCardsInfoSaga);
  yield takeLatest(deleteCardRequest.type, deleteCardSaga);
  yield takeLatest(updateCardRequest.type, updateCardSaga);
  yield takeLatest(affiliateSummaryRequest.type, fetchAffiliateSummaryAndBalanceHistory);
  yield takeLatest(updatePayoutRequestDateRequest.type, updatePayoutRequestDateSaga);
}
