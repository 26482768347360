// @mui
import {
  Stack,
  Typography,
  Avatar,
  useMediaQuery,
  Theme,
  IconButton,
  MenuItem,
  Tooltip,
} from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label/Label';
import { IClosetDetails } from '../utils/types';
import MenuPopover from 'src/components/menu-popover/MenuPopover';

import { useDispatch } from 'react-redux';
import {
  ConnectionStatus,
  setIsRemoveClosetDialogOpen,
  countryDisplayNames,
  setIsConnectDialogOpen,
  setSelectedCloset,
  setShouldOpenExpiredSubscriptionDialog,
} from 'src/store/automations/slices/myClosetSlice';
import { useState } from 'react';
import { useCheckSubscriptionStatus } from 'src/hooks/useCheckSubscriptionStatus';

export default function ClosetCardDetail({ closet }: { closet: IClosetDetails }) {
  const dispatch = useDispatch();
  const [openDropdown, setOpenDropdown] = useState<HTMLElement | null>(null);
  const isDesktop = useResponsive('up', 'md');
  const matches = useMediaQuery('(min-width:835px)');

  const hasConnection = closet?.status === ConnectionStatus.Connected;
  const hasDisconnected = closet?.status === ConnectionStatus.Disconnected;
  const hasRemoved = closet?.status === ConnectionStatus.Removed;

  const { shouldUpgradeSubscriptionDialogOpen } = useCheckSubscriptionStatus();

  const cardDummyImage = 'https://primelister-files.s3.us-west-1.amazonaws.com/image_thumbnail.svg';
  const styles = {
    root: {
      borderRadius: '8px',
      border: (theme: Theme) => `solid 1px ${theme.palette.divider}`,
      '&:hover': {
        bgcolor: 'background.paper',
        boxShadow: (theme: Theme) => theme.customShadows.z20,
      },
      cursor: 'default',
      minHeight: '110px',
      width: 1,
      minWidth: '260px',
      mb: !hasDisconnected ? '0px' : '16px',
    },
    title: {
      root: {
        width: 1,
        bgcolor: '#F4F6F8',
        alignItems: 'center',
        minHeight: '54px',
        pt: '24px',
        pb: '8px',
        position: 'relative',
        paddingLeft: '112px',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        paddingRight: '15px',
        display: 'flex',
        justifyContent: 'space-between',
      },
      image: {
        position: 'absolute',
        top: '16px',
        left: '16px',
        minWidth: '80px',
        maxWidth: '80px',
        minHeight: '80px',
        maxHeight: '80px',
        zIndex: 1,
      },
      iconButton: {
        width: '36px',
        height: '36px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '7px',
        right: '7px',
      },
    },
    details: {
      root: {
        width: 1,
        alignItems: matches ? 'center' : 'flex-start',
        justifyContent: matches ? 'flex-start' : 'space-evenly',
        minHeight: matches ? '56px' : '136px',
        position: 'relative',
        paddingLeft: '112px',
      },
      emailRoot: {
        minWidth: matches ? '320px' : 1,
        maxWidth: matches ? '320px' : 1,
        alignItems: 'center',
        color: 'rgba(99, 115, 129, 1)',
        overflow: 'hidden',
        pr: '5px',
      },
      countryRoot: {
        minWidth: '190px',
        alignItems: 'center',
        color: 'rgba(99, 115, 129, 1)',
      },
      countryIcon: { maxHeight: '20px', maxWidth: '20px', ...(!matches && { ml: '2px' }) },
    },
  };

  const { root, title, details } = styles;

  let countryName = countryDisplayNames[closet?.country] || 'Poshmark (AU)';

  const connectionStatus = hasConnection
    ? ConnectionStatus.Connected
    : hasDisconnected
    ? ConnectionStatus.Disconnected
    : ConnectionStatus.Removed;

  const handlePopoverMenuClose = () => setOpenDropdown(null);

  const handlePopoverMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setOpenDropdown(event.currentTarget);

  const handleRemoveCloset = () => {
    dispatch(
      setIsRemoveClosetDialogOpen({
        openDialog: true,
        removeClosetData: { id: closet?._id, name: closet?.poshmarkUserName },
      })
    );
    handlePopoverMenuClose();
  };

  const handleReconnectCloset = () => {
    if (shouldUpgradeSubscriptionDialogOpen) {
      dispatch(setShouldOpenExpiredSubscriptionDialog(true));
    } else {
      dispatch(
        setSelectedCloset({
          ...closet,
        })
      );
      dispatch(setIsConnectDialogOpen(true));
    }
    handlePopoverMenuClose();
  };

  const statusText = connectionStatus.charAt(0).toUpperCase() + connectionStatus.slice(1);

  return (
    <Stack alignItems={isDesktop ? 'center' : 'flex-start'} sx={root}>
      <Stack direction={'row'} sx={title.root}>
        <Avatar sx={title.image} src={closet?.poshmarkAvatar || cardDummyImage} />
        <Typography variant="subtitle2">{closet?.poshmarkUserName}</Typography>
        <IconButton color="default" sx={title.iconButton} onClick={handlePopoverMenuOpen}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
        <MenuPopover open={openDropdown} onClose={handlePopoverMenuClose} arrow="right-top">
          {!hasRemoved ? (
            <MenuItem onClick={handleRemoveCloset} sx={{ color: 'error.main' }}>
              <Iconify icon="mdi:remove-box" />
              Remove Closet
            </MenuItem>
          ) : (
            <MenuItem onClick={handleReconnectCloset}>
              <Avatar
                src={`/icons/myClosetIcons/reconnectIcon.svg`}
                sx={{ fontSize: '1rem', maxWidth: '20px', maxHeight: '20px', mr: 1 }}
              />
              Connect Closet
            </MenuItem>
          )}
        </MenuPopover>
      </Stack>
      <Stack direction={matches ? 'row' : 'column'} sx={details.root}>
        <Stack direction={'row'} spacing={'12px'} sx={details.emailRoot}>
          <Iconify
            icon="eva:email-fill"
            width={'24px'}
            height={'24px'}
            sx={{ minWidth: '24px', maxWidht: '24px' }}
          />
          <Typography
            variant="body2"
            sx={{
              maxWidth: '90%',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {closet?.poshmarkEmail}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={'12px'} sx={details.countryRoot}>
          <Avatar src="/icons/myClosetIcons/closetCountryIcon.svg" sx={details.countryIcon} />
          <Typography variant="body2">{countryName}</Typography>
        </Stack>
        <Stack direction="row" sx={{ alignItems: 'center', color: 'rgba(99, 115, 129, 1)' }}>
          <Tooltip
            arrow
            disableHoverListener={!hasRemoved}
            disableInteractive={!hasRemoved}
            disableTouchListener={!hasRemoved}
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            title={
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '18px',
                }}
              >
                This closet has been removed successfully and will no longer be displayed here
                starting from your next billing cycle.
              </Typography>
            }
            placement={'top'}
          >
            <Label
              variant="soft"
              color={hasConnection ? 'success' : hasDisconnected ? 'warning' : 'error'}
              sx={{ textTransform: 'none', fontSize: '12px' }}
            >
              {statusText}
            </Label>
          </Tooltip>
        </Stack>
      </Stack>
    </Stack>
  );
}
