import { SxProps, Tooltip, Typography, TypographyProps } from '@mui/material';
import { debounce } from 'lodash';
import React, { useLayoutEffect, useRef, useState, useCallback } from 'react';
import './OverflowTypograpghy.css';

export interface OverflowTypograpyProps extends TypographyProps {
  children: JSX.Element | string | string[] | JSX.Element[] | React.ReactNode;
  sxProps?: SxProps;
}

const OverflowTypography: React.FC<OverflowTypograpyProps> = React.memo(
  ({ children, sxProps, ...props }) => {
    const ref = useRef<HTMLSpanElement>(null);
    const [tooltipEnabled, setTooltipEnabled] = useState(false);

    const compareSize = useCallback(() => {
      if (ref.current) {
        const isOverflowing =
          ref.current.scrollHeight > ref.current.offsetHeight ||
          ref.current.scrollWidth > ref.current.offsetWidth;
        setTooltipEnabled((prevState) => prevState !== isOverflowing && isOverflowing);
      }
    }, []);

    const debouncedCompareSize = useCallback(debounce(compareSize, 100), [compareSize]);

    useLayoutEffect(() => {
      debouncedCompareSize();
      window.addEventListener('resize', debouncedCompareSize);
      return () => window.removeEventListener('resize', debouncedCompareSize);
    }, [debouncedCompareSize]);

    return (
      <Tooltip title={children} placement="top" disableHoverListener={!tooltipEnabled}>
        <Typography
          ref={ref}
          noWrap
          overflow="hidden"
          textOverflow="ellipsis"
          sx={{
            ...sxProps,
          }}
          {...props}
        >
          {children}
        </Typography>
      </Tooltip>
    );
  }
);

export default OverflowTypography;
