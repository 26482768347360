import { Box, MenuItem } from '@mui/material';

import InventoryDataGrid from './dataGrid/InventoryDataGrid';

export default function InventoryTable() {
  return (
    <Box sx={{ width: '100%' }}>
      <InventoryDataGrid />
    </Box>
  );
}
