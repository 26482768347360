// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography, BoxProps, alpha } from '@mui/material';
// components

import Image from 'src/components/image/';

// utils

import { accountAffiliateRequest } from 'src/store/dashboard/slices/accountSlice';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { IRootState } from 'src/store';
import { bgGradient } from 'src/utils/cssStyles';

// ----------------------------------------------------------------------

export default function AffiliateBanner() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { loading } = useSelector((state: IRootState) => state.account);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...bgGradient({
          direction: '135deg',
          startColor: alpha('#5BE49B', 0.2),
          endColor: alpha('#00A76F', 0.2),
        }),
        [theme.breakpoints.down(768)]: {
          flexDirection: 'column',
          padding: '40px 24px',
          textAlign: 'center',
        },
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          color: 'common.white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          [theme.breakpoints.down(768)]: {
            padding: '0',
          },
          padding: '40px',
        }}
      >
        <Stack
          sx={{
            maxWidth: '758px',
          }}
          direction="column"
        >
          <Typography
            variant="h4"
            sx={{
              whiteSpace: 'pre-line',
              color: '#004B50',
              fontSize: '24px !important',
              lineHeight: '30px !important',
              fontWeight: 700,
              [theme.breakpoints.down(768)]: {
                fontSize: '20px !important',
                lineHeight: '26px !important',
                width: '100%',
              },
            }}
          >
            Primelister Affiliate Program
          </Typography>

          <Box>
            <Typography
              variant="body2"
              sx={{
                mt: 3,
                fontSize: '18px !important',
                color: '#004B50',
                lineHeight: '23.4px !important',
                fontWeight: 600,
                opacity: 0.8,
                [theme.breakpoints.down(768)]: {
                  mb: 1,
                  mt: '12px',
                  fontSize: '14px !important',
                  fontWeight: 600,
                  lineHeight: '18.2px !important',
                },
              }}
            >
              You can start earning money by inviting other users using your affiliate link.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: '18px !important',
                color: '#004B50',
                lineHeight: '23.4px !important',
                fontWeight: 400,
                mt: 1,
                opacity: 0.8,
                [theme.breakpoints.down(768)]: {
                  mb: '40px',
                  fontSize: '13px !important',
                  lineHeight: '16.9px !important',
                },
              }}
            >
              Affiliates earn{' '}
              <span
                style={{
                  fontWeight: 700,
                }}
              >
                {' '}
                30%
              </span>{' '}
              of all referred recurring revenue. Referred customers save{' '}
              <span
                style={{
                  fontWeight: 700,
                }}
              >
                30%
              </span>{' '}
              on their first payment.
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Image
        disabledEffect
        alt="Affiliate illustration"
        src="/assets/illustrations/characters/character_11.png"
        sx={{
          zIndex: 9,
          width: '148.11px',
          marginRight: '60px',
          height: '100%',
          transform: 'scale(0.9)',
          filter: 'drop-shadow(0 12px 24px rgba(0,0,0,0.24))',
          [theme.breakpoints.down(768)]: {
            marginRight: '0',
          },
        }}
      />
    </Box>
  );
}
