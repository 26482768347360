import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//redux
import { setIsInitialDeactivateOpen } from 'src/store/dashboard/slices/accountSlice';

// components
import DeactivateDialog from './components/DeactivateDialog';
import AccountDetailForm from './components/AccountDetailForm';

export default function AccountGeneral() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (typeof event.data.includes === 'function' && event.data.includes('Tally.FormSubmitted')) {
        dispatch(setIsInitialDeactivateOpen(true));
      } else {
        return;
      }
    });

    return () => {
      window.removeEventListener('message', () => {});
    };
  }, []);

  return (
    <>
      <AccountDetailForm />
      <DeactivateDialog />
    </>
  );
}
