import { Avatar, Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';

import {
  DataGridTagsColumnInterface,
  IInventoryTag,
} from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import Label, { LabelColor } from 'src/components/label';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { useMemo, useState } from 'react';
import {
  setCurrentSelectedTags,
  setIsInventoryQuickEditPopoverOpen,
  setQuickEditAnchorPosition,
  setQuickEditSelectedListing,
  setSelectedQuickEditItemType,
} from 'src/store/dashboard/slices/inventorySlice';
import { InventoryQuickEditPopoverEnum } from '../../others/InventoryQuickEditPopover';
import OverflowTypography from 'src/components/product-card/OverflowTypography';

export default function DataGridTagsColumn({ tags, rowData }: DataGridTagsColumnInterface) {
  // Define color rules as constants outside the component
  const { dense } = useSelector((state: IRootState) => state.inventory);
  const [showEditIcon, setShowEditIcon] = useState(false);

  const { added, id, refs, title, image } = rowData;

  const listingData = useMemo(() => ({ id, refs, title, image }), [id, refs, title, image]);

  const colorRules = [
    { colorName: 'Green', colorCode: 'success' },
    { colorName: 'Gray', colorCode: 'default' },
    { colorName: 'Blue', colorCode: 'info' },
    { colorName: 'Purple', colorCode: 'purple' },
    { colorName: 'Orange', colorCode: 'warning' },
    { colorName: 'Red', colorCode: 'error' },
  ];

  const dispatch = useDispatch();

  const handleEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setQuickEditAnchorPosition({ top: event.clientY, left: event.clientX }));
    dispatch(setCurrentSelectedTags(tags));
    dispatch(setQuickEditSelectedListing(listingData));
    dispatch(setSelectedQuickEditItemType(InventoryQuickEditPopoverEnum.TAGS));
    dispatch(setIsInventoryQuickEditPopoverOpen(true));
  };

  const renderEditIcon = () => (
    <Tooltip title="Edit Tags">
      <Box
        sx={{
          width: '24px',
          height: '24px',
          padding: '0px',
          position: 'absolute',
          right: '0',
          zIndex: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          backgroundColor: '#ECEDEF',
          ':hover': {
            backgroundColor: '#DFE1E6',
          },
        }}
      >
        <IconButton disableRipple sx={{}} onClick={handleEdit}>
          <Avatar src="/icons/editIcon.svg" sx={{ width: 16, height: 16 }} />
        </IconButton>
      </Box>
    </Tooltip>
  );

  const shouldAdjustDensePadding = dense && tags && tags.length > 1;

  return (
    <Stack
      width="100%"
      onMouseEnter={() => setShowEditIcon(true)}
      onMouseLeave={() => setShowEditIcon(false)}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
        textAlign: 'center',
        padding: '4px',
        position: 'relative',
      }}
    >
      <Stack
        spacing={dense ? 0.25 : 0.5}
        sx={{
          py: shouldAdjustDensePadding ? '4px' : 0,
          mr: '4px',
          display: 'flex',
        }}
      >
        {tags ? (
          tags.map((item: IInventoryTag, index) => (
            <Label
              key={index}
              variant="soft"
              color={
                // Simplify color lookup by finding matching color rule by color name and returning color code
                (colorRules.find((rule) => rule.colorName === item.color)
                  ?.colorCode as LabelColor) || 'default'
              }
              sx={{
                textTransform: 'none',
                px: 0.5,
                py: 0.25,
                maxWidth: '120px',
                width: 'min-content',
              }}
            >
              <OverflowTypography
                sx={{
                  textTransform: 'none',
                  fontSize: '0.75rem',
                  fontWeight: 700,
                }}
              >
                {item.name}
              </OverflowTypography>
            </Label>
          ))
        ) : (
          // Use empty string as placeholder for missing tags
          <Typography variant="body2">{''}</Typography>
        )}
      </Stack>
      {showEditIcon && renderEditIcon()}
      <style
        dangerouslySetInnerHTML={{
          __html: `
        .datagrid-cell-tags {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      `,
        }}
      />
    </Stack>
  );
}
