import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { IRootState } from 'src/store';

import {
  setIsRelistHourDialogOpen,
  setRelistHourDialogError,
  updateAutomationSettingsRequest,
} from 'src/store/automations/slices/automationsSlice';
import { LoadingButton } from '@mui/lab';

import { Automation } from '@beta.limited/primelister';
import { useEffect, useState } from 'react';

import { IDataForUpdateAutomationSettings } from '../../types/types';
import { formatTimeZone, getHourRange } from '../../helpers/hourHelpers';

export default function RelistHourDialog() {
  const dispatch = useDispatch();
  //Store states
  const { isRelistHourDialogOpen, selectedRelistHourData, loading, relistHourDialogError } =
    useSelector((state: IRootState) => state.automations);
  const { userAccountDetail } = useSelector((state: IRootState) => state.account);
  const { activeClosetCredentialsId } = useSelector((state: IRootState) => state.myCloset);
  const { activeClosetAutomationPlatform, selectedAutomationOption } = useSelector(
    (state: IRootState) => state.automations
  );

  const [hourEnabled, setHourEnabled] = useState(selectedRelistHourData?.enabled);

  //Change handlers

  //Dialog Closer
  const handleClose = () => {
    dispatch(setRelistHourDialogError(''));
    dispatch(
      setIsRelistHourDialogOpen({
        isOpen: false,
        relistingHourDialogData: selectedRelistHourData,
      })
    );
  };
  // Request handler
  const handleSaveChanges = () => {
    const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
    const dataForUpdating: IDataForUpdateAutomationSettings = {
      settings: {
        [Automation.AUTO_RELIST]: {
          config: {
            taskHours: [
              {
                timeOfDay: selectedRelistHourData?.timeOfDay,
                enabled: !hourEnabled,
              },
            ],
          },
        },
      },
      toastMessage,
      displayName: selectedAutomationOption.displayName,
      activeClosetAutomationPlatform,
      activeClosetCredentialsId,
      isFromRelistDialog: true,
    };
    dispatch(updateAutomationSettingsRequest(dataForUpdating));
  };

  //Update itself for different hour ranges
  useEffect(() => {
    setHourEnabled(selectedRelistHourData?.enabled);
  }, [selectedRelistHourData]);

  const hourRange = getHourRange(selectedRelistHourData?.timeOfDay || '1 AM');

  //Button disable conditions

  const hasError = !!relistHourDialogError;

  const textStyle = {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: '400',
    color: '#212B36',
  };

  const renderEnableHourContent = () => {
    const action = hourEnabled ? 'disable' : 'enable';
    const status = hourEnabled ? 'disabled' : 'enabled';
    const timeZoneFormatted = formatTimeZone(userAccountDetail.timeZone.gmt);

    return (
      <Stack direction="row" alignItems="center" justifyContent={'space-between'}>
        <Typography sx={textStyle}>
          Re-list automation will be {status} for <b>{hourRange} </b>
          (GMT {timeZoneFormatted}). Are you sure you want to {action} it?
        </Typography>
      </Stack>
    );
  };

  const renderAPIAlert = () => (
    <Alert
      severity="error"
      variant="standard"
      sx={{ mt: '24px !important', width: '100%', color: '#7A4100' }}
    >
      {relistHourDialogError}
    </Alert>
  );

  const dialogTitle = hourEnabled ? 'Disable Re-listing Hour' : 'Enable Re-listing Hour';
  const actionButtonText = hourEnabled ? 'Disable' : 'Enable';

  const CancelButton = () => (
    <Button variant={hourEnabled ? 'outlined' : 'text'} color="inherit" onClick={handleClose}>
      Cancel
    </Button>
  );

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '448px',
            maxHeight: !hasError ? '198.5px' : 'auto',
          },
        },
      }}
      open={isRelistHourDialogOpen}
      onClose={handleClose}
    >
      <DialogTitle sx={{ pb: '24px' }}>{dialogTitle}</DialogTitle>
      <DialogContent
        sx={{
          overflow: 'hidden',
        }}
      >
        {renderEnableHourContent()}
        {hasError && renderAPIAlert()}
      </DialogContent>
      <DialogActions>
        {!hourEnabled && <CancelButton />}
        <LoadingButton
          disabled={hasError}
          loading={loading}
          variant="contained"
          color={hourEnabled ? 'error' : 'primary'}
          onClick={handleSaveChanges}
          sx={{
            transition: 'none !important',
          }}
        >
          {actionButtonText}
        </LoadingButton>
        {hourEnabled && <CancelButton />}
      </DialogActions>
    </Dialog>
  );
}
