import { GridFilterItem, GridFilterModel } from '@mui/x-data-grid-pro';
export const areBothFilled = (item: any) =>
  item.value[0] !== '' &&
  item.value[0] !== undefined &&
  item.value[0] !== null &&
  item.value[1] !== '' &&
  item.value[1] !== undefined &&
  item.value[1] !== null;

export const dataGridFilterControlsForRequest = (model: GridFilterModel) => {
  const areBothEmpty = (item: any) =>
    (item.value[0] === '' || item.value[0] === undefined || item.value[0] === null) &&
    (item.value[1] === '' || item.value[1] === undefined || item.value[1] === null);

  const betweenValuesController =
    model.items &&
    model.items.every((item) => {
      if (item.operatorValue?.toLowerCase() === 'between') {
        const checkBothValues = areBothEmpty(item) || areBothFilled(item);
        return checkBothValues;
      } else {
        return true;
      }
    });

  const checkEveryFilterItem = () =>
    model.items.every((item) => {
      // Check if the item's "operatorValue" is "isEmpty" or "isNotEmpty"
      if (item.operatorValue === 'isEmpty' || item.operatorValue === 'isNotEmpty') {
        // Return true if the item's "value" is undefined
        return item.value === undefined;
      } else {
        // Return true if the item's "value" is not undefined
        return item.value !== undefined;
      }
    });
  return checkEveryFilterItem() && betweenValuesController;
};

export const queryFilterItemControl = (item: GridFilterItem) =>
  (item.operatorValue === 'between' && areBothFilled(item)) ||
  (item.operatorValue !== 'between' &&
    item.value &&
    item.value.length !== 0 &&
    item.value !== null &&
    item.value !== undefined) ||
  ((item.operatorValue === 'isEmpty' || item.operatorValue === 'isNotEmpty') &&
    item.value === undefined);
