import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { crosslistEndpoints } from 'src/endpoints';
import { shapeListingsForImportCheck } from 'src/pages/dashboard/Inventory/helpers/helpersForImportStatusCheck';

import axiosInstance from 'src/utils/axios';
import {
  importGetInitialStatusRequest,
  resetImportDialogState,
  setHasMoreImportableListings,
  setImportLoading,
  setImportNextPageID,
  setListingsForImport,
} from '../slices/importTableSlice';

import {
  //--All shops
  getAllConnectedApiShopsFail,
  getAllConnectedApiShopsRequest,
  getAllConnectedApiShopsSuccess,
  //--Shopify
  shopifyAuthFail,
  shopifyAuthRequest,
  shopifyAuthSuccess,
  shopifyCreateCredentialSuccess,
  shopifyCreateCredentialFail,
  shopifyCreateCredentialRequest,
  getShopifyProductsSuccess,
  getShopifyProductsFail,
  getShopifyProductsRequest,
  getShopifyProductDetailSuccess,
  getShopifyProductDetailFail,
  getShopifyProductDetailRequest,

  //--Ebay
  ebayAuthFail,
  ebayAuthRequest,
  ebayAuthSuccess,
  ebayCreateCredentialRequest,
  ebayCreateCredentialSuccess,
  ebayCreateCredentialFail,
  getEbayProductsRequest,
  getEbayProductDetailRequest,
  getEbayProductsSuccess,
  getEbayProductsFail,
  getEbayProductDetailSuccess,
  getEbayProductDetailFail,
  IAPIMarketplaces,
} from '../slices/myShopsAPITableSlice';
import { ConnectionStatus } from 'src/store/automations/slices/myClosetSlice';
import { setOpenAPIConnectionRequiredDialog } from '../slices/inventorySlice';
import { Marketplace } from '@beta.limited/primelister';

//--SHOPIFY
function* shopifyAuthSaga(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(crosslistEndpoints.myshops.shopify.GET_SHOPIFY_API_AUTH(), {
        params: {
          shop: action.payload,
        },
      })
    );
    window.open(response.data.data, '_self');
    yield put(shopifyAuthSuccess());
  } catch (error) {
    yield put(shopifyAuthFail(error.message));
  }
}

function* ebayAuthSaga(action: PayloadAction<{ country: string }>) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(crosslistEndpoints.myshops.ebay.GET_EBAY_API_AUTH(), {
        params: {
          country: action.payload,
        },
      })
    );

    window.open(response.data.data, '_self');
    yield put(ebayAuthSuccess());
  } catch (error) {
    yield put(ebayAuthFail(error.message));
  }
}

function* ebayCreateCredentialsSaga(action: PayloadAction<{ code: string }>) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.post(
        crosslistEndpoints.myshops.ebay.GET_EBAY_API_CREATE_CREDENTIALS(),
        action.payload
      )
    );

    yield put(ebayCreateCredentialSuccess(response.data.data));
    if (response.data.data) {
      const { connectionStatus } = response.data.data;

      if (connectionStatus === ConnectionStatus.Connected) {
        yield put(getAllConnectedApiShopsRequest());
      }
    }
  } catch (error) {
    yield put(ebayCreateCredentialFail(error.message));
  }
}

function* shopifyCreateCredentialsSaga(action: PayloadAction<{ shop: string; code: string }>) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.post(
        crosslistEndpoints.myshops.shopify.GET_SHOPIFY_API_CREATE_CREDENTIALS(),
        action.payload
      )
    );
    yield put(shopifyCreateCredentialSuccess(response.data.data));
  } catch (error) {
    yield put(shopifyCreateCredentialFail(error.message));
  }
}

function* getShopifyProductsSaga(action: PayloadAction<{ limit: number; nextPage?: string }>) {
  try {
    yield put(setImportLoading(true));
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(crosslistEndpoints.myshops.shopify.GET_SHOPIFY_API_PRODUCTS(), {
        params: {
          ...action.payload,
        },
      })
    );
    const listingsToBeCheckedForImport = shapeListingsForImportCheck(
      response.data.listings,
      Marketplace.SHOPIFY
    );

    yield put(setListingsForImport(response.data.listings));
    yield put(importGetInitialStatusRequest(listingsToBeCheckedForImport));
    yield put(getShopifyProductsSuccess(response.data));

    if (response.data.nextPage) {
      yield put(setImportNextPageID(response.data.nextPage));
      yield put(setHasMoreImportableListings(true));
    } else {
      yield put(setHasMoreImportableListings(false));
    }
    yield put(setImportLoading(false));
  } catch (error) {
    yield put(setImportLoading(false));
    yield put(getShopifyProductsFail(error.message));
  }
}

function* getShopifyProductDetailSaga(action: PayloadAction<{ id: string }>) {
  try {
    yield call(() =>
      axiosInstance.get(
        crosslistEndpoints.myshops.shopify.GET_SHOPIFY_API_PRODUCT_DETAILS(action.payload.id)
      )
    );
    yield put(getShopifyProductDetailSuccess());
  } catch (error) {
    yield put(getShopifyProductDetailFail(error.message));
  }
}

function* getEbayProductsSaga(
  action: PayloadAction<{
    limit: number;
    nextPage?: string;
    platformName: string;
    importSearchText: string;
  }>
) {
  try {
    yield put(setImportLoading(true));

    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(
        crosslistEndpoints.myshops.ebay.GET_EBAY_API_PRODUCTS(
          action.payload.nextPage ?? 1,
          encodeURIComponent(action.payload.importSearchText) ?? ''
        )
      )
    );

    const listingsToBeCheckedForImport = shapeListingsForImportCheck(
      response.data.listings,
      action.payload.platformName
    );

    yield put(setListingsForImport(response.data.listings));
    yield put(importGetInitialStatusRequest(listingsToBeCheckedForImport));
    yield put(
      getEbayProductsSuccess({
        ...response.data,
        platformName: action.payload.platformName as unknown as IAPIMarketplaces,
      })
    );

    if (response.data.nextPage) {
      yield put(setImportNextPageID(response.data.nextPage));
      yield put(setHasMoreImportableListings(true));
    } else {
      yield put(setHasMoreImportableListings(false));
    }
    yield put(setImportLoading(false));
  } catch (error) {
    yield put(setImportLoading(false));
    if (error.message.toLowerCase().includes('auth tokens')) {
      yield put(resetImportDialogState());
      yield put(setOpenAPIConnectionRequiredDialog(true));
    }
    yield put(
      getEbayProductsFail({
        message: error.message,
        platformName: action.payload.platformName as unknown as IAPIMarketplaces,
      })
    );
  }
}

function* getEbayProductDetailSaga(action: PayloadAction<{ id: string }>) {
  try {
    yield call(() =>
      axiosInstance.get(
        crosslistEndpoints.myshops.ebay.GET_EBAY_API_PRODUCT_DETAILS(action.payload.id)
      )
    );
    yield put(getEbayProductDetailSuccess());
  } catch (error) {
    yield put(getEbayProductDetailFail(error.message));
  }
}
//--FETCH ALL SHOPS INFO OF THE USER
function* getAllConnectedApiShopsSaga(action: PayloadAction<{ id: string }>) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(crosslistEndpoints.myshops.GET_ALL_SHOPS())
    );

    yield put(getAllConnectedApiShopsSuccess(response.data.shops));
  } catch (error) {
    yield put(getAllConnectedApiShopsFail(error.message));
  }
}

export function* myShopsAPITableModuleSaga() {
  yield takeLatest(getAllConnectedApiShopsRequest.type, getAllConnectedApiShopsSaga);
  yield takeLatest(shopifyAuthRequest.type, shopifyAuthSaga);
  yield takeLatest(shopifyCreateCredentialRequest.type, shopifyCreateCredentialsSaga);
  yield takeLatest(getShopifyProductsRequest.type, getShopifyProductsSaga);
  yield takeLatest(getShopifyProductDetailRequest.type, getShopifyProductDetailSaga);
  yield takeLatest(ebayAuthRequest.type, ebayAuthSaga);
  yield takeLatest(ebayCreateCredentialRequest.type, ebayCreateCredentialsSaga);
  yield takeLatest(getEbayProductsRequest.type, getEbayProductsSaga);
  yield takeLatest(getEbayProductDetailRequest.type, getEbayProductDetailSaga);
}
