import { Stack, useMediaQuery } from '@mui/material';

const ShareSettings = () => {
  const matches = useMediaQuery('(min-width:400px)');

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        userSelect: 'none',
        width: matches ? '360px' : '95%',
        height: 'max-content',
        border: '1px dashed rgba(212, 218, 223, 1)',
        borderRadius: '8px',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '14px',
        p: '8px 8px',
        lineHeight: '22px',
        alignSelf: 'center',
      }}
    >
      <p>
        ℹ️
        <br />
        <br /> PrimeLister employs a smart algorithm to maximize visibility and safeguard your
        Poshmark closet by fine-tuning the sharing dynamics like speed and frequency.
      </p>
    </Stack>
  );
};

export default ShareSettings;
