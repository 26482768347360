import { IDateObject } from 'src/store/automations/slices/statisticsSlice';
import { getDateOptionsForStatistics } from '../helpers/getDateOptionsForStatistics';

export const getStatisticsDropDownItems = (timezone: string): IDateObject[] => {
  const { today, yesterday, sevenDaysAgo, fourteenDaysAgo, thirtyDaysAgo } =
    getDateOptionsForStatistics(timezone);

  return [
    {
      buttonText: 'Today',
      dateOptions: {
        startDate: today,
        endDate: today,
      },
      urlParam: 'today',
    },
    {
      buttonText: 'Yesterday',
      dateOptions: {
        startDate: yesterday,
        endDate: yesterday,
      },
      urlParam: 'yesterday',
    },
    {
      buttonText: 'Since Yesterday',
      dateOptions: {
        startDate: yesterday,
        endDate: today,
      },
      urlParam: 'since-yesterday',
    },
    {
      buttonText: 'Last 7 Days',
      dateOptions: {
        startDate: sevenDaysAgo,
        endDate: today,
      },
      urlParam: 'last-7-days',
    },
    {
      buttonText: 'Last 14 Days',
      dateOptions: {
        startDate: fourteenDaysAgo,
        endDate: today,
      },
      urlParam: 'last-14-days',
    },
    {
      buttonText: 'Last 30 Days',
      dateOptions: {
        startDate: thirtyDaysAgo,
        endDate: today,
      },
      urlParam: 'last-30-days',
    },
    {
      buttonText: 'All Time',
      dateOptions: {} as IDateObject['dateOptions'],
      urlParam: 'all-time',
    },
  ];
};
