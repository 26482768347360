import { Typography, Stack, useMediaQuery } from '@mui/material';

const AccountSubscriptionCard = ({
  item,
}: {
  item: { title: string; content: string | number | JSX.Element };
}) => {
  const fontChangeMatches = useMediaQuery('(min-width:500px)');
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ minWidth: fontChangeMatches ? '110px' : '90px' }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            display: 'block',
            color: '#41526e90',
            fontSize: fontChangeMatches ? '14px' : '12px !important',
          }}
        >
          {item.title}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            display: 'block',
            color: '#41526e90',
            fontSize: fontChangeMatches ? '14px' : '12px !important',
          }}
        >
          :
        </Typography>
      </Stack>
      <Typography
        variant="subtitle2"
        sx={{
          display: 'block',
          color: '#41526e90',
          fontSize: fontChangeMatches ? '14px' : '12px !important',
        }}
      >
        {item.content}
      </Typography>
    </Stack>
  );
};

export default AccountSubscriptionCard;
