import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvent, useEffect } from 'react';
// form
import { useFormik } from 'formik';

// @mui
import { Button, Card, FormControlLabel, Stack, Switch, Typography } from '@mui/material';

import {
  accountGetSubscriptionsRequest,
  accountSubscriptionChangeRequest,
} from 'src/store/dashboard/slices/accountSlice';
import { IRootState } from 'src/store';
import { ACCOUNT_OPTIONS, AFFILIATE_OPTIONS, MOBILE_OPTIONS } from './notificationConstants';

// ----------------------------------------------------------------------

export default function AccountNotifications() {
  const dispatch = useDispatch();
  const { userSubscriptionsList, loading } = useSelector((state: IRootState) => state.account);

  const handleAccountFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      unsubscribedProductTips: !userSubscriptionsList.unsubscribedProductTips,
      unsubscribedErrorNotification: !userSubscriptionsList.unsubscribedErrorNotification,
      unsubscribedScheduleReminder: !userSubscriptionsList.unsubscribedScheduleReminder,
      unsubscribedMissedTasks: !userSubscriptionsList.unsubscribedMissedTasks,
      unsubscribedNewRelease: !userSubscriptionsList.unsubscribedNewRelease,
      unsubscribedAffiliateNewPayment: !userSubscriptionsList.unsubscribedAffiliateNewPayment,
      unsubscribedAffiliateSubPayment: !userSubscriptionsList.unsubscribedAffiliateSubPayment,
      unsubscribedAffiliateRefund: !userSubscriptionsList.unsubscribedAffiliateRefund,
      unsubscribedMobileSaleNotification: !userSubscriptionsList.unsubscribedMobileSaleNotification,
    },
    onSubmit: (values) => {},
  });

  const handleSubscriptionChange = (
    e: ChangeEvent<HTMLInputElement>,
    newValue: boolean,
    item: { value: string; label: string }
  ) => {
    handleAccountFormik.handleChange(e);
    dispatch(
      accountSubscriptionChangeRequest({
        name: item.value,
        value: !newValue,
      })
    );
  };

  useEffect(() => {
    dispatch(accountGetSubscriptionsRequest());
  }, []);

  return (
    <form>
      <Card sx={{ p: 3 }}>
        <Typography variant="overline" component="div" sx={{ color: 'text.secondary' }}>
          Account
        </Typography>

        <Stack alignItems="flex-start" sx={{ mt: 2, ml: 1 }}>
          {ACCOUNT_OPTIONS.map((account) => (
            <FormControlLabel
              checked={handleAccountFormik.values[`${account.value}`]}
              key={account.value}
              control={
                <Switch
                  disabled={loading}
                  name={account.value}
                  value={handleAccountFormik.values[`${account.value}`]}
                  onChange={(e, newValue) => handleSubscriptionChange(e, newValue, account)}
                />
              }
              label={account.label}
            />
          ))}
        </Stack>


        <Typography
          variant="overline"
          component="div"
          sx={{ color: 'text.secondary', mt: 5, ml: 1 }}
        >
          Mobile
        </Typography>

        <Stack sx={{ mt: 2, mb: 5, ml: 1 }}>
          {MOBILE_OPTIONS.map((mobile) => (
            <FormControlLabel
              sx={{ width: '100%' }}
              checked={handleAccountFormik.values[`${mobile.value}`]}
              key={mobile.value}
              control={
                <Switch
                  disabled={loading}
                  name={mobile.value}
                  value={handleAccountFormik.values[`${mobile.value}`]}
                  onChange={(e, newValue) => handleSubscriptionChange(e, newValue, mobile)}
                />
              }
              label={mobile.label}
            />
          ))}
        </Stack>

        <Typography
          variant="overline"
          component="div"
          sx={{ color: 'text.secondary', mt: 5, ml: 1 }}
        >
          Affiliate Program{' '}
        </Typography>

        <Stack sx={{ mt: 2, mb: 5, ml: 1 }}>
          {AFFILIATE_OPTIONS.map((affiliate) => (
            <FormControlLabel
              sx={{ width: '100%' }}
              checked={handleAccountFormik.values[`${affiliate.value}`]}
              key={affiliate.value}
              control={
                <Switch
                  disabled={loading}
                  name={affiliate.value}
                  value={handleAccountFormik.values[`${affiliate.value}`]}
                  onChange={(e, newValue) => handleSubscriptionChange(e, newValue, affiliate)}
                />
              }
              label={affiliate.label}
            />
          ))}
        </Stack>

        
      </Card>
    </form>
  );
}
