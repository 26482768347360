import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Stack,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  InputAdornment,
  Switch,
  useMediaQuery,
} from '@mui/material';
import { DesktopDateTimePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import CommonTitleTooltip from './shared/CommonTitleTooltip';
import useResponsive from 'src/hooks/useResponsive';
import CustomDateTimePicker from './shared/CustomDateTimePicker';
import { commonDateTimePickerStyles } from '../CreateNewTaskDialog';
import { getCurrencyInfo } from 'src/pages/automations/AutomationsPage/helpers/getCurrencyInfo';
import { IRootState } from 'src/store';
import { validatePositiveNumber } from '../../helpers/validation';
import { UpdateJobPriceChangeMode, UpdateJobPriceChangeType } from '@beta.limited/primelister';
import ScheduleOption from './shared/ScheduleOption';

interface UpdatePriceDialogContentProps {
  isScheduleEnabled: boolean;
  setIsScheduleEnabled: (value: boolean) => void;
  priceUpdateValue: number | string;
  setPriceUpdateValue: (value: number | string) => void;
  selectedDateTime: Date | null;
  setSelectedDateTime: (date: Date | null) => void;
  isPriceUpdateValueValid: boolean;
  setIsPriceUpdateValueValid: (value: boolean) => void;
  priceUpdateMethod: UpdateJobPriceChangeMode;
  setPriceUpdateMethod: (value: UpdateJobPriceChangeMode) => void;
  priceUpdateType: UpdateJobPriceChangeType; // New prop
  setPriceUpdateType: (value: UpdateJobPriceChangeType) => void; // New prop
}

export default function UpdatePriceDialogContent({
  isScheduleEnabled,
  setIsScheduleEnabled,
  priceUpdateValue,
  setPriceUpdateValue,
  selectedDateTime,
  setSelectedDateTime,
  isPriceUpdateValueValid,
  setIsPriceUpdateValueValid,
  priceUpdateMethod,
  setPriceUpdateMethod,
  priceUpdateType,
  setPriceUpdateType,
}: UpdatePriceDialogContentProps) {
  const dispatch = useDispatch();
  const isMobileScreen = useResponsive('down', 'sm');
  const isSmallerMobileScreen = useMediaQuery('(max-width: 321px)');
  const priceUpdateTypeOptions = [
    {
      label: isSmallerMobileScreen ? 'Drop Price' : 'Drop Price ⬇️',
      value: UpdateJobPriceChangeType.DECREASE,
    },
    {
      label: isSmallerMobileScreen ? 'Increase Price' : 'Increase Price ⬆️',
      value: UpdateJobPriceChangeType.INCREASE,
    },
  ];
  const { activeClosetAutomationPlatform } = useSelector((state: IRootState) => state.automations);
  const { currencySymbol, currency } = getCurrencyInfo(activeClosetAutomationPlatform);

  const priceUpdateMethodOptions = [
    { label: 'Percentage (%)', value: UpdateJobPriceChangeMode.PERCENTAGE },
    {
      label: currency !== 'USD' ? 'Amount (C$)' : 'Amount ($)',
      value: UpdateJobPriceChangeMode.FIXED,
    },
  ];

  const validationSchema = Yup.object().shape({
    priceUpdateValue: Yup.number().when('priceUpdateMethod', {
      is: UpdateJobPriceChangeMode.PERCENTAGE,
      then: Yup.number()
        .min(1, 'Enter a whole number between 1 and 100.')
        .max(100, 'Enter a whole number between 1 and 100.'),
      otherwise: Yup.number()
        .min(1, 'Enter a whole number between 1 and 99999.')
        .max(99999, 'Enter a whole number between 1 and 99999.'),
    }),
  });

  const handlePriceUpdateValueFormik = useFormik({
    initialValues: {
      priceUpdateValue: '',
      priceUpdateMethod,
      priceUpdateType: UpdateJobPriceChangeType.DECREASE,
    },
    validationSchema,
    onSubmit: () => {},
  });

  const getAdornment = () => {
    const symbol =
      handlePriceUpdateValueFormik.values.priceUpdateMethod === UpdateJobPriceChangeMode.PERCENTAGE
        ? '%'
        : currencySymbol;
    const sign =
      handlePriceUpdateValueFormik.values.priceUpdateType === UpdateJobPriceChangeType.DECREASE
        ? '-'
        : '+';
    return `${sign}${symbol}`;
  };

  return (
    <Box
      sx={{
        overflowX: 'hidden',
      }}
    >
      <Stack
        gap={3}
        sx={{
          marginTop: '8px',
        }}
      >
        <Box>
          <Stack
            sx={{
              marginBottom: '8px',
            }}
            direction="row"
            justifyContent="space-between"
          >
            <Typography
              variant="subtitle1"
              noWrap
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '22px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Price Update Type
              <CommonTitleTooltip title="Choose to drop or increase the price of the listings." />
            </Typography>
          </Stack>

          <RadioGroup
            row
            sx={{
              flexWrap: 'nowrap',
            }}
            name="updatePriceType"
            value={handlePriceUpdateValueFormik.values.priceUpdateType}
            onChange={(e) => {
              handlePriceUpdateValueFormik.setFieldValue('priceUpdateType', e.target.value);
              setPriceUpdateType(e.target.value as UpdateJobPriceChangeType);
            }}
          >
            {priceUpdateTypeOptions.map((option) => (
              <FormControlLabel
                key={option.label}
                value={option.value}
                control={<Radio />}
                label={option.label}
                sx={{
                  '& .MuiTypography-root': {
                    whiteSpace: 'nowrap',
                  },
                  '&:nth-of-type(2)': {
                    marginRight: '0',
                  },
                  marginLeft: 0,
                  marginRight: '8px',
                }}
              />
            ))}
          </RadioGroup>
        </Box>

        <Box>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              variant="subtitle1"
              noWrap
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '22px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Price Update Method
              <CommonTitleTooltip title="Choose to update the price by a percentage or a monetary amount." />
            </Typography>
          </Stack>

          <RadioGroup
            row
            name="updatePriceMethod"
            sx={{
              flexWrap: 'nowrap',
            }}
            value={handlePriceUpdateValueFormik.values.priceUpdateMethod}
            onChange={(e) => {
              handlePriceUpdateValueFormik.setFieldValue('priceUpdateMethod', e.target.value);
              setPriceUpdateMethod(e.target.value as UpdateJobPriceChangeMode);
            }}
          >
            {priceUpdateMethodOptions.map((option) => (
              <FormControlLabel
                key={option.label}
                value={option.value}
                control={<Radio />}
                label={option.label}
                sx={{
                  '& .MuiTypography-root': {
                    whiteSpace: 'nowrap',
                  },
                  '&:nth-of-type(1)': {
                    marginRight: !isSmallerMobileScreen ? '16px' : '8px',
                  },
                  '&:nth-of-type(2)': {
                    marginRight: '0',
                  },
                  marginLeft: 0,
                }}
              />
            ))}
          </RadioGroup>
        </Box>

        <Box>
          <Stack>
            <Typography
              variant="subtitle1"
              noWrap
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '22px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Price Update Value
              <CommonTitleTooltip title="Enter the value to drop or increase the listing price." />
            </Typography>

            <TextField
              placeholder="Enter Value"
              size="small"
              name="priceUpdateValue"
              value={handlePriceUpdateValueFormik.values.priceUpdateValue}
              onChange={(e) => {
                const value = e.target.value;
                // Allow only positive numbers
                if (validatePositiveNumber(value)) {
                  handlePriceUpdateValueFormik.handleChange(e);
                  setIsPriceUpdateValueValid(handlePriceUpdateValueFormik.isValid);
                  setPriceUpdateValue(value === '' ? '' : Number(value));
                }
              }}
              onBlur={handlePriceUpdateValueFormik.handleBlur}
              error={Boolean(
                handlePriceUpdateValueFormik.touched.priceUpdateValue &&
                  handlePriceUpdateValueFormik.errors.priceUpdateValue
              )}
              helperText={
                handlePriceUpdateValueFormik.touched.priceUpdateValue &&
                handlePriceUpdateValueFormik.errors.priceUpdateValue
              }
              FormHelperTextProps={{
                sx: {
                  whiteSpace: 'nowrap',
                },
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">{getAdornment()}</InputAdornment>,
              }}
              sx={{
                maxWidth: '164px',
                mt: '8px',
                height: '40px',
                '& .MuiInputBase-input::placeholder': {
                  fontSize: '14px',
                },
              }}
            />
          </Stack>
        </Box>

        <Typography
          noWrap
          sx={{
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: '26px',
            marginTop: '8px',
            marginBottom: '-8px',
          }}
        >
          Options
        </Typography>
        <ScheduleOption
          isScheduleEnabled={isScheduleEnabled}
          setIsScheduleEnabled={setIsScheduleEnabled}
          selectedDateTime={selectedDateTime}
          setSelectedDateTime={setSelectedDateTime}
          tooltipTitle="Select a date and time to schedule the update of listing prices."
        />
      </Stack>
    </Box>
  );
}
