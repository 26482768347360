import { Dispatch } from '@reduxjs/toolkit';
import { resetActivityState } from 'src/store/automations/slices/activitySlice';
import { resetAutomationsState } from 'src/store/automations/slices/automationsSlice';
import { resetMyClosetState } from 'src/store/automations/slices/myClosetSlice';
import { resetMyListingsState } from 'src/store/automations/slices/myListingsSlice';
import { resetStatisticsState } from 'src/store/automations/slices/statisticsSlice';
import { resetAccountState } from 'src/store/dashboard/slices/accountSlice';
import { resetGroupsAndTagsState } from 'src/store/dashboard/slices/groupsAndTagsSlice';
import { resetHomeState } from 'src/store/dashboard/slices/homeSlice';
import { resetImportTableState } from 'src/store/dashboard/slices/importTableSlice';
import { resetInventoryState } from 'src/store/dashboard/slices/inventorySlice';
import { resetMyShopsAPITableState } from 'src/store/dashboard/slices/myShopsAPITableSlice';
import { resetMyShopsState } from 'src/store/dashboard/slices/myShopsSlice';
import { resetPricingState } from 'src/store/dashboard/slices/pricingSlice';
import { resetTasksState } from 'src/store/dashboard/slices/tasksSlice';

const resetStates = (dispatch: Dispatch) => {
  dispatch(resetAccountState());
  dispatch(resetGroupsAndTagsState());
  dispatch(resetHomeState());
  dispatch(resetImportTableState());
  dispatch(resetInventoryState());
  dispatch(resetMyShopsAPITableState());
  dispatch(resetMyShopsState());
  dispatch(resetPricingState());
  dispatch(resetTasksState());
  dispatch(resetAutomationsState());
  dispatch(resetActivityState());
  dispatch(resetMyClosetState());
  dispatch(resetStatisticsState());
  dispatch(resetMyListingsState());
};

export default resetStates;
