import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetMyListingsPayload } from '../sagas/myListingsSaga';
import { ProductCardProps } from 'src/components/product-card';
import {
  filterOptionsList,
  IFilterOptionItem,
  ISortOptionItem,
  sortOptionsList,
} from 'src/pages/automations/MyListingsPage/utils/constants';
import {
  IPriceUpdateActionJob,
  IRelistJob,
  ISendOfferJob,
} from 'src/pages/automations/MyListingsPage/types/bulk-action';
import listingMapper from 'src/pages/automations/MyListingsPage/helpers/listingMapper';
import { ProductCardPropsApiResponse } from 'src/pages/automations/MyListingsPage/types/listing';
import { NewTaskDialogType } from 'src/pages/automations/MyListingsPage/dialogs/CreateNewTaskDialog';
import { IFilterCategory } from 'src/pages/automations/MyListingsPage/components/shared/types/CategoryTreeView';

export interface IMyListingsState {
  initialLoading: boolean;
  loading: boolean;
  error: string;
  myListings: ProductCardProps[];
  listingsNextPageId: string;
  totalListingCount: number;
  selectedListings: Set<ProductCardProps['id']>;
  isListingBulkActionsMenuOpen: boolean;
  hasActiveSearch: boolean;
  searchQuery: string;
  isSearchLoading: boolean;
  isFetchingMoreListings: boolean;
  selectedFilterOption: IFilterOptionItem;
  selectedSortOption: ISortOptionItem;
  isCreateNewTaskDialogOpen: boolean;
  hasFetchedMoreListings: boolean;
  isPortalOpen: boolean;
  activeNewTaskDialogType: NewTaskDialogType;
  isDateTimePickerMobileOpen: boolean;
  isTaskPending: boolean;
  dateTimePickerHasError: boolean;
  isListingsFilterDrawerOpen: boolean;
  isFiltersBadgeInvisible: boolean;
  filterCategories: IFilterCategory[];
  selectedFilterDepartment: string;
  selectedFilterCategory: string;
  selectedFilterSubcategory: string;
  isCategoriesLoading: boolean;
  loadingNodeId: string;
}

const initialState: IMyListingsState = {
  initialLoading: true,
  loading: false,
  error: '',
  myListings: [],
  listingsNextPageId: '',
  totalListingCount: 0,
  selectedListings: new Set<string>(),
  isListingBulkActionsMenuOpen: false,
  hasActiveSearch: false,
  searchQuery: '',
  isSearchLoading: false,
  isFetchingMoreListings: false,
  selectedFilterOption: filterOptionsList[1],
  selectedSortOption: sortOptionsList[0],
  isCreateNewTaskDialogOpen: false,
  hasFetchedMoreListings: false,
  isPortalOpen: false,
  activeNewTaskDialogType: NewTaskDialogType.NONE,
  isDateTimePickerMobileOpen: false,
  isTaskPending: false,
  dateTimePickerHasError: false,
  isListingsFilterDrawerOpen: false,
  isFiltersBadgeInvisible: true,
  filterCategories: [],
  selectedFilterDepartment: 'all-departments',
  selectedFilterCategory: '',
  selectedFilterSubcategory: '',
  isCategoriesLoading: false,
  loadingNodeId: '',
};

const myListingsSlice = createSlice({
  name: 'myListings',
  initialState,
  reducers: {
    setInitialLoading: (state, action: PayloadAction<boolean>) => {
      state.initialLoading = action.payload;
    },

    setLoadingNodeId: (state, action: PayloadAction<string>) => {
      state.loadingNodeId = action.payload;
    },
    setIsFiltersBadgeInvisible: (state, action: PayloadAction<boolean>) => {
      state.isFiltersBadgeInvisible = action.payload;
    },
    setIsDateTimePickerMobileOpen: (state, action: PayloadAction<boolean>) => {
      state.isDateTimePickerMobileOpen = action.payload;
    },
    setDateTimePickerHasError: (state, action: PayloadAction<boolean>) => {
      state.dateTimePickerHasError = action.payload;
    },
    setActiveNewTaskDialogType: (state, action: PayloadAction<NewTaskDialogType>) => {
      state.activeNewTaskDialogType = action.payload;
    },

    setHasFetchedMoreListings: (state, action: PayloadAction<boolean>) => {
      state.hasFetchedMoreListings = action.payload;
    },

    setIsPortalOpen: (state, action: PayloadAction<boolean>) => {
      state.isPortalOpen = action.payload;
    },

    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    getMyListingsRequest: (state, action: PayloadAction<IGetMyListingsPayload>) => {
      if (action.payload.filters?.maxId) {
        state.isFetchingMoreListings = true;
      } else {
        state.loading = true;
      }
      state.error = '';
    },

    getMyListingsSuccess: (state, action: PayloadAction<ProductCardPropsApiResponse[]>) => {
      state.loading = false;
      state.isFetchingMoreListings = false;
      const mappedPayload = action.payload.map(listingMapper);
      // Get unique listings from the mapped payload
      const existingIds = new Set(state.myListings.map((item) => item.id));
      const newListings = mappedPayload.filter((item) => !existingIds.has(item.id));
      state.myListings = [...state.myListings, ...newListings];
      state.error = '';
    },

    getFilterCategoriesRequest: (
      state,
      action: PayloadAction<{
        credentialsId: string;
        department?: string;
        category?: string;
      }>
    ) => {
      state.isCategoriesLoading = true;
      state.error = '';
    },

    getFilterCategoriesSuccess: (state, action: PayloadAction<IFilterCategory[]>) => {
      state.isCategoriesLoading = false;
      state.error = '';

      state.filterCategories = [
        {
          id: 'all-departments',
          name: 'All Categories',
        },
        ...action.payload,
      ];
    },

    getFilterCategoriesFail: (state, action) => {
      state.isCategoriesLoading = false;
      state.error = action.payload;
    },

    setSelectedFilterDepartment: (state, action: PayloadAction<string>) => {
      state.selectedFilterDepartment = action.payload;
    },

    getMyListingsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    setSelectedFilterCategory: (state, action: PayloadAction<string>) => {
      state.selectedFilterCategory = action.payload;
    },

    setIsCategoriesLoading: (state, action: PayloadAction<boolean>) => {
      state.isCategoriesLoading = action.payload;
    },

    setSelectedFilterSubcategory: (state, action: PayloadAction<string>) => {
      state.selectedFilterSubcategory = action.payload;
    },

    setSelectedListings: (state, action: PayloadAction<Set<ProductCardProps['id']>>) => {
      state.selectedListings = action.payload;
    },

    setListingsNextPageId: (state, action: PayloadAction<string>) => {
      state.listingsNextPageId = action.payload;
    },
    setTotalListingCount: (state, action: PayloadAction<number>) => {
      state.totalListingCount = action.payload;
    },

    setIsListingBulkActionsMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.isListingBulkActionsMenuOpen = action.payload;
    },

    setMyListings: (state, action: PayloadAction<ProductCardProps[]>) => {
      state.myListings = action.payload;
    },

    setHasActiveSearch: (state, action: PayloadAction<boolean>) => {
      state.hasActiveSearch = action.payload;
    },

    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },

    setIsSearchLoading: (state, action: PayloadAction<boolean>) => {
      state.isSearchLoading = action.payload;
    },

    setIsFetchingMoreListings: (state, action: PayloadAction<boolean>) => {
      state.isFetchingMoreListings = action.payload;
    },

    setSelectedFilterOption: (state, action: PayloadAction<IFilterOptionItem>) => {
      state.selectedFilterOption = action.payload;
    },

    setSelectedSortOption: (state, action: PayloadAction<ISortOptionItem>) => {
      state.selectedSortOption = action.payload;
    },

    setIsCreateNewTaskDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isCreateNewTaskDialogOpen = action.payload;
    },

    setResetFiltersAndQueries: (state) => {
      state.selectedFilterOption = filterOptionsList[1];
      state.selectedSortOption = sortOptionsList[0];
      state.searchQuery = '';
      state.hasActiveSearch = false;
    },
    createSendOfferJobRequest: (
      state,
      action: PayloadAction<ISendOfferJob & { isMobile: boolean }>
    ) => {
      state.isTaskPending = true;
      state.error = '';
    },
    createSendOfferJobSuccess: (state) => {
      state.isTaskPending = false;
      state.error = '';
    },
    createSendOfferJobFail: (state, action) => {
      state.isTaskPending = false;
      state.error = action.payload;
    },

    createPriceUpdateJobRequest: (
      state,
      action: PayloadAction<IPriceUpdateActionJob & { isMobile: boolean }>
    ) => {
      state.isTaskPending = true;
      state.error = '';
    },
    createPriceUpdateJobSuccess: (state) => {
      state.isTaskPending = false;
      state.error = '';
    },
    createPriceUpdateJobFail: (state, action) => {
      state.isTaskPending = false;
      state.error = action.payload;
    },

    createRelistJobRequest: (state, action: PayloadAction<IRelistJob & { isMobile: boolean }>) => {
      state.isTaskPending = true;
      state.error = '';
    },
    createRelistJobSuccess: (state) => {
      state.isTaskPending = false;
      state.error = '';
    },
    createRelistJobFail: (state, action) => {
      state.isTaskPending = false;
      state.error = action.payload;
    },

    setIsListingsFilterDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isListingsFilterDrawerOpen = action.payload;
    },
    //--
    resetMyListingsState: () => initialState,
  },
});

export const {
  setLoadingNodeId,
  setIsLoading,
  setIsCategoriesLoading,
  setSelectedFilterDepartment,
  getFilterCategoriesRequest,
  getFilterCategoriesSuccess,
  getFilterCategoriesFail,
  setSelectedFilterCategory,
  setSelectedFilterSubcategory,
  setIsFiltersBadgeInvisible,
  setIsListingsFilterDrawerOpen,
  setDateTimePickerHasError,
  setActiveNewTaskDialogType,
  setHasFetchedMoreListings,
  createRelistJobRequest,
  createRelistJobSuccess,
  createRelistJobFail,
  createPriceUpdateJobFail,
  createPriceUpdateJobRequest,
  createPriceUpdateJobSuccess,
  createSendOfferJobFail,
  createSendOfferJobRequest,
  createSendOfferJobSuccess,
  getMyListingsFail,
  getMyListingsRequest,
  getMyListingsSuccess,
  resetMyListingsState,
  setInitialLoading,
  setListingsNextPageId,
  setTotalListingCount,
  setSelectedListings,
  setIsListingBulkActionsMenuOpen,
  setMyListings,
  setHasActiveSearch,
  setSearchQuery,
  setIsSearchLoading,
  setIsFetchingMoreListings,
  setSelectedFilterOption,
  setSelectedSortOption,
  setResetFiltersAndQueries,
  setIsCreateNewTaskDialogOpen,
  setIsPortalOpen,
  setIsDateTimePickerMobileOpen,
} = myListingsSlice.actions;

export default myListingsSlice.reducer;
