import ListingItemEditForm from './components/ListingItemEditForm';
import { Container, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { inventoryGetListingDetailsToEditRequest } from 'src/store/dashboard/slices/inventorySlice';
import { getGroupsRequest, getTagsRequest } from 'src/store/dashboard/slices/groupsAndTagsSlice';
import PlatfromLinksContainer from './components/PlatformLinksContainer';
import EditPageDialogs from '../../components/dialogs/edit/EditPageDialogs';

function EditPage() {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(inventoryGetListingDetailsToEditRequest(id));
      dispatch(getTagsRequest());
      dispatch(getGroupsRequest());
    }
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Edit Item | PrimeLister</title>
      </Helmet>
      <Container maxWidth={'lg'} disableGutters={true}>
        <ListingItemEditForm />
        <Stack direction="row" sx={{ display: 'flex', justifyContent: 'flex-start', my: '2.5rem' }}>
          <Typography variant="h6">Listing Links</Typography>
        </Stack>
        <PlatfromLinksContainer />
      </Container>
      <EditPageDialogs />
    </>
  );
}

export default EditPage;
