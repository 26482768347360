// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
  signup: '/sign-up',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
};

export const PATH_DASHBOARD = {
  root: '/home',
  groupsAndTags: '/groups-and-tags',
  user: {
    root: '/user',
  },
  tasks: '/tasks',
  subscription: {
    root: '/subscription',
    crosslist: '/subscription/crosslist',
    poshmarkAutomation: '/subscription/poshmark-automation',
    paymentSummary: '/subscription/payment-summary',
  },
  inventory: {
    root: '/inventory',
    edit: '/inventory/:id',
  },
  myShops: '/my-shops',
};

export const PATH_AUTOMATIONS = {
  root: '/automations',
  automations: '/automations/poshmark',
  account: '/automations/user',
  activity: '/automations/activity',
  myCloset: '/automations/my-closet',
  statistics: '/automations/statistics',
  listings: '/automations/my-listings',
};
