import { ProductCardProps } from 'src/components/product-card';
import { ProductCardPropsApiResponse } from 'src/pages/automations/MyListingsPage/types/listing';

const listingMapper = (listing: ProductCardPropsApiResponse): ProductCardProps => ({
  ...listing,
  statusTagVisibility: !!listing.statusLabel,
  conditionChipVisibility: !!listing.conditionChip,
  shippingChipVisibility: !!listing.shippingType,
  // This is a fix for poshmark bug where the price can be negative
  originalPriceVisibility: !!listing.originalPrice && Number(listing.originalPrice) > 0,
  likeCountVisibility: !!listing.likeCount,
  commentCountVisibility: !!listing.commentCount,
  // This is a fix for poshmark bug where the price can be negative
  price: Number(listing.price) < 0 ? '0' : listing.price.toString(),
  originalPrice: listing.originalPrice.toString(),
});

export default listingMapper;
