import { Id, toast, TypeOptions } from 'react-toastify';

export interface IToastUpdateParams {
  message: string;
  type?: TypeOptions;
  isLoading?: boolean;
  time?: number;
  closeClick?: boolean;
  customToastId?: number | string;
}
export const toastTracker = (toastId: Id) => {
  const toastUpdate = ({
    message,
    type,
    isLoading,
    time,
    closeClick,
    customToastId,
  }: IToastUpdateParams) => {
    toast.update(toastId, {
      render: message,
      type: type || 'default',
      isLoading: isLoading || false,
      autoClose: 60000,
      closeButton: closeClick || false,
      toastId: customToastId,
    });
  };
  return { toastUpdate };
};
