import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IFilterCategory } from 'src/pages/automations/MyListingsPage/components/shared/types/CategoryTreeView';
import { IRootState } from 'src/store';
import {
  getFilterCategoriesRequest,
  setLoadingNodeId,
  setSelectedFilterCategory,
  setSelectedFilterDepartment,
  setSelectedFilterSubcategory,
} from 'src/store/automations/slices/myListingsSlice';

/**
 * Custom hook to manage tree view operations.
 */
export const useTreeView = () => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const dispatch = useDispatch();
  const {
    filterCategories,
    selectedFilterDepartment,
    selectedFilterCategory,
    selectedFilterSubcategory,
  } = useSelector((state: IRootState) => state.myListings);
  const { activeClosetCredentialsId } = useSelector((state: IRootState) => state.myCloset);
  /**
   * Generates a node ID based on the node ID, parent IDs, and department.
   * @param nodeId - The ID of the node.
   * @param parentIds - The IDs of the parent nodes.
   * @param department - The department of the node.
   * @returns The generated node ID.
   */
  const generateNodeId = (nodeId: string, parentIds: string[], department: string): string => {
    const parentPart = parentIds.length > 0 ? `${parentIds.join('_')}_` : '';
    return `${department}_${parentPart}${nodeId}`;
  };

  /**
   * Checks if a node is selected.
   * @param params - The parameters object.
   * @param params.nodeId - The ID of the node.
   * @param params.nodeIdAlternative - The alternative ID of the node.
   * @param params.selectedIds - The IDs of the selected nodes.
   * @param params.parentIds - The IDs of the parent nodes.
   * @returns True if the node is selected, false otherwise.
   */
  const isNodeSelected = ({
    nodeId,
    nodeIdAlternative,
    selectedIds,
    parentIds,
  }: {
    nodeId: string;
    nodeIdAlternative: string;
    selectedIds: string[];
    parentIds: string[];
  }): boolean => {
    const isSelected = selectedIds.includes(nodeId) || selectedIds.includes(nodeIdAlternative);
    const isDepartment = selectedFilterDepartment === nodeId;
    const isCategory =
      selectedFilterCategory === nodeId && parentIds.includes(selectedFilterDepartment);
    const isSubcategory =
      selectedFilterSubcategory === nodeId && parentIds.includes(selectedFilterCategory);
    const isAllDepartments =
      selectedFilterDepartment === 'all-departments' && nodeIdAlternative === 'all-departments';

    return isSelected || isDepartment || isCategory || isSubcategory || isAllDepartments;
  };

  /**
   * Finds the initial selected IDs based on the selected department, category, and subcategory.
   * @param categories - The list of categories.
   * @param selectedDepartment - The selected department.
   * @param selectedCategory - The selected category.
   * @param selectedSubcategory - The selected subcategory.
   * @returns The list of initial selected IDs.
   */
  const findInitialSelectedIds = (
    categories: IFilterCategory[],
    selectedDepartment: string,
    selectedCategory: string,
    selectedSubcategory: string
  ): string[] => {
    const selectedIds: string[] = [];
    const findSelectedIds = (nodes: IFilterCategory[], parentId: string | null = null) => {
      nodes.forEach((node) => {
        if (node.id === selectedDepartment) {
          selectedIds.push(node.id);
          if (selectedCategory && node.children) {
            const category = node.children.find((cat) => cat.id === selectedCategory);
            if (category) {
              selectedIds.push(category.id);
              if (selectedSubcategory && category.children) {
                const subcategory = category.children.find((sub) => sub.id === selectedSubcategory);
                if (subcategory) {
                  selectedIds.push(subcategory.id);
                }
              }
            }
          }
        } else if (node.children) {
          findSelectedIds(node.children, node.id);
        }
      });
    };

    findSelectedIds(categories);
    return selectedIds;
  };

  /**
   * Handles the selection logic based on the node's parent IDs.
   * @param id - The ID of the node.
   * @param parentIds - The IDs of the parent nodes.
   * @param onSelect - Callback function to handle selection.
   * @returns The list of new selected IDs.
   */
  const handleSelectionLogic = (
    id: string,
    parentIds: string[],
    onSelect: (department: string, category: string, subcategory: string) => void
  ): string[] => {
    let newSelectedIds: string[] = [];

    switch (parentIds.length) {
      case 0:
        // Department selected
        newSelectedIds = [id];
        dispatch(setSelectedFilterDepartment(id));
        dispatch(setSelectedFilterCategory(''));
        dispatch(setSelectedFilterSubcategory(''));
        dispatch(
          getFilterCategoriesRequest({ credentialsId: activeClosetCredentialsId, department: id })
        );
        onSelect(id, '', '');
        break;
      case 1:
        // Category selected
        const departmentId = parentIds[0];
        newSelectedIds = [id, departmentId];
        dispatch(setSelectedFilterDepartment(departmentId));
        dispatch(setSelectedFilterCategory(id));
        dispatch(setSelectedFilterSubcategory(''));
        dispatch(
          getFilterCategoriesRequest({
            credentialsId: activeClosetCredentialsId,
            department: departmentId,
            category: id,
          })
        );
        onSelect(departmentId, id, '');
        break;
      case 2:
        // Subcategory selected
        const deptId = parentIds[0];
        const categoryId = parentIds[1];
        newSelectedIds = [id, categoryId, deptId];
        dispatch(setSelectedFilterDepartment(deptId));
        dispatch(setSelectedFilterCategory(categoryId));
        dispatch(setSelectedFilterSubcategory(id));
        onSelect(deptId, categoryId, id);
        dispatch(setLoadingNodeId('')); // Clear loadingNodeId for subcategory
        break;
      default:
        break;
    }

    return newSelectedIds;
  };

  return {
    selectedIds,
    setSelectedIds,
    findInitialSelectedIds,
    generateNodeId,
    isNodeSelected,
    handleSelectionLogic,
  };
};
