import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { IRootState } from 'src/store';

import { useNavigate } from 'react-router';
import { setIsConnectRemovedClosetDialogOpen } from 'src/store/automations/slices/myClosetSlice';

const ConnectRemovedClosetDialog = () => {
  const dispatch = useDispatch();

  const { isConnectRemovedClosetDialogOpen } = useSelector((state: IRootState) => state.myCloset);

  const handleClose = () => dispatch(setIsConnectRemovedClosetDialogOpen(false));

  const handleOpenChat = () => {
    window.$crisp.push(['do', 'chat:open']);
    handleClose();
  };

  const dialogTitle = 'Action Restricted';
  const dialogContent =
    'You tried to reconnect a closet that you previously deleted. The deleted closet can only be reconnected by the support team. Please send a message explaining why you deleted the closet and why you want to reconnect it. You can contact us via the live chat in the lower right, or by emailing support@primelister.com';

  return (
    <Dialog fullWidth maxWidth="sm" open={isConnectRemovedClosetDialogOpen} onClose={handleClose}>
      <DialogTitle sx={{ pb: 3 }}>{dialogTitle}</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>{dialogContent}</DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleOpenChat}>
          Open Chat
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConnectRemovedClosetDialog;
