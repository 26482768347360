import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { extensionActionsEndpoints } from 'src/endpoints';
import axiosInstance, { setHeader } from 'src/utils/axios';
import cookie from 'react-cookies';

const ExtensionUninstallPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const userData = cookie.load('userData') || {};

  const emailParam = searchParams.get('email');

  const handleExtensionUninstall = async () => {
    await axiosInstance.put(extensionActionsEndpoints.UPDATE_INSTALLATION_STATUS(), {
      installationStatus: false,
    });
  };

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      setHeader(userData.accessToken);
      handleExtensionUninstall();
    }
  }, []);

  const tallyFormURL = `https://tally.so/r/mVrZjm?transparentBackground=1&amp;email=${emailParam}`;

  return (
    <>
      <Helmet>
        <title> Sorry to see you go 😞 | PrimeLister</title>
      </Helmet>

      <Stack
        sx={{
          maxWidth: '100%',
          height: '100vh',
          maxHeight: '100vh',
        }}
      >
        <iframe
          src={tallyFormURL}
          style={{
            border: 0,
            marginTop: 0,
            marginRight: 0,
          }}
          width={'100%'}
          height={'100%'}
          title="Uninstall Extension Survey"
        />
      </Stack>
    </>
  );
};

export default ExtensionUninstallPage;
