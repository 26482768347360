import { forwardRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';

// @mui
import { Slide, Dialog, Button, DialogTitle, DialogActions, DialogContent } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import {
  setIsSetDefaultCardDialogOpen,
  updateCardRequest,
} from 'src/store/dashboard/slices/accountSlice';
import { IRootState } from 'src/store';

import { LoadingButton } from '@mui/lab';

// -- Transition effect for Dialog
const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SetDefaultCardDialog = () => {
  const dispatch = useDispatch();

  const { actionsSelectedCard, isSetDefaultCardDialogOpen, loading } = useSelector(
    (state: IRootState) => state.account
  );

  const handleModalClose = () => {
    dispatch(setIsSetDefaultCardDialogOpen(false));
  };

  const handleUpdateCard = () => {
    dispatch(updateCardRequest(actionsSelectedCard.id));
  };

  return (
    <Dialog
      open={isSetDefaultCardDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleModalClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-slide-title">Set Default Credit Card</DialogTitle>

      <DialogContent>
        Would you like to set this card as your default payment method? Your default card will be
        used for all future transactions.
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <Button variant="outlined" color="inherit" onClick={handleModalClose}>
          Cancel
        </Button>

        <LoadingButton
          loading={loading}
          color="primary"
          variant="contained"
          sx={{ textTransform: 'none' }}
          onClick={handleUpdateCard}
        >
          Yes, Make Default
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SetDefaultCardDialog;
