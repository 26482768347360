import React from 'react';
import ConnectClosetDialog from './ConnectClosetDialog';
import ConnectRemovedClosetDialog from './ConnectRemovedClosetDialog';
import ExpiredSubscriptionDialog from './ExpiredSubscriptionDialog';
import RemoveClosetDialog from './RemoveClosetDialog';
import UpgradeSubscriptionDialog from './UpgradeSubscriptionDialog';

const MyClosetDialogs = () => (
  <>
    <ConnectClosetDialog />
    <UpgradeSubscriptionDialog />
    <RemoveClosetDialog />
    <ExpiredSubscriptionDialog />
    <ConnectRemovedClosetDialog />
  </>
);

export default MyClosetDialogs;
