import { LoadingButton } from '@mui/lab';
import { Stack, MenuItem, Avatar, Typography } from '@mui/material';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from 'src/components/iconify';
import MenuPopover from 'src/components/menu-popover';
import { IRootState } from 'src/store';
import {
  getActivityListRequest,
  setInitialLoading,
  setSelectedActivity,
} from 'src/store/automations/slices/activitySlice';
import {
  ACTIVITY_REQUEST_TYPE,
  automationItemsList,
  completedTabItemsList,
} from '../utils/constants';
import { checkValueForRelist } from '../utils/helpers';
import { IActivityItem } from '../utils/types';
import { useLocation } from 'react-router';

const ActivityDropDown = () => {
  const dispatch = useDispatch();
  const { activeClosetAutomationPlatform } = useSelector((state: IRootState) => state.automations);
  const { activeClosetCredentialsId } = useSelector((state: IRootState) => state.myCloset);
  const { selectedActivity, initialLoading } = useSelector((state: IRootState) => state.activity);

  const [openDropdown, setOpenDropdown] = useState<HTMLElement | null>(null);

  const handlePopoverClose = () => setOpenDropdown(null);

  const handleDropdown = (e: React.MouseEvent<HTMLElement>) =>
    setOpenDropdown(e.currentTarget.firstElementChild as HTMLElement);
  const location = useLocation();

  const hashValue = location.hash.substring(1);
  const matchedItem = completedTabItemsList.find((item) => item.urlParam === hashValue);
  if (matchedItem && matchedItem !== selectedActivity) {
    dispatch(setSelectedActivity(matchedItem));
  }

  const handleActivitySelection = async (item: IActivityItem) => {
    const requestValue = checkValueForRelist(item.value);
    handlePopoverClose();
    setTimeout(() => {
      dispatch(setSelectedActivity(item));
      if (activeClosetCredentialsId) {
        dispatch(setInitialLoading(true));
        dispatch(
          getActivityListRequest({
            automation: requestValue,
            activeClosetAutomationPlatform,
            cursor: 0,
            requestType: ACTIVITY_REQUEST_TYPE.newRequest,
            activeClosetCredentialsId,
          })
        );
      }

      window.location.hash = item.urlParam;
    }, 500);
  };

  return (
    <Stack direction="row" spacing={1}>
      <LoadingButton
        loading={initialLoading}
        color="inherit"
        onClick={handleDropdown}
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'left',
          backgroundColor: '#EDEFF2',
          color: 'black',
          p: '4px 8px',
          borderRadius: '8px',
          height: '36px',
          textTransform: 'none',
        }}
      >
        <Avatar
          src={`/icons/activityIcons/${selectedActivity.autoIcon ?? 'allActivities'}.svg`}
          sx={{ fontSize: '1rem', maxWidth: '20px', maxHeight: '20px', mr: 1 }}
        />
        <Typography
          sx={{
            fontSize: '14px !important',
            lineHeight: '24px',
            fontWeight: 700,
          }}
        >
          {selectedActivity.displayName}
        </Typography>
        <Iconify
          icon={openDropdown ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}
          sx={{ ml: '10px' }}
        />
      </LoadingButton>
      <MenuPopover
        open={openDropdown}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        arrow={'top-left'}
        sx={{ minWidth: 212, ml: '-26px !important', mt: '6px !important' }}
        disabledArrow={false}
      >
        {completedTabItemsList.map((item) => (
          <MenuItem
            onClick={() => handleActivitySelection(item)}
            key={item.name}
            sx={{
              fontWeight: '400 !important',
              backgroundColor: item.value === selectedActivity.value ? '#EDEFF2' : 'white',
              p: '6px 8px !important',
              maxHeight: '34px !important',
              minHeight: '34px !important',
            }}
          >
            <Avatar
              src={`/icons/activityIcons/${item.autoIcon}.svg`}
              sx={{
                mr: 1,
                fontSize: '1rem',
                borderRadius: '0',
                maxWidth: '20px',
                maxHeight: '20px',
              }}
            />
            {item.displayName}
          </MenuItem>
        ))}
      </MenuPopover>
    </Stack>
  );
};

export default ActivityDropDown;
