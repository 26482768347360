import { useCallback } from 'react';
import axiosInstance from 'src/utils/axios';
import { crosslistEndpoints } from 'src/endpoints';

export enum PermissionCheckActionEnum { // ListingTaskType for webbackend
  COPY = 'copy',
  CROSSLIST = 'crosslist',
  DELIST = 'delist',
  IMPORT = 'import',
  RELIST = 'relist',
  REMOVE = 'remove',
  //SEND_OFFER = 'sendOffer',
}

// This is for bulk actions menu items
export const permissionCheckActionMapping: Record<number, string> = {
  0: PermissionCheckActionEnum.REMOVE,
  1: PermissionCheckActionEnum.COPY,
  2: PermissionCheckActionEnum.RELIST,
  5: PermissionCheckActionEnum.DELIST,
};

const useCheckPermission = () => {
  const checkPermission = useCallback(
    async (marketplace: string, taskType: PermissionCheckActionEnum) => {
      const result = await axiosInstance.post(crosslistEndpoints.account.CHECK_PERMISSION(), {
        marketplace,
        taskType: taskType,
      });

      return result;
    },
    []
  );

  return { checkPermission };
};

export default useCheckPermission;
