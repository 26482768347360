import { useDispatch, useSelector } from 'react-redux';
import { TableRow, TableCell, IconButton, Avatar } from '@mui/material';

import Iconify from 'src/components/iconify';
import { IRootState } from 'src/store';
import {
  IMyShopsTableRow,
  setIsAPITableActionsMenuOpen,
  setSelectedActionRow,
} from 'src/store/dashboard/slices/myShopsSlice';
import { LoadingButton } from '@mui/lab';

export default function APITableRow({ row }: { row: IMyShopsTableRow }) {
  const dispatch = useDispatch();
  const { isAPITableActionsMenuOpen } = useSelector((state: IRootState) => state.myShops);

  const openActionsMenu = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(
      setIsAPITableActionsMenuOpen(isAPITableActionsMenuOpen === null ? event.currentTarget : null)
    );
    dispatch(setSelectedActionRow(row));
  };
  const { isConnected } = row;

  const renderShopName = isConnected ? row.username : row.shop;

  return (
    <TableRow>
      <TableCell sx={{ display: 'flex', alignItems: 'center', minHeight: '68px !important' }}>
        <Avatar src={row.icon} sx={{ width: 24, height: 24, mr: 1 }} />
        {row.name}
      </TableCell>
      <TableCell align="center">{renderShopName}</TableCell>
      <TableCell align="center">
        <LoadingButton
          loading={row.isLoading} //this will be changed with new integration
          variant="soft"
          color={isConnected ? 'success' : 'error'}
          sx={{ maxHeight: '24px !important', fontSize: '12px !important' }}
        >
          {isConnected ? 'Connected' : 'Unconnected'}
        </LoadingButton>
      </TableCell>

      <TableCell align="center">
        <IconButton
          className="connectToAPIButton"
          color="default"
          onClick={(event) => openActionsMenu(event)}
        >
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
