import Iconify from 'src/components/iconify';

import Label from 'src/components/label/Label';

import { DataGridTypeColumnInterface } from '../../../../types/types';

interface ITaskTypeItem {
  name: string;
  icon: string;
  color: string;
}

export const TASK_TYPE: ITaskTypeItem[] = [
  {
    name: 'Delete',
    icon: 'ant-design:delete-filled',
    color: '#A82E24',
  },
  {
    name: 'Duplicate',
    icon: 'ion:copy',
    color: '#1C388F',
  },
  {
    name: 'Re-list',
    icon: 'el:retweet',
    color: '#1C388F',
  },
  {
    name: 'Cross-list',
    icon: 'ph:arrows-left-right-bold',
    color: '#1C388F',
  },
  {
    name: 'Import',
    icon: 'mingcute:file-import-fill',
    color: '#1C388F',
  },
  {
    name: 'Delist',
    icon: 'mdi:file-remove',
    color: '#A82E24',
  },
  {
    name: 'Offer to Likers',
    icon: 'material-symbols:request-quote',
    color: '#2D6A97',
  },
  {
    name: 'Delete from Inventory',
    icon: 'mdi:close-circle',
    color: '#A82E24',
  },
];

export default function DataGridTypeColumn({ typeName }: DataGridTypeColumnInterface) {
  const adjustedTypeName = typeName === 'Offer' ? 'Offer to Likers' : typeName;
  const item = TASK_TYPE.find((item) => item?.name.toLowerCase() === adjustedTypeName?.toLowerCase());
  return (
    <Label
      variant="soft"
      sx={{
        color: `${item?.color ? item?.color : 'black'}`,
        backgroundColor: `${item?.color ? item?.color : '#000000'}16`,
        textTransform: 'none',
      }}
      startIcon={<Iconify sx={{ minWidth: 16 }} icon={item?.icon!} />}
    >
      {adjustedTypeName ? adjustedTypeName : ''}
    </Label>
  );
}
