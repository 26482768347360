import { Stack, useMediaQuery } from '@mui/material';

const ReturnFollowSettings = () => {
  const matches = useMediaQuery('(min-width:400px)');
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        userSelect: 'none',
        width: matches ? '360px' : '95%',
        height: matches ? '108px' : 'max-content',
        border: '1px dashed rgba(212, 218, 223, 1)',
        borderRadius: '8px',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '14px',
        p: '10px 14px',
        lineHeight: '22px',
        alignSelf: 'center',
      }}
    >
      <p>
        ℹ️ <br /> No advanced configuration needed for{' '}
        <b>
          <i> Return Follow </i>
        </b>{' '}
        automation - just enable and go.
      </p>
    </Stack>
  );
};

export default ReturnFollowSettings;
