import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Stack,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  InputAdornment,
  Switch,
  useMediaQuery,
} from '@mui/material';
import { DesktopDateTimePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import CommonTitleTooltip from './shared/CommonTitleTooltip';
import useResponsive from 'src/hooks/useResponsive';
import { commonDateTimePickerStyles } from '../CreateNewTaskDialog';
import CustomDateTimePicker from './shared/CustomDateTimePicker';
import ScheduleOption from './shared/ScheduleOption';

interface RelistDialogContentProps {
  isScheduleEnabled: boolean;
  setIsScheduleEnabled: (value: boolean) => void;
  selectedDateTime: Date | null;
  setSelectedDateTime: (date: Date | null) => void;
}

export default function RelistDialogContent({
  isScheduleEnabled,
  setIsScheduleEnabled,
  selectedDateTime,
  setSelectedDateTime,
}: RelistDialogContentProps) {

  return (
    <Box sx={{
      overflowX: 'hidden',
    }}>
      <Stack>
        <Typography
          noWrap
          sx={{
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: '26px',
            marginTop: '8px',
            marginBottom: '-8px',
          }}
        >
          Options
        </Typography>
        <ScheduleOption
          isScheduleEnabled={isScheduleEnabled}
          setIsScheduleEnabled={setIsScheduleEnabled}
          selectedDateTime={selectedDateTime}
          setSelectedDateTime={setSelectedDateTime}
          tooltipTitle="Select a date and time to schedule the re-list."
          sx={{
            marginTop: '24px'
          }}
        />
      </Stack>
    </Box>
  );
}
