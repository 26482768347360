import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';

import {
  inventoryRemoveGroupsRequest,
  setIsRemoveGroupsDialogOpen,
} from 'src/store/dashboard/slices/inventorySlice';
import { IRootState } from 'src/store';
import { getFilterModelShaped } from 'src/pages/dashboard/Inventory/helpers/getFilterModelShaped';
import { LoadingButton } from '@mui/lab';

export default function InventoryRemoveGroupsDialog() {
  const dispatch = useDispatch();

  const {
    isRemoveGroupsDialogOpen,
    loading,
    page,
    rowsPerPage,
    sortModel,
    inventoryFilterModel,
    searchValue,
    bulkActionsListingList,
  } = useSelector((state: IRootState) => state.inventory);

  const handleClose = () => {
    dispatch(setIsRemoveGroupsDialogOpen(false));
  };

  const handleRemoveGroups = () => {
    const itemsToDeleteList = bulkActionsListingList.map((listing) => listing._id);

    const orderBy = sortModel.length > 0 ? sortModel[0].field : 'added';
    const order = sortModel.length > 0 ? sortModel[0].sort : 'desc';
    dispatch(
      inventoryRemoveGroupsRequest({
        items: itemsToDeleteList,
        getData: {
          page,
          rowsPerPage,
          orderBy,
          order,
          filter: getFilterModelShaped(inventoryFilterModel),
          searchValue,
        },
      })
    );
  };

  const isRemoveButtonDisabled = false;

  return (
    <Dialog fullWidth maxWidth="xs" open={isRemoveGroupsDialogOpen} onClose={handleClose}>
      <DialogTitle>Remove Groups</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>
        <Stack spacing={2}>
          <Typography variant="body2">
            The groups of your selected listings will be removed. Are you sure you want to remove
            them?
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="error"
          onClick={handleRemoveGroups}
          disabled={isRemoveButtonDisabled}
          sx={{ textTransform: 'none' }}
        >
          Remove
        </LoadingButton>
        <Button disabled={loading} variant="text" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
