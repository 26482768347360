import React, { forwardRef, ForwardedRef, FunctionComponent } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Theme } from '@mui/material';
import { GridComponents as VirtuosoGridComponents } from 'react-virtuoso';

import { useTheme } from '@mui/material/styles';

const List = forwardRef<
  HTMLDivElement,
  { style?: React.CSSProperties; children?: React.ReactNode }
>(({ style, children, ...props }, ref: ForwardedRef<HTMLDivElement>) => {
  const theme = useTheme();
  return (
    <Grid2
      container
      spacing={3}
      sx={{
        margin: '0 !important',
        [theme.breakpoints.down('md')]: {
          justifyContent: 'center',
        },
      }}
      {...props}
      ref={ref}
      style={{
        ...style,
      }}
    >
      {children}
    </Grid2>
  );
});

interface ItemProps {
  children?: React.ReactNode;
  [x: string]: any;
}

const Item: FunctionComponent<ItemProps> = ({ children, ...props }) => (
  <Grid2 xs={12} sm={5} md={4} lg={3} {...props}>
    {children}
  </Grid2>
);

const createGridComponents = (): VirtuosoGridComponents => ({
  List,
  Item,
});

export { List, Item, createGridComponents };
