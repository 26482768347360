export interface IFilterOptionItem {
  value: FilterOptionsEnum;
  name: string;
  displayName: string;
  icon?: string;
}

export interface ISortOptionItem {
  value: SortOptionsEnum;
  name: string;
  displayName: string;
  icon?: string;
}

export enum FilterOptionsEnum {
  ALL = 'all',
  AVAILABLE = 'available',
  COMING_SOON = 'coming_soon',
  SOLD_OUT = 'sold_out',
}

export enum SortOptionsEnum {
  BEST_MATCH = 'best_match',
  ADDED_ASC = 'added_asc',
  ADDED_DESC = 'added_desc',
}

export const filterOptionsList: IFilterOptionItem[] = [
  {
    value: FilterOptionsEnum.ALL,
    name: 'allListings',
    displayName: 'All',
  },
  {
    value: FilterOptionsEnum.AVAILABLE,
    name: 'availableListings',
    displayName: 'Available',
  },
  {
    value: FilterOptionsEnum.COMING_SOON,
    name: 'comingSoonListings',
    displayName: 'Dropping Soon',
  },
  {
    value: FilterOptionsEnum.SOLD_OUT,
    name: 'soldOutListings',
    displayName: 'Sold',
  },
];

export const sortOptionsList: ISortOptionItem[] = [
  {
    value: SortOptionsEnum.BEST_MATCH,
    name: 'justShared',
    displayName: 'Just Shared',
  },
  {
    value: SortOptionsEnum.ADDED_DESC,
    name: 'justIn',
    displayName: 'Just In',
  },
  {
    value: SortOptionsEnum.ADDED_ASC,
    name: 'oldToNew',
    displayName: 'Old To New',
  },
];
