import { useEffect, useState } from 'react';

const useUserFirstName = (username: string) => {
  const [firstName, setFirstName] = useState('');

  useEffect(() => {
    try {
      if (username) {
        const nameParts = username.split(' ');
        if (nameParts.length > 0) {
          const rawFirstName = nameParts[0];
          const capitalizedFirstName =
            rawFirstName.charAt(0).toUpperCase() + rawFirstName.slice(1).toLowerCase();
          setFirstName(capitalizedFirstName);
        } else {
          setFirstName('');
        }
      } else {
        setFirstName('');
      }
    } catch (error) {
      console.error('An error occurred while processing the username:', error);
      setFirstName('');
    }
  }, [username]);

  return firstName;
};

export default useUserFirstName;
