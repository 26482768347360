import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import Iconify from 'src/components/iconify/Iconify';
import OverflowTypography from 'src/components/product-card/OverflowTypography';

interface NoOptionsFoundProps {
  type: 'group' | 'tag';
  inputValue: string;
  onCreateClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const NoOptionsFound: React.FC<NoOptionsFoundProps> = ({
  type,
  inputValue,
  onCreateClick,
  onMouseDown,
}) => (
  <Box sx={{ height: '94px' }} onMouseDown={onMouseDown}>
    <Typography
      sx={{
        color: '#212B36',
        fontSize: '14px',
        lineHeight: '22px',
        fontWeight: 400,
        padding: '6px 8px',
        mt: '4px',
        mb: '4px',
      }}
    >
      No result found
    </Typography>
    <Box
      sx={{
        minWidth: '64px',
        maxWidth: '296px',
        height: '44px',
        borderRadius: '8px',
        backgroundColor: '#FFFFFF',
        padding: '0 8px',
        cursor: 'pointer',
      }}
      onClick={onCreateClick}
    >
      <Paper
        sx={{
          padding: '10px 12px',
          display: 'flex',
          alignItems: 'center',
          height: '44px',
          border: '1px solid rgba(145, 158, 171, 0.32)',
          width: '296px',
        }}
      >
        <Iconify
          icon="mingcute:add-line"
          color={'#212B36'}
          width={20}
          height={20}
          sx={{ mr: '8px' }}
        />
        <Box sx={{ color: '#212B36', fontSize: '14px', lineHeight: '24px', fontWeight: 600 }}>
          Create
        </Box>
        <OverflowTypography
          sx={{
            color: '#212B36',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '24px',
            ml: '8px',
            maxWidth: '180px',
          }}
        >
          {inputValue}
        </OverflowTypography>
      </Paper>
    </Box>
  </Box>
);

export default NoOptionsFound;
