import Image from 'src/components/image';
import { DataGridImageColumnInterface } from '../../../../types/types';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';

export default function DataGridImageColumn({ url }: DataGridImageColumnInterface) {
  const { dense } = useSelector((state: IRootState) => state.tasks);

  let imageStyle = {
    borderRadius: dense ? 0.75 : 1.25,
    width: dense ? 32 : 40,
    minWidth: dense ? 32 : 40,
    height: dense ? 32 : 40,
    backgroundSize: 'cover',
  };

  return (
    <Image
      src={url ? url : 'https://r2.primelister.com/image_thumbnail.svg'}
      disabledEffect
      visibleByDefault
      sx={imageStyle}
    />
  );
}
