import { TaskHourConfig, TimeOfDay } from '@beta.limited/primelister';

export function sortTimeOfDay(taskHourConfigs: TaskHourConfig[] | null | undefined) {
  if (!taskHourConfigs || taskHourConfigs.length === 0) {
    return [];
  }

  const configsCopy = [...taskHourConfigs];
  function convertToSortOrder(time: TimeOfDay) {
    const [hour, period] = time.split(' ');
    let parsedHour = parseInt(hour, 10);

    if (period === 'PM' && time !== TimeOfDay.PM_12) parsedHour += 12;
    else if (time === TimeOfDay.PM_12) parsedHour = 12;
    else if (time === TimeOfDay.AM_12) parsedHour = 0;

    return parsedHour;
  }

  configsCopy.sort((a, b) => {
    const orderA = convertToSortOrder(a.timeOfDay);
    const orderB = convertToSortOrder(b.timeOfDay);

    return orderA - orderB;
  });

  return configsCopy;
}
