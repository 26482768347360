import { useEffect, useState } from 'react';

function useIsDocumentLoaded() {
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(document.readyState === 'complete');

  useEffect(() => {
    const handleReadyStateChange = () => {
      setIsDocumentLoaded(document.readyState === 'complete');
    };

    document.addEventListener('readystatechange', handleReadyStateChange);

    // Cleanup function
    return () => {
      document.removeEventListener('readystatechange', handleReadyStateChange);
    };
  }, []);

  return isDocumentLoaded;
}

export default useIsDocumentLoaded;
