import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  Typography,
  TextField,
} from '@mui/material';

import {
  inventoryAddToGroupRequest,
  setOpenInventoryAddGroupDialog,
  setSelectedGroupToAdd,
} from 'src/store/dashboard/slices/inventorySlice';
import { IRootState } from 'src/store';
import { ChangeEvent } from 'react';
import { getFilterModelShaped } from 'src/pages/dashboard/Inventory/helpers/getFilterModelShaped';
import { LoadingButton } from '@mui/lab';

export default function InventoryAddGroupDialog() {
  const dispatch = useDispatch();

  const {
    openInventoryAddGroupDialog,
    loading,
    page,
    rowsPerPage,
    sortModel,
    inventoryFilterModel,
    searchValue,
    selectedGroupToAdd,
    bulkActionsListingList,
  } = useSelector((state: IRootState) => state.inventory);

  const { groupsData } = useSelector((state: IRootState) => state.groupsAndTags);

  const handleGroupSelect = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(setSelectedGroupToAdd(event.target.value));
  };

  const handleClose = () => {
    dispatch(setOpenInventoryAddGroupDialog(false));
  };

  const bulkItems = bulkActionsListingList.map((listing) => listing._id);

  const handleAddToGroup = () => {
    const orderBy = sortModel.length > 0 ? sortModel[0].field : 'added';
    const order = sortModel.length > 0 ? sortModel[0].sort : 'desc';
    dispatch(
      inventoryAddToGroupRequest({
        groupId: selectedGroupToAdd,
        items: bulkItems,
        groupName: groupsData.find((item) => item.id === selectedGroupToAdd)?.name,

        getData: {
          page,
          rowsPerPage,
          orderBy,
          order,
          filter: getFilterModelShaped(inventoryFilterModel),
          searchValue,
        },
      })
    );
  };

  const renderGroupMenuItem = () =>
    groupsData.map((group) => (
      <MenuItem key={group.id} value={group.id}>
        {group.name}
      </MenuItem>
    ));

  return (
    <Dialog fullWidth maxWidth="xs" open={openInventoryAddGroupDialog} onClose={handleClose}>
      <DialogTitle>Add Listings to Group</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>
        <Stack spacing={2}>
          <Typography variant="body2">
            Please select a group to add the selected listings to.
          </Typography>

          <TextField
            select
            label="Select a group"
            placeholder="Select a group"
            value={selectedGroupToAdd}
            onChange={handleGroupSelect}
          >
            {renderGroupMenuItem()}
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} variant="text" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          onClick={handleAddToGroup}
          disabled={!selectedGroupToAdd}
          sx={{ textTransform: 'none' }}
        >
          Add to Group
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
