import { ProductCardProps, ProductCardStatusLabelEnum } from './types';
import Label from '../label';
import { format } from 'date-fns';
import { getProductCardStatusLabelBgColor, getProductCardStatusLabelColor } from './helpers';

const StatusLabel = ({
  statusLabel,
  droppingSoonDate,
}: {
  statusLabel: ProductCardProps['statusLabel'];
  droppingSoonDate?: ProductCardProps['droppingSoonDate'];
}) => {
  const formattedDate = droppingSoonDate ? format(new Date(droppingSoonDate), 'MMM dd') : '';
  const formattedLabel =
    statusLabel === ProductCardStatusLabelEnum.DROPPING_SOON && droppingSoonDate
      ? `Drops ${formattedDate}`
      : statusLabel;

  if (Object.values(ProductCardStatusLabelEnum).includes(statusLabel)) {
    return (
      <Label
        sx={{
          color: getProductCardStatusLabelColor(statusLabel),
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 700,
          backgroundColor: getProductCardStatusLabelBgColor(statusLabel),
          padding: '2px 8px',
          borderRadius: '6px',
          textAlign: 'center',
          textTransform: 'uppercase',
        }}
      >
        {formattedLabel}
      </Label>
    );
  } else {
    return null;
  }
};

export default StatusLabel;
