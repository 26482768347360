import { Box, useMediaQuery, useTheme } from '@mui/material';
import ListingsFilter from './ListingsFilter';
import ListingsSearch from './ListingsSearch';
import { IRootState } from 'src/store';
import { useSelector } from 'react-redux';
import useResponsive from 'src/hooks/useResponsive';

export default function ListingCardsFilterContainer() {
  const theme = useTheme();
  const { isPortalOpen } = useSelector((state: IRootState) => state.myListings);
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const isMobileWeb = useResponsive('down', 'sm'); // < 600 px
  const isMobile = isMobileWeb || isPrimeListerMobileApp;
  const isSmallerScreen = useMediaQuery('(max-width:375px)');

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: !isPortalOpen ? '100%' : '1056px',
        justifyContent: 'space-between',
        gap: '16px',
        [theme.breakpoints.down('sm')]: {
          whiteSpace: 'nowrap',
          justifyContent: 'space-between',
          alignSelf: 'stretch',
          padding: '6px 16px',
          borderBottom: '1px solid rgba(145, 158, 171, 0.20)',
          boxShadow: '0px 4px 8px 0px rgba(145, 158, 171, 0.16)',
          height: '52px',
        },
      }}
    >
      <ListingsSearch />
      <ListingsFilter />
    </Box>
  );
}
