import { ICouponDetails, ISelectedSubscription } from 'src/store/dashboard/slices/pricingSlice';

export const getPriceCardAmounts = (
  couponDetails: ICouponDetails,
  selectedSubscription: ISelectedSubscription,
  isCouponValid: boolean,
  isYearly: boolean
) => {
  function truncateToDecimals(num: number, dec = 2) {
    if (num % 1 === 0) {
      return Number(num.toFixed(dec));
    } else {
      const calcDec = Math.pow(10, dec);
      return Math.trunc(num * calcDec) / calcDec;
    }
  }

  const { discounts } = couponDetails;
  const { annualPrice, price } = selectedSubscription;

  const discountMultiplier = isYearly && annualPrice ? annualPrice : price;

  const discountAmount = truncateToDecimals(
    isCouponValid && discounts ? discounts[0].value * discountMultiplier : Number(0)
  );

  const totalPrice = truncateToDecimals(
    isCouponValid && discounts ? discountMultiplier - discountAmount : Number(discountMultiplier),
    2
  ).toFixed(2);

  const discountRate =
    isCouponValid && couponDetails.discounts ? couponDetails.coupon.discountPercentage : 0;

  return { discountAmount, totalPrice, discountRate };
};
